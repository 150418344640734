import React, {useEffect, useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Spin
} from "antd";
import { Content } from "antd/lib/layout/layout";
import pagoService from "../../services/pago.service";
import { NumericFormat } from 'react-number-format';
import { useAuth } from '../../context/auth.context';
import moment from 'moment'
import saldoService from "../../services/saldo.service";
import pagofavorService from "../../services/pagofavor.service";

const NewPagoSaldoModal = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  const estudiante_id = props.estudiante_id
  const obligaciones = props.children
  const allObligaciones = props.obligacionesF
  const [loading,setLoading] = useState(false) 
  const [listaDatos,setListaDatos] = useState([]);
  const [saldosFavor,setSaldosFavor] = useState([]);
  const [saldosTotal,setSaldoTotal] = useState(0);
  form.setFieldsValue({fecha:moment().format('YYYY-MM-DD')})


  useEffect(()=>{
    getSaldos()
  },[])
  
  // const obligacion = props.children
  // form.setFieldsValue({concepto: "PAGO DE " + obligacion.concepto + " PAGARE No " +obligacion.codigo_pagare })


  const getSaldos = () =>{
    setLoading(true)
    saldoService.get(estudiante_id).then((response) => {
     
      console.log("SALDOS",response.data)
    setSaldosFavor(response.data[0])
    setSaldoTotal(response.data[1])
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
      setLoading(false);
    });
  }

  const setDataInput = (e,item) =>{
    let data = {codigo_pagare:item,valor:e.target.value}
    let index = listaDatos.findIndex(p => p.codigo_pagare == item);
    if(index == -1){
      listaDatos.push(data)
    } else{
      listaDatos[index].valor = e.target.value
    }
   
   
    console.log(listaDatos)
    // console.log(e,item)
    //  let index = listaDatos.indexOf(item)
   
    //  listaDatos[index].codigo = e.target.value
    //  setListaDatos(listaDatos => [...listaDatos] );
   
  }

  const buscarObligacion = (codigo) =>{
    console.log("CODIGO",codigo)
    console.log("all",allObligaciones)
    let filtro = allObligaciones.filter(item=>item.rta.codigo_pagare == codigo)
    if(filtro.length>0){

      return ` Saldo $ ${filtro[0].saldo}`
    }
  
  }

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
   
    const form = values;
    let totalObligaciones = 0
    let totalValor = parseFloat(form.valor.replace(/,/g, ''));
    console.log("LISTA DATOS",listaDatos)
    if(listaDatos.length>0){
     
    for(let item of listaDatos){
      let valorPago = parseFloat(item.valor.replace(/,/g, ''));
      totalObligaciones += valorPago
    }
  }
  else{
    message.error("Elija las obligaciones a Pagar")
  }

    if(totalObligaciones==totalValor && totalValor <= saldosTotal){
     
   
      form.empresa_id = empresa_id;
      form.estudiante_id = estudiante_id
      //form.concepto = "PAGO DE " + obligacion.concepto 
      form.pagares = listaDatos;
      // form.moneda = obligacion.moneda
      form.usuario_id = usuario_id
      form.estado = 'Generado'
      console.log(form)
      if(listaDatos.length>0 && obligaciones.length == listaDatos.length){
       
        setLoading(true)
        props.updateLoading(true);
        pagofavorService
        .create(form)
        .then((response) => {
          console.log(response.data);
          props.updateLoading(false);
          props.updateVisible(false);
          props.updateListObligaciones();
          props.updatePago(response.data)
          props.updateViewPDF(true)
          setLoading(false)
          message.success("Guardado Correctamente");
        })
        .catch((e) => {
          props.updateLoading(false);
          setLoading(false)
          message.error("No se ha podido guardar");
          console.log(e);
        });
      } else{
        message.error("complete los valores a pagar por cada obligación!")
      }
    } else{
      console.log(totalObligaciones)
      message.error("El valor de las obligaciones/saldo a favor no coincide con el valor a pagar!")
    }
  
    
  };
  return (
    <Modal title='Crear Pago' width={1080} visible={props.visible} onOk={form.submit} onCancel={handleCancel}>

      {loading && (
          <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
      )}

      

      {!loading && (
      <Content>

{saldosTotal && (
                  <div style={{backgroundColor:'#fffbe6', padding:'10px',marginBottom:'5px', border:'1px solid #ffe58f'}}><b> El Estudiante tiene un saldo a favor disponible de:</b> $ <NumericFormat value={saldosTotal} displayType="text" allowLeadingZeros thousandSeparator="," /></div>
                  )}

                  <br />
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>

          <Col span={12}>
              <Form.Item
                label="Fecha de Pago "
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese fecha de pago",
                  },
                ]}
              >
               <Input type="date"></Input>
               
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Valor Total del Pago: "
                name="valor"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Valor del Pago",
                  },
                ]}
              >
                <NumericFormat className="ant-input" thousandSeparator="," decimalScale={3} />
               
              </Form.Item>
            </Col>
            


            {obligaciones.map(item=>{
              return <Form.Item label={"Pagaré "+ item } name={item}>  
                <div>{buscarObligacion(item)}</div>
               <NumericFormat onChange={(e)=>{setDataInput(e,item)}} className="ant-input" placeholder="Ingrese los valores a descargar" thousandSeparator="," decimalScale={3} /></Form.Item>
            })}

            {/* <Col span={24}>
              <Form.Item
                label="Concepto: "
                name="concepto"
                rules={[
                  {
                    required: false,
                    message: "Ingrese Concepto",
                  },
                ]}
              >
               <Input/>
               
              </Form.Item>
            </Col> */}

            <Col span={24}>
              <Form.Item
                label="Observaciones: "
                name="observaciones"
                rules={[
                  {
                    required: false,
                    message: "Ingrese observaciones",
                  },
                ]}
              >
               <TextArea></TextArea>
               
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
      )}
    </Modal>
  );
};

export default NewPagoSaldoModal;
