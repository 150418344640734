import React from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import matriculaService from '../../../services/matricula.service'
import { NumericFormat } from 'react-number-format';
import { useAuth } from '../../../context/auth.context';
import moment from "moment";
const ObservacionEditModal = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
//   const obligacion_id = props.obligacion_id
  const estudiante = props.children

   form.setFieldsValue(estudiante)
  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.fechaObservacion = moment().format('YYYY-MM-DD HH:mm:ss')
    //form.empresa_id = empresa_id;
    // form.obligacion_id = obligacion_id
    //form.concepto = "PAGO DE " + obligacion.concepto 
   
    // form.moneda = obligacion.moneda
    // form.usuario_id = usuario_id
    // form.estado = 'Generado'
    console.log(form)
    matriculaService
      .update(estudiante.id,form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateEvaluacion();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };
  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
          
       

            <Col span={24}>
              <Form.Item
                label="Observaciones: "
                name="observaciones"
                rules={[
                  {
                    required: false,
                    message: "Ingrese observaciones",
                  },
                ]}
              >
               <TextArea rows={10}></TextArea>
               
              </Form.Item>
            </Col>

        
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default ObservacionEditModal;
