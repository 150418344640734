import React from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Button
} from "antd";
import { Content } from "antd/lib/layout/layout";
import matriculaService from '../../../services/matricula.service'
import parametroService from '../../../services/parametro.service'
import subparametroService from '../../../services/subparametro.service'
import { NumericFormat } from 'react-number-format';
import { useAuth } from '../../../context/auth.context';
import {DeleteOutlined,EditOutlined} from '@ant-design/icons';
import { useEffect } from "react";
import { useState } from "react";
const {confirm} = Modal;
const ParametroEditModal = (props) => {
  const { TextArea } = Input;
  const [formPadre] = Form.useForm();
  const [formHijo] = Form.useForm();
  const [formNewPadre] = Form.useForm();
  const [formNewHijo] = Form.useForm();
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
//   const obligacion_id = props.obligacion_id
  const curso = props.children
  const [data,setData] = useState([])

  const [viewNewPadre,setViewNewPadre] = useState(false)
  const [viewNewHijo,setViewNewHijo] = useState(false)
  const [viewEditPadre,setViewEditPadre] = useState(false)
  const [viewEditHijo,setViewEditHijo] = useState(false)
  const [padreSelected,setPadreSelected] = useState()
  const [hijoSelected,setHijoSelected] = useState()
   
  const handleCancel = () => {
    props.updateVisible(false);
  };

  const handleCancelPadre = () => {
    setViewEditPadre(false);
    setPadreSelected(null)
  };


 const handleCancelHijo = () => {
    setViewEditHijo(false);
    setHijoSelected(null)
  };

  const handleCancelNewPadre = () => {
    setViewNewPadre(false);
  };

  const handleCancelNewHijo = () => {
    setViewNewHijo(false);
  };

  useEffect(()=>{
    loadParametros()
  },[])

  const loadParametros = () =>{
    parametroService.getForain(curso.id).then(res=>{
        console.log(res.data)
        setData(res.data)
    })
  }

  const activateEditPadre = (record) =>{
setViewEditPadre(true)
setPadreSelected(record)
formPadre.setFieldsValue({nombre:record.nombre,porcentaje:record.porcentaje})
  }

  const activateEditHijo= (record) =>{
    setViewEditHijo(true)
    setHijoSelected(record)
    formHijo.setFieldsValue({nombre:record.nombre,porcentaje:record.porcentaje})
      }

  const editPadre = (values) =>{
    let form = values
    form.id = padreSelected.id
    console.log(values)
    parametroService.update(form.id,form).then(res=>{
        console.log(res)
        message.success("Corte Actualizado")
        loadParametros()
        setViewEditPadre(false)
    })
  }

  const editHijo = (values) =>{
    let form = values
    form.id = hijoSelected.id
    console.log(values)
    subparametroService.update(form.id,form).then(res=>{
        console.log(res)
        message.success("sub-item Actualizado")
        loadParametros()
        setViewEditHijo(false)
    })
  }

  const deleteHijo = (record) =>{
    confirm({
        title:"Desea eliminar el item?",
        okText:"Si",
        okType:'danger',
        cancelText:"No",
        onOk(){
            subparametroService.delete(record.id).then(res=>{
                message.success("Eliminado")
                setViewEditHijo(false)
                loadParametros()
            }).catch(error=>{
                message.error("El subitem ya contiene notas y no es posible eliminar")
                setViewEditHijo(false)
            })
        }
      })
   
  }

  const newPadre = (values) =>{
   
        let form = values
        form.curso_id = curso.id
        console.log(values)

        parametroService.create(values).then(res=>{
            message.success("Creado Correctamente")
            setViewNewPadre(false)
            loadParametros()
        }).catch(error=>{
            message.error("Ha ocurrido un error")
            setViewNewPadre(false)
        })
  }

  const newHijo = (values) =>{
   
    let form = values
    form.parametro_id = padreSelected.id
    console.log(values)

    subparametroService.create(values).then(res=>{
        message.success("Creado Correctamente")
        setViewNewHijo(false)
        loadParametros()
    }).catch(error=>{
        message.error("Ha ocurrido un error")
        setViewNewHijo(false)
    })
}

  const activateNewPadre = () =>{
    setViewNewPadre(true)
  }

  const activateNewHijo = (record) =>{
    setViewNewHijo(true)
    setPadreSelected(record)
  }

  const deletePadre = (record) =>{
    confirm({
        title:"Desea eliminar el item?",
        okText:"Si",
        okType:'danger',
        cancelText:"No",
        onOk(){
            parametroService.delete(record.id).then(res=>{
                message.success("Eliminado")
                setViewEditPadre(false)
                loadParametros()
            }).catch(error=>{
                message.error("El item ya contiene notas y no es posible eliminar")
                setViewEditPadre(false)
            })
        }
      })
   
  }

  return (
    <Modal title="Ajustar parámetros de evaluación" width={1080} visible={props.visible}  onCancel={handleCancel}>
      <Content>
      


        <table width="100%" >
            <tr>
                <th> <strong>Nombre</strong> </th>
                <th><strong>Porcentaje</strong></th>
                <th></th>
                <th><strong>Opciones</strong></th>
            </tr>
            
                {data.map((item=>{
                    return <><tr style={{textAlign:'center'}}>
                    <td style={{color:'red'}}>{item.nombre}</td>
                    <td>{item.porcentaje} %</td>
                    <td></td>
                    <td><Button type='default' onClick={()=>{activateEditPadre(item)}}><EditOutlined/></Button> 
                    <Button type='danger' onClick={()=>{deletePadre(item)}}><DeleteOutlined/></Button></td>
                
                    </tr>
                    <tr>
                        <th></th>
                        <th><strong>Sub-items</strong></th>
                        <th><strong>Porcentaje</strong></th>
                    </tr>

                    
                    
                    {item['SubparametroCursos'].map((sub=>{
                        return <tr  style={{textAlign:'center'}}> 
                        <td></td>
                        <td>{sub.nombre}</td>
                        <td>{sub.porcentaje} %</td>
                        <td><Button type='default' onClick={()=>{activateEditHijo(sub)}}><EditOutlined/></Button> 
                    <Button type='danger' onClick={()=>{deleteHijo(sub)}}><DeleteOutlined/></Button></td>
                      </tr>
                    }))}

                    <tr style={{textAlign:'center'}}>
                        <td></td>
                        <td><Button type="primary" onClick={()=>{activateNewHijo(item)}}>Agregar Sub-item</Button></td>
                    </tr>
                      <tr>
                        <td colSpan='4'><hr /></td>
                    </tr>
                  </>
                }))}

                <tr style={{textAlign:'center'}}>
                <td><Button type='primary' onClick={()=>{activateNewPadre()}}>Agregar Corte</Button></td>
                </tr>
                
           
        </table>
      </Content>

                {padreSelected && viewEditPadre && (
      <Modal visible={viewEditPadre} onOk={formPadre.submit} onCancel={handleCancelPadre}>
        <Form form={formPadre} layout="vertical"  onFinish={editPadre}>
            <Form.Item label="Nombre" name='nombre'>
                <Input ></Input>
            </Form.Item>

            <Form.Item label="Porcentaje" name='porcentaje'>
                <Input type="number"></Input>
            </Form.Item>
        </Form>
      </Modal>
      )}

{hijoSelected && viewEditHijo && (
      <Modal visible={viewEditHijo} onOk={formHijo.submit} onCancel={handleCancelHijo}>
        <Form form={formHijo} layout="vertical"  onFinish={editHijo}>
            <Form.Item label="Nombre" name='nombre'>
                <Input ></Input>
            </Form.Item>

            <Form.Item label="Porcentaje" name='porcentaje'>
                <Input type="number"></Input>
            </Form.Item>
        </Form>
      </Modal>
      )}

{ viewNewPadre && (
      <Modal visible={viewNewPadre} onOk={formNewPadre.submit} onCancel={handleCancelNewPadre}>
        <Form form={formNewPadre} layout="vertical"  onFinish={newPadre}>
            <Form.Item label="Nombre" name='nombre'>
                <Input ></Input>
            </Form.Item>

            <Form.Item label="Porcentaje" name='porcentaje'>
                <Input type="number"></Input>
            </Form.Item>
        </Form>
      </Modal>
      )}

{ viewNewHijo && (
      <Modal visible={viewNewHijo} onOk={formNewHijo.submit} onCancel={handleCancelNewHijo}>
        <Form form={formNewHijo} layout="vertical"  onFinish={newHijo}>
            <Form.Item label="Nombre" name='nombre'>
                <Input ></Input>
            </Form.Item>

            <Form.Item label="Porcentaje" name='porcentaje'>
                <Input type="number"></Input>
            </Form.Item>
        </Form>
      </Modal>
      )}

    </Modal>
  );
};

export default ParametroEditModal;
