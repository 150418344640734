import http from "../utils/http-common";

class PreguntaService {
    getAll() {
      return http.get("/preguntas");
    }
    get(id) {
      return http.get(`/preguntas/${id}`);
    }
    create(data) {
      return http.post("/preguntas", data);
    }
    update(id, data) {
      return http.put(`/preguntas/${id}`, data);
    }
    delete(id) {
      return http.delete(`/preguntas/${id}`);
    }
   
  }
  export default new PreguntaService();