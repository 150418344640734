import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../context/auth.context'
export function ProtectedRoute({rol,children}) {
    const {user,role} = useAuth()
   
    if(!user) return <Navigate to="/login" />

   const index = rol.findIndex(item => item.includes(role))
    

    if(rol && index == -1){
      return <Navigate to="/acceso-denegado" replace />
    }
  return (
    <>{children}</>
  )
}
