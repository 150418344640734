import React from "react";
import {
  Modal,
    Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect } from "react";
import histofaseService from "../../services/histofase.service";
import { useState } from "react";
import moment from "moment";
const HistorialFaseModal = (props) => {
    const lead_id = props.lead_id;
    const [historial,setHistorial] = useState([])
    const [loading, setLoading] = useState(false);

    const columns = [

        {
            title: "Etapa",
             render: (record) => <div>{record.Fase.nombre}</div>,
          },

        {
            title: "Comentario",
            dataIndex: "comentario",
            key: "comentario",
            // render: (text) => <a>{text}</a>,
          },
          {
            title: "Fecha Registro",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
          },
    ]

    useEffect(()=>{
        listHistorial()
    },[])


    const listHistorial = () =>{
        histofaseService.getForain(lead_id)
        .then((response) => {
            let filterHistorial = response.data;
            console.log(response.data)
            setHistorial(filterHistorial);
            
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
    }
    

    const handleCancel = () => {
        props.updateVisible(false);
      };

    return (
        <Modal visible={props.visible} onOk={handleCancel} onCancel={handleCancel}>
        <Content>
            <Table columns={columns} dataSource={historial}></Table>
        </Content>
        </Modal>
    );
};

export default HistorialFaseModal;