import React, {useEffect, useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Spin
} from "antd";
import { Content } from "antd/lib/layout/layout";
import archivoCursoService from "../../services/archivo_curso.service";
import { useAuth } from '../../context/auth.context';
import storage from "../../services/firebase"
import {ref, uploadBytesResumable,getDownloadURL} from "firebase/storage"
import moment from "moment";
const ArchivoCursoModal = (props) => {
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  const curso = props.children
  const [file,setFile] = useState()
  const { TextArea } = Input;
  const [percent,setPercent] = useState()
  const [semanas,setSemanas] = useState()
  const [activarSemana,setActivarSemana] = useState(false)
  const [viewForm,setViewForm] = useState(false)
  const [totalSemanas,setTotalSemanas] = useState()

  const handleCancel = () => {
    props.updateVisible(false);
  };


  useEffect(()=>{
    calcularSemanas(curso.fechaInicial,curso.fechaFinal)
  },[])

  const  obtenerMartesDeSemana = (numeroSemana, año) => {
    const fechaMartes = moment().year(año).week(numeroSemana).day(3);
    return fechaMartes.format('YYYY-MM-DD'); // Cambia el formato de fecha si lo deseas
  };

  const  obtenerViernesDeSemana = (numeroSemana, año) => {
    const fechaMartes = moment().year(año).week(numeroSemana).day(4);
    return fechaMartes.format('YYYY-MM-DD'); // Cambia el formato de fecha si lo deseas
  };
 
 const compararFechas = (fecha1, fecha2) => {
    return fecha1.isBefore(fecha2); // Compara si fecha1 es antes que fecha2
  };

  const calcularSemanas = (fechaInicial,fechaFinal)=>{
   
    let fechaInicialCurso = moment(fechaInicial, "YYYY-MM-DD HH:mm:ss")
    let fechaFinalCurso = moment(fechaFinal, 'YYYY-MM-DD 23:59:59')
    let todasFechas = [];
    let todasSemanas = [];

    while (fechaInicialCurso.isSameOrBefore(fechaFinalCurso)) {
      todasFechas.push(fechaInicialCurso.format('YYYY-MM-DD'));
      fechaInicialCurso.add(1, 'days');
    }



    let semanaHoy = moment().week()
    let diaHoy = moment().day()

  
    
    for(let item of todasFechas){
      
      let fecha = moment(item)
      let numSemana = fecha.week()
      //var numSemana = item.week();
      todasSemanas.push(numSemana)
    }

   
    const arraySinRepetidos = todasSemanas.filter((elem, index) => {
      return todasSemanas.indexOf(elem) === index;
    });

  
    let data = []
   
    if(role != "Superadministrador"){

  
      
    for (let i = 0; i < arraySinRepetidos.length; i++) {
      const semana = arraySinRepetidos[i];
      let disabled = true;
    
      if(curso.jornada != "Sábados"){
      if (semana > semanaHoy || (semana === semanaHoy  && compararFechas(moment(), obtenerMartesDeSemana(semanaHoy+1,moment().format('YYYY'))) )) {
        disabled = false;
      }
    } else {
      if (semana > semanaHoy || (semana === semanaHoy  && compararFechas(moment(), obtenerViernesDeSemana(semanaHoy+1,moment().format('YYYY'))) )) {
        disabled = false;
      }
    }
    
      data.push({
        id: i + 1,
        nombre: "Semana " + (i + 1),
        semana: semana,
        disabled: disabled,
      });
   
  } 

    } else{
      for (let i = 0; i < arraySinRepetidos.length; i++) {
        const semana = arraySinRepetidos[i];
        data.push({
          id: i + 1,
          nombre: "Semana " + (i + 1),
          semana: semana,
          disabled: false,
        });
      }
    }

    
    
    setTotalSemanas(data)
    return data
  }

  

  const onFinish = (values) => {
    setLoading(true);
    props.updateLoading(true)
    const form = values;
    form.empresa_id = empresa_id;
    form.usuario_id = usuario_id;
    form.estado = "Cargado";
    form.curso_id = curso.id
    const storageRef = ref(storage, `/cursos/${curso.id}/${new Date().getTime() + "-" + file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        "state_changed",
        (snapshot) => {
        const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        
        // update progress
        setPercent(percent);
        },
        (err) => console.log(err),
        () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        console.log(url);

            form.url = url
    archivoCursoService
    .create(form)
    .then((response) => {
      console.log(response.data);
      setLoading(false);
      props.updateLoading(true)
      props.updateVisible(false);
      props.updateCurso();
      setFile(null)
      message.success("Guardado Correctamente");
    })
    .catch((e) => {
        setLoading(false);
        props.updateLoading(true)
      message.error("No se ha podido guardar");
      console.log(e);
    });

        });
        }
        );
        };


        const validateLesson = (valor) =>{
          if(valor == "LESSON PLAN"){
            setActivarSemana(true)
            calcularSemanas(curso.fechaInicial,curso.fechaFinal)
          } else{
            setActivarSemana(false)
          }
        }

 

  const selectFile = (e) =>{
   
    const file = e.target.files[0];

    const fileType = file.type;

    if(fileType == "application/pdf"){
      setFile(file)
    } else{
      message.error("Solo archivos PDF")
      form.setFieldsValue({file:null})
    }

   
  }

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel} okText="Cargar Archivo" cancelText="Cancelar">
      <Content>
        
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Tipo de Archivo: "
                name="tipo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de Archivo",
                  },
                ]}
              >
                <Select onChange={validateLesson}> 
                    <Select.Option value="LESSON PLAN">LESSON PLAN</Select.Option>
                    <Select.Option value="EXAMEN FINAL">EXAMEN FINAL</Select.Option>
                   
                </Select>
              </Form.Item>
            </Col>


{activarSemana && (
            <Col span={24}>
              <Form.Item
                label="Semana: "
                name="semana"
                rules={[
                  {
                    required: activarSemana,
                    message: "Por favor ingrese la semana de Lesson Plan",
                  },
                ]}
              >
                 <Select> 
                {totalSemanas.map(item=>{
                  return  <Select.Option disabled={item.disabled} value={item.id}>{item.nombre}</Select.Option>
                })}
                 </Select>
                
              </Form.Item>
            </Col>

)}

            <Col span={24}>
              <Form.Item
                label="Cargar Archivo: "
                name="file"
                rules={[
                  {
                    required: true,
                    message: "Por favor Seleccione el archivo",
                  },
                ]}
              >
                <Input type="file" accept="image/*,.pdf"  onChange={(e)=>{selectFile(e)}}></Input>
              </Form.Item>
            </Col>

              <Col span={24}>
              <Form.Item
                label="Observaciones: "
                
                name="observaciones"
                rules={[
                  { required: false, message: "Por favor ingrese Observaciones" },
                ]}
              >
               <TextArea  />
              </Form.Item>
              </Col>

          </Row>
        </Form>
      
      </Content>
    </Modal>
  );
};

export default ArchivoCursoModal;
