import http from "../utils/http-common";

class UsuarioService {
    getAll() {
      return http.get("/usuarios");
    }
    get(id) {
      return http.get(`/usuarios/${id}`);
    }
    create(data) {
      return http.post("/usuarios", data);
    }

    changePassword(data) {
      return http.post("/usuarios/password", data);
    }

    login(data) {
      return http.post("/usuarios/login", data);
    }

    update(id, data) {
      return http.put(`/usuarios/${id}`, data);
    }
    delete(id) {
      return http.delete(`/usuarios/${id}`);
    }
   
  }
  export default new UsuarioService();