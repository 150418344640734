import http from "../utils/http-common";

class UsuarioService {
    getAll() {
      return http.get("/workgroups");
    }
    get(id) {
      return http.get(`/workgroups/${id}`);
    }
    create(data) {
      return http.post("/workgroups", data);
    }
    update(id, data) {
      return http.put(`/workgroups/${id}`, data);
    }
    delete(id) {
      return http.delete(`/workgroups/${id}`);
    }
   
  }
  export default new UsuarioService();