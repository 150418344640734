import React, {useEffect, useState} from "react"
import MyLayout from "../../components/MyLayout"
import {useLocation} from "react-router-dom"
import evaluacion_estudianteService from "../../services/evaluacion_estudiante.service"
import "./HistorialAcademico.css"
import moment from "moment"
import {Card, Spin} from "antd"

const HistorialAcademico = () => {
  let location = useLocation()
  let myEstudiante = location.state.estudiante
  const [notas, setNotas] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    listEvaluaciones()
  }, [])

  function formatName(record) {
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${
      record.primerApellido
    } ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const listEvaluaciones = () => {
    setLoading(true)
    evaluacion_estudianteService
      .getHistorial(myEstudiante.id)
      .then((res) => {
        console.log(res.data)
        setLoading(false)
        let cursos = []
        let cursosAsignatura = []

        for (let curso of res.data) {
          if (
            cursos.filter((item) => item.curso == curso.curso_id).length == 0
          ) {
            let matricula = curso.Matriculas.filter(
              (item) => item.curso_id == curso.curso_id
            )
            cursos.push({
              curso: curso.curso_id,
              infoCurso: curso.Curso,
              matricula: matricula,
            })
          }
        }

        for (let c of cursos) {
          let totalNotaCurso = 0
          let dataFilter = res.data.filter((item) => item.curso_id == c.curso)
          let cursosparams = []
          let cursosSubparam = []
          for (let dataF of dataFilter) {
            if (
              cursosparams.filter(
                (item) => item.parametroP == dataF.parametro_id
              ).length == 0
            ) {
              cursosparams.push({
                parametroP: dataF.parametro_id,
                porcentajeP: dataF.ParametroCurso.porcentaje,
                nombreP: dataF.ParametroCurso.nombre,
              })
            }
          }

          for (let para of cursosparams) {
            let totalNotasSub = 0
            let totalNotasPadre = 0
            let datasub = dataFilter.filter(
              (item) => item.parametro_id == para.parametroP
            )
            for (let notasSub of datasub) {
              totalNotasSub +=
                notasSub.valor * (notasSub.SubparametroCurso.porcentaje / 100)
            }
            totalNotasPadre = totalNotasSub * (para.porcentajeP / 100)
            cursosSubparam.push({
              padre: para,
              hijo: datasub,
              notasHijoResumen: totalNotasSub,
              notaPadre: totalNotasPadre,
            })
          }

          for (let nPadre of cursosSubparam) {
            totalNotaCurso += nPadre.notaPadre
          }

          let estado

          totalNotaCurso < 2.95
            ? (estado = "No aprobada")
            : (estado = "Aprobada")

          cursosAsignatura.push({
            curso: c,
            totalGeneral: totalNotaCurso,
            data: cursosSubparam,
            estado: estado,
          })
        }

        console.log(cursosAsignatura)
        setNotas(cursosAsignatura)
      })
      .catch((error) => {
        setLoading(false)
      })
  }
  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    )
  return (
    <Card>
      <div>
        <table className="tableInfo">
          <tr>
            <td rowSpan={3}>
              <img className="logo" src="./assets/img/logo.png" alt="" />
            </td>
            <td colSpan={3}>
              {" "}
              <strong>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS </strong>{" "}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <strong> RESULTADOS ACADÉMICOS</strong>{" "}
            </td>
          </tr>
          <tr>
            <td>Código: 01</td>
            <td>Versión: 0.1</td>
            <td>Fecha Versión: 5/06/2014</td>
          </tr>
        </table>

        <br />
        <table className="tablaDatos">
          <tr>
            <td>
              <strong>Estudiante: </strong> {formatName(myEstudiante)}{" "}
            </td>
            <td>
              <strong>Identificación: </strong> {myEstudiante.numId}
            </td>
          </tr>
        </table>
        <br />

        {notas.map((item) => {
          return (
            <>
              <table className="tablaDatos">
                <tr>
                  <th>
                    <strong>Curso </strong>{" "}
                  </th>
                  <th>
                    <strong>Fecha Inicio/Fecha Fin</strong>{" "}
                  </th>
                  <th>
                    <strong>Jornada </strong>{" "}
                  </th>
                  <th>
                    <strong>Periodo </strong>{" "}
                  </th>
                  <th>
                    <strong>Nota Final </strong>{" "}
                  </th>
                  <th>
                    <strong>Estado</strong>{" "}
                  </th>
                </tr>

                <tr>
                  <td>
                    {" "}
                    {item.curso.infoCurso.curso} - {item.curso.infoCurso.nivel}{" "}
                    - {item.curso.infoCurso.ciclo}
                  </td>
                  <td style={{textAlign: "center"}}>
                    {" "}
                    {moment(item.curso.infoCurso.fechaInicial).format(
                      "DD-MM-YYYY"
                    )}{" "}
                    /{" "}
                    {moment(item.curso.infoCurso.fechaFinal).format(
                      "DD-MM-YYYY"
                    )}{" "}
                  </td>
                  <td style={{textAlign: "center"}}>
                    {" "}
                    {item.curso.infoCurso.jornada}
                  </td>
                  <td style={{textAlign: "center"}}>
                    {" "}
                    {item.curso.infoCurso.periodo}
                  </td>
                  <td style={{fontSize: "16px", textAlign: "center"}}>
                    {item.totalGeneral.toFixed(1)}{" "}
                  </td>
                  <td> {item.estado} </td>
                </tr>

                {item.data.map((corte) => {
                  return (
                    <>
                      {" "}
                      <tr>
                        <td>
                          {" "}
                          {corte.padre.nombreP} - {corte.padre.porcentajeP}%
                        </td>

                        {corte.hijo.map((hijo) => {
                          return (
                            <td style={{textAlign: "center"}}>
                              {" "}
                              <strong> {hijo.SubparametroCurso.nombre}</strong>
                            </td>
                          )
                        })}
                        {/* <td >{corte.padre.porcentajeP}%</td> */}
                        {/* <td >{corte.notasHijoResumen.toFixed(1)}</td> */}
                      </tr>
                      <tr>
                        <td></td>
                        {corte.hijo.map((hijo) => {
                          return (
                            <td style={{textAlign: "center"}}> {hijo.valor}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        <td>
                          <strong>PROMEDIO</strong>
                        </td>
                        <td colSpan={4} style={{textAlign: "center"}}>
                          {corte.notasHijoResumen.toFixed(1)}
                        </td>
                      </tr>
                    </>
                  )
                })}

                <tr>
                  {item.curso.matricula && item.curso.matricula.length > 0 && (
                    <td colSpan={6} className="observaciones">
                      <strong>OBSERVACIONES: </strong>
                      {item.curso.matricula[0].observaciones}
                    </td>
                  )}
                </tr>
              </table>

              <br />
              <br />
            </>
          )
        })}

        <div className="footer">
          <div>Brighton - Centro de Formación en Inglés </div>

          <div>
            {" "}
            Fecha de impresión: {new Date().getDate()}/
            {new Date().getMonth() + 1}/{new Date().getFullYear()}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default HistorialAcademico
