import React, { useEffect, useState } from 'react';
import MyLayout from '../../components/MyLayout';
import {useLocation} from 'react-router-dom'
import "./ImprimirLiquidacion.css"

import moment from 'moment';
import {Card, Spin} from 'antd'

const ImprimirLiquidacion = () => {

    let location = useLocation();
    let myLiquidacion = location.state.liquidacion
    const [notas,setNotas] = useState([])
    const [loading,setLoading] = useState(false)


    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })
  

    useEffect(()=>{
       console.log(myLiquidacion)
    },[])


    function formatName (record){
        let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
        nombre = nombre.toUpperCase()
        return nombre
      }

  
    if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
    return (
      
        
            <div>

                <div className="content">

                <table className='tableInfo'>
                    <tr>
                        <td rowSpan={3}><img className='logo' src="./assets/img/logo.png" alt="" /></td>
                        <td colSpan={3}> <strong>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS </strong> </td>
                    </tr>
                    <tr>
                        <td ><strong> GESTIÓN DEL TALENTO HUMANO</strong> </td>
                        <td colSpan={2}>CODIGO: GTH-FO-01</td>
                    </tr>
                    <tr>
                        <td ><strong> CERTIFICACION DE AUTORIZACION DE PAGO PARA CONTRATOS DE PRESTACION DE SERVICIOS </strong> </td>
                        <td>VERSIÓN: 2</td>
                        <td>FECHA: 14/04/2023</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    <td colSpan={2} > Fecha de impresión: {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}</td>
                    </tr>
                </table>
                
<br />
                <table className='tablaDatos' >
                    <tr><td colSpan={2} className="encabezado"> <strong>INFORMACION DE CONTRATISTA  </strong> </td></tr>
                    <tr>
                        <td width={200}><strong>Nombres:</strong></td>
                        <td>{myLiquidacion.Docente.primerNombre} {myLiquidacion.Docente.segundoNombre} </td>
                    </tr>

                    <tr>
                        <td><strong>Apellidos:</strong></td>
                        <td>{myLiquidacion.Docente.primerApellido} {myLiquidacion.Docente.segundoApellido} </td>
                    </tr>

                    <tr>
                  <td><strong>Identificación: </strong></td>
                  <td> {myLiquidacion.Docente.numId}</td>
                    </tr>

                    <tr>
                  <td><strong>Mes: </strong></td>
                  <td> {myLiquidacion.mes} {myLiquidacion.annio}</td>
                    </tr>


                </table>


                <table className='tablaDatos'>
                <tr>
                        <td colSpan={4} className="encabezado"> <strong>INFORMACION DE PAGO </strong> </td>
                    </tr>

                    <tr>
                        <th>Concepto</th>
                        <th>Jornada</th>
                        <th>Horas</th>
                        <th>Valor</th>
                        </tr>

                    {myLiquidacion.DetalleLiquidacionDocentes.map(item=>{
                        return <>
                        <tr >
                            <td width={1000} style={{textAlign:'center'}}>Cod. {item.relacion_id} / {item.concepto}</td>
                            <td style={{textAlign:'center'}}>{item.jornada}</td>
                            <td style={{textAlign:'center'}}>{item.horas}</td>
                            <td style={{textAlign:'center'}}>{formatterPeso.format(item.valor)}</td>
                            </tr>

                            {item.VistaLiquidacionDocentes.length>0 && (
                            <tr >
                                <td >

                                  
                                <tr>
                                    <th>Fecha</th>
                                    <th>Horas</th>
                                    <th>No. Estudiantes</th>
                                    <th>Tarifa</th>
                                    <th>Subtotal</th>
                                </tr>
                             
                               
                                {  item.VistaLiquidacionDocentes.map(vista=>{
                                return <>
                                
                                <tr class="no-divider" style={{textAlign:'center'}}>
                                    <td width={200}  >{moment(vista.fecha).format("DD-MM-YYYY")}</td>
                                    <td width={200} >{vista.horas}</td>
                                    <td width={200}>{vista.numEstudiantes}</td>
                                    <td width={200} >{formatterPeso.format(vista.tarifa)}</td>
                                    <td width={200} >{formatterPeso.format(vista.valor)}</td>
                                </tr>
                                </>
                            })}
                            
                                </td>
                                <td></td>
                                <td></td>
                            </tr>

)}
                        </>
                    })}

                    <tr style={{textAlign:'center'}}>
                        <td colSpan={3}><strong>VALOR AUTORIZADO PARA PAGO:</strong></td>
                    <td> <strong>{formatterPeso.format(myLiquidacion.valor)}</strong> </td>
                    </tr>

                    <tr style={{textAlign:'center'}}>
                        <td colSpan={3}><strong>APORTE OBLIG. PENSION 16%</strong></td>
                    <td> <strong>{formatterPeso.format(0)}</strong> </td>
                    </tr>

                    <tr style={{textAlign:'center'}}>
                        <td colSpan={3}><strong>APORTE OBLIG. SALUD 12.5%</strong></td>
                    <td> <strong>{formatterPeso.format(0)}</strong> </td>
                    </tr>

                    <tr style={{textAlign:'center'}}>
                        <td colSpan={3}><strong>CLASIFICACION DEL RIESGO ARL </strong></td>
                    <td>  </td>
                    </tr>

                    <tr style={{textAlign:'center'}}>
                        <td colSpan={3}><strong>APORTES ARL </strong></td>
                    <td>  </td>
                    </tr>

                    <tr style={{textAlign:'center'}}>
                        <td colSpan={4}>El valor de los pagos correspondientes a los aportes obligartorios a pension, salud, deben estar calculados con base en el 40% de los honorarios mensuales (sin inlcuir el IVA); en este valor  deben estar excluidos. los intereses de mora si estos fueron causados </td>
                 
                    </tr>

                    <tr>
                        <td colSpan={4} className="encabezado"> <strong>CERTIFICACION DEL CONTRATISTA </strong> </td>
                    </tr>

                    <tr>
                        <td colSpan={4} >
                        <br />
                            <ul>
                                <li>
                            Anexo 1. Anexo factura original (si aplica)  aportes obligartorios a pension, salud, arl (si aplica), del respectivo periodo y documentos establecidos en el contrato como requisitos para pago.
                                </li>

                                <li>
                                Anexo 2. En calidad de contratista certifico bajo la gravedad de juramento, que los documentos soporte de pago de los aportes obligatorios al sistema de seguridad social corresponden a ingresos provenientes del presente contrato sujeto a retencion en la fuente a titulo de renta articulo 4 decreto 2271 del 18 de junio de 2009.
                                </li>
                                </ul>

                                <br />
                                <br />
                                <div style={{textAlign:'center'}}>
                                <div>_____________________________</div>
                                <div>FIRMA CONTRATISTA</div>
                                <br />
                                </div>
                           

 </td>
                    </tr>


                    <tr>
                        <td colSpan={4} className="encabezado"> <strong>CERTIFICACION DEL SUPERVISOR DEL CARGO </strong> </td>
                  
                    </tr>

                    <tr>
                        <td colSpan={4} style={{textAlign:'center'}} > 
                        <br />
                        <br />
                        <div>NOMBRE Y CARGO SUPERVISOR DEL CONTRATO </div>
                        <div>En mi calidad de supervisor del  presente  contrato de prestacion de servicios, certifico el cumplimiento por parte del contratista  de las actividades descritas en el formato de informe de activiades y certificacion del supervisor, el cual sera remitido en original a la oficina de Tesoreria para su inclusion  en el expediente contractual, razon por la cual se autorizo el pago</div>
                        </td>
                  
                    </tr>

                </table>
<br />
</div>

                {/* <div className="footer">

                <div>Brighton - Centro de Formación en Inglés </div>
        
          <div > Fecha de impresión: {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}</div>
          </div> */}
       
            </div>
        
       
    );
};

export default ImprimirLiquidacion;