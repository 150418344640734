import { Card } from 'antd';
import React from 'react';
import {useLocation} from 'react-router-dom'
import moment from 'moment';
const HistorialSeguimiento = () => {
    let location = useLocation();
    let mySeguimiento = location.state.seguimiento


    function formatName (record){
        let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
        nombre = nombre.toUpperCase()
        return nombre
      }

    return (
        <Card>
            <div>
            <table className='tableInfo'>
                    <tr>
                        <td rowSpan={3}><img className='logo' src="./assets/img/logo.png" alt="" /></td>
                        <td colSpan={3}> <strong>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS </strong> </td>
                    </tr>
                    <tr>
                        <td colSpan={3}><strong>PLAN DE MEJORA INDIVIDUAL</strong> </td>
                    </tr>
                    <tr>
                        <td>Código: PF-FO-06</td>
                        <td>Versión: 1</td>
                        <td>Página: 1</td>
                    </tr>
                </table>

                <table className='tablaDatos' >
                <tr>
                        <td colSpan={4}><strong>Docente: </strong> {formatName(mySeguimiento.Docente)} </td>
                        {/* <td><strong>Identificación: </strong> {mySeguimiento.Estudiante.numId}</td> */}
                    </tr>
                    <tr>
                        <td colSpan={4}><strong>Estudiante: </strong> {formatName(mySeguimiento.Estudiante)} </td>
                       
                    </tr>

                    <tr>
                    <td colSpan={4}><strong>Celular: </strong> {mySeguimiento.Estudiante.celular}</td>
                    </tr>

                    <tr>
                    <td colSpan={4}> {mySeguimiento.Curso.curso} - {mySeguimiento.Curso.nivel} - {mySeguimiento.Curso.ciclo}</td>
                    </tr>

                    <tr>
                    <td colSpan={2} style={{textAlign:'center'}}> Fecha de Inicio: {moment(mySeguimiento.Curso.fechaInicial).format("DD-MM-YYYY")}  </td>
                    <td colSpan={2} style={{textAlign:'center'}}> Fecha Final: {moment(mySeguimiento.Curso.fechaFinal).format("DD-MM-YYYY")}</td>
                    </tr>

                    <tr>
                        <th colSpan={4}> <strong>OBSERVACION</strong> </th>
                       
                    </tr>

                     <tr>
                            <td  colSpan={4}>{mySeguimiento.observacion}</td>
                        </tr>

                        <tr>
                        <th colSpan={4}><strong>Acciones de Mejoramiento</strong></th>
                       
                    </tr>

                    <tr >
                        <th><strong>Causa</strong></th>
                        <th><strong>Acciones a desarrollar</strong></th>
                        <th><strong>Fecha de cumplimiento</strong></th>
                        <th><strong>Responsable</strong></th>
                    </tr>

                  

                    {mySeguimiento.Accions.map((item=>{
                        return <tr style={{textAlign:'center'}}>
                        <td>{item.causa}</td>
                        <td>{item.acciones}</td>
                        <td>{moment(item.fecha).format('DD-MM-YYYY')}</td>
                        <td>{item.responsable}</td>
                        </tr>

                    }))}


                    <tr style={{textAlign:'center'}}>
                        <td colspan={2}><br />  <br /> Firma Docente {formatName(mySeguimiento.Docente)}</td>
                        <td colspan={2}><br /> <br /> Firma Estudiante y/o Acudiente {formatName(mySeguimiento.Estudiante)}</td>
                    </tr>

                   

                </table>

                <div className="footer">

<div>Brighton - Centro de Formación en Inglés </div>

<div > Fecha de impresión: {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}</div>
</div>


            </div>
        </Card>
    );
};

export default HistorialSeguimiento;