import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  TimePicker,
} from "antd";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";
import docenteService from "../../services/docente.service";
import estudianteService from "../../services/estudiante.service";
import tutoriaService from "../../services/tutoria.service";
import { useAuth } from '../../context/auth.context';
const TutoriaModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [docentes,setDocentes] = useState([])
    const [estudiantes,setEstudiantes] = useState([])
  const [form] = Form.useForm();
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;

  useEffect(()=>{
    listDocentes()
    listEstudiantes()
  },[])

  const listDocentes = () => {
    setLoading(true);
    docenteService
      .getAll()
      .then((response) => {
        let filterDocentes = response.data;
        console.log(response.data);
        filterDocentes = response.data.filter(
          (item) => item.estado == "Activo"
        );
        setDocentes(filterDocentes);
     
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const listEstudiantes = () => {
    setLoading(true);
    estudianteService
      .getAll()
      .then((response) => {
        let filterEstudiantes = response.data;
        console.log(response.data);
        // filterEstudiantes = response.data.filter(
        //   (item) => item.estado == "Activo"
        // );
        setEstudiantes(filterEstudiantes.reverse());
      
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    console.log(values)
   
    const form = values;
    form.empresa_id = empresa_id
    form.usuario_id = usuario_id
    form.hora = moment(values.hora._d).format('HH:mm:ss')
    console.log(form)
    props.updateLoading(true);
    console.log("ENVIAR",form)
    tutoriaService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListTutoria();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onSearch = (value) => {
    
  };

  const onSearchEstudiante = (value) => {
    
  };

    return (
        <Modal width={1024} visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
        <Content>
        
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row>
  
            <Col span={8}>
              <Form.Item
                label="Docente: "
                name="docente_id"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Docente",
                  },
                ]}
              >
                <Select showSearch onSearch={onSearch}  optionFilterProp="children">
                  {docentes.map((item) => (
                    <Select.Option value={item.id} key={item.id}>{item.primerNombre} {item.segundoNombre} {item.primerApellido} {item.segundoApellido}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Estudiante: "
                name="estudiante_id"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Estudiante",
                  },
                ]}
              >
                <Select showSearch onSearch={onSearchEstudiante}  optionFilterProp="children">
                  {estudiantes.map((item) => (
                    <Select.Option value={item.id} key={item.id}>{item.primerNombre} {item.segundoNombre} {item.primerApellido} {item.segundoApellido}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

           

              <Col span={8}>
                <Form.Item
                  label="Fecha de Tutoria: "
                  name="fecha"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha",
                    },
                  ]}
                >
                 <Input type="date"></Input>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Tema de clase: "
                  name="tema"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Tema",
                    },
                  ]}
                >
                 <Input></Input>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Hora de Tutoria: "
                  name="hora"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Hora",
                    },
                  ]}
                >
                 <TimePicker use12Hours format="h:mm A"  minuteStep={15} placeholder="Hora de Tutoria"></TimePicker>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="No. de Horas: "
                  name="horas"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese No. de Horas",
                    },
                  ]}
                >
                <Input type="number"></Input>
                </Form.Item>
              </Col>


              <Col span={8}>
                <Form.Item
                  label="Modo: "
                  name="modo"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Modo",
                    },
                  ]}
                >
                 <Select>
                 
                   <Select.Option value="Gratuito">Gratuito</Select.Option>
                   <Select.Option value="Pagada">Pagada</Select.Option>
                
                 </Select>
                </Form.Item>
              </Col>


              

            </Row>
          </Form>
        </Content>
      </Modal>
    );
};

export default TutoriaModal;