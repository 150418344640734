import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import {
  Breadcrumb,
  Table,
  Button,
  Spin,
  Input,
  Avatar,
  Select,
  Tooltip,
} from "antd"
import MyLayout from "../../components/MyLayout"
import estudianteService from "../../services/estudiante.service"
import EstudianteModal from "../../components/modals/EstudianteModal"
import moment from "moment"
import {SearchOutlined, UserOutlined} from "@ant-design/icons"
import myIcon from "../../services/icono.service"
import {useAuth} from "../../context/auth.context"
const Estudiante = () => {
  const [viewNewEstudiante, setViewNewEstudiante] = useState(false)
  const [loading, setLoading] = useState(false)
  const [estudiantes, setEstudiantes] = useState([])
  const empresa_id = 1
  const {role} = useAuth()
  const [estado, setEstado] = useState("Activo")

  const [pagination, setPagination] = useState({
    current: 1, // Página actual
    pageSize: 4, // Tamaño de página
  })

  const handlePaginationChange = (current, pageSize) => {
    setPagination({current, pageSize})
  }

  const storageKey = "estudiantePagination"

  const storageFilter = "estudianteFilter"

  useEffect(() => {
    const savedPagination = JSON.parse(localStorage.getItem(storageKey))

    if (savedPagination) {
      setPagination(savedPagination)
    }

    listEstudiantesEstado("Activo")
  }, [])

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(pagination))
  }, [pagination])

  const listEstudiantes = () => {
    setLoading(true)
    estudianteService
      .getForain(empresa_id)
      .then((response) => {
        let filterLead = response.data
        console.log(response.data)
        // filterMedios = response.data.filter(
        //   (item) => item.empresa_id == empresa_id
        // );
        setEstudiantes(filterLead)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const listEstudiantesEstado = (status) => {
    setLoading(true)
    estudianteService
      .getStatus(status)
      .then((response) => {
        let filterLead = response.data
        console.log(response.data)
        // filterMedios = response.data.filter(
        //   (item) => item.empresa_id == empresa_id
        // );
        setEstudiantes(filterLead)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const filtrarEstado = (valor) => {
    setEstado(valor)
    localStorage.setItem(storageFilter, JSON.stringify(valor))
    listEstudiantesEstado(valor)
  }

  const validarTipo = (tipo) => {
    if (tipo == "Niño") {
      return "text-center bg-purple-500 rounded px-1"
    } else if (tipo == "Adolescente") {
      return " text-center bg-yellow-500 rounded px-1"
    } else if (tipo == "Adulto") {
      return "text-center bg-green-500 rounded px-1"
    }
  }

  const formatName = (record) => {
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${
      record.primerApellido
    } ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const calculateCategory = (dateString) => {
    const edad = moment().diff(dateString, "years", true).toFixed(0)

    if (edad > 0 && edad <= 12) {
      return "Niño"
    }
    if (edad > 12 && edad <= 18) {
      return "Adolescente"
    }
    if (edad > 18) {
      return "Adulto"
    }
  }

  const calculateAge = (dateString) => {
    return moment().diff(dateString, "years", true).toFixed(0)
  }

  const columns = [
    {
      title: " ",
      render: (record) => {
        if (record.url != null) {
          return (
            <Avatar
              size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80}}
              src={record.url}
            />
          )
        } else {
          return (
            <Avatar
              size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80}}
              icon={<UserOutlined />}
            />
          )
        }
      },
    },
    {
      title: "Codigo",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Codigo"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        let nombre = record.id.toString()
        return nombre.includes(value.toLowerCase())
      },
      key: "id",
      render: (record) => <div>{record.id}</div>,
    },
    {
      title: "Nombres",
      //dataIndex: "nombre",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Nombre"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        let nombre =
          record.primerNombre +
          " " +
          record.primerApellido +
          " " +
          record.segundoApellido
        return nombre.toLowerCase().includes(value.toLowerCase())
      },

      key: "primerNombre",
      render: (record) => (
        <Link to={"/ver-estudiante"} state={{estudiante: record}}>
          {formatName(record)}
        </Link>
      ),
    },
    {
      title: "No. Identificación",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese No. ID"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.numId.includes(value)
      },
      key: "numId",
      render: (record) => <div>{record.numId}</div>,
    },
    {
      title: "Celular",
      dataIndex: "celular",
      key: "celular",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Categoria",
      key: "fechaNacimiento",
      dataIndex: "fechaNacimiento",
      render: (fecha) => {
        const formats = ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"]
        const date = moment(fecha, formats, true) // Intenta parsear con los formatos
        console.log(date)
        return (
          <div className={`${validarTipo(calculateCategory(date))}`}>
            <Tooltip title={calculateAge(date)}>
              {calculateCategory(date)}
            </Tooltip>
          </div>
        )
      },
    },

    {
      title: "Fecha Registro",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (fecha) => {
        let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a")
        return <div>{myFecha}</div>
      },
    },

    // {
    //   title: "Opciones",

    //   key: "action",
    //    //render: (medio) => <div>{medio.Medio.nombre}</div>,
    // },
  ]

  const newEstudiante = () => {
    setViewNewEstudiante(true)
  }

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    )
  return (
    <MyLayout>
      <Breadcrumb style={{margin: "16px 0"}}>
        <Breadcrumb.Item>Institucional</Breadcrumb.Item>
        <Breadcrumb.Item>Estudiante</Breadcrumb.Item>
      </Breadcrumb>

      <div className="filtro">
        {" "}
        Filtrar:{" "}
        <Select
          defaultValue={estado}
          onChange={filtrarEstado}
          style={{width: 200}}
        >
          <Select.Option value="Activo">Activo</Select.Option>
          <Select.Option value="Inactivo">Inactivo</Select.Option>
          <Select.Option value="Retirado">Retirado</Select.Option>
          <Select.Option value="Finalizado">Finalizado</Select.Option>
          <Select.Option value="Usuario">Usuario</Select.Option>
        </Select>
      </div>

      {(role == "Superadministrador" ||
        role == "Administrativo" ||
        role == "Seguimiento" ||
        role == "Cumplimiento") && (
        <Button type="primary" onClick={newEstudiante}>
          Crear Estudiante
        </Button>
      )}

      <EstudianteModal
        visible={viewNewEstudiante}
        updateVisible={setViewNewEstudiante}
        updateLoading={setLoading}
        updateListEstudiante={listEstudiantes}
      ></EstudianteModal>

      <Table
        columns={columns}
        dataSource={estudiantes}
        pagination={{
          ...pagination,
          onChange: handlePaginationChange,
        }}
      />
    </MyLayout>
  )
}

export default Estudiante
