import http from "../utils/http-common";

class SaldoService {
    getAll() {
      return http.get("/saldo");
    }
    get(id) {
      return http.get(`/saldo/${id}`);
    }

    getType(tipo,id) {
        return http.get(`/saldo/${tipo}/${id}`);
      }

    getForain(id) {
      return http.get(`/saldo/forain/${id}`);
    }

    getReporte() {
      return http.get(`/saldo/reporte/favor`);
    }

    create(data) {
      return http.post("/saldo", data);
    }
    update(id, data) {
      return http.put(`/saldo/${id}`, data);
    }
    delete(id) {
      return http.delete(`/saldo/${id}`);
    }
   
  }
  export default new SaldoService();