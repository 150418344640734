import React, {useEffect} from "react"
import MySider from "../components/MySider"
import {Breadcrumb, Layout, Menu, Avatar, Badge, Button, Col, Row} from "antd"
import {
  BarsOutlined,
  CheckOutlined,
  BarChartOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  HomeOutlined,
  AlertOutlined,
  CalendarOutlined,
  DotChartOutlined,
} from "@ant-design/icons"
import {Link} from "react-router-dom"
import notificacionService from "../services/notificacion.service"
import actividadService from "../services/actividad.service"
import {useAuth} from "../context/auth.context"
import {useState} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import moment from "moment"
import Finder from "../components/Finder"
const {Header, Content, Footer} = Layout

const MyLayout = (props) => {
  const {user, role, saveRole, saveUser} = useAuth()
  const empresa_id = user.empresa_id
  const [notificaciones, setNotificaciones] = useState([])
  const [tareasHoy, setTareasHoy] = useState([])
  const navigate = useNavigate()

  const location = useLocation()

  // Accede a la propiedad pathname para obtener la ruta actual
  const currentPath = location.pathname

  if (currentPath != "/cursos" && currentPath != "/ver-curso") {
    window.localStorage.removeItem("cursosFilter")
    window.localStorage.removeItem("cursosPagination")
  }

  if (currentPath != "/estudiantes" && currentPath != "/ver-estudiante") {
    window.localStorage.removeItem("estudiantePagination")
  }
  // console.log(currentPath)
  //const [role,setRole] = useState('Estudiante')
  let items
  let busquedaHoy = {fechas: [moment(), moment()], tipo: "Todos"}
  useEffect(() => {
    notificacionService
      .getByRole({role})
      .then((res) => {
        setNotificaciones(
          res.data.filter(
            (item) => item.user_id == user.id || item.user_id == null
          )
        )
        console.log(res.data)
      })
      .catch((error) => {
        console.log(error)
      })

    actividadService.filter(busquedaHoy).then((res) => {
      setTareasHoy(res.data)
    })
  }, [])

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    }
  }

  const salir = () => {
    window.localStorage.removeItem("user")
    window.localStorage.removeItem("role")
    window.localStorage.removeItem("docentePagination")
    window.localStorage.removeItem("docenteFilter")
    window.localStorage.removeItem("crmFilter")
    window.localStorage.removeItem("mejoramientoPagination")
    window.localStorage.removeItem("estudiantePagination")
    window.localStorage.removeItem("crmPagination")
    window.localStorage.removeItem("liquidacionPagination")
    window.localStorage.removeItem("cursosFilter")
    window.localStorage.removeItem("cursosPagination")
    saveUser(null)
    saveRole(null)
    navigate("/login")
  }

  if (role == "Estudiante") {
    items = [
      // getItem("Perfil Estudiante", "1", <BarChartOutlined />, [
      //   getItem(<Link to="/docentes">Informacion Personal</Link>,"2",<CheckOutlined />),
      // ]),

      getItem("Perfil Estudiante", "1", <BarChartOutlined />, [
        getItem(
          <Link to="/perfil/estudiante">Informacion Personal</Link>,
          "2",
          <CheckOutlined />
        ),
      ]),

      getItem("Académico", "3", <BarChartOutlined />, [
        getItem(
          <Link to="/cursos/estudiante">Mis Cursos</Link>,
          "4",
          <CheckOutlined />
        ),
      ]),

      // getItem("Academico", "3", <BarChartOutlined />, [
      //   getItem(<Link to="/docentes">Mis Cursos</Link>,"4",<CheckOutlined />),
      // ]),

      // getItem("Financiero", "5", <BarChartOutlined />, [
      //   getItem(<Link to="/docentes">Mis pagos</Link>,"6",<CheckOutlined />),
      // ]),

      getItem(`${user.nombre}`, "7", <UserOutlined />, [
        getItem(<Button onClick={salir}>Salir</Button>, "31", <UserOutlined />),
      ]),
    ]
  }
  if (role == "Docente") {
    items = [
      getItem("Perfil Docente", "1", <BarChartOutlined />, [
        getItem(
          <Link to="/perfil/docente">Informacion Personal</Link>,
          "2",
          <CheckOutlined />
        ),
      ]),

      getItem("Biblioteca Virtual", "1444", <BarChartOutlined />, [
        getItem(
          <Link to="/biblioteca">Biblioteca Virtual</Link>,
          "24567",
          <CheckOutlined />
        ),
        getItem(
          <Link to="/review-biblioteca">Archivos Biblioteca Virtual</Link>,
          "24674",
          <CheckOutlined />
        ),
      ]),

      getItem("Académico", "3", <BarChartOutlined />, [
        getItem(
          <Link to="/cursos/docente">Mis Cursos</Link>,
          "4",
          <CheckOutlined />
        ),
        getItem(
          <Link to="/mejoramiento">Planes de Mejoramiento</Link>,
          "252",
          <TeamOutlined />
        ),
      ]),

      getItem("Tickets Soporte", "14544", <CheckOutlined />, [
        getItem(
          <a
            href="https://noteforms.com/forms/tickets-brighton-fwsufb"
            target="_blank"
          >
            Crear Ticket Soporte
          </a>,
          "24567",
          <CheckOutlined />
        ),
      ]),

      getItem(
        "",
        "25",
        <Link to="/notificaciones">
          <Badge count={notificaciones.length} size="small">
            <AlertOutlined />
          </Badge>
        </Link>
      ),

      // getItem("Financiero", "5", <BarChartOutlined />, [
      //   getItem(<Link to="/docentes">Mis pagos</Link>,"6",<CheckOutlined />),
      // ]),

      getItem(`${user.nombre}`, "7", <UserOutlined />, [
        getItem(
          <Link to="/password">Cambiar Contraseña</Link>,
          "8",
          <UserOutlined />
        ),
        getItem(<Button onClick={salir}>Salir</Button>, "31", <UserOutlined />),
      ]),
    ]
  }
  if (role == "Superadministrador") {
    items = [
      getItem(<Link to="/">Inicio</Link>, "1", <HomeOutlined />),
      getItem("Institucional", "2", <BarChartOutlined />, [
        getItem(<Link to="/docentes">Docentes</Link>, "3", <CheckOutlined />),
        getItem(
          <Link to="/estudiantes">Estudiantes</Link>,
          "4",
          <CheckOutlined />
        ),
        getItem(
          <Link to="/biblioteca">Biblioteca Virtual</Link>,
          "24567",
          <CheckOutlined />
        ),
      ]),

      getItem("Académico", "5", <SettingOutlined />, [
        getItem(<Link to="/cursos">Cursos</Link>, "6", <TeamOutlined />),
        getItem(
          <Link to="/examenes">Exámenes Internacionales</Link>,
          "7",
          <TeamOutlined />
        ),
        getItem(<Link to="/tutorias">Tutorias</Link>, "8", <TeamOutlined />),
        getItem(
          <Link to="/constancias">Constancias</Link>,
          "8a",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/derechos-grado">Derechos de grado</Link>,
          "8a3",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/mejoramiento">Planes de Mejoramiento</Link>,
          "252",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/formularios">
            Formularios de Exámenes Internacionales{" "}
          </Link>,
          "254",
          <TeamOutlined />
        ),
      ]),

      getItem("CRM", "9", <BarChartOutlined />, [
        //  getItem(<Link to="/embudo">Embudos</Link>,"6",<CheckOutlined />),
        getItem(
          <Link to="/leads">Oportunidades</Link>,
          "10",
          <CheckOutlined />
        ),
      ]),
      getItem("Financiero", "11", <SettingOutlined />, [
        getItem(<Link to="/tarifas">Tarifas</Link>, "12", <BarsOutlined />),
        getItem(
          <Link to="/tarifa/docente">Tarifa Docente</Link>,
          "13",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/liquidacion/docente">Liquidación Docente</Link>,
          "14",
          <BarsOutlined />
        ),
      ]),

      getItem("Ajustes", "15", <SettingOutlined />, [
        getItem(
          <Link to="/workgroup">Grupos de Trabajo</Link>,
          "16",
          <TeamOutlined />
        ),
        getItem(<Link to="/usuario">Usuarios</Link>, "17", <UserOutlined />),
        getItem(
          <Link to="/medio">Medio de Contacto</Link>,
          "18",
          <BarsOutlined />
        ),
        // getItem(<Link to="/canal">Canales de Contacto</Link>, "11",<BarsOutlined />),
        getItem(
          <Link to="/pregunta">Preguntas Adicionales</Link>,
          "19",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/config-biblioteca">Config Biblioteca Virtual</Link>,
          "2467",
          <CheckOutlined />
        ),
        getItem(
          <Link to="/review-biblioteca">Archivos Biblioteca Virtual</Link>,
          "24674",
          <CheckOutlined />
        ),
        // getItem(<Link to="/carga">Carga Masiva</Link>, "20",<BarsOutlined />),

        getItem("Estructuración", "21", <BarChartOutlined />, [
          getItem(
            <Link to="/jornadas">Jornadas</Link>,
            "22",
            <CheckOutlined />
          ),
          getItem(
            <Link to="/periodos">Periodos</Link>,
            "23",
            <CheckOutlined />
          ),
          getItem(<Link to="/niveles">Niveles</Link>, "24", <CheckOutlined />),
        ]),
      ]),

      getItem("Reportes", "25", <DotChartOutlined />, [
        getItem(
          <Link to="/reporte-cuadre">Reportes Cuadre Financiero</Link>,
          "26",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-academico">Reportes Cuadre Académico</Link>,
          "266",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-ingresos">Reportes Ingresos</Link>,
          "32",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-estudiantes">Reportes Estudiantes</Link>,
          "323",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-saldo">Reportes Saldos a favor</Link>,
          "3235",
          <BarsOutlined />
        ),
      ]),

      getItem("Tickets Soporte", "14544", <CheckOutlined />, [
        getItem(
          <a
            href="https://noteforms.com/forms/tickets-brighton-fwsufb"
            target="_blank"
          >
            Crear Ticket Soporte
          </a>,
          "24567",
          <CheckOutlined />
        ),
      ]),

      getItem(
        "",
        "27",
        <Link to="/notificaciones">
          <Badge count={notificaciones.length} size="small">
            <AlertOutlined />
          </Badge>
        </Link>
      ),
      getItem(
        "",
        "30",
        <Link to="/tareas">
          <Badge count={tareasHoy.length} size="small">
            <CalendarOutlined />
          </Badge>
        </Link>
      ),

      getItem(`${user.nombre}`, "28", <UserOutlined />, [
        getItem(
          <Link to="/password">Cambiar Contraseña</Link>,
          "29",
          <UserOutlined />
        ),
        getItem(<Button onClick={salir}>Salir</Button>, "31", <UserOutlined />),
      ]),
    ]
  }

  if (role == "Administrativo") {
    items = [
      // getItem(<Finder></Finder>),
      getItem(<Link to="/">Inicio</Link>, "1", <HomeOutlined />),
      getItem("Institucional", "2", <BarChartOutlined />, [
        getItem(<Link to="/docentes">Docentes</Link>, "3", <CheckOutlined />),
        getItem(
          <Link to="/estudiantes">Estudiantes</Link>,
          "4",
          <CheckOutlined />
        ),
      ]),

      getItem("Académico", "5", <SettingOutlined />, [
        getItem(<Link to="/cursos">Cursos</Link>, "6", <TeamOutlined />),
        getItem(
          <Link to="/examenes">Exámenes Internacionales</Link>,
          "7",
          <TeamOutlined />
        ),
        getItem(<Link to="/tutorias">Tutorias</Link>, "8", <TeamOutlined />),
        getItem(
          <Link to="/constancias">Constancias</Link>,
          "8a",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/derechos-grado">Derechos de grado</Link>,
          "8a3",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/formularios">
            Formularios de Exámenes Internacionales{" "}
          </Link>,
          "254",
          <TeamOutlined />
        ),
      ]),

      getItem("CRM", "9", <BarChartOutlined />, [
        //  getItem(<Link to="/embudo">Embudos</Link>,"6",<CheckOutlined />),
        getItem(
          <Link to="/leads">Oportunidades</Link>,
          "10",
          <CheckOutlined />
        ),
      ]),
      getItem("Financiero", "11", <SettingOutlined />, [
        getItem(<Link to="/tarifas">Tarifas</Link>, "12", <BarsOutlined />),
        getItem(
          <Link to="/tarifa/docente">Tarifa Docente</Link>,
          "13",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/liquidacion/docente">Liquidación Docente</Link>,
          "14",
          <BarsOutlined />
        ),
      ]),

      getItem("Ajustes", "29", <SettingOutlined />, [
        //  getItem(<Link to="/workgroup">Grupos de Trabajo</Link>,"16",<TeamOutlined />),
        getItem(<Link to="/usuario">Usuarios</Link>, "30", <UserOutlined />),
        getItem(
          <Link to="/medio">Medio de Contacto</Link>,
          "31",
          <BarsOutlined />
        ),
        // getItem(<Link to="/canal">Canales de Contacto</Link>, "11",<BarsOutlined />),
        getItem(
          <Link to="/pregunta">Preguntas Adicionales</Link>,
          "33",
          <BarsOutlined />
        ),
        //  getItem(<Link to="/carga">Carga Masiva</Link>, "20",<BarsOutlined />),
        getItem("Estructuración", "34", <BarChartOutlined />, [
          getItem(
            <Link to="/jornadas">Jornadas</Link>,
            "35",
            <CheckOutlined />
          ),
          getItem(
            <Link to="/periodos">Periodos</Link>,
            "36",
            <CheckOutlined />
          ),
          getItem(<Link to="/niveles">Niveles</Link>, "37", <CheckOutlined />),
        ]),
      ]),

      getItem("Reportes", "15", <DotChartOutlined />, [
        getItem(
          <Link to="/reporte-cuadre">Reportes Cuadre Financiero</Link>,
          "26",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-academico">Reportes Cuadre Académico</Link>,
          "266",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-ingresos">Reportes Ingresos</Link>,
          "32",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-estudiantes">Reportes Estudiantes</Link>,
          "323",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-saldo">Reportes Saldos a favor</Link>,
          "3235",
          <BarsOutlined />
        ),
      ]),

      getItem(
        "",
        "25",
        <Link to="/notificaciones">
          <Badge count={notificaciones.length} size="small">
            <AlertOutlined />
          </Badge>
        </Link>
      ),
      getItem(
        "",
        "30",
        <Link to="/tareas">
          <Badge count={tareasHoy.length} size="small">
            <CalendarOutlined />
          </Badge>
        </Link>
      ),

      getItem(`${user.nombre}`, "26", <UserOutlined />, [
        getItem(
          <Link to="/password">Cambiar Contraseña</Link>,
          "27",
          <UserOutlined />
        ),
        // getItem(<Link to="/login">Salir</Link>,"28",<UserOutlined />),
        getItem(<Button onClick={salir}>Salir</Button>, "31", <UserOutlined />),
      ]),
    ]
  }

  if (role == "Seguimiento") {
    items = [
      // getItem(<Finder></Finder>),
      getItem(<Link to="/">Inicio</Link>, "1", <HomeOutlined />),
      getItem("Institucional", "2", <BarChartOutlined />, [
        getItem(<Link to="/docentes">Docentes</Link>, "3", <CheckOutlined />),
        getItem(
          <Link to="/estudiantes">Estudiantes</Link>,
          "4",
          <CheckOutlined />
        ),
      ]),

      getItem("Académico", "5", <SettingOutlined />, [
        getItem(<Link to="/cursos">Cursos</Link>, "6", <TeamOutlined />),
        getItem(
          <Link to="/examenes">Exámenes Internacionales</Link>,
          "7",
          <TeamOutlined />
        ),
        getItem(<Link to="/tutorias">Tutorias</Link>, "8", <TeamOutlined />),
        getItem(
          <Link to="/constancias">Constancias</Link>,
          "8a",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/derechos-grado">Derechos de grado</Link>,
          "8a3",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/mejoramiento">Planes de Mejoramiento</Link>,
          "252",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/formularios">
            Formularios de Exámenes Internacionales{" "}
          </Link>,
          "254",
          <TeamOutlined />
        ),
      ]),

      getItem("CRM", "9", <BarChartOutlined />, [
        //  getItem(<Link to="/embudo">Embudos</Link>,"6",<CheckOutlined />),
        getItem(
          <Link to="/leads">Oportunidades</Link>,
          "10",
          <CheckOutlined />
        ),
      ]),
      getItem("Financiero", "11", <SettingOutlined />, [
        getItem(<Link to="/tarifas">Tarifas</Link>, "12", <BarsOutlined />),
        getItem(
          <Link to="/tarifa/docente">Tarifa Docente</Link>,
          "13",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/liquidacion/docente">Liquidación Docente</Link>,
          "14",
          <BarsOutlined />
        ),
      ]),

      getItem("Ajustes", "29", <SettingOutlined />, [
        //  getItem(<Link to="/workgroup">Grupos de Trabajo</Link>,"16",<TeamOutlined />),
        getItem(<Link to="/usuario">Usuarios</Link>, "30", <UserOutlined />),
        //  getItem(<Link to="/medio">Medio de Contacto</Link>, "31",<BarsOutlined />),
        // getItem(<Link to="/canal">Canales de Contacto</Link>, "11",<BarsOutlined />),
        //  getItem(<Link to="/pregunta">Preguntas Adicionales</Link>, "33",<BarsOutlined />),
        //  getItem(<Link to="/carga">Carga Masiva</Link>, "20",<BarsOutlined />),
        //  getItem("Estructuración", "34", <BarChartOutlined />, [
        //    getItem(<Link to="/jornadas">Jornadas</Link>,"35",<CheckOutlined />),
        //    getItem(<Link to="/periodos">Periodos</Link>,"36",<CheckOutlined />),
        //    getItem(<Link to="/niveles">Niveles</Link>,"37",<CheckOutlined />),

        //  ]),
      ]),

      // getItem("Reportes", "15", <SettingOutlined />, [
      //   getItem(<Link to="/reporte-cuadre">Reportes Cuadre Mensual</Link>, "16",<BarsOutlined />),
      //   getItem(<Link to="/reporte-ingresos">Reportes Ingresos</Link>, "32",<BarsOutlined />),
      // ]),

      getItem(
        "",
        "25",
        <Link to="/notificaciones">
          <Badge count={notificaciones.length} size="small">
            <AlertOutlined />
          </Badge>
        </Link>
      ),
      getItem(
        "",
        "30",
        <Link to="/tareas">
          <Badge count={tareasHoy.length} size="small">
            <CalendarOutlined />
          </Badge>
        </Link>
      ),

      getItem(`${user.nombre}`, "26", <UserOutlined />, [
        getItem(
          <Link to="/password">Cambiar Contraseña</Link>,
          "27",
          <UserOutlined />
        ),
        // getItem(<Link to="/login">Salir</Link>,"28",<UserOutlined />),
        getItem(<Button onClick={salir}>Salir</Button>, "31", <UserOutlined />),
      ]),
    ]
  }

  if (role == "Cumplimiento") {
    items = [
      // getItem(<Finder></Finder>),
      getItem(<Link to="/">Inicio</Link>, "1", <HomeOutlined />),
      getItem("Institucional", "2", <BarChartOutlined />, [
        getItem(<Link to="/docentes">Docentes</Link>, "3", <CheckOutlined />),
        getItem(
          <Link to="/estudiantes">Estudiantes</Link>,
          "4",
          <CheckOutlined />
        ),
      ]),

      getItem("Académico", "5", <SettingOutlined />, [
        getItem(<Link to="/cursos">Cursos</Link>, "6", <TeamOutlined />),
        getItem(
          <Link to="/examenes">Exámenes Internacionales</Link>,
          "7",
          <TeamOutlined />
        ),
        getItem(<Link to="/tutorias">Tutorias</Link>, "8", <TeamOutlined />),
        getItem(
          <Link to="/constancias">Constancias</Link>,
          "8a",
          <TeamOutlined />
        ),
        getItem(
          <Link to="/derechos-grado">Derechos de grado</Link>,
          "8a3",
          <TeamOutlined />
        ),
      ]),

      getItem("CRM", "9", <BarChartOutlined />, [
        //  getItem(<Link to="/embudo">Embudos</Link>,"6",<CheckOutlined />),
        getItem(
          <Link to="/leads">Oportunidades</Link>,
          "10",
          <CheckOutlined />
        ),
      ]),
      getItem("Financiero", "11", <SettingOutlined />, [
        getItem(<Link to="/tarifas">Tarifas</Link>, "12", <BarsOutlined />),
        getItem(
          <Link to="/tarifa/docente">Tarifa Docente</Link>,
          "13",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/liquidacion/docente">Liquidación Docente</Link>,
          "14",
          <BarsOutlined />
        ),
      ]),

      getItem("Ajustes", "29", <SettingOutlined />, [
        //  getItem(<Link to="/workgroup">Grupos de Trabajo</Link>,"16",<TeamOutlined />),
        getItem(<Link to="/usuario">Usuarios</Link>, "30", <UserOutlined />),
        getItem(
          <Link to="/medio">Medio de Contacto</Link>,
          "31",
          <BarsOutlined />
        ),
        // getItem(<Link to="/canal">Canales de Contacto</Link>, "11",<BarsOutlined />),
        getItem(
          <Link to="/pregunta">Preguntas Adicionales</Link>,
          "33",
          <BarsOutlined />
        ),
        //  getItem(<Link to="/carga">Carga Masiva</Link>, "20",<BarsOutlined />),
        getItem("Estructuración", "34", <BarChartOutlined />, [
          getItem(
            <Link to="/jornadas">Jornadas</Link>,
            "35",
            <CheckOutlined />
          ),
          getItem(
            <Link to="/periodos">Periodos</Link>,
            "36",
            <CheckOutlined />
          ),
          getItem(<Link to="/niveles">Niveles</Link>, "37", <CheckOutlined />),
        ]),
      ]),

      getItem("Reportes", "15", <DotChartOutlined />, [
        getItem(
          <Link to="/reporte-cuadre">Reportes Cuadre Financiero</Link>,
          "26",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-academico">Reportes Cuadre Académico</Link>,
          "266",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-ingresos">Reportes Ingresos</Link>,
          "32",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-estudiantes">Reportes Estudiantes</Link>,
          "323",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-saldo">Reportes Saldos a favor</Link>,
          "3235",
          <BarsOutlined />
        ),
      ]),

      getItem(
        "",
        "25",
        <Link to="/notificaciones">
          <Badge count={notificaciones.length} size="small">
            <AlertOutlined />
          </Badge>
        </Link>
      ),
      getItem(
        "",
        "30",
        <Link to="/tareas">
          <Badge count={tareasHoy.length} size="small">
            <CalendarOutlined />
          </Badge>
        </Link>
      ),

      getItem(`${user.nombre}`, "26", <UserOutlined />, [
        getItem(
          <Link to="/password">Cambiar Contraseña</Link>,
          "27",
          <UserOutlined />
        ),
        // getItem(<Link to="/login">Salir</Link>,"28",<UserOutlined />),
        getItem(<Button onClick={salir}>Salir</Button>, "31", <UserOutlined />),
      ]),
    ]
  }

  if (role == "Coordinador Académico") {
    items = [
      getItem(<Link to="/">Inicio</Link>, "1", <HomeOutlined />),
      getItem("Institucional", "2", <BarChartOutlined />, [
        getItem(<Link to="/docentes">Docentes</Link>, "3", <CheckOutlined />),
        getItem(
          <Link to="/estudiantes">Estudiantes</Link>,
          "4",
          <CheckOutlined />
        ),
      ]),

      getItem("Académico", "5", <SettingOutlined />, [
        getItem(<Link to="/cursos">Cursos</Link>, "6", <TeamOutlined />),
        getItem(
          <Link to="/examenes">Exámenes Internacionales</Link>,
          "7",
          <TeamOutlined />
        ),
        getItem(<Link to="/tutorias">Tutorias</Link>, "8", <TeamOutlined />),
        getItem(
          <Link to="/mejoramiento">Planes de Mejoramiento</Link>,
          "252",
          <TeamOutlined />
        ),
      ]),

      getItem("Reportes", "15", <SettingOutlined />, [
        getItem(
          <Link to="/reporte-cuadre">Reportes Cuadre Financiero</Link>,
          "26",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-academico">Reportes Cuadre Académico</Link>,
          "266",
          <BarsOutlined />
        ),
        getItem(
          <Link to="/reporte-estudiantes">Reportes Estudiantes</Link>,
          "323",
          <BarsOutlined />
        ),
      ]),

      //  getItem("CRM", "9", <BarChartOutlined />, [
      //    //  getItem(<Link to="/embudo">Embudos</Link>,"6",<CheckOutlined />),
      //    getItem(<Link to="/leads">Oportunidades</Link>,"10",<CheckOutlined />),
      //  ]),
      //  getItem("Financiero", "11", <SettingOutlined />, [
      //    getItem(<Link to="/tarifas">Tarifas</Link>, "12",<BarsOutlined />),
      //    getItem(<Link to="/tarifa/docente">Tarifa Docente</Link>, "13",<BarsOutlined />),
      //    getItem(<Link to="/liquidacion/docente">Liquidación Docente</Link>, "14",<BarsOutlined />),
      //  ]),

      //  getItem("Ajustes", "29", <SettingOutlined />, [
      //   //  getItem(<Link to="/workgroup">Grupos de Trabajo</Link>,"16",<TeamOutlined />),
      //    getItem(<Link to="/usuario">Usuarios</Link>, "30", <UserOutlined />),
      //    getItem(<Link to="/medio">Medio de Contacto</Link>, "31",<BarsOutlined />),
      //    // getItem(<Link to="/canal">Canales de Contacto</Link>, "11",<BarsOutlined />),
      //    getItem(<Link to="/pregunta">Preguntas Adicionales</Link>, "33",<BarsOutlined />),
      //   //  getItem(<Link to="/carga">Carga Masiva</Link>, "20",<BarsOutlined />),
      //    getItem("Estructuración", "34", <BarChartOutlined />, [
      //      getItem(<Link to="/jornadas">Jornadas</Link>,"35",<CheckOutlined />),
      //      getItem(<Link to="/periodos">Periodos</Link>,"36",<CheckOutlined />),
      //      getItem(<Link to="/niveles">Niveles</Link>,"37",<CheckOutlined />),

      //    ]),
      //  ]),

      // getItem("Reportes", "15", <SettingOutlined />, [
      //   getItem(<Link to="/reporte-cuadre">Reportes Cuadre Mensual</Link>, "16",<BarsOutlined />),
      //   getItem(<Link to="/reporte-ingresos">Reportes Ingresos</Link>, "32",<BarsOutlined />),
      // ]),

      getItem("Tickets Soporte", "14544", <CheckOutlined />, [
        getItem(
          <a
            href="https://noteforms.com/forms/tickets-brighton-fwsufb"
            target="_blank"
          >
            Crear Ticket Soporte
          </a>,
          "24567",
          <CheckOutlined />
        ),
      ]),

      getItem(
        "",
        "25",
        <Link to="/notificaciones">
          <Badge count={notificaciones.length} size="small">
            <AlertOutlined />
          </Badge>
        </Link>
      ),
      //  getItem("", "30", <Link to="/tareas"><Badge  size="small"><CalendarOutlined /></Badge></Link> ),

      getItem(`${user.nombre}`, "26", <UserOutlined />, [
        getItem(
          <Link to="/password">Cambiar Contraseña</Link>,
          "27",
          <UserOutlined />
        ),
        // getItem(<Link to="/login">Salir</Link>,"28",<UserOutlined />),
        getItem(<Button onClick={salir}>Salir</Button>, "31", <UserOutlined />),
      ]),
    ]
  }

  if (role == "Marketing") {
    items = [
      getItem(<Link to="/">Inicio</Link>, "1", <HomeOutlined />),
      getItem("Institucional", "2", <BarChartOutlined />, [
        getItem(
          <Link to="/biblioteca">Biblioteca Virtual</Link>,
          "24567",
          <CheckOutlined />
        ),
        getItem(
          <Link to="/config-biblioteca">Config Biblioteca Virtual</Link>,
          "2467",
          <CheckOutlined />
        ),
        getItem(
          <Link to="/review-biblioteca">Archivos Biblioteca Virtual</Link>,
          "24674",
          <CheckOutlined />
        ),
      ]),

      getItem(`${user.nombre}`, "28", <UserOutlined />, [
        getItem(
          <Link to="/password">Cambiar Contraseña</Link>,
          "29",
          <UserOutlined />
        ),
        getItem(<Button onClick={salir}>Salir</Button>, "31", <UserOutlined />),
      ]),
    ]
  }

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Layout className="site-layout">
        <Menu
          mode="horizontal"
          items={items}
          style={{backgroundColor: "#031A29", color: "#F4F4F6"}}
        ></Menu>

        {(role == "Superadministrador" ||
          role == "Administrativo" ||
          role == "Seguimiento" ||
          role == "Cumplimiento") &&
          currentPath != "/ver-docente" &&
          currentPath != "/ver-estudiante" && (
            <Col sm={12} md={6}>
              <Finder></Finder>
            </Col>
          )}
        <Content
          style={{
            margin: "0 16px",
            overflowX: "scroll",
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            {props.children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default MyLayout
