import React from 'react';
import MyLayout from '../../components/MyLayout';
import { Alert, Button, Modal, Table, message } from 'antd';
import { useEffect } from 'react';
import notificacionService from '../../services/notificacion.service';
import { useState } from 'react';
import { useAuth } from '../../context/auth.context';
import { Link } from 'react-router-dom';
import moment from 'moment';
const Notificacion = () => {
    const [notificaciones,setNotificaciones] = useState([])
    const {role,user} = useAuth()
    const [viewReporte,setViewReporte] = useState(false)
    const [reporteSelected,setReporteSelected] = useState()
    const [tituloSelected,setTituloSelected] = useState()
    const [columns,setColumns] = useState([])
    useEffect(()=>{
        notificacionService.getByRole({role}).then(res=>{
            setNotificaciones(res.data.filter(item=>item.user_id == user.id || item.user_id == null))
        }).catch(error=>{
            console.log(error)
        })
    },[])

    const handleClose =(item) =>{
        notificacionService.delete(item.id).then(res=>{
            console.log(res)
        })
    }

    const verReporte = (record,titulo) =>{
        setReporteSelected(null)
        setViewReporte(true)
        let info = JSON.parse(record.descripcion)
        if(info.length != 0){
        const keys = Object.keys(info[0]);
        let col = []
        for(let item of keys){
          col.push({title:item, key: item, dataIndex: item}) 
           
        }
        console.log(col)

        setColumns(col)
        setReporteSelected(info)
        if(titulo != null){
        setTituloSelected(titulo)
    } else {
        setTituloSelected("Reporte")
    }
} else{
    message.error('El Reporte no contiene información')
}
    }

    return (
        <MyLayout>

{notificaciones.map((item)=>{
    const url = item.role !== 'Docente' ? '/ver-curso' : '/ver-curso/docente';

    if(item.tipo == "Curso"){
    return <><Alert
    message={item.descripcion  + "  (" + moment(item.createdAt).format("DD-MM-YYYY")+")"}
    description={<Link to={url}
    state={{curso: {id:item.relacion_id}}}> Ver {item.tipo}</Link>}
    type="info"
    showIcon
    closable
    afterClose={()=>{handleClose(item)}} 
  />
  <br />
  </>
  }

  

  if(item.tipo == "Estudiante"){
    return <><Alert
    message={item.descripcion}
    description={<Link to={"/ver-estudiante"}
    state={{estudiante: {id:item.relacion_id}}}>Ver {item.tipo} {item.titulo} </Link> }
    type="info"
    showIcon
    closable
    afterClose={()=>{handleClose(item)}} 
  />
  <br />
  </>
  }

  if(item.tipo == "Mejoramiento"){
    return <><Alert
    message={item.descripcion +" (" + moment(item.createdAt).format("DD-MM-YYYY")+")"}
     description={<Link to={"/mejoramiento"}>Ver {item.tipo}  </Link> }
    type="info"
    showIcon
    closable
    afterClose={()=>{handleClose(item)}} 
  />
  <br />
  </>
  }


  if(item.tipo == "Reporte"){
    return <><Alert
    message={"Se ha generado un reporte   (" + moment(item.createdAt).format("DD-MM-YYYY")+")"}
    description={<Button onClick={()=>verReporte(item,item.titulo)}> Ver {item.titulo} </Button>}
    type="info"
    showIcon
    closable
    afterClose={()=>{handleClose(item)}} 
  />
  <br />
  </>
  }

  
})}

{viewReporte && reporteSelected && (
    <Modal visible={viewReporte} title={tituloSelected} width={1080} onCancel={()=>setViewReporte(false)} onOk={()=>setViewReporte(false)}>
            
            <Table columns={columns} dataSource={reporteSelected}></Table>
    </Modal>
)}
           
        </MyLayout>
    );
};

export default Notificacion;