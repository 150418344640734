import React, {useState,useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom'
import docenteService from '../../services/docente.service';
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Col, Row, Collapse, Space,Divider,Tooltip,Modal, Avatar, Spin} from "antd";
import {LogoutOutlined, LineChartOutlined,CheckCircleOutlined,UserOutlined} from '@ant-design/icons'
import CursoDocente from '../../components/CursoDocente';
import Webcam from 'react-webcam'
import storage from "../../services/firebase"
import {ref, uploadBytesResumable,getDownloadURL} from "firebase/storage"
import { useAuth } from '../../context/auth.context';
const { Panel } = Collapse;
const Perfil = () => {

    const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const correo = user.correo
  const tipo = user.tipo
  const usuario_id = user.id;
  const [docente,setDocente] = useState()
  const [fotoPerfil,setFotoPerfil] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    getDocente()
  },[])

  const getDocente = () =>{
    setLoading(true)
    docenteService.getPerfil({correo,tipo}).then((response)=>{
        let filterDocente = response.data[0];
setDocente(filterDocente)
setFotoPerfil(filterDocente.url)
setLoading(false)
    }).catch(error=>{
        console.log(error)
        setLoading(false)
    })
  }
  if (loading)
  return (
    <div className="spin_container">
      <Spin className="spin" size="large" />
    </div>
  );
    return (
        <MyLayout>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Institucional</Breadcrumb.Item>
          <Breadcrumb.Item>Docente </Breadcrumb.Item>
        </Breadcrumb>

        {docente && (
            <>
        <Row>
            <Col span={24}>
                <div className='info'>
         
                { docente.url != null && (
       <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} src={docente.url}/>
      )
                }
     { docente.url == null && (
       <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} icon={<UserOutlined />}/>
      )} 
                <div className='nombre'>{docente.primerNombre} {docente.segundoNombre} {docente.primerApellido} {docente.segundoApellido}   <div className='numId'> {docente.tipoId} No. {docente.numId} </div></div>
              
                </div>
            </Col>

            <Col span={24}>
            <Space direction="vertical" className='space'>
                <Collapse collapsible="header" defaultActiveKey={['1']}>
                    <Panel header="Información personal" key="1">
                     <Row>
                        <Col xs={24} md={12}  className="columna">
                            <div><strong>Nombres:</strong> {docente.primerNombre} {docente.segundoNombre}</div>
                            <div><strong>Identificación:</strong> {docente.numId} </div>
                            <div><strong>Teléfono:</strong> {docente.telefono} </div>
                            <div><strong>Correo electrónico:</strong> {docente.correo} </div>
                            <div><strong>Lugar de residencia:</strong> {docente.residencia} </div>
                            <div><strong>Fecha de nacimiento:</strong> {docente.fechaNacimiento} </div>
                            <div><strong>Tipo: </strong> {docente.rango} </div>
                        </Col>

                        <Col xs={24} md={12} className="columna">
                        <div><strong>Apellidos:</strong> {docente.primerApellido} {docente.segundoApellido}</div>
                        <div><strong>Sexo:</strong> {docente.sexo} </div>
                        <div><strong>Celular:</strong> {docente.celular}</div>
                        <div><strong>Dirección:</strong> {docente.direccion} </div>
                        <div><strong>Barrio:</strong> {docente.barrio} </div>
                        <div><strong>Lugar de nacimiento:</strong> {docente.lugarNacimiento} </div>
                        <div><strong>Estado:</strong> {docente.estado} </div>
                        </Col>
                     </Row>
                    </Panel>                   
                </Collapse>

              



            </Space>
            </Col>
      
        </Row>
        </>
)}
      
    

        </MyLayout>
    );
};

export default Perfil;