import React, {useEffect, useState} from "react"
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  TimePicker,
} from "antd"
import moment from "moment"
import {Content} from "antd/lib/layout/layout"

import estudianteService from "../../services/estudiante.service"
import constanciaService from "../../services/constancia.service"
import {useAuth} from "../../context/auth.context"
import derechoService from "../../services/derecho.service"
const {TextArea} = Input
const DerechoModal = (props) => {
  const [loading, setLoading] = useState(false)

  const [estudiantes, setEstudiantes] = useState([])
  const [form] = Form.useForm()
  const {user, role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id

  useEffect(() => {
    listEstudiantes()
  }, [])

  const listEstudiantes = () => {
    setLoading(true)
    estudianteService
      .getAll()
      .then((response) => {
        let filterEstudiantes = response.data
        console.log(response.data)
        // filterEstudiantes = response.data.filter(
        //   (item) => item.estado == "Activo"
        // );
        setEstudiantes(filterEstudiantes.reverse())

        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const onFinish = (values) => {
    console.log(values)

    const form = values
    form.empresa_id = empresa_id
    form.usuario_id = usuario_id
    console.log(form)
    props.updateLoading(true)

    derechoService
      .create(form)
      .then((response) => {
        console.log(response.data)
        props.updateLoading(false)
        props.updateVisible(false)
        props.updateListConstancia()
        message.success("Guardado Correctamente")
      })
      .catch((e) => {
        props.updateLoading(false)
        message.error("No se ha podido guardar")
        console.log(e)
      })
  }

  const handleCancel = () => {
    props.updateVisible(false)
  }

  const onSearchEstudiante = (value) => {}

  return (
    <Modal
      width={1024}
      visible={props.visible}
      onOk={form.submit}
      onCancel={handleCancel}
    >
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{span: 24}}
          wrapperCol={{span: 20}}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="Estudiante: "
                name="estudiante_id"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Estudiante",
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={onSearchEstudiante}
                  optionFilterProp="children"
                >
                  {estudiantes.map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.primerNombre} {item.segundoNombre}{" "}
                      {item.primerApellido} {item.segundoApellido}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Fecha: "
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha",
                  },
                ]}
              >
                <Input type="date"></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  )
}

export default DerechoModal
