import http from "../utils/http-common";

class SeguimientoActividadService {
    getAll() {
      return http.get("/diario/actividad");
    }
    get(id) {
      return http.get(`/diario/actividad/${id}`);
    }

    getForain(id) {
      return http.get(`/diario/actividad/forain/${id}`);
    }

    finalizar(id) {
      return http.get(`/diario/actividad/finalizar/${id}`);
    }


    inactive(id) {
      return http.get(`/diario/actividad/inactive/${id}`);
    }


    create(data) {
      return http.post("/diario/actividad", data);
    }
    update(id, data) {
      return http.put(`/diario/actividad/${id}`, data);
    }
    delete(id) {
      return http.delete(`/diario/actividad/${id}`);
    }
   
  }
  export default new SeguimientoActividadService();