import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import { Breadcrumb, Table, Button, Spin, Input, Select, Avatar } from "antd";
import MyLayout from "../../components/MyLayout";
import docenteService from "../../services/docente.service";
import DocenteModal from "../../components/modals/DocenteModal";
import moment from 'moment'
import {SearchOutlined, UserOutlined} from '@ant-design/icons';
import myIcon from '../../services/icono.service'
import "./Docente.css"
import { useAuth } from '../../context/auth.context';

const Docente = () => {
    const [viewNewDocente, setViewNewDocente] = useState(false);
    const [loading, setLoading] = useState(false);
    const [docentes, setDocentes] = useState([]);
    const empresa_id = 1;
    const [estado,setEstado] = useState("Activo")
    const {role} = useAuth()

    const [pagination, setPagination] = useState({
      current: 1, // Página actual
      pageSize: 4, // Tamaño de página
    });

    const handlePaginationChange = (current, pageSize) => {
      setPagination({ current, pageSize });
    };

    const storageKey = 'docentePagination';
const storageFilter = 'docenteFilter';
let savedFilter;
    useEffect(() => {

      const savedPagination = JSON.parse(localStorage.getItem(storageKey));
      if(JSON.parse(localStorage.getItem(storageFilter))){
         savedFilter = JSON.parse(localStorage.getItem(storageFilter));
         setEstado(savedFilter)
         listDocentes(savedFilter);
      } else{
        localStorage.setItem(storageFilter, JSON.stringify(estado));
        listDocentes(estado);
      }

      if (savedPagination) {
        setPagination(savedPagination);
      }

       
      }, []);


      useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(pagination));
      }, [pagination]);

      useEffect(() => {
        localStorage.setItem(storageFilter, JSON.stringify(estado));
      }, [estado]);


      const listDocentes = (myestado) => {
        setLoading(true);
        docenteService
          .getForain(empresa_id)
          .then((response) => {
            let filterDocente = response.data;
            console.log(response.data);
            filterDocente = response.data.filter(
               (item) => item.estado == myestado
             );
           setEstado(myestado)
            setDocentes(filterDocente);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const formatName = (record)=>{
        let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
        nombre = nombre.toUpperCase()
        return nombre
      }

    const columns = [ {
      title:' ',
      render:(record) => { if(record.url != null){
      return <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} src={record.url}/>
    }
     else{
      return <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} icon={<UserOutlined />}/>
    } 
  }
    },
        {
          title: "Nombre",
          //dataIndex: "nombre",
          filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
            return <Input autoFocus placeholder='Ingrese Nombre' 
            value = {selectedKeys[0]} 
            onChange={(e)=>{
              setSelectedKeys(e.target.value?[e.target.value]:[])
             confirm({closeDropdown:false})
            }}
            onPressEnter={()=>{
              confirm()
            }} 
            
            onBlur={()=>{
              confirm()
            }} >
            
            </Input>
          },
          filterIcon:()=>{
            return <SearchOutlined />
          },
          onFilter:(value,record)=>{
            let nombre = record.primerNombre + " " + record.primerApellido + " " + record.segundoApellido
            return nombre.toLowerCase().includes(value.toLowerCase())
          },

          key: "primerNombre",
           render: (record) => <Link to={"/ver-docente"}
           state={{docente: record}}>{formatName(record)}</Link>,
        },
        {
            title: "No. Identificación",
            dataIndex: "numId",
            key: "numId",
            // render: (text) => <a>{text}</a>,
          },
        {
            title: "Celular",
            dataIndex: "celular",
            key: "celular",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Estado",
            // dataIndex: "estado",
            // key: "estado",
             render: (record) =>{ 
              if(record.estado == "Activo"){
                return <div style={{color:'green'}}>{record.estado}</div>
             } else{
              return <div style={{color:'red'}}>{record.estado}</div>
            }
            },
          },
         
          {
            title: "Fecha Registro",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Opciones",
           
            key: "action",
             //render: (medio) => <div>{medio.Medio.nombre}</div>,
          },
      ];

      
      const newDocente = () => {
        setViewNewDocente(true);
      };

      const filtrarEstado = (valor) =>{
        setEstado(valor)
        localStorage.setItem(storageFilter, JSON.stringify(valor));
        listDocentes(valor)
      }

      


  if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Institucional</Breadcrumb.Item>
        <Breadcrumb.Item>Docente</Breadcrumb.Item>
      </Breadcrumb>

      {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
      <Button type="primary" className='btn_action' onClick={newDocente}>
        Crear Docente
      </Button>
      )}

      <div className='filtro'> Filtrar: <Select defaultValue={estado}  onChange={filtrarEstado} style={{width:200}}> 
        <Select.Option  value="Activo">Activo</Select.Option> 
        <Select.Option value="Inactivo">Inactivo</Select.Option> 
        </Select>
        </div>

      {viewNewDocente && (

      <DocenteModal
        visible={viewNewDocente}
        updateVisible={setViewNewDocente}
        updateLoading={setLoading}
        updateListDocente={listDocentes}
      ></DocenteModal>
      )}
      

      <Table
        columns={columns}
        dataSource={docentes}
        pagination={{
          ...pagination,
          onChange: handlePaginationChange,
        }}
      />
    </MyLayout>
    );
};

export default Docente;