import React, { useEffect, useState } from 'react';
import MyLayout from '../../components/MyLayout';
import { Table, Button, Tooltip, Modal, Form, Input, message, Spin } from 'antd';
import mejoramientoService from '../../services/mejoramiento.service';
import { useAuth } from '../../context/auth.context';
import moment from 'moment';
import {
    FolderViewOutlined, FilePdfOutlined, SearchOutlined, FundViewOutlined, EditOutlined,DeleteOutlined, CloseCircleOutlined, CheckOutlined
  } from "@ant-design/icons";
import accionService from '../../services/accion.service';
import { useNavigate } from "react-router-dom";
import docenteService from '../../services/docente.service';

  const { TextArea } = Input;

const Mejoramiento = () => {
  const navigate = useNavigate();
    const [mejoramientos,setMejoramientos]= useState([])
    const [viewModalAcciones,setViewModalAcciones] = useState(false)
    const [formAccion] = Form.useForm()
    const [planSelected,setPlanSelected] = useState()
    const {user,role} = useAuth()
    const [docente,setDocente] = useState()
    const [loading,setLoading] = useState(false)
    const [viewAcciones,setViewAcciones] = useState(false)
    const [seguimientoSelected,setSeguimientoSelected] = useState()
    const [acciones,setAcciones] = useState([])
    const [viewModalEditAccion,setViewModalEditAccion] = useState(false)
    const [accionSelected,setAccionSelected] = useState()

    const [pagination, setPagination] = useState({
      current: 1, // Página actual
      pageSize: 4, // Tamaño de página
    });

    const handlePaginationChange = (current, pageSize) => {
      setPagination({ current, pageSize });
    };

const storageKey = 'mejoramientoPagination';

    

    const [formEditAccion] = Form.useForm()

    const {confirm} = Modal;

    function formatName (record){
        let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
        nombre = nombre.toUpperCase()
        return nombre
      }

    const columns = [

      {
        title: 'ID Plan',
        render:(record)=><div>{record.id}</div>
    },

        {
        title: 'ID Curso',
        render:(record)=><div>{record.Curso.id}</div>
    },
    {
        title: 'Curso',
        render:(record)=><div>{record.Curso.curso} - {record.Curso.nivel} - {record.Curso.ciclo}</div>
    },

    {
      title: 'Estudiante',
      filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
        return <Input autoFocus placeholder='Ingrese Nombre' 
        value = {selectedKeys[0]} 
        onChange={(e)=>{
          setSelectedKeys(e.target.value?[e.target.value]:[])
         confirm({closeDropdown:false})
        }}
        onPressEnter={()=>{
          confirm()
        }} 
        
        onBlur={()=>{
          confirm()
        }} >
        
        </Input>
      },
      filterIcon:()=>{
        return <SearchOutlined />
      },
      onFilter:(value,record)=>{
        let nombre = formatName(record.Estudiante)
        return nombre.toLowerCase().includes(value.toLowerCase())
      },
      render:(record)=><div>{formatName(record.Estudiante)}</div>
  },

    {
        title: 'Docente',
        filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
          return <Input autoFocus placeholder='Ingrese Nombre' 
          value = {selectedKeys[0]} 
          onChange={(e)=>{
            setSelectedKeys(e.target.value?[e.target.value]:[])
           confirm({closeDropdown:false})
          }}
          onPressEnter={()=>{
            confirm()
          }} 
          
          onBlur={()=>{
            confirm()
          }} >
          
          </Input>
        },
        filterIcon:()=>{
          return <SearchOutlined />
        },
        onFilter:(value,record)=>{
          let nombre = formatName(record.Docente)
          return nombre.toLowerCase().includes(value.toLowerCase())
        },
        render:(record)=><div>{formatName(record.Docente)}</div>
    },

    // {
    //     title: 'Fecha Inicio / Fecha Fin',
    //     render:(record)=><div>{moment(record.Curso.fechaInicial).format('DD-MM-YYYY')} al {moment(record.Curso.fechaFinal).format('DD-MM-YYYY')} </div>
    // },

    {
        title: 'Fecha Seguimiento',
        render:(record)=><div>{moment(record.createdAt).format('DD-MM-YYYY')}  </div>
    },

    {
      title: 'Estado',
      render:(record)=><div>{record.estado} </div>
  },

    {
        title: 'Opciones',
        render:(record)=>{
         
        if(record.estado == "Activo"){
       return <>
        <Tooltip title="Crear Seguimiento">
            <Button onClick={()=>{handlerAcciones(record)}} className='btn_action' type='primary'><FolderViewOutlined /></Button>
            </Tooltip>
      

        <Tooltip title="Ver acciones a desarrollar">
        <Button type='primary' onClick={()=>{setListAcciones(record)}} className='btn_action'><FundViewOutlined />
        </Button>
        </Tooltip> 

        {(role == "Superadministrador" || role == "Coordinador Académico") && (

        <Tooltip title="Cerrar Caso">
        <Button type='primary' onClick={()=>{setCerrarCaso(record)}} className='btn_action'><CloseCircleOutlined />
        </Button>
        </Tooltip> 

        )}

        <Tooltip title="Ver formato de Seguimiento">
        <Button type='primary' onClick={()=>{irHistorialSeguimiento(record)}} className='btn_action'><FilePdfOutlined />
        </Button>
        </Tooltip> 
        {role == "Superadministrador" && (
          <>
           <Tooltip title="Eliminar Seguimiento">
          <Button type='danger' onClick={()=>{deleteSeguimiento(record)}} className='btn_action'><DeleteOutlined />
          </Button>
          </Tooltip> 

         
          </>
         
        )}
          </>
        }else{
          return  <>
          <Tooltip title="Ver formato de Seguimiento">
        <Button type='primary' onClick={()=>{irHistorialSeguimiento(record)}} className='btn_action'><FilePdfOutlined />
        </Button>
        </Tooltip> 
        
        {role == "Superadministrador" && (
         <Tooltip title="Reactivar Seguimiento">
        <Button type='success' onClick={()=>{reactivarSeguimiento(record)}} className='btn_action'><CheckOutlined />
        </Button>
        </Tooltip> 
          )}
        </>
      
        
        }}
    },

    ]

    const setCerrarCaso = (record) =>{
      confirm({
        title:"Desea cerrar el caso del plan de mejoramiento?",
        okText:"Si",
        okType:'danger',
        cancelText:"No",
        onOk(){
          
            mejoramientoService.inactive(record.id)
            .then((response) => {
              console.log(response)
              getDocente()
              
            })
            .catch((e) => {
              console.log(e);
           
            });
            
            
        
          
        }})
    }


    const reactivarSeguimiento = (record) =>{
      confirm({
        title:"Desea reactivar el plan de mejoramiento?",
        okText:"Si",
        okType:'danger',
        cancelText:"No",
        onOk(){
          
            mejoramientoService.reactive(record.id)
            .then((response) => {
              console.log(response)
              getDocente()
              
            })
            .catch((e) => {
              console.log(e);
           
            });
            
            
        
          
        }})
    }

    const columnsAcciones = [
      {
        title: 'Causa',
        render:(record)=><div>{record.causa}</div>
    },

    {
      title: 'Fecha ',
      render:(record)=><div>{moment(record.createdAt).format('DD-MM-YYYY')}  </div>
  },

    {
      title: 'Acciones',
      render:(record)=><div><Button onClick={()=>{goEditAccion(record)}}><EditOutlined /></Button> 
      {/* <Button onClick={()=>{deleteAction(record)}} type='danger'><DeleteOutlined /></Button> */}
      </div>
  },
    ]

    useEffect(()=>{


      const savedPagination = JSON.parse(localStorage.getItem(storageKey));
      
      if (savedPagination) {
        setPagination(savedPagination);
      }
       
        getDocente()

    },[])


    useEffect(() => {
      localStorage.setItem(storageKey, JSON.stringify(pagination));
    }, [pagination]);

    const goEditAccion = (record) =>{
      setViewModalEditAccion(true)
      setAccionSelected(record)
      formEditAccion.setFieldsValue(record)
    }

    const editAccion = (values) =>{
      setLoading(true)
      mejoramientoService.update(accionSelected.id,values).then(res=>{
        console.log(res.data)
        message.success('Actualizado')
        setViewModalEditAccion(false)
        getAcciones(seguimientoSelected)
        getDocente()
        setLoading(false)
      }).catch(error=>{
        console.log(error)
        setLoading(false)
        message.error('No se ha podido actualizar')
      })
    }

    const handlerAcciones = (record) =>{
        setViewModalAcciones(true)
        setPlanSelected(record)
    }

     const irHistorialSeguimiento = (record)=>{
      navigate("/historial-seguimiento",{ state: { seguimiento: record } });
    }

    const setListAcciones = (record) =>{
      setSeguimientoSelected(null)
      setViewAcciones(true)
      setSeguimientoSelected(record)

    getAcciones(record)

    }

    const getAcciones = (plan) =>{
      mejoramientoService.getForain(plan.id).then(res=>{
        setAcciones(res.data)
        console.log(res.data)
      })
    }

    const guardarAccion = (values) =>{
      setLoading(true)
      console.log(values)
      let form = values
      form.mejoramiento_id = planSelected.id
      accionService.create(form).then(res=>{
        message.success("Registro Creado")
        formAccion.resetFields()
        setViewModalAcciones(false)
        setLoading(false)

        
      }).catch(error=>{
        message.success("Error al guardar")
        setLoading(false)
      })
    }


    const getDocente = () =>{
     setLoading(true)
      docenteService.getPerfil({correo:user.correo,tipo:role}).then((response)=>{
          let filterDocente = response.data[0];
          console.log("Docente",filterDocente)
  setDocente(filterDocente)
        if(role == "Docente"){
  getMejoramientos(filterDocente.id)
} else{
  getMejoramientos(1)
}

      }).catch(error=>{
          console.log(error)
          setLoading(false)
      })
    }


    const getMejoramientos = (id) =>{
        mejoramientoService.getAll().then(res=>{

          let mejoramientos = res.data
          console.log(user)
          console.log(mejoramientos)

          if(role == "Docente"){
            setMejoramientos(mejoramientos.filter(item=>item.docente_id == id))
          } else{
            setMejoramientos(res.data)
          }
        
          setLoading(false)
        }).catch(error=>{
          setLoading(false)
        })
    }

    const deleteSeguimiento = (record) =>{


      confirm({
        title:"Desea eliminar el plan de mejoramiento No. "+record.id+"?",
        okText:"Si",
        okType:'danger',
        cancelText:"No",
        onOk(){
          
          
          setLoading(true)
          mejoramientoService.delete(record.id).then(res=>{
            message.success("Eliminado Correctamente")
            getMejoramientos()
            setLoading(false)
          }).catch(error=>{
            message.error("No se pudo eliminar")
            setLoading(false)
          })
        
          
        }})

     
    }

    if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
    return (
        <MyLayout>
           <Table columns={columns} dataSource={mejoramientos} scroll={{ x: '100%'}} pagination={{
          ...pagination,
          onChange: handlePaginationChange,
        }} ></Table>

           {viewModalAcciones && (
            <Modal visible={viewModalAcciones} title="Registrar Acciones de Mejoramiento" okText="Guardar" onOk={formAccion.submit} onCancel={()=>setViewModalAcciones(false)}>

                <Form layout='vertical' form={formAccion} onFinish={guardarAccion}>
                    <Form.Item label = "Causa" name='causa' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Observacion",
                    },
                  ]}>
                        <TextArea></TextArea>
                    </Form.Item>

                    <Form.Item label = "Acciones a desarrollar" name='acciones' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Acciones a desarrollar",
                    },
                  ]}>
                        <TextArea></TextArea>
                    </Form.Item>


                     <Form.Item label = "Fecha de cumplimiento" name='fecha' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha de cumplimiento",
                    },
                  ]}>
                        <Input type='date'></Input>
                    </Form.Item>


                    <Form.Item label = "Responsable" name='responsable' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Responsable",
                    },
                  ]}>
                        <Input></Input>
                    </Form.Item>

                </Form>

            </Modal>
           )}


                {viewAcciones && acciones && (
                  <Modal visible={viewAcciones} title="Lista de Acciones a Desarrollar" okText="Cerrar" onCancel={()=>setViewAcciones(false)} onOk={()=>setViewAcciones(false)}> 
                    <Table columns={columnsAcciones} dataSource={acciones}></Table>
                  </Modal>
                )}


{viewModalEditAccion && (
            <Modal visible={viewModalEditAccion} title="Editar Acciones de Mejoramiento" okText="Editar" onOk={formEditAccion.submit} onCancel={()=>setViewModalEditAccion(false)}>

                <Form layout='vertical' form={formEditAccion} onFinish={editAccion}>
                    <Form.Item label = "Causa" name='causa' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Observacion",
                    },
                  ]}>
                        <TextArea></TextArea>
                    </Form.Item>

                    <Form.Item label = "Acciones a desarrollar" name='acciones' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Acciones a desarrollar",
                    },
                  ]}>
                        <TextArea></TextArea>
                    </Form.Item>


                     <Form.Item label = "Fecha de cumplimiento" name='fecha' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha de cumplimiento",
                    },
                  ]}>
                        <Input type='date'></Input>
                    </Form.Item>


                    <Form.Item label = "Responsable" name='responsable' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Responsable",
                    },
                  ]}>
                        <Input></Input>
                    </Form.Item>

                </Form>

            </Modal>
           )}


        </MyLayout>
    );
};

export default Mejoramiento;