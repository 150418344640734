import http from "../utils/http-common";

class TarifaRangoService {
    getAll() {
      return http.get("/tarifa/rango");
    }
    get(id) {
      return http.get(`/tarifa/rango/${id}`);
    }

    getForain(id) {
      return http.get(`/tarifa/rango/forain/${id}`);
    }

    create(data) {
      return http.post("/tarifa/rango", data);
    }
    update(id, data) {
      return http.put(`/tarifa/rango/${id}`, data);
    }
    delete(id) {
      return http.delete(`/tarifa/rango/${id}`);
    }
   
  }
  export default new TarifaRangoService();