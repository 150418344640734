import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import { Breadcrumb, Table, Button, Spin, Input, Layout } from "antd";
import MyLayout from "../../components/MyLayout";
import rangoDocenteService from "../../services/rango_docente.service";
import TarifaRangoModal from '../../components/modals/TarifaRangoModal';
import moment from 'moment'
import {SearchOutlined} from '@ant-design/icons';
import myIcon from '../../services/icono.service'
import { NumericFormat } from 'react-number-format';

const TarifaDocente = () => {
    const [rangos, setRangos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewNewTarifa, setViewNewTarifa] = useState(false);
    const [rangoSelected,setRangoSelected] = useState()
    const empresa_id = 1

  
    const columns = [
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            // render: (text) => <a>{text}</a>,
          },
          {
            title:'Opciones',
            render: (record)=> <Button type="primary" onClick={()=>{newTarifa(record)}}>Tarifas</Button>
          }
        
    ]

    useEffect(() => {
        
      listRangoDocente();
      }, []);


      const listRangoDocente = () => {
        setLoading(true);
        rangoDocenteService
          .getForain(empresa_id)
          .then((response) => {
            let filterRango = response.data;
            console.log(response.data);
            // filterMedios = response.data.filter(
            //   (item) => item.empresa_id == empresa_id
            // );
            setRangos(filterRango);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const newTarifa = (record) => {
        setViewNewTarifa(true);
        setRangoSelected(record)
      };

      
    return (
        <MyLayout>
           <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Financiero</Breadcrumb.Item>
        <Breadcrumb.Item>Tarifas Docente</Breadcrumb.Item>
      </Breadcrumb>


      {viewNewTarifa && rangoSelected && (
      <TarifaRangoModal
        visible={viewNewTarifa}
        updateVisible={setViewNewTarifa}
        updateLoading={setLoading}
        updateListTutoria={listRangoDocente}
      >{rangoSelected}</TarifaRangoModal>
      )}


        <Table
        columns={columns}
        dataSource={rangos}
        pagination={{ pageSize: 4 }}
      />
      </MyLayout>
    );
};

export default TarifaDocente;