import React, {useState, useEffect} from "react"
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Table,
} from "antd"
import {Content} from "antd/lib/layout/layout"

const Ciclosq10Modal = (props) => {
  const [form] = Form.useForm()
  const ciclosq10 = props.children
  const [ciclos, setCiclos] = useState()

  useEffect(() => {
    organizarData(ciclosq10)
  }, [])

  const handleCancel = () => {
    props.updateVisible(false)
  }

  const organizarData = (data) => {
    let cursos = []
    let cursosAsignatura = []

    console.log("ciclos", data)

    for (let curso of data) {
      if (
        cursos.filter((item) => item.curso == curso.codigo_curso).length == 0
      ) {
        cursos.push({curso: curso.codigo_curso, asignatura: curso.asignatura})
      }
    }

    for (let c of cursos) {
      let totalNotaCurso = 0
      let cursosparams = []
      let cursosSubparam = []
      let dataFilter = data.filter((item) => item.codigo_curso == c.curso)
      for (let dataF of dataFilter) {
        if (
          cursosparams.filter(
            (item) => item.parametroP == dataF.parametro_padre
          ).length == 0
        ) {
          cursosparams.push({
            parametroP: dataF.parametro_padre,
            porcentajeP: dataF.porcentaje_padre,
          })
        }
      }
      for (let para of cursosparams) {
        let totalNotasSub = 0
        let totalNotasPadre = 0
        let datasub = dataFilter.filter(
          (item) => item.parametro_padre == para.parametroP
        )
        for (let notasSub of datasub) {
          totalNotasSub += notasSub.nota * (notasSub.porcentaje_hijo / 100)
        }
        totalNotasPadre = totalNotasSub * (para.porcentajeP / 100)
        cursosSubparam.push({
          padre: para,
          hijo: datasub,
          notasHijoResumen: totalNotasSub,
          notaPadre: totalNotasPadre,
        })
      }

      for (let nPadre of cursosSubparam) {
        totalNotaCurso += nPadre.notaPadre
      }

      let estado

      totalNotaCurso < 2.95 ? (estado = "No aprobada") : (estado = "Aprobada")

      cursosAsignatura.push({
        curso: c,
        totalGeneral: totalNotaCurso,
        data: cursosSubparam,
        estado: estado,
      })
    }

    console.log(cursosAsignatura)
    setCiclos(cursosAsignatura)

    // let cortes = []
    // let corteCompleto = []
    // let notas = []
    // let cursos = []
    // console.log(data)

    // for(let dato of data){

    //   if(cursos.filter(item=>item.curso == dato.codigo_curso).length == 0){

    //     let parametros = data.filter(item=>item.codigo_curso == dato.codigo_curso)

    //     for(let param of parametros){
    //       if(cortes.filter(item=>item.parametro_padre == param.parametro_padre && item.codigo_curso == dato.codigo_curso).length == 0){
    //         cortes.push({parametro_padre:param.parametro_padre,porcentaje_padre:param.porcentaje_padre})
    //       }
    //     }

    //     for(let corte of cortes){
    //       let notas = parametros.filter(item=>item.parametro_padre == corte.parametro_padre)
    //       corteCompleto.push({parametro_padre:corte.parametro_padre,porcentaje_padre:corte.parametro_padre})

    //     }

    //     cursos.push({asignatura:dato.asignatura,curso:dato.codigo_curso, cortes:cortes})

    //     console.log("corte",cortes)

    //     // for(let item of parametros){
    //     //   cortes.push
    //     // }

    //     // for(let curso of cursos){
    //     //   let padres = data.filter(item=>item.parametro_padre == curso.parametro_padre)
    //     // }

    //    }
    //   }

    //   console.log(cursos)

    // let asignaturas = []

    // for(let dato of data){

    //   let parametropadre = []
    //   let parametrohijo = []
    //   if(asignaturas.filter(item=>item.asignatura == dato.asignatura).length == 0){

    //    let niveles = data.filter(item=>item.asignatura == dato.asignatura)

    //    for(let nivel of niveles){
    //     if(parametropadre.filter(item=>item.parametro_padre == nivel.parametro_padre).length == 0){
    //       parametropadre.push({parametro_padre:nivel.parametro_padre,porcentaje_padre:nivel.porcentaje_padre})
    //     }
    //    }

    //    for(let hijo of parametropadre){
    //     let hijos = data.filter(item=>item.parametro_padre == hijo.parametro_padre && item.asignatura == dato.asignatura).sort()

    //     let cursos = []
    //     let totalCorte = 0
    //     for(let item of hijos){
    //       totalCorte += item.nota * (item.porcentaje_hijo/100)
    //       if(cursos.filter(mycurso=>mycurso.curso == item.codigo_curso).length == 0){
    //         let NotasCurso = hijos.filter(h=>h.codigo_curso == item.codigo_curso)
    //         cursos.push({curso:item.codigo_curso,notas:NotasCurso, padre:hijo})
    //       }
    //     }

    //     console.log(cursos)

    //     parametrohijo.push({padre:hijo,hijo:hijos,totalCorte:totalCorte,cursos:cursos})
    //    }

    //     asignaturas.push({asignatura:dato.asignatura,cortes:parametrohijo})
    //   }
    // }

    // console.log(asignaturas)

    // setCiclos(asignaturas)
  }

  function normalizeAndSum(object, parent) {
    if (object.hijo) {
      let tamanohijo = object.hijo.length
      object.hijo = Object.values(object.hijo)

      for (const child of object.hijo) normalizeAndSum(child, object)
    }
    if (parent) {
      let tamanohijo = parent.hijo.length
      parent.nota =
        parent.hijo.reduce((acc, {nota}) => acc + nota, 0) / tamanohijo
    }

    return object
  }

  const columns = [
    {
      title: "Asignatura",
      render: (record) => <div>{record.asignatura}</div>,
    },
    //   {
    //     title:'Nota',
    //     render:(record)=><div>{(record.nota)}</div>
    // },
  ]

  const onFinish = (values) => {
    props.updateLoading(false)
    props.updateVisible(false)
  }
  return (
    <Modal
      width={1080}
      visible={props.visible}
      onOk={form.submit}
      onCancel={handleCancel}
    >
      <Content>
        {ciclos && (
          <table style={{width: "100%"}}>
            <tr>
              <th>Asignatura</th>
              <th>Estado</th>
              <th>Nota</th>
            </tr>
            {ciclos.map((item) => {
              return (
                <>
                  <tr>
                    <td> {item.curso.asignatura}</td>
                    <td> {item.estado}</td>
                    <td> {item.totalGeneral.toFixed(1)}</td>
                  </tr>
                  {/* <tr>
          {item.cortes.map((corte=>{
            return <td>{corte.padre.parametro_padre} {corte.padre.porcentaje_padre}%</td>
          }))}
          </tr>

          <tr>
          {item.cortes.map((corte=>{
      return <td>{parseFloat(corte.totalCorte).toFixed(1)}</td>
          }))}
          </tr> */}
                </>
              )
            })}
          </table>
        )}
      </Content>
    </Modal>
  )
}

export default Ciclosq10Modal
