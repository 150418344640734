import React, {useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Spin
} from "antd";
import { Content } from "antd/lib/layout/layout";
import archivoCursoService from "../../services/archivo_curso.service";
import { useAuth } from '../../context/auth.context';
import storage from "../../services/firebase"
import {ref, uploadBytesResumable,getDownloadURL} from "firebase/storage"
import { useEffect } from "react";
import calendarioService from "../../services/calendario.service"
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import seguimiento_actividadService from "../../services/seguimiento_actividad.service";

const SeguimientoActividadModal = (props) => {
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  const curso_id = props.curso_id
  const [clases,setClases] = useState()

  useEffect(()=>{
    listHorario()
  },[])

  const listHorario = () =>{
    calendarioService.getForain(curso_id).then(res=>{
      let listaPermitidos = []
      for(let item of res.data){
        let hoy = moment()
      let fecha = moment(item.start).format('YYYY-MM-DD')
      let dia = moment(item.start).day()

      let diferencia = hoy.diff(fecha,'hours')

      if(dia != 6){
      if(diferencia <38){
        listaPermitidos.push(item)
      }
    } else{
      if(diferencia < 62){
        listaPermitidos.push(item)
      }
    }
      }

      if(role == "Docente"){
      setClases(listaPermitidos)
    }else{
      setClases(res.data)
    }
    })
  }



  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
 
   
    const form = values;
    form.empresa_id = empresa_id;
    form.usuario_id = usuario_id;
    form.curso_id = curso_id

    seguimiento_actividadService.create(form).then(res=>{
      console.log(res)
     
      props.updateVisible(false);
      props.updateSeguimientos()
      message.success("Registro Guardado")
    }).catch(error=>{
      console.log(error)
     
      props.updateVisible(false);
      message.error("No se ha podido registrar")
    })

        };



  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
  return (
    <Modal width={720} visible={props.visible} onOk={form.submit} onCancel={handleCancel} okText="Guardar Actividades" cancelText="Cancelar">
      <Content>
        {clases && clases.length>0 && (
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Fecha de Clase: (DD-MM-YYYY) "
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione Fecha de Clase",
                  },
                ]}
              >
                 {clases && (
                <Select> 
                 {clases.map(item=>{
                  return   <Select.Option value={item.start}>{moment(item.start).format('DD-MM-YYYY')}</Select.Option>
                 })}
                   
                 
                    
                
                </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Objetivo de clase: "
                name="objetivo"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione Objetivo de Clase",
                  },
                ]}
              >
                 <TextArea></TextArea>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Habilidad: "
                name="habilidad"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese habilidad",
                  },
                ]}
              >
                 <TextArea></TextArea>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Describa actividades realizadas: "
                name="actividades"
                rules={[
                  {
                    required: true,
                    message: "Por favor describa actividades realizadas",
                  },
                ]}
              >
                 <TextArea></TextArea>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Observaciones: "
                name="observaciones"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese observaciones",
                  },
                ]}
              >
                 <TextArea></TextArea>
              </Form.Item>
            </Col>

          </Row>
        </Form>
        )}

{clases && clases.length== 0 && (
    
   <div style={{textAlign:'center'}}><h2>No existen fechas disponibles</h2></div> 
)
}
      </Content>
    </Modal>
  );
};

export default SeguimientoActividadModal;
