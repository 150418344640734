import React, {useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Spin
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useAuth } from '../../../context/auth.context';
import { useEffect } from "react";
import calendarioService from "../../../services/calendario.service"
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import seguimiento_actividadService from "../../../services/seguimiento_actividad.service";

const SeguimientoActividadEditModal = (props) => {
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  const seguimiento = props.children
  const curso_id = seguimiento.curso_id
  const [clases,setClases] = useState()

  useEffect(()=>{
    listHorario()
    form.setFieldsValue(seguimiento)
    form.setFieldsValue({fecha:moment(seguimiento.fecha).format('MM-DD-YYYY')})
  },[])

  const listHorario = () =>{
    calendarioService.getForain(curso_id).then(res=>{
      console.log(res.data)
      
      setClases(res.data)
    })
  }



  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
 
   
    const form = values;
    form.empresa_id = empresa_id;
    form.usuario_id = usuario_id;
    form.curso_id = curso_id
    form.fecha = moment(values.fecha).format('MM-DD-YYYY')

    seguimiento_actividadService.update(seguimiento.id,form).then(res=>{
      console.log(res)
     
      props.updateVisible(false);
      props.updateSeguimientos()
      message.success("Registro Guardado")
    }).catch(error=>{
      console.log(error)
     
      props.updateVisible(false);
      message.error("No se ha podido registrar")
    })

        };



  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
  return (
    <Modal width={720} visible={props.visible} onOk={form.submit} onCancel={handleCancel} okText="Guardar Actividades" cancelText="Cancelar">
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Fecha de Clase: "
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione Fecha de Clase",
                  },
                ]}
              >
                 {clases && (
                <Select> 
                 {clases.map(item=>{
                  return   <Select.Option value={moment(item.start).format('MM-DD-YYYY')}>{moment(item.start).format('DD-MM-YYYY')}</Select.Option>
                 })}
                   
                 
                    
                
                </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Objetivo de clase: "
                name="objetivo"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione Objetivo de Clase",
                  },
                ]}
              >
                 <TextArea></TextArea>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Habilidad: "
                name="habilidad"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese habilidad",
                  },
                ]}
              >
                 <TextArea></TextArea>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Describa actividades realizadas: "
                name="actividades"
                rules={[
                  {
                    required: true,
                    message: "Por favor describa actividades realizadas",
                  },
                ]}
              >
                 <TextArea></TextArea>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Observaciones: "
                name="observaciones"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese observaciones",
                  },
                ]}
              >
                 <TextArea></TextArea>
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default SeguimientoActividadEditModal;
