import React, { useEffect,useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import moment from "moment";
import logo from "./logo";
import regular from './fonts/Roboto-Regular.ttf'
import bold from './fonts/Roboto-Bold.ttf'
import matriculaService from "../../services/matricula.service";

Font.register({ family: 'Roboto', fonts:[{src:regular},{src:bold, fontWeight: 'bold'}] });



const styles = StyleSheet.create({
  body: {
    padding: 25,
    fontFamily: 'Roboto'
    
  },


  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:20
  },

  table2: {
    display: "table",
    width: "auto",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableRow2: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol2: {
    width: "80%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol3: {
    width: "20%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol4: {
    width: "50%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    
  },

  tableCell2: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },

  tableCellLeft: {
    textAlign: 'left',
    marginTop: 5,
    fontSize: 10,
  },

  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "14.2857143%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
   
  },

  tableCol5: {
    width: "25%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
   
  },

  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },

  
  logo: {
    width: 50,
    height: 70,
    textAlign: "center",
  },

  footer:{
    position: 'absolute',
    bottom: 30,
    textAlign: 'center',
    fontSize: 8,
    left: 0,
    right: 0,
  },
  footer2:{
    position: 'absolute',
    bottom: 10,
    textAlign: 'center',
    fontSize: 8,
    left: 0,
    right: 0,
  },

  titulo:{
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    
  },

  negrita:{
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  }

});

const formatName = (record)=>{
  let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
  nombre = nombre.toUpperCase()
  return nombre
}

const CursoEstudiantesPDF = (props) => {
   const matriculas = props.children;
  const curso = props.curso;
  console.log(matriculas)
  console.log(curso)
  // const [matriculas,setMatriculas] = useState()


  // useEffect(()=>{
  //   matriculaService.getForain(curso.id).then(res=>{
  //     setMatriculas(res.data)
  //   })
  // },[])

  const [numPages, setNumPages] = useState(0);
      const [pageNumber, setPageNumber] = useState(1);
    
      function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }

  return (
    <Document>
      <Page size="letter" orientation='landscape' style={styles.body}>

      

      <View style={styles.table2}>
      <View style={styles.tableRow2}>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell2}>
              <Image style={[styles.logo]} src={logo}></Image>
              </Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={[styles.tableCell2,styles.titulo]}>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS</Text>

          

              <View style={styles.table2}>
      <View style={styles.tableRow2}>
<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Código: 0 </Text>
</View>
<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Versión: 1 </Text>
</View>

<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Fecha Versión : 4/05/2015 </Text>
</View>

<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Página: 1 </Text>
</View>

</View>
</View>



              <Text style={[styles.tableCell2,styles.titulo]}>ESTUDIANTES MATRICULADOS</Text>
             
            </View>
        </View>
      </View>

      <View style={styles.table2}>
      <View style={styles.tableRow2}>
      <View style={styles.tableCol4}>
      <Text style={styles.tableCellLeft}>
      <Text style={styles.negrita}>Curso/Nivel: </Text>   {curso.curso} / {curso.nivel} 
      </Text>
      </View>

      <View style={styles.tableCol4}>
      <Text style={styles.tableCellLeft}>
      <Text style={styles.negrita}>Jornada: </Text> {curso.jornada}
      </Text>
      </View>
      </View>
      </View>

      <View style={styles.table2}>
      <View style={styles.tableRow2}>
      <View style={styles.tableCol4}>
      <Text style={styles.tableCellLeft}>
      <Text style={styles.negrita}>Ciclo: </Text>  {curso.ciclo}
      </Text>
      </View>

      <View style={styles.tableCol4}>
      <Text style={styles.tableCellLeft}>
      <Text style={styles.negrita}>Docente: </Text>   {formatName(curso.Docente)}
      </Text>
      </View>
      </View>
      </View>

      <View style={styles.table2}>
      <View style={styles.tableRow2}>
      <View style={styles.tableCol4}>
      <Text style={styles.tableCellLeft}>
      <Text style={styles.negrita}>Programa: </Text>  {curso.idioma}
      </Text>
      </View>

      <View style={styles.tableCol4}>
      <Text style={styles.tableCellLeft}>
      <Text style={styles.negrita}>Periodo: </Text>   {curso.periodo}
      </Text>
      </View>
      </View>
      </View>

      <View style={styles.table2}>
      <View style={styles.tableRow2}>
      <View style={styles.tableCol4}>
      <Text style={styles.tableCellLeft}>
      <Text style={styles.negrita}>Fecha inicial: </Text>  {moment(curso.fechaInicial).format("DD-MM-YYYY")}
      </Text>
      </View>

      <View style={styles.tableCol4}>
      <Text style={styles.tableCellLeft}>
      <Text style={styles.negrita}>Fecha final: </Text>  {moment(curso.fechaFinal).format("DD-MM-YYYY")}
      </Text>
      </View>
      </View>
      </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>

          <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Código</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Estudiante</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Identificación</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Teléfono</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Celular</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Correo</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Estado</Text>
            </View>
          </View>

          {matriculas.map((item) => {
            return (
              <View style={styles.tableRow}>

<View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.Estudiante.id} </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatName(item.Estudiante)}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.Estudiante.numId} </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.Estudiante.telefono}{" "}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.Estudiante.celular}{" "}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.Estudiante.correo}{" "}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.estado} </Text>
                </View>
              </View>
            );
          })}
    
        </View>



         
        <Text style={[styles.footer]}>Brighton - Centro de Formación en Inglés 
        </Text>
        
          <Text style={[styles.footer2]} > Fecha de impresión: {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}</Text>
           
       

      </Page>
    </Document>
  );
};

export default CursoEstudiantesPDF;
