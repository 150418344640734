import React, {useState} from 'react';
import './CursoEstudiante.css'
import {Row, Col, Button, Tooltip, Collapse} from 'antd'
import {LogoutOutlined, LineChartOutlined,CheckCircleOutlined,UserOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import RetiroModal from './modals/RetiroModal';
import {Link} from 'react-router-dom'
import moment from 'moment';
import './CursoDocente.css'
const { Panel } = Collapse;
const CursoDocente = (props) => {
    const curso = props.children
    const matricula = props.children
    const [viewRetiroModal,setViewRetiroModal] = useState(false);
    const [matriculaSelected,setMatriculaSelected] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const viewHistomodal = (record)=>{
      setViewRetiroModal(true)
      setMatriculaSelected(record.id)
    }

    const updateDocente = () =>{
      props.updateDocente()
    }

    const validarHorario = (record) =>{
      
      if(record.horaInicial){

        let cadenaHorario = ""
        let dias = JSON.parse(record.diasHorario)
        for(let item of dias){
          cadenaHorario += item.substr(0,3) + ", "
        }

        cadenaHorario += "- "+ moment(record.fechaInicial + " "+record.horaInicial).format('hh:mm a') + " a " + moment(record.fechaInicial + " "+record.horaFinal).format('hh:mm a')
        
        return cadenaHorario
      } else{
        return "No hay calendario asignado"
      }

    }

    const irCurso = (curso) =>{
      navigate("/ver-curso/docente",{ state: { curso: curso } });
    }

    return (

<Collapse  >
<Panel header={ "Cod. "+curso.id+ " / "+ curso.idioma +"-"+ curso.curso +"-"+ curso.nivel +"-"+ curso.ciclo} >
          <Row>
          <Col xs={24} md={12} >
             <div className='fechai'> <strong> Fecha de Inicio: </strong> {moment(curso.fechaInicial).format("DD-MM-YYYY")} </div>
             </Col>
             <Col xs={24} md={12} >
             <div className='fechaf'> <strong>Fecha Final : </strong> {moment(curso.fechaFinal).format("DD-MM-YYYY")}</div>
             </Col>

             <Col xs={24} md={12} >
             <div className='fechaf'> <strong>Horario : </strong> {validarHorario(curso)} </div>
             </Col>

             <Col xs={24} md={12} >
             <div className='jornada'><strong> Jornada: </strong>  {curso.jornada}</div>
           </Col>
           <Col xs={24} md={12} >
             <div className='periodo'><strong> Periodo: </strong> {curso.periodo}</div>
             </Col>
             <Col xs={24} md={12} >
             <div className={curso.estado == "Activo" ? 'activeCourse': 'finalizeCourse'}><strong>  </strong> {curso.estado}</div>
             </Col>

             <Col xs={24} md={12} >
             <div className='estado'><strong> Horas del curso: </strong> {parseInt(curso.horas)}</div>
             </Col>

             <Col xs={24} md={12} >
             <div><Button type='primary' onClick={()=>{irCurso(curso)}}>Ver Curso</Button></div>
             </Col>

             {/* {curso.modo == "Curso" && (
               <Col xs={24} md={12} >
            <Tooltip title="Ver evaluaciones">
                            <Link to={"/ver-evaluacion"} state={{curso:curso}} >
              <span className='btn_option'>
              Evaluaciones <LineChartOutlined />
                </span>
                </Link>
                </Tooltip> 
                </Col>
)}
 <Col xs={24} md={12} >
                <Tooltip title="Ver inasistencias">
                   <Link to={"/ver-inasistencia"} state={{curso:curso}} >
              <span className='btn_option'>
              Inasistencias <CheckCircleOutlined />
                </span>
                </Link>
                </Tooltip>
                </Col> */}
             
          </Row>
      </Panel>

      {matriculaSelected && (
          <RetiroModal 
          matricula_id={matriculaSelected}
          updateLoading={setLoading}
          visible={viewRetiroModal} 
          updateVisible={setViewRetiroModal}
          updateDocente = {updateDocente}
          >

          </RetiroModal>
          )}
</Collapse>
    );
};

export default CursoDocente;