import React, {useState, useEffect} from 'react';

import { Breadcrumb, Table, Button, Spin, Input } from "antd";
import MyLayout from "../../components/MyLayout";
import nivelService from "../../services/nivel.service";
import NivelModal from "../../components/modals/NivelModal";
import moment from 'moment'


const Nivel = () => {
    const [viewNewNivel, setViewNewNivel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [niveles, setNiveles] = useState([]);
    const empresa_id = 1;

    useEffect(() => {
     
        listNiveles();
      }, []);


      const listNiveles = () => {
        setLoading(true);
        nivelService
          .getForain(empresa_id)
          .then((response) => {
            let filterNiveles = response.data;

            setNiveles(filterNiveles);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

    const columns = [

        {
            title: "Código",
            dataIndex: "codigo",
            key: "codigo",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Tipo",
            dataIndex: "tipo",
            key: "tipo",
            // render: (text) => <a>{text}</a>,
          },
        
        {
            title: "Nivel",
            dataIndex: "nombre",
            key: "nombre",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Ciclo",
            dataIndex: "ciclo",
            key: "ciclo",
            // render: (text) => <a>{text}</a>,
          },

        

          {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            // render: (text) => <a>{text}</a>,
          },
       
         
          {
            title: "Fecha Registro",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Opciones",
           
            key: "action",
             //render: (medio) => <div>{medio.Medio.nombre}</div>,
          },
      ];

      
      const newNivel = () => {
        setViewNewNivel(true);
      };


  if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Institucional</Breadcrumb.Item>
        <Breadcrumb.Item>Niveles</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newNivel}>
        Crear Nivel
      </Button>

      <NivelModal
        visible={viewNewNivel}
        updateVisible={setViewNewNivel}
        updateLoading={setLoading}
        updateListNivel={listNiveles}
      ></NivelModal>

      

      <Table
        columns={columns}
        dataSource={niveles}
        pagination={{ pageSize: 4 }}
      />
    </MyLayout>
    );
};

export default Nivel;