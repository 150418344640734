import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import { Breadcrumb, Table, Button, Spin, Input, Modal, Select } from "antd";
import MyLayout from "../../components/MyLayout";
import leadService from "../../services/lead.service";
import LeadModal from "../../components/modals/LeadModal";
import moment from 'moment'
import {SearchOutlined, DeleteOutlined} from '@ant-design/icons';
import myIcon from '../../services/icono.service'
import { useAuth } from '../../context/auth.context';
const Leads = () => {
  const {confirm} = Modal;
    const [viewNewLead, setViewNewLead] = useState(false);
    const [loading, setLoading] = useState(false);
    const [leads, setLeads] = useState([]);
    const [leadsFilter, setLeadsFilter] = useState([]);
    const [estado,setEstado] = useState([ 'Presentación'])
    const empresa_id = 1;
    const {role} = useAuth()
    const [filter,setFilter] = useState([ 'Presentación' ])
    const [pagination, setPagination] = useState({
      current: 1, // Página actual
      pageSize: 4, // Tamaño de página
    });

    

    const handlePaginationChange = (current, pageSize) => {
      setPagination({ current, pageSize });
    };

const storageKey = 'crmPagination';
const storageFilter = 'crmFilter';
let savedFilter;
    useEffect(() => {
      const savedPagination = JSON.parse(localStorage.getItem(storageKey));
      if(JSON.parse(localStorage.getItem(storageFilter))){
        
         savedFilter = JSON.parse(localStorage.getItem(storageFilter));
         console.log("SAVE FILTER",savedFilter)
         if(savedFilter.length>0){
         setFilter(savedFilter);
         setEstado(savedFilter)
        } else{
          localStorage.setItem(storageFilter, JSON.stringify([ 'Presentación']));
        }
      } else{
        localStorage.setItem(storageFilter, JSON.stringify([ 'Presentación' ]));
      }
     
      if (savedPagination) {
        setPagination(savedPagination);
      }


        listLeads();
      }, []);


      useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(pagination));
      }, [pagination]);

      useEffect(() => {
        localStorage.setItem(storageFilter, JSON.stringify(filter));
      }, [filter]);

      const deleteLead = (record) =>{
       
        confirm({
          title:"Desea eliminar la oportunidad, No podrá recuperarse?",
          okText:"Si",
          okType:'danger',
          cancelText:"No",
          onOk(){
            setLoading(true)
              leadService.delete(record.id)
              .then((response) => {
                listLeads()
                setLoading(false)
              })
              .catch((e) => {
                console.log(e);
                setLoading(false)
              });
              
           
          }})
      }

      const filtrarEstado = (valores) =>{
        setFilter(valores)
        setEstado(valores)
        localStorage.setItem(storageFilter, JSON.stringify(valores));
        listLeads()

      //   let dataAll = leads
      //   if(valores.length>0){
      //   console.log(valores)
        
      //   let query = "";
      //   for(let item of valores){
      //     query += item
      //   }

      //   console.log(query)
      //   let filtro = dataAll.filter(item=>valores.includes(item.Fase.nombre))
      //   setLeadsFilter(filtro)
      // } else{
      //   setLeadsFilter(dataAll)
      // }
      }


      const listLeads = () => {
        savedFilter = JSON.parse(localStorage.getItem(storageFilter));
       
        setLoading(true);
        leadService
          .getStatus({query:savedFilter})
          .then((response) => {
            let filterLead = response.data;
            console.log(response.data);
            
            setLeads(filterLead);
            setLeadsFilter(filterLead);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

    const columns = [
        {
          title: "Nombre",
          //dataIndex: "nombre",
          filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
            return <Input autoFocus placeholder='Ingrese Nombre' 
            value = {selectedKeys[0]} 
            onChange={(e)=>{
              setSelectedKeys(e.target.value?[e.target.value]:[])
             confirm({closeDropdown:false})
            }}
            onPressEnter={()=>{
              confirm()
            }} 
            
            onBlur={()=>{
              confirm()
            }} >
            
            </Input>
          },
          filterIcon:()=>{
            return <SearchOutlined />
          },
          onFilter:(value,record)=>{
            return record.nombre.toLowerCase().includes(value.toLowerCase())
          },
          key: "nombre",
           render: (record) => <Link to={"/ver-lead"}
           state={{lead: record}}>{record.nombre}</Link>,
        },
        {
            title: "Celular",
            dataIndex: "celular",
            key: "celular",
            // render: (text) => <a>{text}</a>,
          },
          

          {
            title: "Medio de Contacto",
            key: "medio",
             render: (medio) => myIcon(medio.medio) ,
          },

          {
            title: "Fase",
            key: "fase_id",
             render: (fase) =>{if(fase.Fase) return ( <div style={{color:fase.Fase.color}}>{fase.Fase.nombre}</div> )},
          },

          {
            title: "Usuario",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Nombre Usuario' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              return record.Usuario.nombre.toLowerCase().includes(value.toLowerCase())
            },
             render: (record) => <div>{record.Usuario.nombre.toUpperCase()}</div> ,
          },

          {
            title: "Fecha Registro",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Opciones",
           
            key: "action",
             render: (record) => {  
              if(role == "Superadministrador")
             return <div> 
               <Button type='danger' onClick={()=>{deleteLead(record)}}><DeleteOutlined/></Button>
             </div>
            },
          },
      ];

      
      const newLead = () => {
        setViewNewLead(true);
      };


  if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>CRM</Breadcrumb.Item>
        <Breadcrumb.Item>Oportunidades</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newLead}>
        Crear Oportunidad
      </Button>

      <div className='filtro'> Filtrar: <Select defaultValue={estado} mode="multiple" placeholder="Seleccione fase"  onChange={filtrarEstado} style={{width:200}}> 
        <Select.Option  value="Presentación">Presentación</Select.Option> 
        {/* <Select.Option value="Visita Institucional">Visita Institucional</Select.Option> 
        <Select.Option value="Envio del contrato">Envio del contrato</Select.Option>  */}
        <Select.Option value="Cerrado ganado">Cerrado ganado</Select.Option> 
        <Select.Option value="Cerrado perdido">Cerrado perdido</Select.Option> 
        </Select>
        </div>

      <LeadModal
        visible={viewNewLead}
        updateVisible={setViewNewLead}
        updateLoading={setLoading}
        updateListLead={listLeads}
      ></LeadModal>

      

      <Table
        columns={columns}
        dataSource={leadsFilter}
        pagination={{
          ...pagination,
          onChange: handlePaginationChange,
        }}
      />
    </MyLayout>
    );
};

export default Leads;