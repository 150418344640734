import React from 'react';
import { Breadcrumb, Layout, Card, Button, Row, Col,Spin,Popover, Segmented  } from "antd";
import './ListEmbudo.css'
import { SettingOutlined } from '@ant-design/icons';
import Embudo from './Embudo';
const ListEmbudo = (props) => {

    const selectEmbudo = (item) =>{
       props.selectEmbudo(item)
    }

    const newEmbudo = () =>{
        props.newEmbudo(true)
    }
    const configEmbudo = () =>{
       // props.newEmbudo(true)
    }
  

    return (
        <div className='list-embudo'>
             {props.embudos.map((item) => (
                   <Embudo embudo={item} selectEmbudo={selectEmbudo} ></Embudo>
                  ))}
                <div className='new-embudo' onClick={newEmbudo}> <div></div >Crear</div>
                <div className='config-embudo' onClick={configEmbudo}> <div></div ><SettingOutlined /></div>
           
        </div>
    );
};

export default ListEmbudo;