import React, {useEffect, useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  message,
  Select
} from "antd";
import { Content } from "antd/lib/layout/layout";
import periodoService from "../../services/periodo.service";


const PeriodoModal = (props) => {


  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const empresa_id = 1;

  useEffect(() => {

    form.resetFields()
  }, []);

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.empresa_id = empresa_id;
    periodoService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListPeriodo();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>


            <Col span={24}>
              <Form.Item
                label="Nombre: "
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Fecha Inicio: "
                name="fechaInicial"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha Inicio",
                  },
                ]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Fecha Final: "
                name="fechaFinal"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha Fin",
                  },
                ]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Orden: "
                name="orden"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Orden",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default PeriodoModal;
