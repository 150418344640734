import React, {useEffect, useState} from "react"
import MyLayout from "../../components/MyLayout"
import {
  Breadcrumb,
  Table,
  Button,
  Spin,
  Input,
  Tree,
  Modal,
  Form,
  message,
  Select,
  Row,
  Col,
} from "antd"

import archivoBVService from "../../services/archivobv.service"
import {SearchOutlined} from "@ant-design/icons"
import {
  CheckOutlined,
  UserOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons"

const ReviewBiblioteca = () => {
  const [loading, setLoading] = useState()
  const [archivos, setArchivos] = useState([])
  const [viewEditFiles, setViewEditFiles] = useState(false)
  const [formNewFile] = Form.useForm()
  const [archivoSeleccionado, setArchivoSeleccionado] = useState()
  const {TextArea} = Input
  const {confirm} = Modal
  const columns = [
    {
      title: "Recurso",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Nombre"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.nombre.toLowerCase().includes(value.toLowerCase())
      },
      render: (record) => <div>{record.nombre}</div>,
    },

    {
      title: "Tipo",
      render: (record) => <div>{record.tipo}</div>,
    },

    {
      title: "Nivel",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Nivel"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.CategoriaBV.Padre.nombre
          .toLowerCase()
          .includes(value.toLowerCase())
      },
      render: (record) => (
        <div>
          {record.CategoriaBV.Padre.nombre}-{record.CategoriaBV.nombre}
        </div>
      ),
    },

    {
      title: "Ver archivo",
      render: (record) => {
        if (record.tipo == "LINK") {
          return (
            <div>
              <a href={record.url} target="_blank">
                Visitar Link
              </a>
            </div>
          )
        } else {
          return (
            <div>
              <Button
                onClick={() => {
                  descargar(record.url)
                }}
              >
                Descargar{" "}
              </Button>
            </div>
          )
        }
      },
    },

    {
      title: "Información",
      render: (record) => <div>{record.info}</div>,
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese palabra clave"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.info.toLowerCase().includes(value.toLowerCase())
      },
    },

    {
      title: "Cargado por",
      render: (record) => {
        let nombreUsuario =
          record.Usuario && record.Usuario.nombre ? record.Usuario.nombre : "-"
        return <div>{nombreUsuario}</div>
      },
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese nombre"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.Usuario && record.Usuario.nombre
          ? record.Usuario.nombre.toLowerCase().includes(value.toLowerCase())
          : "-"
      },
    },

    {
      title: "Clasificación",
      render: (record) => <div>{record.clasificacion}</div>,
    },

    {
      title: "Estado",
      render: (record) => <div>{record.estado}</div>,
    },

    {
      title: "Opciones",
      render: (record) => {
        if (record.estado == "Pendiente") {
          return (
            <div style={{display: "flex", gap: "2px"}}>
              <Button
                onClick={() => {
                  aprobar(record)
                }}
                type="primary"
              >
                <CheckOutlined />
              </Button>
              <Button
                onClick={() => {
                  desactivar(record)
                }}
                type="danger"
              >
                <CloseOutlined></CloseOutlined>
              </Button>
              <Button
                onClick={() => {
                  actualizar(record)
                }}
                type="default"
              >
                <EditOutlined></EditOutlined>
              </Button>
            </div>
          )
        }

        if (record.estado == "Activo") {
          return (
            <Button
              onClick={() => {
                desactivar(record)
              }}
              type="danger"
            >
              <CloseOutlined />
            </Button>
          )
        }

        if (record.estado == "Rechazado") {
          return (
            <>
              <Button
                onClick={() => {
                  aprobar(record)
                }}
                type="primary"
              >
                <CheckOutlined />
              </Button>
            </>
          )
        }
      },
    },
  ]

  useEffect(() => {
    loadArchivos()
  }, [])

  const loadArchivos = () => {
    setLoading(true)
    archivoBVService.getPendientes().then((res) => {
      setArchivos(res.data)
      console.log(res.data)
      setLoading(false)
    })
  }

  const subirArchivo = (values) => {
    setLoading(true)
    archivoBVService
      .update(archivoSeleccionado.id, values)
      .then((res) => {
        loadArchivos()
        setViewEditFiles(false)
        setLoading(false)
      })
      .catch((error) => {
        message.error("No se pudo guardar")
        setLoading(false)
      })
  }

  const descargar = (url) => {
    window.open(url)
  }

  const aprobar = (record) => {
    confirm({
      title: "Desea aprobar el archivo?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        let data = {id: record.id, estado: "Activo"}
        console.log(data)
        archivoBVService
          .status(data)
          .then((res) => {
            message.success("Archivo Aprobado")
            loadArchivos()
          })
          .catch((error) => {
            message.error("No se pudo aprobar")
          })
      },
    })
  }

  const actualizar = (record) => {
    setViewEditFiles(true)
    setArchivoSeleccionado(record)
    formNewFile.setFieldsValue(record)
  }

  const desactivar = (record) => {
    confirm({
      title: "Desea rechazar el archivo?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        let data = {id: record.id, estado: "Rechazado"}
        console.log(data)
        archivoBVService
          .status(data)
          .then((res) => {
            message.success("Archivo Rechazado")
            loadArchivos()
          })
          .catch((error) => {
            message.error("No se pudo rechazar")
          })
      },
    })
  }

  return (
    <MyLayout>
      <Breadcrumb style={{margin: "16px 0"}}>
        <Breadcrumb.Item>Archivos</Breadcrumb.Item>
        <Breadcrumb.Item>Biblioteca Virtual</Breadcrumb.Item>
      </Breadcrumb>

      <Table dataSource={archivos} columns={columns}></Table>

      {viewEditFiles && (
        <Modal
          visible={viewEditFiles}
          title="Editar Recurso"
          cancelText="Cancelar"
          onCancel={() => {
            setViewEditFiles(false)
          }}
          okText="Guardar"
          onOk={formNewFile.submit}
        >
          <Form
            form={formNewFile}
            name="basic"
            layout="vertical"
            onFinish={subirArchivo}
          >
            <Form.Item
              name="nombre"
              label="Nombre Recurso"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese nombre",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="tipo"
              label="Tipo de Archivo"
              rules={[
                {
                  required: false,
                  message: "Por favor ingrese Tipo de Archivo",
                },
              ]}
            >
              <Select>
                <Select.Option value="AUDIO">AUDIO</Select.Option>
                <Select.Option value="VIDEO">VIDEO</Select.Option>
                <Select.Option value="LINK">LINK</Select.Option>
                <Select.Option value="OTRO">OTRO</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="info"
              label="Información del recurso"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese información del recurso",
                },
              ]}
            >
              <TextArea></TextArea>
            </Form.Item>

            <Form.Item
              name="clasificacion"
              label="Clasificación del archivo"
              rules={[
                {
                  required: false,
                  message: "Por favor ingrese Clasificación del archivo",
                },
              ]}
            >
              <Select>
                <Select.Option value="NIÑOS">NIÑOS</Select.Option>
                <Select.Option value="JOVENES">JOVENES</Select.Option>
                <Select.Option value="ADULTOS">ADULTOS</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </MyLayout>
  )
}

export default ReviewBiblioteca
