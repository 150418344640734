import React, {useState} from 'react';
import './CursoEstudiante.css'
import {Row, Col, Button, Tooltip} from 'antd'
import {LogoutOutlined} from '@ant-design/icons'
import RetiroModal from './modals/RetiroModal';

import moment from 'moment';
import { useAuth } from '../context/auth.context';
const CursoEstudiante = (props) => {
    const curso = props.children.Curso
    console.log(curso)
    const matricula = props.children
    const [viewRetiroModal,setViewRetiroModal] = useState(false);
    const [matriculaSelected,setMatriculaSelected] = useState();
    const [loading, setLoading] = useState(false);
    const {role} = useAuth()

    const viewHistomodal = (record)=>{
      setViewRetiroModal(true)
      setMatriculaSelected(record.id)
    }

    const updateEstudiante = () =>{
      props.updateEstudiante()
    }

    return (
        <div className='producto'>
         <div className='idioma'>
            <div>{curso.idioma}</div>

            {matricula.estado == "Activo" && (role == "Superadministrador" || role == "Administrativo" || role == "Cumplimiento" || role == "Seguimiento") && (
            <Tooltip title="Retirar">
              <span className='btn_option' onClick={()=>viewHistomodal(matricula)}>
                <LogoutOutlined />
                </span>
                </Tooltip> 
      )}
         </div>

         <Row>
            <Col span={12}>
            <div className='curso'> <strong> Código de Curso: </strong> {curso.id} </div>
            </Col>
            <Col span={12}>
            <div className='jornada'><strong> Código de matricula: </strong>  {matricula.id}</div>
            </Col>
         </Row>

         <Row>
            <Col span={12}>
            <div className='curso'> <strong> Curso: </strong> {curso.curso} - {curso.nivel} - {curso.ciclo}</div>
            </Col>
            <Col span={12}>
            <div className='jornada'><strong> Jornada: </strong>  {curso.jornada}</div>
            </Col>
         </Row>

         <Row>
            <Col span={12}>
            <div className='fechai'> <strong> Fecha de Inicio: </strong> {moment(curso.fechaInicial).format("DD-MM-YYYY")} </div>
            </Col>
            <Col span={12}>
            <div className='fechaf'> <strong>Fecha Final : </strong> {moment(curso.fechaFinal).format("DD-MM-YYYY")}</div>
            </Col>
         </Row>

         <Row>
            <Col span={12}>
            <div className='periodo'><strong> Periodo: </strong> {curso.periodo}</div>
            </Col>
            <Col span={12}>
            <div className='estado'><strong> Estado: </strong> {matricula.estado}</div>
            </Col>
         </Row>

         {matriculaSelected && (
          <RetiroModal 
          matricula_id={matriculaSelected}
          modo={curso.modo}
          updateLoading={setLoading}
          visible={viewRetiroModal} 
          updateVisible={setViewRetiroModal}
           updateEstudiante = {updateEstudiante}
           updateCurso = {updateEstudiante}
          >

          </RetiroModal>
          )}
         </div>
       
    );
};

export default CursoEstudiante;