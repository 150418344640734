import http from "../utils/http-common";

class ParametroService {
    getAll() {
      return http.get("/parametro");
    }
    get(id) {
      return http.get(`/parametro/${id}`);
    }


    getForain(id) {
      return http.get(`/parametro/forain/${id}`);
    }

    create(data) {
      return http.post("/parametro", data);
    }
    update(id, data) {
      return http.put(`/parametro/${id}`, data);
    }
    delete(id) {
      return http.delete(`/parametro/${id}`);
    }
   
  }
  export default new ParametroService();