import { Row, Col, Card, Button, Form, Input, Select, message, Spin, Checkbox } from 'antd';
import React, {useState} from 'react';
import formularioService from "../../services/formulario.service"

const TOEFL = () => {
    const [terminos,setTerminos] = useState(true)
    const [guardado,setGuardado] = useState(false)
    const [loading,setLoading] = useState(false)
    const aceptar = () =>{
        setTerminos(false)
    }

    const plainOptions = ['READING', 'WRITING', 'LISTENING','SPEAKING'];


    const guardarTOEFL = (values) =>{
        setLoading(true)
        let form = values
        form.nombres =  values.nombres.toUpperCase()
        form.apellidos =  values.apellidos.toUpperCase()
        form.examen = "TOEFL"
        formularioService.create(form).then(res=>{
            setGuardado(true)
            message.success("Registro guardado")
            setLoading(false)
        }).catch(error=>{
            message.error("No se pudo Guardar")
            setGuardado(false)
            setLoading(false)
        })
    }

    if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <Row>
            <Col xs={2} md={3}>
            </Col>

            <Col xs={20} md={18}>
              <Card >

              <img style={{width:'100%'}} src="./assets/img/menutoefl.png" alt="" />

              <h1>Registro TOEFL</h1>
                {terminos && !guardado && (
                <div>
                

                <p>
                Con el diligenciamiento del presente formulario, autorizo de manera expresa, consciente, informada y previa a BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS, identificada con el NIT: 98391866-4, a capturar, almacenar y verificar la autenticidad de mis datos y a conservarlos bajo su operación, control o supervisión con el fin de utilizarlos para dar cumplimiento a derechos y obligaciones de naturaleza comercial y gremial, y salvaguardar la seguridad de BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS. Lo anterior, para los fines establecidos en la Política de Tratamiento y Privacidad publicada en la página web de la entidad www.brightonenglishcenter.edu.co, asimismo, autorizo a BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS en la Ciudad de Pasto a transferir y transmitir mis datos a terceros receptores de los mismos. </p>

<p>En pleno conocimiento de que no estoy obligado a permitir el tratamiento de mis datos sensibles, autorizo, de manera voluntaria, expresa, consciente, informada y previa a BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS a que, en desarrollo de nuestra relación, conozca, capture, almacene y verifique, la autenticidad de mis datos personales sensibles como pudieran ser datos biométricos, origen racial, opinión política, convicción religiosa y filosófica, pertenencia a sindicatos, salud y sexualidad entre otros similares definidos por la ley, jurisprudencia y decisiones administrativas. Lo anterior, de acuerdo con la política de tratamiento de datos personales de la entidad a la que autorizo.</p>

<p>Declaro que, BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS me ha informado de los derechos que me asisten como titular de los datos suministrados, de acuerdo con el artículo 8 de la Ley 1581 de 2012, los cuales corresponden a: I, Conocer, actualizar y rectificar los datos personales frente a BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS; II. Solicitar prueba de la autorización otorgada; III, Ser informado por BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS, previa solicitud, del uso que se les ha dado a mis datos; IV. Presentar quejas ante la Superintendencia de Industria y Comercio por infracciones frente a la Ley; V. Revocar la autorización y/o solicitar la supervisión del dato cuando no se hayan respetado los principios, derechos y garantías constitucionales y legales. </p>
<p>Responsable del tratamiento: BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS, NIT: 98391866-4, dirección: Cra 38 No. 19 – 26 Palermo, Pasto; Teléfono: 7362555. </p>
         
                <card>
                    <h2>Acepto la política de tratamiento de datos</h2>
                    <br />
                <Button type='primary' onClick={aceptar}>Acepto</Button>
                </card>
                </div>
                )}

                {!terminos && !guardado && (
<>
                    <div style={{backgroundColor:"rgb(58, 187, 209)",padding:"5px"}}><h3>Datos personales</h3></div>
                    <Form style={{marginTop:'20px'}} layout='vertical' onFinish={guardarTOEFL}>
                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Correo",
                    },
                  ]} label="Correo" name="correo">
                            <Input type="email"></Input>
                        </Form.Item>

                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Nombres del candidato",
                    },
                  ]} label="NOMBRES" name="nombres">
                    
                            <Input placeholder='Colocar sus nombres, en cado de no tener segundo nombre colocar unicamente el primero' ></Input>
                        </Form.Item>

                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Apellidos del candidato",
                    },
                  ]} label="APELLIDOS" name="apellidos">
               
                            <Input placeholder='Colocar sus apellidos.'></Input>
                        </Form.Item>


                        <Form.Item
                label="DIRECCIÓN (RESIDENCIA)"
                name="direccion"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese dirección",
                  },
                ]}
              >

             
                <Input placeholder='Dirección de residencia' />
              </Form.Item>


                        <Form.Item
                label="REGIÓN O PAÍS"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Región o País",
                  },
                ]}
              >

                <Input />
              </Form.Item>

                      

              

              <Form.Item
                label="Ciudad"
                name="town"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Ciudad",
                  },
                ]}
              >
               
                <Input placeholder='i.e: Si su ciudad de residencia es Pasto, colóquela así: PASTO' />
              </Form.Item>

            
              <Form.Item
                label="CÓDIGO POSTAL"
                name="postcode"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese codigo postal",
                  },
                ]}
              >
                {/* <p>Si no conoce cuál es tu código postal, sigue este link: <a href="https://codigo-postal.co/colombia/">https://codigo-postal.co/colombia/</a></p> */}
                <Input />
              </Form.Item>

                        <Form.Item rules={[
                    {
                      required: true,
                      message: "FECHA DE NACIMIENTO (dd/mm/yyyy)",
                    },
                  ]} label="FECHA DE NACIMIENTO (dd/mm/yyyy)" name="fechaNacimiento">
                            <Input type='date' ></Input>
                        </Form.Item>


                        <Form.Item
                label="GENERO"
                name="genero"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Genero",
                  },
                ]}
              >
                <Select>
                <Select.Option value="Femenino">Femenino</Select.Option>
                    <Select.Option value="Masculino">Masculino</Select.Option>
                    <Select.Option value="Otro">Otro</Select.Option>

                </Select>
              </Form.Item>

              {/* <Form.Item
                label="GDPR Consent Provided"
                name="consentimiento"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese consentimiento",
                  },
                ]}
              >

                <p>Si eres menor de edad, necesitas diligenciar un formulario con el consentimiento de un adulto</p>
                <Select>
                <Select.Option value="Yes">Yes</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                   

                </Select>
              </Form.Item> */}


              <Form.Item
                label="REQUISITOS ESPECIALES"
                name="discapacidad"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese si el aspirante tiene alguna discapacidad física o mental",
                  },
                ]}
              >

              
                <Select placeholder="Informar si el aspirante tiene alguna discapacidad física o mental">
                <Select.Option value="Si">Si</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                   

                </Select>
              </Form.Item>

              <Form.Item
                label="DETALLES DE LOS REQUISITOS ESPECIALES"
                name="details"
                rules={[
                  {
                    required: false,
                    message: "Si la respuesta anterior fue 'si', especificar el tipo de discapacidad",
                  },
                ]}
              >

               
                <Input placeholder="Si la respuesta anterior fue 'si', especificar el tipo de discapacidad"></Input>
              </Form.Item>

              <Form.Item
                label="CELULAR"
                name="nombreContacto"
                rules={[
                  {
                    required: true,
                    message: "Número de teléfono móvil del candidato",
                  },
                ]}
              >
                <Input />

                
              </Form.Item>

              <Form.Item
                label="TIPO DE IDENTIFICACIÓN"
                name="tipoId"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de ID",
                  },
                ]}
              >


                <Select>
                <Select.Option value="Cédula de ciudadanía">Cédula de ciudadanía</Select.Option>
                    <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                    <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                    <Select.Option value="Pasaporte">Pasaporte</Select.Option>

                </Select>
              </Form.Item>

              {/* <Form.Item rules={[
                    {
                      required: true,
                      message: "Dirección de correo electrónico del candidato",
                    },
                  ]} label="Dirección de correo electrónico del candidato" name="correoCandidato">
                     
                            <Input type="email" placeholder='i.e: Si su e-mail es example@example.com, colóquelo como: EXAMPLE@EXAMPLE.COM'></Input>
                           
                        </Form.Item> */}


                    
              <Form.Item
                label="NÚMERO DE IDENTIFICACIÓN"
                name="numId"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese No. de Documento",
                  },
                ]}
              >

             
                <Input placeholder='Número de cédula, tarjeta de identidad, etc, sin puntos. i.e: 108576345' />
              </Form.Item>
   
              <Form.Item
                label="MODALIDAD DEL EXAMEN"
                name="tipo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Modalidad del exámen",
                  },
                ]}
              >
                <Select>
                <Select.Option value="TOEFL computador desde casa">TOEFL computador desde casa</Select.Option>
                    <Select.Option value="TOEFL presencial en Brighton">TOEFL presencial en Brighton</Select.Option>
                   
                    

                </Select>
              </Form.Item>
                       



              <Form.Item rules={[
                    {
                      required: true,
                      message: "Fecha de presentación del exámen (dd/mm/yyyy)",
                    },
                  ]} label="Fecha de presentación del exámen (dd/mm/yyyy)" name="fechaPrueba">

                  
                            <Input type='date' ></Input>
                        </Form.Item>


                        <Form.Item
                label="Jornada de presentación del exámen"
                name="jornada"
                rules={[
                  {
                    required: true,
                    message: "Por favor Jornada de presentación del exámen",
                  },
                ]}
              >

              
                <Select>
                <Select.Option value="Mañana">Mañana</Select.Option>
                    <Select.Option value="Tarde">Tarde</Select.Option>
                </Select>
              </Form.Item>


              <Button type="primary" htmlType="submit">
          Enviar
        </Button>

                    </Form>

                    </>
                )}

                {guardado && (
                  <div style={{textAlign:'center'}}>  <h1>Registro Guardado</h1>  </div>
                )}

              </Card>
            </Col>

            <Col xs={2} md={3}>
            </Col>
        </Row>
    );
};

export default TOEFL;