import React from 'react';

const Denegado = () => {
    return (
        <div style={{textAlign:'center',padding:50}}>
          <h1>  Acceso Denegado </h1>
          <h4>  No tiene permisos para visualizar esta página! </h4>
        </div>
    );
};

export default Denegado;