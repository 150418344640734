import React from 'react';
import {
    Modal,
    Form,
    Col,
    Row,
    Input,
    DatePicker,
    Select,
    message,
  } from "antd";
  import { Content } from "antd/lib/layout/layout";
  import embudoService from "../../services/embudo.service";

const EmbudoModal = (props) => {
    const [form] = Form.useForm();
    form.resetFields()
    const empresa_id = 1;
  const handleCancel = () => {
    props.updateVisible(false);
  };


  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.empresa_id = empresa_id;
    embudoService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListEmbudo();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

    return (

        <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
           
      <Content>
   
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Nombre: "
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
    );
};

export default EmbudoModal;