import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Table, Button, Spin, Input, Tooltip,Modal } from "antd";
import MyLayout from "../../components/MyLayout";
import examenService from "../../services/examen.service";
import ExamenModal from "../../components/modals/ExamenModal";
import moment from 'moment'

import {EditOutlined,EyeInvisibleOutlined,DeleteOutlined,CheckCircleOutlined,CalendarOutlined} from '@ant-design/icons';
import "./Examen.css"
import { useAuth } from '../../context/auth.context';
const {confirm} = Modal;

const Examen = () => {
    const [viewNewExamen, setViewNewExamen] = useState(false);
    const [viewEditExamen, setViewEditExamen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [examenes, setExamenes] = useState([]);
    const [examenSelected,setExamenSelected] = useState()
    const {role} = useAuth()
    const empresa_id = 1;

    useEffect(() => {
     
        listExamenes();
      }, []);


      const listExamenes = () => {
        setLoading(true);
        examenService
          .getForain(empresa_id)
          .then((response) => {
            let filterExamenes = response.data;

            setExamenes(filterExamenes);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

    const columns = [

        {
            title: "Codigo",
            dataIndex: "id",
            key: "id",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Exámen",
            render: (record) => 
            <Link to={"/ver-examen"}
           state={{examen: record}}><div>{record.nombre} </div></Link>
          },

          


          // {
          //   title: "Tipo",
          //   dataIndex: "tipo",
          //   key: "tipo",
          //   // render: (text) => <a>{text}</a>,
          // },

          // {
          //   title: "Modo",
          //   dataIndex: "modo",
          //   key: "modo",
          //   // render: (text) => <a>{text}</a>,
          // },

          // {
          //   title: "Fecha de Presentación",
          //   key: "fecha",
          //   dataIndex: "fecha",
          //   render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY"); return ( <div>{myFecha} </div> )}
          // },

          // {
          //   title: "Hora",
          //   // render: (record) => {let myFecha = moment(record.hora).format("hh:mm:ss a"); return ( <div>{myFecha}</div> )}
          //   render: (record) =>  <div>{record.hora}</div>
          // },
        

          {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            // render: (text) => <a>{text}</a>,
          },
       
         
          {
            title: "Fecha Registro",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Opciones",
           
            key: "action",
             render: (record) => { return <>
            
             </>},
          },
      ];

      
      const newExamen = () => {
        setViewNewExamen(true);
      };

      const editExamen = (record) => {
        setViewEditExamen(true);
        setExamenSelected(record)
      };

   

  if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Academico</Breadcrumb.Item>
        <Breadcrumb.Item>Examenes</Breadcrumb.Item>
      </Breadcrumb>

{(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
      <Button type="primary" onClick={newExamen}>
        Crear Exámen
      </Button>
      )}

      <ExamenModal
        visible={viewNewExamen}
        updateVisible={setViewNewExamen}
        updateLoading={setLoading}
        updateListExamenes={listExamenes}
      ></ExamenModal>



    
      <Table
        columns={columns}
        dataSource={examenes}
        pagination={{ pageSize: 4 }}
      />
    </MyLayout>
    );
};

export default Examen;