import React, {useState,useCallback} from 'react';
import { Modal, Row, Col } from 'antd';
import {useLocation} from 'react-router-dom'
import MyLayout from '../../components/MyLayout';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import esCo from 'date-fns/locale/es'
import moment from 'moment';
import calendarioService from '../../services/calendario.service'
import { useEffect } from 'react';
const {confirm} = Modal;
const locales = {
    'es': esCo,
  }

  const lang = {
    en: null,
    'en-GB': null,
    es: {
      week: 'Semana',
      work_week: 'Semana de trabajo',
      day: 'Día',
      month: 'Mes',
      previous: 'Atrás',
      next: 'Después',
      today: 'Hoy',
      agenda: 'El Diario',
  
      showMore: (total) => `+${total} más`,
    },}

    let formats = {
      timeGutterFormat: 'hh:mm',
    }


   
const today = new Date();
let min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
let max = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23,59)


  


const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  })

  

const VerCalendario = () => {

  
    let location = useLocation();
    let myCurso = location.state.curso

    const [culture, setCulture] = useState('es')

    useEffect(()=>{
      loadEvents()
    },[])



    
      const [myEvents, setEvents] = useState([])

      const loadEvents = ()=>{
        calendarioService.getForain(myCurso.id).then((response) => {
          console.log(response.data)
          let eventos = response.data
          let listaEventos = []
          for(let item of eventos){
            let myData = {
              id: item.id,
            title: item.title,
            start: new Date(item.start),
            end: new Date(item.end)
            }
            listaEventos.push(myData)
          }
          console.log(listaEventos)
          setEvents(listaEventos)
          
        })
        .catch((e) => {
          console.log(e);
       
        });
      }

      const handleSelectSlot = useCallback(
        ({ start, end }) => {
          confirm({
            title:"Desea crear el Horario?",
            okText:"Si",
            okType:'danger',
            cancelText:"No",
            onOk(){
              const title = myCurso.curso +" - "+ myCurso.nivel +" - "+ myCurso.ciclo
              if (title) {
                calendarioService.create({start,end,title,curso_id:myCurso.id,dia:new Date(start).getDay()})
                .then((response) => {
                  setEvents((prev) => [...prev, { start, end, title }])
                  loadEvents()
                  
                })
                .catch((e) => {
                  console.log(e);
               
                });
                
                
            
              }
            }})
         
        
        },
        [setEvents]
      )

      const handleSelectEvent = useCallback(
        (event) => {
          console.log(event)
          confirm({
            title:"Desea eliminar el Horario?",
            okText:"Si",
            okType:'danger',
            cancelText:"No",
            onOk(){

                calendarioService.delete(event.id)
                .then((response) => {
                  loadEvents()
                  
                })
                .catch((e) => {
                  console.log(e);
               
                });
                
             
            }})
        },
        [])


    return (
        <MyLayout>
         <Row style={{marginBottom:'30px'}}>
             <Col span={24}>
                <div className='info'>
             <div className='nombre'>{myCurso.curso} {myCurso.nivel} {myCurso.ciclo}</div>
             <div className='numId'> <strong>Docente:</strong> {myCurso.Docente.primerNombre} {myCurso.Docente.primerApellido}</div>
             </div> </Col>
                        <Col span={12} className="columna">
                        
                            <div><strong>Codigo:</strong> {myCurso.id} </div>
                            <div><strong>Fecha Inicial:</strong> {moment(myCurso.fechaInicial).format("DD-MM-YYYY")} </div>
                            <div><strong>Periodo:</strong> {myCurso.periodo} </div>
                            <div><strong>Estado:</strong> {myCurso.estado} </div>
                            <div><strong>Horas del curso:</strong> {myCurso.horas} </div>
                          



                        </Col>

                        <Col span={12} className="columna">
                        <div><strong>Programa:</strong> {myCurso.idioma}</div>
                        <div><strong>Fecha Final:</strong> {moment(myCurso.fechaFinal).format("DD-MM-YYYY")}  </div>
                        <div><strong>Jornada:</strong> {myCurso.jornada} </div>
                        <div><strong>Fecha Creación:</strong> {moment(myCurso.createdAt).format("DD-MM-YYYY")}  </div>
                        <div> <strong>Modo:</strong> {myCurso.presentacion}</div>

            
                        </Col>
                     </Row>
            <Calendar 
      localizer={localizer}
      culture={culture}
      messages={lang[culture]}
      events={myEvents}
      startAccessor="start"
      formats={formats}
      endAccessor="end"
      min={min}
      max={max}
      style={{ height: 500 }}
      onSelectSlot={handleSelectSlot}
      onSelectEvent={handleSelectEvent}
      selectable
    />

        
        </MyLayout>
    );
};

export default VerCalendario;