import React, { useState, useEffect } from 'react';
import pagoService from '../../services/pago.service';
import { PDFViewer } from '@react-pdf/renderer';
import {
    Modal,
    Form,
    Col,
    Row,
    Input,
    message,
    Select, 
    Table,
    Button
  } from "antd";
import moment from 'moment';
import {
  FilePdfOutlined, EditOutlined
} from '@ant-design/icons';
import DocPDF from '../../pages/PDF/DocPDF';
 import { NumericFormat } from 'react-number-format';
import PagoEditModal from './Edit/PagoEditModal';
import { useAuth } from '../../context/auth.context';
import pagofavorService from '../../services/pagofavor.service';

const ListPagoModal = (props) => {
    const [pagos, setPagos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewEditPago, setViewEditPago] = useState(false);
    const [pagoSelected, setPagoSelected] = useState();
    const obligacion = props.children
    const estudiante = props.estudiante
    const [viewPDF, setViewPDF] = useState(false);
    const {role} = useAuth()
    const [pagosFavor, setPagosFavor] = useState([]);
    const columns = [
        {
        title: "No.",
        dataIndex: "codigo_recibo",
            key: "codigo_recibo",
    },
    {
        title: "Concepto",
        //render: (record) => <div>PAGO DE CUOTA PAGARE NO. {record.codigo_pagare}</div>
         render: (record) => <div>{record.concepto}</div>
    },

    {
        title: "Valor",
        render: (record) => <div>$ <NumericFormat value={record.valor} displayType="text" allowLeadingZeros thousandSeparator="," /> {record.moneda}</div>,
    },
    {
        title: "Metodo de Pago",
        dataIndex: "metodo",
            key: "metodo",
    },
    {
        title: "Estado",
        dataIndex: "estado",
            key: "estado",
    },

    {
        title: "Fecha Pago",
  
        render: (record) => {let myFecha = moment(record.fecha).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
      },

      {
        title: "Opciones",
  
        render: (record) =>  <div>
           <Button onClick={()=>{viewMyPDF(record)}}><FilePdfOutlined /></Button> 
           <Button onClick={()=>{editPago(record)}}><EditOutlined /></Button>
           </div>

            
      },


]

const columnsSaldo = [
  {
  title: "No.",
  dataIndex: "codigo_recibo",
      key: "codigo_recibo",
},
{
  title: "Concepto",
  //render: (record) => <div>PAGO DE CUOTA PAGARE NO. {record.codigo_pagare}</div>
   render: (record) => <div>{record.concepto}</div>
},

{
  title: "Valor",
  render: (record) => <div>$ <NumericFormat value={record.valor} displayType="text" allowLeadingZeros thousandSeparator="," /> {record.moneda}</div>,
},
{
  title: "Metodo de Pago",
 render:(record) => <div>Saldo a Favor</div>
},
{
  title: "Estado",
  dataIndex: "estado",
      key: "estado",
},

{
  title: "Fecha Pago",

  render: (record) => {let myFecha = moment(record.fecha).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
},

{
  title: "Opciones",

  render: (record) =>  <div>
     <Button onClick={()=>{viewMyPDF(record)}}><FilePdfOutlined /></Button> 
    
     </div>

      
},


]

    useEffect(() => {
        listPagos()
        listPagosFavor()
      }, []);

      const handleCancel = () => {
        props.updateVisible(false);
      };

      const listPagos = () => {
        setLoading(true);
        pagoService
          .getForain(obligacion.id)
          .then((response) => {
            let filterPagos = response.data;
            console.log(response.data)
            setPagos(filterPagos);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const listPagosFavor = () => {
        setLoading(true);
        pagofavorService
          .getForain(obligacion.id)
          .then((response) => {
            let filterPagos = response.data;
            console.log(response.data)
            setPagosFavor(filterPagos);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const viewMyPDF = (record) =>{
        setViewPDF(true)
        setPagoSelected(record)
        //props.updateVisible(false);
      }

      const editPago = (record) =>{
        if(role == "Superadministrador" || role == "Administrativo" || role == "Cumplimiento" || role == "Seguimiento"){
          setViewEditPago(true)
          setPagoSelected(record)
        }
         else{
          message.error("No tienes Permisos para editar")
         }
        
      }
    return (
      <>
        <Modal width={1080} visible={props.visible} onOk={handleCancel} onCancel={handleCancel}>

          {!viewPDF && pagos.length>0 && (
            <>
            <h3>Pagos</h3>
            <Table columns={columns} dataSource={pagos}></Table>
            </>
            )}

{!viewPDF && pagosFavor.length>0 &&  (
  <><h3>Pagos saldo a Favor</h3>
            <Table columns={columnsSaldo} dataSource={pagosFavor}></Table>
            </>
            )}
            
            
            {viewPDF && (
          <PDFViewer style={{width:"100%", height:"90vh"}}>
          <DocPDF obligacion={obligacion} estudiante={estudiante}>{pagoSelected}</DocPDF>
          </PDFViewer>
        )}
        </Modal>

        {
          viewEditPago && (
            <PagoEditModal visible={viewEditPago} updateLoading={setLoading} updateListPagos={listPagos} updateVisible={setViewEditPago}>{pagoSelected}</PagoEditModal>
          )
        }

        
        </>
    );
};

export default ListPagoModal;