import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import { Breadcrumb, Table, Button, Spin, Input, Layout } from "antd";
import MyLayout from "../../components/MyLayout";
import tarifaService from "../../services/tarifa.service";
import TarifaModal from "../../components/modals/TarifaModal";
import moment from 'moment'
import {SearchOutlined, EditOutlined} from '@ant-design/icons';
import myIcon from '../../services/icono.service'
import { NumericFormat } from 'react-number-format';
import TarifaEditModal from '../../components/modals/Edit/TarifaEditModal';

const Tarifa = () => {
    const [tarifas, setTarifas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewNewTarifa, setViewNewTarifa] = useState(false);
    const [viewEditTarifa, setViewEditTarifa] = useState(false);
    const [tarifaSelected,setViewTarifaSelected] = useState()
    const empresa_id = 1

    const editTarifa = (record) =>{
      setViewEditTarifa(true)
      setViewTarifaSelected(record)
    }

    const columns = [
        {
            title: "Tipo",
            dataIndex: "tipo",
            key: "tipo",
            // render: (text) => <a>{text}</a>,
          },
          {
            title: "Caracteristica",
            dataIndex: "caracteristica",
            key: "caracteristica",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Año",
            dataIndex: "annio",
            key: "annio",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Valor",
             render: (record) => <div>$ <NumericFormat value={record.valor} displayType="text" allowLeadingZeros thousandSeparator="," /></div>,
          },

          {
            title: "Opciones",
            render: (record) => <div><Button type='primary' onClick={()=>editTarifa(record)}><EditOutlined/> </Button></div>
          }
    ]

    useEffect(() => {
        
        listTarifas();
      }, []);


      const listTarifas = () => {
        setLoading(true);
        tarifaService
          .getForain(empresa_id)
          .then((response) => {
            let filterLead = response.data;
            console.log(response.data);
            // filterMedios = response.data.filter(
            //   (item) => item.empresa_id == empresa_id
            // );
            setTarifas(filterLead);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const newTarifa = () => {
        setViewNewTarifa(true);
      };


      if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );

    return (
        <MyLayout>
           <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Ajustes</Breadcrumb.Item>
        <Breadcrumb.Item>Tarifas</Breadcrumb.Item>
      </Breadcrumb>


      <Button type="primary" onClick={newTarifa}>
        Crear Tarifa
      </Button>

{viewNewTarifa && (
      <TarifaModal
        visible={viewNewTarifa}
        updateVisible={setViewNewTarifa}
        updateLoading={setLoading}
        updateListTutoria={listTarifas}
      ></TarifaModal>
      )}

      {viewEditTarifa && tarifaSelected && (
        <TarifaEditModal visible={viewEditTarifa}
        updateVisible={setViewEditTarifa}
        updateLoading={setLoading}
        updateListTarifa={listTarifas}>{tarifaSelected}</TarifaEditModal>
      )}

        <Table
        columns={columns}
        dataSource={tarifas}
        pagination={{ pageSize: 4 }}
      />
      </MyLayout>
    );
};

export default Tarifa;