
import React, { useState, useEffect } from "react";
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Table, Button, Spin,Select,Input } from "antd";
import preguntaService from "../../services/pregunta.service";
import PreguntaModal from "../../components/modals/PreguntaModal";
const Pregunta = () => {

    const [viewNewPregunta, setViewNewPregunta] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const empresa_id = 1;

    useEffect(() => {
        listPreguntas();
      }, []);


      const listPreguntas = () => {
        setLoading(true);
        preguntaService
          .getAll()
          .then((response) => {
            let filterPreguntas = response.data;
            console.log(response.data);
            filterPreguntas = response.data.filter(
              (item) => item.empresa_id == empresa_id
            );
            setPreguntas(filterPreguntas);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const stringToObjects = (data)=>{
        var mydata = data.replace("[","").replace("]","")
        let array = mydata.split(','); 
        let array2 = []
        
        for(let item of array){
            array2.push(JSON.parse(item))
        }
        return array2
      }

      const columns = [
        {
          title: "Nombre",
          dataIndex: "nombre",
          // render: (text) => <a>{text}</a>,
        },
        {
            title: "Obligatorio",
            dataIndex: "obligatorio",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Opciones",
            //dataIndex: "obligatorio",
             render: (item) => { 
              if(item.tipo == "Múltiples opciones (Única respuesta)"){
                let res = stringToObjects(item.data)
                 return <Select style={{width:'100%'}}>
                    {res.map((item)=>{
                      return  <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                    })}
                 </Select>
                }

                else if(item.tipo == "Múltiples opciones (Múltiple respuesta)"){
                  let res = stringToObjects(item.data)
                   return <Select style={{width:'100%'}} mode="multiple">
                      {res.map((item)=>{
                        return  <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                      })}
                   </Select>
                  }

                else if(item.tipo == "Casilla de redacción"){
                  return <Input></Input>
                }

                else if(item.tipo == "Sí o No"){
                  return <Select style={{width:'100%'}}>
                    <Select.Option value="Sí">Sí</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                }

                else if(item.tipo == "Fecha"){
                  return <Input type="date"></Input>
                }
              }
          },
          {
            title: "Estado",
            dataIndex: "estado",
            // render: (text) => <a>{text}</a>,
          },
          {
            title: "Opciones",
           
            // render: (text) => <a>{text}</a>,
          },
      ];

      const newPregunta = () => {
        setViewNewPregunta(true);
      };

      if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Ajustes</Breadcrumb.Item>
        <Breadcrumb.Item>Preguntas Adicionales</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newPregunta}>
        Crear Pregunta Adicional
      </Button>

      <PreguntaModal
        visible={viewNewPregunta}
        updateVisible={setViewNewPregunta}
        updateLoading={setLoading}
        updateListPregunta={listPreguntas}
      ></PreguntaModal>

      <Table
        columns={columns}
        dataSource={preguntas}
        pagination={{ pageSize: 5 }}
      />
    </MyLayout>
    );
};

export default Pregunta;