import http from "../utils/http-common";

class MatriculaExamenService {
    getAll() {
      return http.get("/matricula/examenes");
    }
    get(id) {
      return http.get(`/matricula/examenes/${id}`);
    }

    getForain(id) {
      return http.get(`/matricula/examenes/forain/${id}`);
    }

    create(data) {
      return http.post("/matricula/examenes", data);
    }

    retiro(data) {
      return http.post("/matricula/examenes/retiro", data);
    }

    update(id, data) {
      return http.put(`/matricula/examenes/${id}`, data);
    }
    delete(id) {
      return http.delete(`/matricula/examenes/${id}`);
    }

    createFromForm(data){
      return http.post("/matricula/examenes/forms", data);
    }
   
  }
  export default new MatriculaExamenService();