import React, { useEffect, useState, useRef } from 'react';
import MyLayout from '../../components/MyLayout';
import {Table, Form, Select, DatePicker, Button, Col, Row, Card, Modal, Spin} from 'antd'
import moment from 'moment';
import reportesService from '../../services/reportes.service';
import { PDFViewer } from '@react-pdf/renderer';
import ReporteIngresosPDF from '../PDF/ReporteIngresosPDF';
import Checkbox from 'antd/lib/checkbox/Checkbox';
const XLSX = require("sheetjs-style");
const { RangePicker } = DatePicker;

const ReporteEstudiantes = () => {

    const [estudiantes,setEstudiantes] = useState()
    const [conFecha,setConFecha] = useState(false)
    const ref = useRef(null);
  
    const [loading, setLoading] = useState(false);


    const cuadre = React.useCallback(async () => {


        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.table_to_sheet(ref.current);
          var wscols = []
          for(let i=0;i<15;i++){
            wscols.push({ wch: 20 })
          }
    
       // var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
    
        ws["!cols"] = wscols;
    
        let range1 = ["A4","B1","B2","B3"]
    
        for(let item of range1){
          console.log(ws[item])
    
        ws[item].s = {
          font: {
            sz: 14,
            bold: true,
            color: { rgb: "#FFFFFF" },
          },
    
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
        };
    }
          
    
        XLSX.utils.book_append_sheet(wb, ws, "Reporte Estudiantes");
       
        XLSX.writeFile(wb, "Reporte-Estudiantes.xlsx");
      });
      

    const loadReporte1 =(values) =>{
        setLoading(true)
          console.log(values)
         
          reportesService.estudiantes(values).then(res=>{
              console.log(res.data)
            
              setEstudiantes(res.data)

              cuadre()
             
              setLoading(false)
          })
      }


      const activarFechas = (e) =>{
        let estado = e.target.checked
        if(estado){
            setConFecha(true)
        } else{
            setConFecha(false)
        }
      }

      const formatName = (record)=>{
        let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
        nombre = nombre.toUpperCase()
        return nombre
      }
    


    return (
        <MyLayout>
             <Card>
           <h4>Reporte Estudiantes</h4>



           <Form  name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off" onFinish={loadReporte1}
         >
            <Form.Item>
                <Checkbox onChange={activarFechas}>Elegir rango de fecha</Checkbox>
            </Form.Item>


            {conFecha && (
                <Form.Item label="Fechas" name="fechas" rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fechas",
                    },
                  ]}>
            <RangePicker  placeholder={['Fecha Inicial','Fecha Final']} />
            </Form.Item>
            )}


            <Form.Item  label="Estado" name="estado" rules={[
                    {
                      required: true,
                      message: "Estado",
                    },
                  ]}>
                <Select >
                {/* <Select.Option value="TODOS">TODOS</Select.Option> */}
                    <Select.Option value="Activo">Activo</Select.Option>
                    <Select.Option value="Inactivo">Inactivo</Select.Option>
                    <Select.Option value="Retirado">Retirado</Select.Option>
                    <Select.Option value="Finalizado">Finalizado</Select.Option>
                   
                    {/* <Select.Option value="CONSIGNACION">TODA CONSIGNACION</Select.Option> */}
                </Select>
            </Form.Item>

                <Form.Item>
                <Button type="primary"  htmlType="submit" >Generar Reporte</Button>
                </Form.Item>
           </Form>
        </Card>


                
                       <table ref={ref} className='cuadre' style={{display:"none"}} >
                       <thead>
                         <tr>
                           <td></td>
                           <th colspan='10'>CENTRO DE FORMACIÓN EN INGLES</th>
                           <th> CODIGO MAC-FO-03 </th>
                         </tr>
                         <tr>
                           <td></td>
                           <th colspan='10'>PROCESO: GESTIÓN MARKETING Y ATENCIÓN AL CLIENTE</th>
                           <th>VERSION 1</th>
                         </tr>
                         <tr>
                           <td></td>
                           <th colspan='10'>CONTROL ACADEMICO Y FINANCIERO DE ESTUDIANTES</th>
                           <th>FECHA 5/21/2019</th>
                         </tr>
               
                         <tr>
                           <th colspan="13">INFORMACION ESTUDIANTES</th>
                          
                         </tr>
                       </thead>
                       <tbody>
                           <tr>
                             
                               <td>Codigo estudiante</td>
                               <td>Tipo ID</td>
                               <td>No. de ID</td>
                               <td>Nombres</td>
                               <td>Sexo</td>
                               <td>Correo</td>
                               <td>Celular</td>
                               <td>Telefono</td>
                               <td>Fecha Nacimiento</td>
                               <td>Lugar Nacimiento</td>
                               <td>Direccion</td>
                               <td>Estado</td>
                               <td>Fecha Registro</td>
                               {/* <td>Inscripción</td>
                               <td>Factura</td>
                               <td>Abono</td>
                               <td>Saldo</td>
                               <td>Recibos de Pago</td> */}
                           </tr>
                           
                           {estudiantes && estudiantes.map((item=>{
                               return <><tr>
                                   <td> {item.id }  </td>
                                   <td> {item.tipoId }  </td>
                                   <td> {item.numId }  </td>
                                   <td> {formatName(item) }  </td>
                                   <td> {item.sexo }  </td>
                                   <td> {item.correo }  </td>
                                   <td> {item.celular }  </td>
                                   <td> {item.telefono }  </td>
                                   <td> {item.fechaNacimiento }  </td>
                                   <td> {item.lugarNacimiento }  </td>
                                   <td> {item.direccion }  </td>
                                   <td> {item.estado }  </td>
                                   <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                             
                               
                               {/* <td>{". " + convertirFecha(item.fechaInicial)}</td>
                               <td>{". " + convertirFecha(item.fechaFinal)}</td> */}
                           </tr>
               
                      
                         
                           </> 
                           }))}
               
                    
                          
                       </tbody>
                     </table>
                 

        </MyLayout>
    );
};

export default ReporteEstudiantes;