import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import medioService from "../../services/medio.service";
import leadService from "../../services/lead.service";
import canalService from "../../services/canal.service";
import { useAuth } from '../../context/auth.context';

const LeadModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [medios, setMedios] = useState([]);
  const [canales, setCanales] = useState([]);
  const [form] = Form.useForm();
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  
  useEffect(() => {
    listMedios();
    listCanales();
    form.resetFields()
  }, []);

  const listMedios = () => {
    setLoading(true);
    medioService
      .getAll()
      .then((response) => {
        let filterMedios = response.data;
        console.log(response.data);
        filterMedios = response.data.filter(
          (item) => item.empresa_id == empresa_id
        );
        setMedios(filterMedios);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const listCanales = () => {
    setLoading(true);
    canalService
      .getAll()
      .then((response) => {
        let filterCanales = response.data;
        console.log(response.data);
        filterCanales = response.data.filter(
          (item) => item.empresa_id == empresa_id
        );
        setCanales(filterCanales);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.empresa_id = empresa_id;
    form.usuario_id = usuario_id;
    if(props.fase_id){
      form.fase_id = props.fase_id
    } else{
      form.fase_id = 1
    }
    leadService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListLead();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
      
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>

          

            <Col span={24}>
              <Form.Item
                label="Nombre: "
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Celular: "
                name="celular"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Celular",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

           

            <Col span={24}>
              <Form.Item
                label="Medio de Contacto: "
                name="medio"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Medio de Contacto",
                  },
                ]}
              >
                <Select>
                  {medios.map((item) => (
                    <Select.Option key={item.nombre}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

           
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default LeadModal;
