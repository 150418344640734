import http from "../utils/http-common";

class SedeService {
    getAll() {
      return http.get("/rango/docente");
    }
    get(id) {
      return http.get(`/rango/docente/${id}`);
    }

    getForain(id) {
      return http.get(`/rango/docente/forain/${id}`);
    }

    create(data) {
      return http.post("/rango/docente", data);
    }
    update(id, data) {
      return http.put(`/rango/docente/${id}`, data);
    }
    delete(id) {
      return http.delete(`/rango/docente/${id}`);
    }
   
  }
  export default new SedeService();