import http from "../utils/http-common";

class CanalService {
    getAll() {
      return http.get("/canales");
    }
    get(id) {
      return http.get(`/canales/${id}`);
    }
    create(data) {
      return http.post("/canales", data);
    }
    update(id, data) {
      return http.put(`/canales/${id}`, data);
    }
    delete(id) {
      return http.delete(`/canales/${id}`);
    }
   
  }
  export default new CanalService();