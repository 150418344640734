import http from "../utils/http-common";

class HistoFaseService {
    getAll() {
      return http.get("/histofases");
    }
    get(id) {
      return http.get(`/histofases/${id}`);
    }

    getForain(id) {
        return http.get(`/histofases/forain/${id}`);
      }

    create(data) {
      return http.post("/histofases", data);
    }
    update(id, data) {
      return http.put(`/histofases/${id}`, data);
    }
    delete(id) {
      return http.delete(`/histofases/${id}`);
    }
   
  }
  export default new HistoFaseService();