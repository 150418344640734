import React, { useRef, useEffect } from "react";
import { Breadcrumb, Layout, Card, Button, Row, Col, Popover } from "antd";
import {
  WhatsAppOutlined,
  FacebookOutlined,
  ClockCircleOutlined,
  PhoneOutlined
  
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./Lead.css";
import myIcon from '../../services/icono.service'
const Lead = (props) => {


  return (
    <Row key={props.data.id}>

      <Col span={12}>
        {props.data.nombre && (
            <Link to={"/ver-lead"} state={{ lead: props.data }}>
              {props.data.nombre}
            </Link>
        )}
      </Col>

      <Col span={12} className='tarea'>
        {props.data.Tareas.length > 0 && (
          <Popover
            content={
              <div>
                {props.data.Tareas[props.data.Tareas.length - 1].descripcion}
              </div>
            }
            title="Tarea"
          >
            <ClockCircleOutlined />
          </Popover>
        )}
      </Col>

      <Col span={24}>
        {props.data.Histofases.length > 0 && (
          <div className="comentario">
            {props.data.Histofases[props.data.Histofases.length - 1].comentario}
          </div>
        )}
      </Col>

      <Col span={12}>
        {props.data.presupuesto && (
          <div className="presupuesto">${props.data.presupuesto}</div>
        )}
      </Col>

      <Col span={24}>
        <div className="medio">
        {myIcon(props.data.medio)}
        </div>
      </Col>

      
    </Row>
  );
};

export default Lead;
