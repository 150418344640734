import React,{useState,useEffect,useContext,EditableContext,useRef} from 'react';
import {Link,useLocation} from 'react-router-dom'
import MyLayout from '../../components/MyLayout';
import evaluacionEstudianteService from '../../services/evaluacion_estudiante.service';
import inasistenciasService from "../../services/inasistencia.service"
import { Table,Modal,Form, Input, Spin, Button, Avatar, Row, Col, message } from 'antd';
import moment from 'moment';
import {LogoutOutlined,LineChartOutlined, CheckCircleOutlined, CloseCircleOutlined, EditOutlined, UserOutlined,SaveOutlined } from '@ant-design/icons'
import "./VerEvaluacion.css"
import ObservacionEditModal from '../../components/modals/Edit/ObservacionEditModal';
import matriculaService from '../../services/matricula.service';
import { useAuth } from '../../context/auth.context';
import parametroService from '../../services/parametro.service'
const {confirm} = Modal;

const VerEvaluacion = () => {
  let location = useLocation();
    
    let myCurso = location.state.curso

     
    const [myEvents, setEvents] = useState([])
    const [columns, setColumns] = useState([])
    const [inasistencias, setInasistencias] = useState([])
    const [viewData,setViewData] = useState(false)
    const EditableContext = React.createContext(null);
    const inputRef = useRef(null);
    const [loading,setLoading] = useState(false);
    const [notas, setNotas] = useState([])
    const [viewModalObservaciones, setViewModalObservaciones] = useState(false)
    const [estudianteSelected,setEstudianteSelected] = useState()
    const [valor, setValor] = useState("");
  const [matriculas,setMatriculas] = useState()
  const {role} = useAuth()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  

    useEffect(()=>{
     

      loadMatriculas()
      loadEvents()

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      // Agregar el evento de cambio de tamaño del navegador
      window.addEventListener('resize', handleResize);
  
      // Limpiar el evento al desmontar el componente
      return () => {
        window.removeEventListener('resize', handleResize);
      };
       

      },[])


   
   

    const loadMatriculas = () =>{
      setLoading(true)
      matriculaService.getForain(myCurso.id).then(res=>{
        setMatriculas(res.data)
        setLoading(false)
      })
     
    }

    


    const guardarNota = (subparametro_id,estudiante_id,curso_id,nota,parametro_id) =>{
    
     
      let valor = parseFloat(nota.current.input.value)
      console.log(valor)
      if(valor >= 0 && valor <=5 && !isNaN(valor)){
      //  setLoading(true)
       
      evaluacionEstudianteService.create({subparametro_id,estudiante_id,curso_id,valor,parametro_id}).then((res=>{
       // loadEvents()
       // setLoading(false)
       if(res.status === 200){
       message.success("Nota Guardada")
      }
      else{
        message.error("No se guardo el registro")
      }
   
      })).catch((error=>{
        console.log(error)
        message.success("No se pudo registrar la nota")
        setLoading(false)
      }))
    } else{
      if(isNaN(valor)){

      } else{
        alert("Valor no correcto")
      }
     
    }
   
    }

    const viewObservaciones = (record) =>{
      setViewModalObservaciones(true)
      setEstudianteSelected(record)
    }

    const validarDispositivo = () =>{
      console.log("Windows HEig",windowWidth)
      if(windowWidth <=600){
        return 'none'
      }
      else{
        return 'left'
      }
     
    }

    const validarDispositivoWidth = () =>{
      if(windowWidth <=600){
        return 180
      }
      else{
        return 200
      }
     
    }

    const handleKeyPress = (event) => {
      const charCode = event.which ? event.which : event.keyCode;
      // Solo permitir números y puntos
      if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      } 
    }

    const handleChange = (event) => {
      setValor(event.target.value);
    };

    const formatName = (record)=>{
      let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
      nombre = nombre.toUpperCase()
      return nombre
    }

    const loadEvents = ()=>{
      
    
      setLoading(true)
    
      evaluacionEstudianteService.getForain(myCurso.id).then((response) => {

        parametroService.getForain(myCurso.id).then(res=>{
          let promedioAcumulado = 0;
         
          let parametros = res.data

        let notas = response.data
        setNotas(notas)
        

        let listaEventos = []
        let listaEncabezado= [ {
          title:' ',
          width: {validarDispositivoWidth},
          fixed: "left", 
          responsive: ['md'], 
        render:(record) => { if(record.Estudiante.url != null){
        return <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64 }} src={record.Estudiante.url}/>
      }
       else{
        return <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64 }} icon={<UserOutlined />}/>
      } 
    }
      },
          
          {
          title:"Estudiante", 
          width: 150,
          fixed: 'left', 
          render:(record)=>{
            // let notasEstudiante = notas.filter(item=>item.estudiante_id==record.Estudiante.id)
            // console.log(notasEstudiante)
             return <div>
            {formatName(record.Estudiante)}
          {record.estado == "Retirado" && (
            <div>({record.estado})</div>
          )}
          </div>
          }
          }]

        
        
        for(let item of parametros){
          
         
          let myData = {
            id: item.id,
          nombre: item.nombre,
          porcentaje: item.porcentaje,
          width: 100,
          }


          listaEventos.push(myData)
          let subparametros = []
          
          for(let sub of item.SubparametroCursos){
        
              subparametros.push({title:sub.nombre, children:[{
                title:sub.porcentaje+"%",
                width: 100,
                editable:true,
                
              render:(record)=>{
                let verificacion = verificar(sub.id,record.Estudiante.id,notas)
                if(record.estado == "Activo" && myCurso.estado == 'Activo' && (role == "Docente" || role == "Superadministrador")){
                if(verificacion.length == 0){
                  //return <div ><Input ref={inputRef} onFocus={(ref)=>{foco(ref)}}></Input></div>
                  return <div >
                    <Input type='text' onKeyPress={handleKeyPress} onChange={handleChange} style={{width:"80px"}} ref={inputRef} 
                    onPressEnter={()=>guardarNota(sub.id,record.Estudiante.id,myCurso.id,inputRef,item.id)} 
                    onBlur={()=>guardarNota(sub.id,record.Estudiante.id,myCurso.id,inputRef,item.id)}
                   >
                      </Input>
                      </div>
                }else{
                 //console.log(record.Estudiante.id,item.nombre,sub.nombre,verificacion[0].valor)
                  return <div ><Input type='text' onKeyPress={handleKeyPress} onChange={handleChange}  style={{width:"80px"}} ref={inputRef}   onPressEnter={()=>guardarNota(sub.id,record.Estudiante.id,myCurso.id,inputRef,item.id)} 
                  onBlur={()=>guardarNota(sub.id,record.Estudiante.id,myCurso.id,inputRef,item.id)} placeholder={verificacion[0].valor} ></Input></div>
                }
              } else{
                if(verificacion.length > 0 ){
                return <div><Input type='text'  style={{width:"80px"}} disabled value={verificacion[0].valor}></Input></div>
              }
              else{
                return <div><Input type='text'  style={{width:"80px"}} disabled value={0}></Input></div>
              }
              }

            }
          }
            ]})
           
          }

         

          listaEncabezado.push(
            {
            title:item.nombre,
            width: 100,
            children:subparametros,
              // render:(record)=> {
              //   return <div>NOTA</div>
              // }
          },
          {
            title:item.porcentaje + "%",
            width: 100,
            render:(record)=>{ 
              
               promedioAcumulado = parseFloat(totalCorte(record.Estudiante.id,item.id,notas)*item.porcentaje/100);

              
              return <div>{totalCorte(record.Estudiante.id,item.id,notas)}</div>
            }
          },
          
          )

         

        }

        listaEncabezado.push(
          {
            title:'PROM',
            width: 100,
            render:(record)=>{ return <div>{calcularPromedio(record.Estudiante.id,notas,parametros)}</div>}
          },

          {
            title:'OBSERVACIONES',
            width: 300,
            render:(record)=>{ 
         
              return <>
              {(role == "Docente" || role == "Superadministrador") && record.estado == "Activo" && (
              <Button onClick={()=>{viewObservaciones(record)}}><EditOutlined/></Button> 
              )}
              <div className='observacion'>  
              {record.observaciones}</div>
              </>
               }
          },

        )

        
       
        setEvents(listaEventos)
        setColumns(listaEncabezado)
        setViewData(true)
       
        setLoading(false)
      }).catch((e) => {
        console.log(e);
      });
      }).catch((e) => {
        console.log(e);
      });
      }

     
     
      const totalCorte = (estudiante_id,parametro_id,notas)=>{
    
        
        let lista = notas
        
         lista = lista.filter(item=>item.estudiante_id==estudiante_id && item.parametro_id == parametro_id)
     
        let total = 0
        if(lista.length>0){
        for(let item of lista){
          total += parseFloat(item.valor)
        }
       
        return (total/lista.length).toFixed(2)
      } else{
        return 0
      }

       
      }

      const verificar = (sub, estudiante,notas) =>{
        let lista = notas
        return lista.filter(item=>item.subparametro_id==sub && item.estudiante_id == estudiante)
    }

    const calcularPromedio = (id,notas,parametros) =>{
      
      let notasEstudiante = notas.filter(item=>item.estudiante_id == id)
      let totalPromedio = 0
      for(let param of parametros){
        let totalSub = 0
        let totalParam = 0
        for(let sub of notasEstudiante){
          if(param.id == sub.parametro_id){
          totalSub += sub.valor * (sub.SubparametroCurso.porcentaje/100)
         // console.log(sub.valor * (sub.SubparametroCurso.porcentaje/100))
        }
        }
        totalParam = totalSub * (param.porcentaje/100)
        totalPromedio += totalParam
      }
      
      return totalPromedio.toFixed(1)
    }

    const validarHorario = (record) =>{
      if(record.horaInicial){

        let cadenaHorario = ""
        let dias = JSON.parse(record.diasHorario)
        for(let item of dias){
          cadenaHorario += item.substr(0,3) + ", "
        }

        cadenaHorario += "- "+ moment(record.fechaInicial + " "+record.horaInicial).format('hh:mm a') + " a " + moment(record.fechaInicial + " "+record.horaFinal).format('hh:mm a')
        
        return cadenaHorario
      } else{
        return "No hay calendario asignado"
      }

    }

    if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
    return (
        <MyLayout>

{myCurso && (
            <>
        <Row style={{margin:'10px'}}>

            <Col span={24}>
            <Row>
             <Col span={24}>
                <div className='info'>
             <div className='nombre'>{myCurso.curso} {myCurso.nivel} {myCurso.ciclo}</div>
             <div className='numId'> <strong>Docente:</strong> {myCurso.Docente.primerNombre} {myCurso.Docente.primerApellido}</div>
             </div> </Col>
                        <Col md={24} lg={12} className="columna">
                        
                            <div><strong>Codigo:</strong> {myCurso.id} </div>
                            <div><strong>Fecha Inicial:</strong> {moment(myCurso.fechaInicial).format("DD-MM-YYYY")} </div>
                            <div><strong>Periodo:</strong> {myCurso.periodo} </div>
                            <div><strong>Estado:</strong> {myCurso.estado} </div>
                            <div><strong>Horario de clase:</strong> {validarHorario(myCurso)}</div>
                            <div><strong>Horas del curso:</strong> {myCurso.horas} </div>



                        </Col>

                        <Col md={24} lg={12} className="columna">
                        <div><strong>Programa:</strong> {myCurso.idioma}</div>
                        <div><strong>Fecha Final:</strong> {moment(myCurso.fechaFinal).format("DD-MM-YYYY")}  </div>
                        <div><strong>Jornada:</strong> {myCurso.jornada} </div>
                        <div><strong>Fecha Creación:</strong> {moment(myCurso.createdAt).format("DD-MM-YYYY")}  </div>
                        <div> <strong>Modo:</strong> {myCurso.presentacion}</div>
                        <div><Button type='primary' onClick={loadEvents}>Guardar Notas <SaveOutlined/></Button></div>

            
                        </Col>
                     </Row>
            </Col>
           
        </Row>
        </>
)}

           {viewData  && (
            <div >
            <Table   className='notas_table' scroll={{ x: 1500, y:500 }}  bordered columns={columns} dataSource={matriculas} pagination={{
        pageSize: 1000 // Limitar a 4 registros por página
      }} >
            </Table>
            </div>
            )}

            {
              viewModalObservaciones && (
                <ObservacionEditModal visible={viewModalObservaciones} 
                updateVisible={setViewModalObservaciones} 
                updateLoading={setLoading}
                updateEvaluacion = {loadMatriculas}
                >{estudianteSelected}</ObservacionEditModal>
              )
            }
        </MyLayout>
    );
};

export default VerEvaluacion;