import http from "../utils/http-common"

class ArchivoBVService {
  getAll() {
    return http.get("/archivobv")
  }

  getPendientes() {
    return http.get("/archivobv/pendientes")
  }

  get(id) {
    return http.get(`/archivobv/${id}`)
  }

  getForain(id) {
    return http.get(`/archivobv/forain/${id}`)
  }

  create(data) {
    return http.post("/archivobv", data)
  }
  update(id, data) {
    return http.put(`/archivobv/${id}`, data)
  }
  delete(id) {
    return http.delete(`/archivobv/${id}`)
  }

  status(data) {
    return http.post("/archivobv/status", data)
  }

  buscar(data) {
    return http.post("/archivobv/buscar", data)
  }
}
export default new ArchivoBVService()
