import React from 'react';
import MyLayout from '../../components/MyLayout';
import { Breadcrumb } from 'antd';
import './Home.css'
const Home = () => {
    return (
        <MyLayout >
             <Breadcrumb style={{ margin: '16px 0' }}>
        {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}

<div className='video'>
        <iframe width="660" height="415" src="https://www.youtube.com/embed/8BPG6_FzKyA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div> 
      </Breadcrumb>
        </MyLayout>
      
    );
};

export default Home;