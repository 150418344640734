import http from "../utils/http-common";

class InasistenciaAdminService {
    getAll() {
      return http.get("/admin/inasistencias");
    }
    get(id) {
      return http.get(`/admin/inasistencias/${id}`);
    }

    getForain(id) {
      return http.get(`/admin/inasistencias/forain/${id}`);
    }

    create(data) {
      return http.post("/admin/inasistencias", data);
    }
    update(id, data) {
      return http.put(`/admin/inasistencias/${id}`, data);
    }
    delete(id) {
      return http.delete(`/admin/inasistencias/${id}`);
    }
   
  }
  export default new InasistenciaAdminService();