import React, { useEffect,useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import moment from "moment";
import logo from "./logo";
import regular from './fonts/Roboto-Regular.ttf'
import bold from './fonts/Roboto-Bold.ttf'

Font.register({ family: 'Roboto', fonts:[{src:regular},{src:bold, fontWeight: 'bold'}] });



const styles = StyleSheet.create({
  body: {
    padding: 25,
    fontFamily: 'Roboto'
    
  },

  recibo:{
    width:10
  },

  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:20
  },

  table2: {
    display: "table",
    width: "auto",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableRow2: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol2: {
    width: "80%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol3: {
    width: "20%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol4: {
    width: "50%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    
  },

  tableCell2: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },

  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
   
  },

  tableCol5: {
    width: "25%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
   
  },

  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
  logo: {
    width: 50,
    height: 70,
    textAlign: "center",
  },

  footer:{
    position: 'absolute',
    bottom: 30,
    textAlign: 'center',
    fontSize: 8,
    left: 0,
    right: 0,
  },
  footer2:{
    position: 'absolute',
    bottom: 10,
    textAlign: 'center',
    fontSize: 8,
    left: 0,
    right: 0,
  },

  titulo:{
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 'bold',
    
  },

  negrita:{
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },

  total:{
    textAlign: 'right',
    marginTop: 15,
    fontSize: 12,
  },

});

const ReporteIngresosPDF = (props) => {
  const pagos = props.children;
  const parametros = props.parametros

  let total = 0
  for(let item of pagos){
    total += item.valor
  }
 
  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  return (
    <Document>
      <Page size="letter" orientation='portrait' style={styles.body}>

      

      <View style={styles.table2}>
      <View style={styles.tableRow2}>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell2}>
              <Image style={[styles.logo]} src={logo}></Image>
              </Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={[styles.tableCell2,styles.titulo]}>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS</Text>

          

              <View style={styles.table2}>
      <View style={styles.tableRow2}>
<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Código: 0 </Text>
</View>
<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Versión: 1 </Text>
</View>

<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Fecha Versión : 20/08/2014 </Text>
</View>

<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Página: 1 </Text>
</View>

</View>
</View>



              <Text style={[styles.tableCell2,styles.titulo]}>REPORTE DE INGRESOS</Text>

              <Text style={[styles.tableCell2,styles.titulo]}>Desde: {moment(parametros.fechas[0]).format('DD-MM-YYYY')} Hasta: {moment(parametros.fechas[1]).format('DD-MM-YYYY')}</Text>
             
            </View>
        </View>
      </View>

   

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol]}>
              <Text style={[styles.tableCell,styles.negrita]}>No. Recibo</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Fecha</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Pagador por</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Identificación</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Cajero</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Detalle</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Forma de Pago</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Total Pagado</Text>
            </View>
          </View>

          {pagos.map((item) => {
            return (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.codigo_recibo} 
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{moment(item.fecha).format('DD-MM-YYYY')} </Text>
                </View>

                {item.PagoObligacions.length>0 && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                 
                  {item.PagoObligacions[0].Obligacion.Estudiante.primerNombre }{" "}
                  {item.PagoObligacions[0].Obligacion.Estudiante.segundoNombre }{" "}
                    {item.PagoObligacions[0].Obligacion.Estudiante.primerApellido }{" "}
                    {item.PagoObligacions[0].Obligacion.Estudiante.segundoApellido }{" "}
                  </Text>
                </View>
)}

{item.Saldo && (
   <View style={styles.tableCol}>
   <Text style={styles.tableCell}>
  
   {item.Saldo.Estudiante.primerNombre }{" "}
   {item.Saldo.Estudiante.segundoNombre }{" "}
     {item.Saldo.Estudiante.primerApellido }{" "}
     {item.Saldo.Estudiante.segundoApellido }{" "}
   </Text>
 </View>
)}

{item.PagoObligacions.length==0 && item.Saldo==null &&  (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
               -
                  </Text>
                </View>
)}

{item.PagoObligacions.length>0 && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.PagoObligacions[0].Obligacion.Estudiante.numId}
                  </Text>
                </View>
)}

{item.Saldo && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.Saldo.Estudiante.numId}
                  </Text>
                </View>
)}

{item.PagoObligacions.length==0 && item.Saldo==null && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                 -
                  </Text>
                </View>
)}

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.Usuario.nombre}
                  </Text>
                </View>

      {item.PagoObligacions.length>0 && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.concepto} </Text>
                </View>

)}

{item.PagoObligacions.length==0 && (
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.observaciones} </Text>
                </View>

)}

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.metodo} </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{formatterPeso.format(item.valor)}</Text>
                </View>
              </View>
            );
          })}
    
        </View>


          <View>
          <Text style={styles.total}>
            Forma de pago: <Text style={[styles.negrita]}>{parametros.listaMetodo}</Text>
            </Text>
          <Text style={styles.total}>
            Total: <Text style={[styles.negrita]}>{formatterPeso.format(total)}</Text>
            </Text>
          </View>
       



         
        <Text style={[styles.footer]}>Brighton - Centro de Formación en Inglés 
        </Text>
        
          <Text style={[styles.footer2]} > Fecha de impresión: {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}</Text>
           
       

      </Page>
    </Document>
  );
};

export default ReporteIngresosPDF;
