import React, {useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Spin
} from "antd";
import { Content } from "antd/lib/layout/layout";
import archivoDocenteService from "../../services/archivo_docente.service";
import { useAuth } from '../../context/auth.context';
import storage from "../../services/firebase"
import {ref, uploadBytesResumable,getDownloadURL} from "firebase/storage"

const ArchivoDocenteModal = (props) => {
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  const docente = props.children
  const [file,setFile] = useState()
  const [percent,setPercent] = useState()

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    props.updateLoading(true)
    const form = values;
    form.empresa_id = empresa_id;
    form.usuario_id = usuario_id;
    form.estado = "Cargado";
    form.docente_id = docente.id
    const storageRef = ref(storage, `/docentes/${docente.numId}/${new Date().getTime() + "-" + file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        "state_changed",
        (snapshot) => {
        const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        
        // update progress
        setPercent(percent);
        },
        (err) => console.log(err),
        () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        console.log(url);

            form.url = url
            archivoDocenteService
    .create(form)
    .then((response) => {
      console.log(response.data);
      setLoading(false);
      props.updateLoading(false)
      props.updateVisible(false);
      props.updateDocente();
      message.success("Guardado Correctamente");
    })
    .catch((e) => {
        setLoading(false);
        props.updateLoading(false)
      message.error("No se ha podido guardar");
      console.log(e);
    });

        });
        }
        );
        };

 

  const selectFile = (e) =>{
    setFile(e.target.files[0])
  }

  
  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Descripción de archivo: "
                name="descripcion"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Descripción del archivo a cargar",
                  },
                ]}
              >
                {/* <Select> 
                    <Select.Option value="LESSON PLAN">LESSON PLAN</Select.Option>
                    <Select.Option value="EXAMEN FINAL">EXAMEN FINAL</Select.Option>
                   
                </Select> */}

                <Input/>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Cargar Archivo: "
                name="file"
                rules={[
                  {
                    required: true,
                    message: "Por favor Seleccione el archivo",
                  },
                ]}
              >
                <Input type="file" accept="image/*,.pdf"  onChange={(e)=>{selectFile(e)}}></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default ArchivoDocenteModal;
