import React, { useEffect, useState } from 'react';
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Table, Button, Spin, Input, Tree, Modal, Form, message, Select, Row, Col } from "antd";

import categoriaBV from "../../services/categoriabv.service"
import "./ConfigBiblioteca.css"
const ConfigBiblioteca = () => {

  const [treeData,setTreeData] = useState()
  const [loading,setLoading] = useState()
  const [viewNewCategoria,setViewNewCategoria] = useState(false)
  const [formCategoria] = Form.useForm();
  const [categoriaSelected,setCategoriaSelected] = useState()
  const [viewPanelCategoria,setViewPanelCategoria] = useState(false)
  const [formEditCategoria] = Form.useForm();
  const [viewEditCategoria,setViewEditCategoria] = useState(false)
  const [viewNewSubcategoria,setViewNewSubcategoria] = useState(false)

  const [formSubcategoria] = Form.useForm();
  useEffect(()=>{
    loadCategorias()
  },[])


  const loadCategorias = () =>{
    setLoading(true)
    categoriaBV.getAll().then(res=>{
     
     const treeDataConvert = convertirData(res.data);
  setTreeData(treeDataConvert)
  setLoading(false)
    })
  }


    function convertirData(data) {
        const treeData = [];
      
        const map = {};
        let i;
        for (i = 0; i < data.length; i += 1) {
          map[data[i].id] = i;
          data[i].children = [];
        }
      
        for (i = 0; i < data.length; i += 1) {
          const nodo = data[i];
          if (nodo.padre_id !== 0) {
            data[map[nodo.padre_id]].children.push(nodo);
          } else {
            treeData.push(nodo);
          }
        }
      
        const formatTree = (node) => {
          return {
            title: node.nombre,
            sigla: node.sigla,
            carga: node.carga,
            orden: node.orden,
            estado: node.estado,
            key: `${node.id}`,
            children: node.children.map(formatTree),
          };
        };
      
        return treeData.map(formatTree);
      }


      const crearCategoria = (values) =>{
        let form = values
        form.padre_id = 0
        categoriaBV.create(form).then(res=>{
          console.log(res)
          message.success("Creado Correctamente")
          setViewNewCategoria(false)
          loadCategorias()
        }).catch(error=>{
          message.error("No se pudo guardar")
          console.log(error)
        })
      }


      const verEditarCategoria = () =>{
        setViewEditCategoria(true)
          formEditCategoria.setFieldsValue(categoriaSelected)
      }

      const verSubcategoria = () =>{
        setViewNewSubcategoria(true)
        formSubcategoria.resetFields()
      }

    const onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
        setCategoriaSelected(info.node)
        console.log('node', info.node);
        setViewPanelCategoria(true)
     
      
      };
      
// Ejemplo de uso:
// const data = [
//     { id: 1, padre_id: 0, nombre: 'Elemental A1', sigla:"A1", carga:"No" },
//     { id: 2, padre_id: 1, nombre: 'Ciclo A1a' , sigla:"A1a", carga:"No"},
//     { id: 3, padre_id: 2, nombre: 'Speaking', sigla:"Speaking", carga:"No" },
//     { id: 4, padre_id: 3, nombre: 'Juegos',  sigla:"Juegos", carga:"No" },
//     { id: 5, padre_id: 4, nombre: 'Juegos de Mesa' ,  sigla:"Juegos de Mesa", carga:"Si" },
  
//     { id: 7, padre_id: 3, nombre: 'Actividades', sigla:"Actividades", carga:"No" },
//     { id: 8, padre_id: 0, nombre: 'A2', sigla:"A2", carga:"No" },
//     { id: 9, padre_id: 0, nombre: 'Elemental B1', sigla:"B1", carga:"No" },
//     { id: 10, padre_id: 0, nombre: 'Elemental B2', sigla:"B2", carga:"No" },
//     { id: 11, padre_id: 0, nombre: 'Elemental C1', sigla:"C1", carga:"No" },
//     { id: 12, padre_id: 0, nombre: 'Elemental C2', sigla:"C2", carga:"No" },
//     { id: 13, padre_id: 0, nombre: 'Bkids', sigla:"Bkids", carga:"No" },
//     { id: 14, padre_id: 0, nombre: 'Entrenamiento examenes', sigla:"E-Examen", carga:"No" },
//   ];
  
const verCategoria = () =>{
  setViewNewCategoria(true)
  formCategoria.resetFields()
}

const editarCategoria = (values) =>{
  console.log(formEditCategoria)
  categoriaBV.update(categoriaSelected.key,values).then(res=>{
    message.success("Actualizado Correctamente")
    setViewPanelCategoria(false)
    setViewEditCategoria(false)
    loadCategorias()
  }).catch(error=>{
    console.log(error)
    message.error("No se pudo actualizar")
  })
}

const crearSubcategoria = (values) =>{
  let form = values
  form.padre_id = categoriaSelected.key
  categoriaBV.create(form).then(res=>{
    console.log(res)
    message.success("Creado Correctamente")
    setViewNewSubcategoria(false)
    setViewPanelCategoria(false)
    loadCategorias()
  }).catch(error=>{
    message.error("No se pudo guardar")
    console.log(error)
  })
}
  
    
if (loading)
return (
  <div className="spin_container">
    <Spin className="spin" size="large" />
  </div>
);
    return (
        <MyLayout>
           <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Ajustes</Breadcrumb.Item>
        <Breadcrumb.Item>Biblioteca Virtual</Breadcrumb.Item>
      </Breadcrumb>


      <Button type="primary" onClick={verCategoria} className='btn_crear'>
        Crear Categoría
      </Button>

      <h3>Categorías</h3>

 <Tree style={{ margin: "16px 0" }} 

      onSelect={onSelect}
      
      treeData={treeData}
    />


    {viewNewCategoria && (
      <Modal visible={viewNewCategoria} title="Crear Categoria" onCancel={()=>{setViewNewCategoria(false)}} cancelText="Cancelar" okText="Guardar" 
      onOk={formCategoria.submit}>
        <Form form={formCategoria} name="basic"
            layout="vertical" onFinish={crearCategoria}>
          <Form.Item name="nombre" label="Nombre Categoría"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese nombre",
                    },
                  ]}>
            <Input/>
          </Form.Item>

          <Form.Item name="sigla" label="Sigla"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese sigla",
                    },
                  ]}>
            <Input/>            
          </Form.Item>

          <Form.Item name="orden" label="Orden de visualización"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Orden de visualización",
                    },
                  ]}>
            <Input type='number' />            
          </Form.Item>

          <Form.Item name="carga" label="Carga archivos a este nivel?"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Carga archivos a este nivel",
                    },
                  ]}>
          <Select>
            <Select.Option value="Si">Si</Select.Option>
            <Select.Option value="No">No</Select.Option>
            </Select>     
          </Form.Item>
          
        </Form>
      </Modal>
    )}

    {viewEditCategoria && categoriaSelected && (
 <Modal visible={viewEditCategoria} title={categoriaSelected.title} onCancel={()=>{setViewEditCategoria(false)}} cancelText="Cancelar" okText="Actualizar" onOk={formEditCategoria.submit}>

<Form form={formEditCategoria} name="basic"
            layout="vertical" onFinish={editarCategoria} > 

<Form.Item name="title" label="Nombre Categoria"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese nombre",
                    },
                  ]}>
            <Input/>
          </Form.Item>

          <Form.Item name="sigla" label="Sigla"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese sigla",
                    },
                  ]}>
            <Input/>            
          </Form.Item>

          <Form.Item name="orden" label="Orden de visualización"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Orden de visualización",
                    },
                  ]}>
            <Input type='number' />            
          </Form.Item>

          <Form.Item name="carga" label="Carga archivos a este nivel?"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Carga archivos a este nivel",
                    },
                  ]}>
          <Select>
            <Select.Option value="Si">Si</Select.Option>
            <Select.Option value="No">No</Select.Option>
            </Select>     
          </Form.Item>

          <Form.Item name="estado" label="Estado"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Estado",
                    },
                  ]}>
          <Select>
            <Select.Option value="Activo">Activo</Select.Option>
            <Select.Option value="Inactivo">Inactivo</Select.Option>
            </Select>     
          </Form.Item>

</Form>

  </Modal>
    )}

    {viewPanelCategoria && categoriaSelected && (
      <Modal visible={viewPanelCategoria} title="Opciones de Categoría" onCancel={()=>{setViewPanelCategoria(false)}} cancelText="Cancelar" okText="Aceptar" 
     >
      <Row>
        <Col md={12}> <Button type='primary' onClick={verEditarCategoria}>Editar Categoría</Button></Col>
        <Col md={12}> <Button type='primary' onClick={verSubcategoria}>Agregar Subcategoría</Button></Col>
      </Row>
     
     

     </Modal>
    ) }


{viewNewSubcategoria && (
      <Modal visible={viewNewSubcategoria} title="Crear Categoria" onCancel={()=>{setViewNewSubcategoria(false)}} cancelText="Cancelar" okText="Guardar" 
      onOk={formSubcategoria.submit}>
        <Form form={formSubcategoria} name="basic"
            layout="vertical" onFinish={crearSubcategoria}>
          <Form.Item name="nombre" label="Nombre Categoría"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese nombre",
                    },
                  ]}>
            <Input/>
          </Form.Item>

          <Form.Item name="sigla" label="Sigla"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese sigla",
                    },
                  ]}>
            <Input/>            
          </Form.Item>

          <Form.Item name="orden" label="Orden de visualización"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Orden de visualización",
                    },
                  ]}>
            <Input type='number' />            
          </Form.Item>

          <Form.Item name="carga" label="Carga archivos a este nivel?"   rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Carga archivos a este nivel",
                    },
                  ]}>
          <Select>
            <Select.Option value="Si">Si</Select.Option>
            <Select.Option value="No">No</Select.Option>
            </Select>     
          </Form.Item>
          
        </Form>
      </Modal>
    )}

        </MyLayout>
    );
};

export default ConfigBiblioteca;