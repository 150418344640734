import React, {useState, useEffect} from 'react';

import { Breadcrumb, Table, Button, Spin, Input, Modal, message,Form, TimePicker,Col,Select } from "antd";
import MyLayout from "../../components/MyLayout";
import { Content } from "antd/lib/layout/layout";
import examenService from "../../services/examen.service";
import moment from 'moment';
import matriculaExamenService from "../../services/matricula_examen.service";

import { useAuth } from '../../context/auth.context';


const MatriculaExamenModal = (props) => {
    const [examenes, setExamenes] = useState([]);
    const [loading, setLoading] = useState(false);
    const estudiante_id = props.estudiante_id
    const tipo = props.tipo
    
    console.log("PROP",props)
    const {user,role} = useAuth()
    const empresa_id = user.empresa_id
    const usuario_id = user.id;
    const [viewModalExamen,setViewModalExamen] = useState(false)
    const [formOpcionesExamen] = Form.useForm();
    const [examenSelected,setExamenSelected] = useState()
    const [viewTRM,setViewTRM] = useState(false)


    const [tipos,setTipos] = useState([{tipo:'General'},{tipo:'Teachers'},{tipo:'Advanced'},
    ,{tipo:'Académico'}])
    const [modos,setModos] = useState([{modo:'Remoto'},
    {modo:'Presencial'}])
    
    const columns = [

      {
          title: "Codigo",
          dataIndex: "id",
          key: "id",
          // render: (text) => <a>{text}</a>,
        },

        {
          title: "Exámen",
          render: (record) => 
         <div>{record.nombre} - {record.tipo}</div>
        },

        


        // {
        //   title: "Tipo",
        //   dataIndex: "tipo",
        //   key: "tipo",
        //   // render: (text) => <a>{text}</a>,
        // },

        // {
        //   title: "Modo",
        //   dataIndex: "modo",
        //   key: "modo",
        //   // render: (text) => <a>{text}</a>,
        // },

        
      

        {
          title: "Estado",
          dataIndex: "estado",
          key: "estado",
          // render: (text) => <a>{text}</a>,
        },
     
       
        {
          title: "Fecha Registro",
          key: "createdAt",
          dataIndex: "createdAt",
          render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
        },

          {
            title: "Opciones",
           
            key: "action",
            render: (record) => <Button onClick={()=>{verOpcionesExamen(record)}}>Matricular</Button>,
          },
      ];


    useEffect(() => {
        listExamenes()
        
      }, []);


      const cancelOpcionesExamen = () => {
        setViewModalExamen(false);
      };

      const verOpcionesExamen = (record) =>{
        setViewModalExamen(true)
        setExamenSelected(record)
        if(record.nombre == "TOEFL"){
          setViewTRM(true)
        }
      }

      const listExamenes = () => {
        setLoading(true);
        examenService
          .getForain(empresa_id)
          .then((response) => {
            let filterExamenes = response.data;
           

            setExamenes(filterExamenes);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const handleCancel = () => {
        props.updateVisible(false);
      };

      const matricular = (examen) =>{
      
        console.log(examen)
        const form = {}
     
        form.examen_id = examenSelected.id
        form.estudiante_id = estudiante_id
        form.empresa_id = empresa_id;
        form.usuario_id = usuario_id;
        form.fecha = examen.fecha;
        form.hora = examen.hora;
        form.tipo = examen.tipo;
        form.modo = examen.modo;
        setLoading(true)
        matriculaExamenService
          .create(form)
          .then((response) => {
            console.log(response.data);
           
            props.updateVisible(false);
            setLoading(false)
            message.success("Guardado Correctamente");
          })
          .catch((e) => {
            setLoading(false)
            message.error("No se ha podido guardar");
            console.log(e);
          });
      }
 
      
    return (
      
        <Modal width={1024} visible={props.visible} onOk={handleCancel} onCancel={handleCancel} >
          {!loading && (
        <Content>
            <h2>Examenes</h2>
             <Table
        columns={columns}
        dataSource={examenes}
        pagination={{ pageSize: 4 }}
      />


{viewModalExamen &&  (

<Modal title="Opciones de matrícula" visible={viewModalExamen} okText="Matricular" onOk={formOpcionesExamen.submit} onCancel={cancelOpcionesExamen}>
<Form form={formOpcionesExamen} layout='vertical' onFinish={matricular}>
            <Form.Item
                label="Fecha de Presentación: "
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha",
                  },
                ]}
              >
               <Input type="date"></Input>
              </Form.Item>


              <Form.Item
                  label="Tipo: "
                  name="tipo"
                  rules={[
                    {
                      required: false,
                      message: "Por favor ingrese Tipo",
                    },
                  ]}
                >
                 <Select >
                  {tipos.map((item=>{
                    return <Select.Option value={item.tipo}>{item.tipo}</Select.Option>
                  }))}
                 </Select>
                </Form.Item>
           

             
                <Form.Item
                  label="Modo: "
                  name="modo"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Modo",
                    },
                  ]}
                >
                 <Select>
                  {modos.map((item=>{
                    return <Select.Option value={item.modo}>{item.modo}</Select.Option>
                  }))}
                 </Select>
                </Form.Item>
            

<Form.Item
                label="Hora de Presentación: "
                name="hora"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Hora",
                  },
                ]}
              >
               <TimePicker use12Hours format="hh:mm A" placeholder="Hora de Presentación"></TimePicker>
              </Form.Item>


                

               {viewTRM && (
                <Form.Item
                  label="Ingrese valor TRM del dia: "
                  name="trm"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Valor Trm del dia",
                    },
                  ]}
                >
                 <Input type="number"></Input>
                </Form.Item>
                )}

          
              </Form>

    
           
</Modal>
)}

        </Content>
)}

{loading && (
 <div className="spin_container">
    <Spin className="spin" size="large" />
  </div>

)}


        </Modal>
        
    );
};

export default MatriculaExamenModal;