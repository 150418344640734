import http from "../utils/http-common";

class EstudianteService {
    getAll() {
      return http.get("/estudiantes");
    }
    get(id) {
      return http.get(`/estudiantes/${id}`);
    }


    getForain(id) {
      return http.get(`/estudiantes/forain/${id}`);
    }

    getStatus(id) {
      return http.get(`/estudiantes/status/${id}`);
    }

    getPerfil(data) {
      return http.post("/estudiantes/perfil", data);
    }


    create(data) {
      return http.post("/estudiantes", data);
    }

    foto(data) {
      return http.post("/estudiantes/foto", data);
    }
    update(id, data) {
      return http.put(`/estudiantes/${id}`, data);
    }
    delete(id) {
      return http.delete(`/estudiantes/${id}`);
    }
   
  }
  export default new EstudianteService();