import http from "../utils/http-common";

class JornadaService {
    getAll() {
      return http.get("/jornadas");
    }
    get(id) {
      return http.get(`/jornadas/${id}`);
    }
    create(data) {
      return http.post("/jornadas", data);
    }
    update(id, data) {
      return http.put(`/jornadas/${id}`, data);
    }
    delete(id) {
      return http.delete(`/jornadas/${id}`);
    }
   
  }
  export default new JornadaService();