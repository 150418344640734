import http from "../utils/http-common";

class EmbudoService {
    getAll() {
      return http.get("/embudos");
    }
    get(id) {
      return http.get(`/embudos/${id}`);
    }
    create(data) {
      return http.post("/embudos", data);
    }
    update(id, data) {
      return http.put(`/embudos/${id}`, data);
    }
    delete(id) {
      return http.delete(`/embudos/${id}`);
    }
   
  }
  export default new EmbudoService();