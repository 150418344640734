import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  TimePicker,
  Table,
  Button
} from "antd";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';
import tarifaRangoService from "../../services/tarifa_rango.service";
const {confirm} = Modal;
const TarifaRangoModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [tarifas, setTarifas] = useState([]);
    const [disabledCaracteristica, setDisabledCaracteristica] = useState()
    const [viewModalEdit,setViewModalEdit] = useState(false)
    const [tarifaSelected,setTarifaSelected] = useState()
    
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const empresa_id = 1;
  const usuario_id = 1;
    const rango = props.children
  useEffect(()=>{
    listTarifas()
  },[])


  const columns = [
    {
        title: "De",
       // dataIndex: "inicial",
        //key: "inicial",
         render: (record) => <div>{record.inicial} Estudiantes</div>,
      },

      {
        title: "Hasta",
        //dataIndex: "final",
        //key: "final",
        render: (record) => <div>{record.final} Estudiantes</div>,
      },

      {
        title: "Valor",
        //dataIndex: "valor",
        //key: "valor",
         render: (record) => <div>$ <NumericFormat value={record.valor} displayType="text" allowLeadingZeros thousandSeparator="," /></div>,
      },

      {
        title: "Opciones",
        render: (record) => <div>
          <Button type="primary" onClick={()=>editTarifa(record)}><EditOutlined/> </Button>
          <Button type="danger" onClick={()=>deleteTarifa(record)}><DeleteOutlined/> </Button>
          </div>,
      },
      
    
]

const deleteTarifa = (record) =>{
    confirm({
        title:"Desea eliminar la tarifa?",
        okText:"Si",
        okType:'danger',
        cancelText:"No",
        onOk(){
          setLoading(true);
          tarifaRangoService.delete(record.id)
        .then((response) => {
          listTarifas()
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
        }
      })
}

const listTarifas = () => {
    setLoading(true);
    tarifaRangoService
      .getForain(rango.id)
      .then((response) => {
        let filterLead = response.data;
        console.log(response.data);
        // filterMedios = response.data.filter(
        //   (item) => item.empresa_id == empresa_id
        // );
        setTarifas(filterLead);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  

  const onFinish = (values) => {
    console.log(values)
   
    const form = values;
    form.empresa_id = empresa_id
    form.rango_id=rango.id
    console.log(form)
    tarifaRangoService
      .create(form)
      .then((response) => {
        console.log(response.data);
        
        listTarifas()
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
       
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  const handleCancel = () => {
    props.updateVisible(false);
  };

//   const validarTipo = (valor) =>{
//     setDisabledCaracteristica(false)
//     form.setFieldsValue({caracteristica:''})
//     setCaracteristicas([])
//     console.log(valor)
//     if(valor == "CURSO"){
        
//         setCaracteristicas(['NORMAL','PERSONALIZADO'])
//     }
//     else if(valor == "EXAMEN" ){
       
//         setCaracteristicas(['APTIS REMOTO','APTIS PRESENCIAL','TOEFL PRESENCIAL','IELTS PRESENCIAL','OOPT PRESENCIAL'])
//     }
//     else{
//         setDisabledCaracteristica(true)
//         form.setFieldsValue({caracteristica:'NORMAL'})
//     }
//   }


const editTarifa = (record) =>{
    setViewModalEdit(true)
    formEdit.setFieldsValue({
        inicial:record.inicial,
        final:record.final,
        valor:record.valor,
        id:record.id
    })

    setTarifaSelected(record)


}
 

 const editarTarifa = (values) =>{
    setLoading(true)
    let form = values
    
    console.log(form)

    tarifaRangoService
    .update(tarifaSelected.id,form)
    .then((response) => {
      console.log(response.data);
      setViewModalEdit(false)
      setLoading(false)
      formEdit.resetFields()
      listTarifas()
      message.success("Actualizado Correctamente");
    })
    .catch((e) => {
      setViewModalEdit(false)

      setLoading(false)
      message.error("No se ha podido actualizar");
      console.log(e);
    });
    
    }

    return (
      <>
        <Modal width={1024} visible={props.visible} onOk={handleCancel} onCancel={handleCancel}>
        <Content>

            <h3>Tarifas {rango.nombre}</h3>
        
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row>
  
        
              <Col span={6}>
                <Form.Item
                  label="Desde: "
                  name="inicial"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese rango inicial",
                    },
                  ]}
                >
                 <Input type="number"></Input>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  label="Hasta: "
                  name="final"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese rango final",
                    },
                  ]}
                >
                 <Input type="number"></Input>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  label="Valor Tarifa: "
                  name="valor"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Valor",
                    },
                  ]}
                >
                 <Input></Input>
                </Form.Item>
              </Col>

              <Col span={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit">Guardar</Button>
                </Form.Item>
              </Col>

        
            </Row>
          </Form>


          <Table
        columns={columns}
        dataSource={tarifas}
        pagination={{ pageSize: 4 }}
      />

        </Content>
      </Modal>

      {viewModalEdit && tarifaSelected && ( 
        <Modal visible={viewModalEdit} onCancel={()=>setViewModalEdit(false)} okText="Editar" onOk={formEdit.submit} cancelText="Cancelar" title="Editar Tarifa">
          <Form name="basic"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={editarTarifa}
            form={formEdit}> 



    <Form.Item
      label="Desde: "
      name="inicial"
      rules={[
        {
          required: true,
          message: "Por favor ingrese rango inicial",
        },
      ]}
    >
     <Input type="number"></Input>
    </Form.Item>
 

 
    <Form.Item
      label="Hasta: "
      name="final"
      rules={[
        {
          required: true,
          message: "Por favor ingrese rango final",
        },
      ]}
    >
     <Input type="number"></Input>
    </Form.Item>


 
    <Form.Item
      label="Valor Tarifa: "
      name="valor"
      rules={[
        {
          required: true,
          message: "Por favor ingrese Valor",
        },
      ]}
    >
     <Input></Input>
    </Form.Item>
  

          </Form>
        </Modal>
      )}
      </>
    );
};

export default TarifaRangoModal;