import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyDKCmk49-gF5XirouHSiOgzxqVWXhrZWCk",
    authDomain: "sistema-8f233.firebaseapp.com",
    projectId: "sistema-8f233",
    storageBucket: "sistema-8f233.appspot.com",
    messagingSenderId: "608437417444",
    appId: "1:608437417444:web:0bf2d848fb9d83df06c3f1",
    measurementId: "G-457CL8CXE2"
});

// Firebase storage reference
const storage = getStorage(app);
export default storage;