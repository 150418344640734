import React, { useEffect, useState } from 'react';
import MyLayout from '../../components/MyLayout';
import actividadService from '../../services/actividad.service';
import {Table, Form, Select, DatePicker, Button, Col, Row, Spin, Input} from 'antd'
import {SearchOutlined} from '@ant-design/icons';
import moment from 'moment';
import {Link} from 'react-router-dom'
import { useForm } from 'antd/lib/form/Form';
const { RangePicker } = DatePicker;
const Tarea = () => {

    const [data,setData] = useState()
    const [dataFilter,setDataFilter] = useState()
    const [loading,setLoading] = useState(false)
    const [form] = useForm()
 useEffect(()=>{
    form.setFieldsValue({fechas:[moment(),moment()]})
    form.setFieldsValue({tipo:"Todos"})
    console.log(form.getFieldsValue())
     loadTareas(form.getFieldsValue())
    
 },[])

 const columns = [
    {
        title: "Tipo",
        dataIndex: "tipo",
            key: "tipo",
    },

    {
        title: "Oportunidad",
        render:(record)=> <Link to={"/ver-lead"}
        state={{lead: record.Lead}}>{record.Lead.nombre}</Link>
    },

    {
        title: "Celular",
        render:(record)=> <div>{record.Lead.celular}</div>
    },
    {
        title: "Descripción",
        dataIndex: "descripcion",
            key: "descripcion",
    },
    

    {
        title: "Fecha",
        render:(record)=> <div>{moment(record.fecha).format('DD/MM/YYYY')}</div>
    },
    {
        title: "Hora",
        render:(record)=> {
            let allFecha = moment(record.fecha).format('YYYY-MM-DD') + " " + record.hora;
            console.log(allFecha);
        return <div>{moment(allFecha).format('hh:mm a')}</div>
    }
    },
    {  title: "Asesor",

    filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
        return <Input autoFocus placeholder='Ingrese Asesor' 
        value = {selectedKeys[0]} 
        onChange={(e)=>{
          setSelectedKeys(e.target.value?[e.target.value]:[])
         confirm({closeDropdown:false})
        }}
        onPressEnter={()=>{
          confirm()
        }} 
        
        onBlur={()=>{
          confirm()
        }} >
        
        </Input>
      },
      filterIcon:()=>{
        return <SearchOutlined />
      },
      onFilter:(value,record)=>{
        let nombre = record.Usuario.nombre
        return nombre.toLowerCase().includes(value.toLowerCase())
      },
    render:(record)=> {return <div>{record.Usuario.nombre.toUpperCase()}</div>}
    },

    {
        title: "Vencimiento",
        render:(record)=>{
            let hoy = moment()
            let fecha = moment(record.fecha).format('YYYY-MM-DD') + " " + record.hora;
           
        
            if(hoy.diff(fecha)>0){
       return <div style={{color:'red'}}>{moment(fecha).startOf('hours').fromNow()}</div>
    } else{
        return <div style={{color:'green'}}>{moment(fecha).startOf('hours').fromNow()}</div>
    }
    } 
    },

    {
        title: "Estado",
        dataIndex: "estado",
            key: "estado",
    },
 
 ]

 const loadTareas = (form) =>{
    setLoading(true)
    actividadService.filter(form).then(
        res=>{
            console.log(res.data)
            setData(res.data)
            setDataFilter(res.data)
            setLoading(false)
        }
        )
 }

 const onFinish = (values) =>{
console.log(values)
loadTareas(values)
// let allData = data
// let fechaInicial;
// let fechaFinal;
// let myTipo = values.tipo || 'Todos'
// console.log(myTipo)
// if(values.fechas){
//     fechaInicial = moment(values.fechas[0]._d).format('YYYY-MM-DD')
//     fechaFinal = moment(values.fechas[1]._d).format('YYYY-MM-DD')
// }

// console.log(fechaInicial)
// console.log(fechaFinal)

    
//             if(myTipo != 'Todos'){
//             let filtro1 = allData.filter(item=>item.tipo == myTipo)
//             setDataFilter(filtro1)
//             if(values.fechas){
//                 var filtro2 = filtro1.filter(
//                     function (a)
//                     {
//                         console.log(new Date(moment(a.fecha).format('YYYY-MM-DD')))
//                         return (new Date(moment(a.fecha).format('YYYY-MM-DD'))) >= new Date(fechaInicial) && (new Date(moment(a.fecha).format('YYYY-MM-DD'))) <= new Date(fechaFinal);
//                     });
//             console.log(filtro2);
//                 setDataFilter(filtro2)
//             }
            
//         } else{
//             if(values.fechas){
//                 var filtro2 = data.filter(
//                     function (a)
//                     {
//                         console.log(new Date(moment(a.fecha).format('YYYY-MM-DD')))
//                         return (new Date(moment(a.fecha).format('YYYY-MM-DD'))) >= new Date(fechaInicial) && (new Date(moment(a.fecha).format('YYYY-MM-DD'))) <= new Date(fechaFinal);
//                     });
//             console.log(filtro2);
//                 setDataFilter(filtro2)
//             } else{
//                 setDataFilter(data)
//             }
            
//         }
        
    
 }

 if (loading)
 return (
   <div className="spin_container">
     <Spin className="spin" size="large" />
   </div>
 );
    return (
        <MyLayout>

            <Form layout='vertical' labelCol={{ span:12 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            form = {form}
            onFinish={onFinish}>
                <Row>
                <Col span={8}>
                <Form.Item label="Tipo" name="tipo" rules={[
                    {
                      required: false,
                      message: "Por favor ingrese Tipo",
                    },
                  ]} >
                <Select defaultActiveFirstOption  >
                  <Select.Option value="Todos">Todos</Select.Option>
                  <Select.Option value="Llamada">Llamada</Select.Option>
                  <Select.Option value="WhatsApp">WhatsApp</Select.Option>
                  <Select.Option value="Nota">Nota</Select.Option>
                  <Select.Option value="Correo">Correo</Select.Option>
                  <Select.Option value="Reunión">Reunión</Select.Option>
                  <Select.Option value="Exámen Diagnóstico">Exámen Diagnóstico</Select.Option>
                  <Select.Option value="Entrega de resultados">Entrega de resultados</Select.Option>
                </Select>
            </Form.Item>
                </Col>

                <Col span={8}>
                <Form.Item label="Fechas" name="fechas" rules={[
                    {
                      required: false,
                      message: "Por favor ingrese Fechas",
                    },
                  ]}>
            <RangePicker  placeholder={['Fecha Inicial','Fecha Final']} />
            </Form.Item>
                </Col>
               
                <Col span={8}>
            <Form.Item style={{marginTop:30}}>
            <Button type="primary" htmlType="submit">
            Filtrar
            </Button>
            </Form.Item>
            </Col>
            </Row>


            </Form>

            <Table columns={columns} dataSource={dataFilter}></Table>
        </MyLayout>
    );
};

export default Tarea;