import http from "../utils/http-common";

class SedeService {
    getAll() {
      return http.get("/sedes");
    }
    get(id) {
      return http.get(`/sedes/${id}`);
    }

    getForain(id) {
      return http.get(`/sedes/forain/${id}`);
    }

    create(data) {
      return http.post("/sedes", data);
    }
    update(id, data) {
      return http.put(`/sedes/${id}`, data);
    }
    delete(id) {
      return http.delete(`/sedes/${id}`);
    }
   
  }
  export default new SedeService();