import http from "../utils/http-common";

class ObligacionService {
    getAll() {
      return http.get("/obligaciones");
    }
    get(id) {
      return http.get(`/obligaciones/${id}`);
    }

    getType(tipo,id) {
        return http.get(`/obligaciones/${tipo}/${id}`);
      }

    getForain(id) {
      return http.get(`/obligaciones/forain/${id}`);
    }

    getByMatricula(id) {
      return http.get(`/obligaciones/buscar/matricula/${id}`);
    }

    create(data) {
      return http.post("/obligaciones", data);
    }
    update(id, data) {
      return http.put(`/obligaciones/${id}`, data);
    }
    delete(id) {
      return http.delete(`/obligaciones/${id}`);
    }

    sendMail(id) {
      return http.get(`/robots/pagos/persona/${id}`);
    }

   
  }
  export default new ObligacionService();