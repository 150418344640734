import http from "../utils/http-common";

class TareaService {
    getAll() {
      return http.get("/tareas");
    }
    get(id) {
      return http.get(`/tareas/forain/${id}`);
    }

    finallyTarea(id) {
      return http.get(`/tareas/finally/${id}`);
    }

    create(data) {
      return http.post("/tareas", data);
    }

    filter(data) {
      return http.post("/tareas/filter", data);
    }

    update(id, data) {
      return http.put(`/tareas/${id}`, data);
    }
    delete(id) {
      return http.delete(`/tareas/${id}`);
    }

    updateAll(data) {
      return http.post(`/tareas/updateAll/`, data);
    }


    
   
  }
  export default new TareaService();