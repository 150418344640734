import React from 'react';
import {
    Modal,
    Form,
    Col,
    Row,
    Input,
    DatePicker,
    Select,
    message,
    TimePicker 
  } from "antd";
  import { Content } from "antd/lib/layout/layout";
  import actividadService from "../../services/actividad.service";
  import { useAuth } from '../../context/auth.context';
  import moment from 'moment';
  const { TextArea } = Input;

const ResultadoTareaModal = (props) => {
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const asesor_id = user.id;
    const [form] = Form.useForm();
 
  const handleCancel = () => {
    props.updateVisible(false);
  };
  
  const onFinish = (values) => {
    //props.updateLoading(true);
    const form = values;
    form.asesor_id = asesor_id;
    form.tarea_id = props.tarea_id;
    form.fechaResultado = new Date()
    console.log(form)
    actividadService
      .update(form.tarea_id,form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListActividad();
        props.updateNewActividad(true);
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

    return (
        <Modal title="Resultado" visible={props.visible} onOk={form.submit} onCancel={handleCancel} okText="Guardar Resultado">
           
        <Content>
     
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row>

            <Col span={24}>
                <Form.Item
                  label="Tipo resultado: "
                  name="tipoResultado"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Estado de Tarea",
                    },
                  ]}
                >
                 <Select>
                    <Select.Option value="Sin Respuesta">Sin Respuesta</Select.Option>
                    <Select.Option value="Ocupado">Ocupado</Select.Option>
                    <Select.Option value="Número incorrecto">Número incorrecto</Select.Option>
                    <Select.Option value="Dejó mensaje">Dejó mensaje</Select.Option>
                    <Select.Option value="Contactado">Contactado</Select.Option>
                 </Select>
                </Form.Item>
              </Col>

            

              
              <Col span={24}>
                <Form.Item
                  label="Resultado: "
                  name="resultado"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Resultado obtenido",
                    },
                  ]}
                >
                 <TextArea  />
                </Form.Item>
              </Col>



            </Row>
          </Form>
        </Content>
      </Modal>
    );
};

export default ResultadoTareaModal;