
import React, { useState, useEffect } from "react";
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Table, Button, Spin } from "antd";
import canalService from "../../services/canal.service";
import CanalModal from "../../components/modals/CanalModal";
const Canal = () => {

    const [viewNewCanal, setViewNewCanal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [canales, setCanales] = useState([]);
    const empresa_id = 1;

    useEffect(() => {
        listCanales();
      }, []);


      const listCanales = () => {
        setLoading(true);
        canalService
          .getAll()
          .then((response) => {
            let filterCanales = response.data;
            console.log(response.data);
            filterCanales = response.data.filter(
              (item) => item.empresa_id == empresa_id
            );
            setCanales(filterCanales);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const columns = [
        {
          title: "Nombre",
          dataIndex: "nombre",
          key: "1",
          // render: (text) => <a>{text}</a>,
        },
      ];

      const newCanal = () => {
        setViewNewCanal(true);
      };

      if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Ajustes</Breadcrumb.Item>
        <Breadcrumb.Item>Canales de Contacto</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newCanal}>
        Crear Canal de Contacto
      </Button>

      <CanalModal
        visible={viewNewCanal}
        updateVisible={setViewNewCanal}
        updateLoading={setLoading}
        updateListCanal={listCanales}
      ></CanalModal>

      <Table
        columns={columns}
        dataSource={canales}
        pagination={{ pageSize: 5 }}
      />
    </MyLayout>
    );
};

export default Canal;