import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  TimePicker,
  Table,
  Button
} from "antd";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";
import {DeleteOutlined} from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';
import liquidacionDocenteService from "../../services/liquidacion_docente.service";
import docenteService from "../../services/docente.service";
import { useAuth } from '../../context/auth.context';
import periodoService from "../../services/periodo.service";
const {confirm} = Modal;
const LiquidacionDocenteModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [tarifas, setTarifas] = useState([]);
    const [disabledCaracteristica, setDisabledCaracteristica] = useState()
  const [form] = Form.useForm();
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  const [docentes, setDocentes] = useState([]);
  const [periodos, setPeriodos] = useState([]);

  useEffect(()=>{
   listDocentes()
   listPeriodos()
  },[])


  const listPeriodos = () => {
    setLoading(true);
    periodoService
      .getAll()
      .then((response) => {
        let filterPeriodo = response.data;
      
       
        setPeriodos(filterPeriodo);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };


  const onFinish = (values) => {
    props.updateLoading(true)
    console.log(values)
    const form = values;
    form.empresa_id = empresa_id
    form.usuario_id = usuario_id
    console.log(form)
    liquidacionDocenteService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateVisible(false)
        props.updateListLiquidaciones()
        props.updateLoading(false)
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false)
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const listDocentes = () => {
    setLoading(true);
    docenteService
      .getAll()
      .then((response) => {
        let filterDocentes = response.data;
        console.log(response.data);
        filterDocentes = response.data.filter(
          (item) => item.estado == "Activo"
        );
        setDocentes(filterDocentes);
       
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onSearch = (value) => {
   
  };


    return (
        <Modal width={1024} visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
        <Content>

        
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row>
  
            <Col span={8}>
              <Form.Item
                label="Docente: "
                name="docente_id"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Docente",
                  },
                ]}
              >
                <Select showSearch onSearch={onSearch}  optionFilterProp="children">
                  {docentes.map((item) => (
                    <Select.Option value={item.id} key={item.id}>{item.primerNombre} {item.segundoNombre} {item.primerApellido} {item.segundoApellido}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
        
            <Col span={8}>
              <Form.Item
                label="Mes: "
                name="mes"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Mes",
                  },
                ]}
              >
                <Select>
                 
                    <Select.Option value="Enero" >Enero</Select.Option>
                    <Select.Option value="Febrero" >Febrero</Select.Option>
                    <Select.Option value="Marzo" >Marzo</Select.Option>
                    <Select.Option value="Abril" >Abril</Select.Option>
                    <Select.Option value="Mayo" >Mayo</Select.Option>
                    <Select.Option value="Junio" >Junio</Select.Option>
                    <Select.Option value="Julio" >Julio</Select.Option>
                    <Select.Option value="Agosto" >Agosto</Select.Option>
                    <Select.Option value="Septiembre" >Septiembre</Select.Option>
                    <Select.Option value="Octubre" >Octubre</Select.Option>
                    <Select.Option value="Noviembre" >Noviembre</Select.Option>
                    <Select.Option value="Diciembre" >Diciembre</Select.Option>
             
                </Select>
              </Form.Item>
            </Col>
        
            <Col span={8}>
              <Form.Item
                label="Año: "
                name="annio"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Año",
                  },
                ]}
              >
                <Select>
                {periodos.map((item=>{
                  return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>
        
              
        
            </Row>
          </Form>



        </Content>
      </Modal>
    );
};

export default LiquidacionDocenteModal;