import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import logo from "./logo";
import NumeroALetras from "./NumeroALetras";
import { NumericFormat } from "react-number-format";
import regular from "./fonts/Roboto-Regular.ttf";
import bold from "./fonts/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: regular }, { src: bold, fontWeight: "bold" }],
});

const DocPDF = (props) => {
  const pago = props.children;
  const estudiante = props.estudiante;

  const styles = StyleSheet.create({
    body: {
      padding: 25,
      fontFamily: "Roboto",
   
    },

    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 20,
    },

    table2: {
      display: "table",
      width: "auto",
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 15
    },

    tableRow2: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol2: {
      width: "60%",
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },

    

    tableCol3: {
      width: "20%",
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },

    tableCol4: {
      width: "33.3333333%",
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },

    tableCell2: {
      margin: "auto",
      marginTop: 3,
      fontSize: 10
    },

    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "70%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },

    tableColS: {
      width: "70%",
    },

    tableColB2: {
      width: "30%",
    },

    tableColB: {
      width: "30%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },

    

    tableCol5: {
      width: "25%",
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },

    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },

    tableCell2B: {
      marginTop: 5,
      fontSize: 10,
    },

    logo: {
      width: 50,
      height: 70,
      textAlign: "center",
    },

    footer: {
      // position: "absolute",
      // bottom: 30,
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderTopWidth: 1,
      marginTop: 20,
      textAlign: "center",
      fontSize: 8,
      left: 0,
      right: 0,
      paddingTop:5
    },
    footer2: {
      // position: "absolute",
      // bottom: 10,
    
      textAlign: "center",
      fontSize: 8,
      left: 0,
      right: 0,
    },

    titulo: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: "bold",
    },

    negrita: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },

    contacto:{
      fontSize: 8,
      textAlign: "center"
      
    }
  });

  const formatName = (record)=>{
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  return (
    <Document>
      <Page size="letter" orientation="portrait" style={styles.body}>
        <View style={styles.table2}>
          <View style={styles.tableRow2}>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell2}>
                <Image style={[styles.logo]} src={logo}></Image>
              </Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={[styles.tableCell2, styles.titulo]}>
              RECIBO DE PAGO
              </Text>
              <Text style={[styles.tableCell2, styles.titulo]}>
              BRIGHTON CENTRO DE FORMACION EN INGLES
              </Text>
            </View>

            <View style={styles.tableCol3}>
              <Text style={[styles.contacto]}>
              98.391.866-4
              </Text>

              <Text style={[styles.contacto]}>
              Carrera 38 No. 19-26 PALERMO
              </Text>

              <Text style={[styles.contacto]}>
              6027362555 - 318 316 3984
              </Text>
            
            </View>
          </View>
        </View>


        <View style={styles.table2}>
      <View style={styles.tableRow2}>
<View style={styles.tableCol4}>
<Text style={styles.tableCell2B}> <Text style={styles.negrita}>Fecha: </Text> {pago.fecha}  </Text>
<Text style={styles.tableCell2B}> <Text style={styles.negrita}>Recibido de:  </Text> {formatName(estudiante)} </Text>
</View>
<View style={styles.tableCol4}>
<Text style={styles.tableCell2B}> </Text>
<Text style={styles.tableCell2B}> <Text style={styles.negrita}>Identificación: </Text>  {estudiante.numId} </Text>
</View>

<View style={styles.tableCol4}>
<Text style={styles.tableCell2}><Text style={styles.negrita}>RECIBO NO. </Text>  </Text>
<Text style={styles.tableCell2}>{pago.codigo_recibo} </Text>
</View>

</View>
</View>


<View style={styles.table}>
          <View style={styles.tableRow}>
            
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell,styles.negrita]}>Concepto</Text>
            </View>
            <View style={styles.tableColB}>
              <Text style={[styles.tableCell,styles.negrita]}>Valor</Text>
            </View>

           
          </View>

          <View style={styles.tableRow}>

            <View style={styles.tableCol}>
              <Text style={[styles.tableCell]}> <Text> {pago.concepto} </Text></Text>
            </View>

            <View style={styles.tableColB}>
              <Text style={[styles.tableCell]}> <Text>{formatterPeso.format(pago.valor)}</Text></Text>
            </View>
          </View>
    
        </View>


       
        <View style={styles.table2}>
        <View style={styles.tableRow2}>
        <View style={styles.tableColS}>
        <View style={styles.tableCell2B}>
        <Text > <Text style={styles.negrita}> Total Letras:  </Text> {NumeroALetras(pago.valor)}</Text>
        </View>
        <View style={styles.tableCell2B}>
        {pago.Usuario && (
        <Text><Text style={styles.negrita}> Cajero </Text> {pago.Usuario.nombre}</Text>
        )}
        </View>

        <View style={styles.tableCell2B}>
        <Text><Text style={styles.negrita}> Observaciones  </Text> {pago.observaciones} </Text>
        </View>
        </View>

        


        <View style={styles.tableColB2}>
        <View style={styles.tableCell2B}>
        <Text style={styles.negrita}>TOTAL PAGADO. <Text >{formatterPeso.format(pago.valor)}</Text></Text>
        </View>
        <View style={styles.tableCell2B}>
        <Text style={styles.negrita}>{pago.metodo} <Text>{formatterPeso.format(pago.valor)}</Text></Text>
        </View>
        </View>

      </View>
</View>


  
<Text style={[styles.footer]}>Brighton - Centro de Formación en Inglés 
        </Text>
        
          <Text style={[styles.footer2]} > Fecha de impresión: {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}</Text>
           
       


      </Page>
    </Document>
  );
};

export default DocPDF;
