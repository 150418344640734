import React, {useState,useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom'
import estudianteService from '../../services/estudiante.service';
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Col, Row, Collapse, Space,Divider,Tooltip,Modal, Avatar, Spin} from "antd";
import {LogoutOutlined, LineChartOutlined,CheckCircleOutlined,UserOutlined} from '@ant-design/icons'

import { useAuth } from '../../context/auth.context';
const { Panel } = Collapse;
const PerfilEstudiante = () => {

    const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const correo = user.correo
  const tipo = user.tipo
  const usuario_id = user.id;
  const [estudiante,setEstudiante] = useState()
  const [fotoPerfil,setFotoPerfil] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    getEstudiante()
  },[])

  const getEstudiante = () =>{
    setLoading(true)
    estudianteService.getPerfil({correo}).then((response)=>{
        let filterEstudiante = response.data[0];
        setEstudiante(filterEstudiante)
setFotoPerfil(setEstudiante.url)
setLoading(false)
    }).catch(error=>{
        console.log(error)
        setLoading(false)
    })
  }
  if (loading)
  return (
    <div className="spin_container">
      <Spin className="spin" size="large" />
    </div>
  );
    return (
        <MyLayout>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Institucional</Breadcrumb.Item>
          <Breadcrumb.Item>Estudiante </Breadcrumb.Item>
        </Breadcrumb>

        {estudiante && (
            <>
        <Row>
            <Col span={24}>
                <div className='info'>
         
                { estudiante.url != null && (
       <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} src={estudiante.url}/>
      )
                }
     { estudiante.url == null && (
       <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} icon={<UserOutlined />}/>
      )} 
                <div className='nombre'>{estudiante.primerNombre} {estudiante.segundoNombre} {estudiante.primerApellido} {estudiante.segundoApellido}   <div className='numId'> {estudiante.tipoId} No. {estudiante.numId} </div></div>
              
                </div>
            </Col>

            <Col span={24}>
            <Space direction="vertical" className='space'>
                <Collapse collapsible="header" defaultActiveKey={['1']}>
                    <Panel header="Información personal" key="1">
                     <Row>
                        <Col xs={24} md={12}  className="columna">
                            <div><strong>Nombres:</strong> {estudiante.primerNombre} {estudiante.segundoNombre}</div>
                            <div><strong>Identificación:</strong> {estudiante.numId} </div>
                            <div><strong>Teléfono:</strong> {estudiante.telefono} </div>
                            <div><strong>Correo electrónico:</strong> {estudiante.correo} </div>
                            <div><strong>Lugar de residencia:</strong> {estudiante.residencia} </div>
                            <div><strong>Fecha de nacimiento:</strong> {estudiante.fechaNacimiento} </div>
                           
                        </Col>

                        <Col xs={24} md={12} className="columna">
                        <div><strong>Apellidos:</strong> {estudiante.primerApellido} {estudiante.segundoApellido}</div>
                        <div><strong>Sexo:</strong> {estudiante.sexo} </div>
                        <div><strong>Celular:</strong> {estudiante.celular}</div>
                        <div><strong>Dirección:</strong> {estudiante.direccion} </div>
                        <div><strong>Barrio:</strong> {estudiante.barrio} </div>
                        <div><strong>Lugar de nacimiento:</strong> {estudiante.lugarNacimiento} </div>
                       
                        </Col>
                     </Row>
                    </Panel>                   
                </Collapse>

              



            </Space>
            </Col>
      
        </Row>
        </>
)}
      
    

        </MyLayout>
    );
};

export default PerfilEstudiante;