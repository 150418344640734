import React,{useState} from 'react';
import MyLayout from '../../components/MyLayout';
import {Form, Input, Button, message, Spin} from "antd"
import usuarioService from '../../services/usuario.service';
import { useAuth } from '../../context/auth.context';
const PasswordPage = () => {
    const [loading,setLoading] = useState(false)
    const {user} = useAuth()

const onFinish = (values) =>{
    setLoading(true)
    
    if(values.password != values.remember){
        message.error("Las contraseñas no coinciden");
    }
     else{
        let form = values
        values.user = user
        usuarioService.changePassword(form).then(res=>{
            setLoading(false)
            message.success("Contraseña actualizada")
        }).catch(error=>{
            setLoading(false)
            message.error("No se ha podido actualizar");
        })
     }

}
if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
    return (
        <MyLayout>
            <div className='centrar'>
                <h3>Cambiar Contraseña</h3>
                <Form layout='vertical' onFinish={onFinish} >
                    <Form.Item name="password" label="Nueva contraseña" rules={[
                  {
                    required: false,
                    message: "Por favor ingrese nueva contraseña",
                  },
                ]}>
                        <Input type="password"></Input>
                    </Form.Item>

                    <Form.Item name="remember" label="Confirmar nueva contraseña" rules={[
                  {
                    required: false,
                    message: "Por favor confirme nueva contraseña",
                  },
                ]}>
                        <Input type="password"></Input>
                    </Form.Item>

                    <Form.Item >
                        <Button type='primary' htmlType='submit' >Cambiar Contraseña</Button>
                    </Form.Item>
                </Form>
            </div>
        </MyLayout>
    );
};

export default PasswordPage;