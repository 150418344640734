import React from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import pagoService from "../../../services/pago.service";
import { NumericFormat } from 'react-number-format';
import { useAuth } from '../../../context/auth.context';
import obligacionesService from "../../../services/obligaciones.service";

const ObligacionEditModal = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const {user,role} = useAuth()

//   const obligacion_id = props.obligacion_id
  const obligacion = props.children
  console.log(obligacion)
  
   form.setFieldsValue(obligacion)
  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    
    //form.empresa_id = empresa_id;
    // form.obligacion_id = obligacion_id
    //form.concepto = "PAGO DE " + obligacion.concepto 
   
    // form.moneda = obligacion.moneda
    // form.usuario_id = usuario_id
    // form.estado = 'Generado'
    console.log(form)
    obligacionesService
      .update(obligacion.id,form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListPagos();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };
  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Valor de la obligación: "
                name="valor"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Valor de la obligación",
                  },
                ]}
              >
                <NumericFormat className="ant-input" thousandSeparator="," decimalScale={3} />
               
              </Form.Item>
            </Col>

           
         
            <Col span={24}>
              <Form.Item
                label="Observaciones: "
                name="observaciones"
                rules={[
                  {
                    required: true,
                    message: "Ingrese Observaciones",
                  },
                ]}
              >
               <TextArea/>
               
              </Form.Item>
            </Col>

           

            <Col span={24}>
              <Form.Item
                label="Estado: "
                name="estado"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Estado",
                  },
                ]}
              >
                <Select>
                    <Select.Option value="Activo">Activo</Select.Option>
                    <Select.Option value="Anulado">Anulado</Select.Option>
                    
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default ObligacionEditModal;
