import http from "../utils/http-common";

class NotificacionService {
    getAll() {
      return http.get("/notificaciones");
    }
    get(id) {
      return http.get(`/notificaciones/${id}`);
    }

    getForain(id,role) {
      return http.get(`/notificaciones/forain/${id}`);
    }

    
    getByRole(data) {
      return http.post("/notificaciones/role/", data);
    }

    create(data) {
      return http.post("/notificaciones", data);
    }
    update(id, data) {
      return http.put(`/notificaciones/${id}`, data);
    }
    delete(id) {
      return http.delete(`/notificaciones/${id}`);
    }
   
  }
  export default new NotificacionService();