import http from "../utils/http-common";

class PagoService {
    getAll() {
      return http.get("/pagos");
    }
    get(id) {
      return http.get(`/pagos/${id}`);
    }

    getType(tipo,id) {
        return http.get(`/pagos/${tipo}/${id}`);
      }

    getForain(id) {
      return http.get(`/pagos/forain/${id}`);
    }

    create(data) {
      return http.post("/pagos", data);
    }
    update(id, data) {
      return http.put(`/pagos/${id}`, data);
    }
    delete(id) {
      return http.delete(`/pagos/${id}`);
    }
   
  }
  export default new PagoService();