import http from "../utils/http-common";

class Matriculaq10Service {
    getAll() {
      return http.get("/matriculaq10");
    }
    get(id) {
      return http.get(`/matriculaq10/${id}`);
    }

    getForain(id) {
      return http.get(`/matriculaq10/forain/${id}`);
    }

    create(data) {
      return http.post("/matriculaq10", data);
    }

    retiro(data) {
      return http.post("/matriculaq10/retiro", data);
    }

    update(id, data) {
      return http.put(`/matriculaq10/${id}`, data);
    }
    delete(id) {
      return http.delete(`/matriculaq10/${id}`);
    }
   
  }
  export default new Matriculaq10Service();