
import {
    WhatsAppOutlined,
    FacebookOutlined,
    ClockCircleOutlined,
    PhoneOutlined
    
  } from "@ant-design/icons";

  import { Breadcrumb, Layout, Card, Button, Row, Col, Popover } from "antd";

function myIcon(medio){
    if(medio.toUpperCase() == "WHATSAPP"){
      return  <WhatsAppOutlined className="medio" style={{ color: '#25D366' }} />
    }

    if(medio.toUpperCase() == "FACEBOOK"){
      return  <FacebookOutlined className="medio" style={{ color: '#4267B2' }} />
    }
    if(medio.toUpperCase() == "LLAMADA"){
      return  <PhoneOutlined className="medio" style={{ color: '#898F9C' }} />
    }

    if(medio.toUpperCase() != "WHATSAPP" && medio.toUpperCase() != "FACEBOOK" && medio.toUpperCase() != "LLAMADA" ){
      return <div>{medio}</div>
    }

    return <Popover title={medio}></Popover>
  }

  export default myIcon