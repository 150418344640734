import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  TimePicker,
} from "antd";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";

import tarifaService from "../../../services/tarifa.service";
const TarifaEditModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [caracteristicas, setCaracteristicas] = useState([]);
    const [disabledCaracteristica, setDisabledCaracteristica] = useState()
  const [form] = Form.useForm();
  const empresa_id = 1;
  const usuario_id = 1;
  const tarifa = props.children

  useEffect(()=>{
   form.setFieldsValue(tarifa)
  },[])

  

  const onFinish = (values) => {
    console.log(values)
   
    const form = values;
    form.empresa_id = empresa_id
    console.log(form)
    props.updateLoading(true);
    console.log("ENVIAR",form)
   tarifaService
      .update(tarifa.id,form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListTarifa();
        message.success("Actualizado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const validarTipo = (valor) =>{
    setDisabledCaracteristica(false)
    form.setFieldsValue({caracteristica:''})
    setCaracteristicas([])
    console.log(valor)
    if(valor == "CURSO"){
        
        setCaracteristicas(['NORMAL','PERSONALIZADO'])
    }
    else if(valor == "EXAMEN" ){
       
        setCaracteristicas(['APTIS REMOTO','APTIS PRESENCIAL','TOEFL PRESENCIAL','IELTS PRESENCIAL','OOPT PRESENCIAL'])
    }
    else{
        setDisabledCaracteristica(true)
        form.setFieldsValue({caracteristica:'NORMAL'})
    }
  }

    return (
        <Modal width={1024} visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
        <Content>
        
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row>
  
            <Col span={8}>
              <Form.Item
                label="Tipo: "
                name="tipo"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Tipo",
                  },
                ]}
              >
                <Select onChange={validarTipo}>
                  <Select.Option value="MATRICULA">MATRICULA</Select.Option>
                  <Select.Option value="EXAMEN DIAGNOSTICO">EXAMEN DIAGNOSTICO</Select.Option>
                  <Select.Option value="CURSO">CURSO</Select.Option>
                  <Select.Option value="ENTRENAMIENTO">ENTRENAMIENTO</Select.Option>
                  <Select.Option value="EXAMEN">EXAMEN</Select.Option>
                  <Select.Option value="TUTORIA">TUTORIA</Select.Option>
                  <Select.Option value="CONSTANCIA">CONSTANCIA</Select.Option>
                  <Select.Option value="HORA">HORA</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Caracteristica: "
                name="caracteristica"
                
                rules={[
                  {
                   
                    required: false,
                    message: "Por favor ingrese Caracteristica",
                  },
                ]}
              >
                <Select disabled={disabledCaracteristica}>
                {caracteristicas.map((item) => (
                    <Select.Option value={item} key={item}>{item}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

           

              <Col span={8}>
                <Form.Item
                  label="Valor: "
                  name="valor"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Valor",
                    },
                  ]}
                >
                 <Input type="number"></Input>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Año: "
                  name="annio"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Año",
                    },
                  ]}
                >
                 <Input></Input>
                </Form.Item>
              </Col>

        
            </Row>
          </Form>
        </Content>
      </Modal>
    );
};

export default TarifaEditModal;