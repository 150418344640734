import React, {useState,useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom'
import docenteService from '../../services/docente.service';
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Col, Row, Collapse, Space,Divider,Tooltip,Modal, Avatar,Spin} from "antd";
import CursoDocente from '../../components/CursoDocente';
import Webcam from 'react-webcam'
import storage from "../../services/firebase"
import {ref, uploadBytesResumable,getDownloadURL} from "firebase/storage"
import {LogoutOutlined, LineChartOutlined,CheckCircleOutlined,UserOutlined} from '@ant-design/icons'
import { useAuth } from '../../context/auth.context';
const { Panel } = Collapse;

const Cursos = () => {

    const {user,role} = useAuth()
    const empresa_id = user.empresa_id
  const correo = user.correo
  const usuario_id = user.id;
    const [docente,setDocente] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cursosAsignados,setCursosAsignados] = useState([]);
    const [entrenamientosAsignados,setEntrenamientosAsignados] = useState([]);
    const [cursosFinalizados,setCursosFinalizados] = useState([]);
    const [entrenamientosFinalizados,setEntrenamientosFinalizados] = useState([]);
    const [viewWebcam, setViewWebcam] = useState(false);
    const webRef = useRef(null)
    const [fotoPerfil,setFotoPerfil] = useState();
    useEffect(() => {
        getDocente()
      }, []);

    const getDocente = () => {
        setLoading(true);
        docenteService.getPerfil({correo})
          .then((response) => {
            let filterDocente = response.data[0];
            console.log(response.data[0]);
            setDocente(filterDocente);
            setFotoPerfil(filterDocente.url)
            let cursos = [];
              let entrenamientos=[];
              let cursosFinalizados = [];
              let entrenamientosFinalizados=[];
            if(filterDocente.Cursos.length>0){
             
              for(let item of filterDocente.Cursos){
                if(item.estado == "Activo"){
                if(item.modo == "Curso"){
                
                  cursos.push(item)
                }else{
                  entrenamientos.push(item)
                }
              }
              else{
                if(item.modo == "Curso" &&  item.estado == "Finalizado"){
                
                  cursosFinalizados.push(item)
                }else{
                  entrenamientosFinalizados.push(item)
                }
              }
              }
            }
            setCursosAsignados(cursos)
            setEntrenamientosAsignados(entrenamientos)
            setCursosFinalizados(cursosFinalizados)
            setEntrenamientosFinalizados(entrenamientosFinalizados)

            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const viewCam = () =>{
        setViewWebcam(true)
      }

      const cancelCam = () =>{
        setViewWebcam(false)
      }

      const capturaFoto = async () =>{
        setLoading(true)
        let img = webRef.current.getScreenshot()
        //img = img.replace("data:image/webp;base64,", "");
        console.log(img)
        setFotoPerfil(img)
        setViewWebcam(false)
   
        let file = await base64ToBlob(img);

        const storageRef = ref(storage, `/docentes/${docente.numId}/FOTO-${docente.numId}`)

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            ); 
            },
            (err) => console.log(err),
            () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            let form = {}
            form.docente_id = docente.id
            form.url = url
            console.log(form)
            docenteService.foto(form).then(res=>{
              console.log(res)
              setLoading(false)
            }).catch(error=>{
              setLoading(false)
            })
            });
            }
            );

        }
      

         async function base64ToBlob(url) {
          let res = await fetch(url);
          let blob = await res?.blob({type:'image/jpeg'});
          return blob;
        }

        if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Institucional</Breadcrumb.Item>
          <Breadcrumb.Item>Docente </Breadcrumb.Item>
        </Breadcrumb>

        {docente && (
            <>
        <Row>
            <Col span={24}>
                <div className='info'> 
                

                { docente.url != null && (
       <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} src={docente.url}/>
      )
                }
     { docente.url == null && (
       <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} icon={<UserOutlined />}/>
      )} 
  
                
                <div className='nombre'>{docente.primerNombre} {docente.segundoNombre} {docente.primerApellido} {docente.segundoApellido}   <div className='numId'> {docente.tipoId} No. {docente.numId} </div></div>
              
                </div>
            </Col>

            <Col span={24}>
            <Space direction="vertical" className='space'>
              


                <Collapse collapsible="header" >
                    <Panel header="Asignación académica" key="2">
                     <Row>
                      

<Divider>Cursos Activos</Divider>

            {/* <Button type='primary' onClick={()=>newMatricula("Curso")}>Matricular</Button> */}

            {cursosAsignados.length==0 && (
                       <Col span={24}>
                       <div className='messageNo'> No tiene cursos Matriculados </div> 
                      
                       </Col>
                       )}

                  {cursosAsignados.length>0 && (
                       <Col span={24}>
                        {cursosAsignados.map(item=>{
                          return <CursoDocente updateEstudiante={getDocente}>{item}</CursoDocente>
                        })}
                       </Col>
                       )} 

<Divider>Entrenamientos Activos</Divider>
                   

                {/* <Button type='primary' onClick={()=>newMatricula("Entrenamiento")}>Matricular</Button> */}

                {entrenamientosAsignados.length==0 && (
                       <Col span={24}>
                     <div className='messageNo'>No tiene Entrenamientos Matriculados </div>  
                    
                       </Col>
                         )}

{entrenamientosAsignados.length>0 && (
                       <Col span={24}>
                        {entrenamientosAsignados.map(item=>{
                          return <CursoDocente updateEstudiante={getDocente} >{item}</CursoDocente>
                        })}
                       </Col>
                       )} 
                     </Row>
                    </Panel>
                </Collapse>

              <Collapse collapsible="header">
              <Panel header="Cursos/entrenamientos finalizados" key="3">
              <Row>
                      

                      <Divider>Cursos Finalizados</Divider>
                      
                                  {/* <Button type='primary' onClick={()=>newMatricula("Curso")}>Matricular</Button> */}
                      
                                  {cursosFinalizados.length==0 && (
                                             <Col span={24}>
                                             <div className='messageNo'> No tiene cursos Finalizados </div> 
                                            
                                             </Col>
                                             )}
                      
                                        {cursosFinalizados.length>0 && (
                                             <Col span={24}>
                                              {cursosFinalizados.map(item=>{
                                                return <CursoDocente updateEstudiante={getDocente}>{item}</CursoDocente>
                                              })}
                                             </Col>
                                             )} 
                      
                      <Divider>Entrenamientos Finalizados</Divider>
                                         
                      
                                      {/* <Button type='primary' onClick={()=>newMatricula("Entrenamiento")}>Matricular</Button> */}
                      
                                      {entrenamientosFinalizados.length==0 && (
                                             <Col span={24}>
                                           <div className='messageNo'>No tiene Entrenamientos Finalizados </div>  
                                          
                                             </Col>
                                               )}
                      
                      {entrenamientosFinalizados.length>0 && (
                                             <Col span={24}>
                                              {entrenamientosFinalizados.map(item=>{
                                                return <CursoDocente updateEstudiante={getDocente} >{item}</CursoDocente>
                                              })}
                                             </Col>
                                             )} 
                                           </Row>
                </Panel>
                </Collapse>

                {/* <Collapse><Panel header="Liquidaciones" key="4">
                    
                    </Panel></Collapse> */}
                
            </Space>
            </Col>
      
        </Row>
        </>
)}
      
      {viewWebcam && (
  <Modal visible={viewWebcam} onCancel={cancelCam} onOk={capturaFoto} okText="Capturar">
    <Webcam height={300} ref={webRef}/>
  </Modal>
)}

        </MyLayout>
    );
};

export default Cursos;