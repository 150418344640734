import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import { Breadcrumb, Table, Button, Spin, Input, Layout, Modal, Tooltip, Select } from "antd";
import MyLayout from "../../components/MyLayout";
import liquidacionDocenteService from "../../services/liquidacion_docente.service";
import LiquidacionDocenteModal from "../../components/modals/LiquidacionDocenteModal";
import DetalleLiquidacionDocenteModal from "../../components/modals/DetalleLiquidacionDocenteModal";
import moment from 'moment'
import {SearchOutlined} from '@ant-design/icons';
import myIcon from '../../services/icono.service'
import { NumericFormat } from 'react-number-format';
import {  DollarOutlined, OrderedListOutlined, DeleteOutlined, FilePdfOutlined, CheckOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
const {confirm} = Modal;
const PagoDocente = () => {
    const [liquidaciones, setLiquidaciones] = useState([]);
    const [liquidacionesFilter, setLiquidacionesFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewNewLiquidacion, setViewNewLiquidacion] = useState(false);
    const [viewDetalleLiquidacion, setViewDetalleLiquidacion] = useState(false);
    const [liquidacionSelected, setLiquidacionSelected] = useState();
    const navigate = useNavigate();
    const empresa_id = 1
    const [estado,setEstado] = useState("Creado")



    const columns = [
        {
            title: "Docente",
            //dataIndex: "docente",
            //key: "tipo",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Nombre' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = formatName(record.Docente)
              return nombre.toLowerCase().includes(value.toLowerCase())
            },

            render: (record) => <div>  {formatName(record.Docente)}</div>,
          },
          {
            title: "Mes",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Mes' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.mes
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            //dataIndex: "mes",
            //key: "mes",
             render: (record) => <div>{record.mes}</div>,
          },

          {
            title: "Año",
            dataIndex: "annio",
            key: "annio",
            // render: (text) => <a>{text}</a>,
          },


          {
            title: "Valor Total",
             render: (record) => <div>$ <NumericFormat value={record.valor} displayType="text" allowLeadingZeros thousandSeparator="," /></div>,
          },

          {
            title: "Fecha Registro",
      
            render: (record) => {let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Estado",
      
            render: (record) => <div>{record.estado}</div>
          },

          {
            title: "Opciones",
             render: (record) => <div>
              <Button type='info' onClick={()=>verDescripcion(record)}> <OrderedListOutlined/> </Button>
              <Tooltip title="Imprimir Liquidación"><Button onClick={()=>{irImprimir(record)}}><FilePdfOutlined /></Button></Tooltip>
              <Button type='danger' onClick={()=>deleteLiquidacion(record)}> <DeleteOutlined/> </Button>
              {record.estado == "Creado" && (
              <Tooltip title="Marcar como pagado"><Button onClick={()=>{marcarPago(record)}}><CheckOutlined /></Button></Tooltip>
              )}
              </div>,
          },

          
    ]

    const [pagination, setPagination] = useState({
      current: 1, // Página actual
      pageSize: 4, // Tamaño de página
    });

    const handlePaginationChange = (current, pageSize) => {
      setPagination({ current, pageSize });
    };

const storageKey = 'liquidacionPagination';

    useEffect(() => {
      const savedPagination = JSON.parse(localStorage.getItem(storageKey));
      
      if (savedPagination) {
        setPagination(savedPagination);
      }

        listLiquidaciones('Creado');
      }, []);


      useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(pagination));
      }, [pagination]);

      const verDescripcion = (record) =>{
        setViewDetalleLiquidacion(true)
        setLiquidacionSelected(record)

      }

      const marcarPago = (record) =>{
        confirm({
          title:"Desea marcar pago de la liquidación?",
          okText:"Si",
          okType:'danger',
          cancelText:"No",
          onOk(){
            setLoading(true)
              liquidacionDocenteService.pay(record.id)
              .then((response) => {
                console.log(response)
                setLoading(false)
                listLiquidaciones(estado);
                
              })
              .catch((e) => {
                console.log(e);
                setLoading(false)
              });

          }})
      }

      const irImprimir = (record) =>{
        navigate("/liquidacion/docente/imprimir",{ state: { liquidacion: record } });
      }

      const formatName = (record)=>{
        let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
        nombre = nombre.toUpperCase()
        return nombre
      }

      const deleteLiquidacion = (record) =>{
        confirm({
          title:"Desea eliminar la liquidación?",
          okText:"Si",
          okType:'danger',
          cancelText:"No",
          onOk(){
            setLoading(true)
              liquidacionDocenteService.delete(record.id)
              .then((response) => {
                console.log(response)
                setLoading(false)
                listLiquidaciones(estado);
                
              })
              .catch((e) => {
                console.log(e);
                setLoading(false)
              });

          }})
      }

      const listLiquidaciones = (estado) => {
        setLoading(true);
        liquidacionDocenteService
          .getForain(estado)
          .then((response) => {
            let filterLead = response.data;
            console.log(response.data);
            // filterMedios = response.data.filter(
            //   (item) => item.empresa_id == empresa_id
            // );
            setLiquidaciones(filterLead);
            setLiquidacionesFilter(filterLead)
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const newLiquidacion = () => {
        setViewNewLiquidacion(true);
      };


      const filtrarEstado = (valor) =>{
        setEstado(valor)
        listLiquidaciones(valor)
      }



      if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
           <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Financiero</Breadcrumb.Item>
        <Breadcrumb.Item>Pago a Docentes</Breadcrumb.Item>
      </Breadcrumb>


      <Button type="primary" onClick={newLiquidacion}>
        Crear Liquidación
      </Button>


      <div className='filtro'> Filtrar: <Select defaultValue={estado}  placeholder="Seleccione estado"  onChange={filtrarEstado} style={{width:200}}> 
        <Select.Option  value="Creado">Creado</Select.Option> 
        <Select.Option value="Pagado">Pagado</Select.Option> 
       
        </Select>
        </div>

{viewNewLiquidacion && (
      <LiquidacionDocenteModal
        visible={viewNewLiquidacion}
        updateVisible={setViewNewLiquidacion}
        updateLoading={setLoading}
        updateListLiquidaciones={listLiquidaciones}
      ></LiquidacionDocenteModal>
      )}

{viewDetalleLiquidacion && (
      <DetalleLiquidacionDocenteModal
        visible={viewDetalleLiquidacion}
        updateVisible={setViewDetalleLiquidacion}
        updateLoading={setLoading}
      >{liquidacionSelected}</DetalleLiquidacionDocenteModal>
      )}

        <Table
        columns={columns}
        dataSource={liquidacionesFilter}
        pagination={{
          ...pagination,
          onChange: handlePaginationChange,
        }}
      />
      </MyLayout>
    );
};

export default PagoDocente;