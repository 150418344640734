import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
} from "antd";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";
import estudianteService from "../../../services/estudiante.service";
import { useAuth } from '../../../context/auth.context';
const EstudianteEditAdicionalModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [viewParentesco,setViewParentesco] = useState(true)
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  const estudiante = props.children
  
  useEffect(() => {
    form.setFieldsValue(estudiante)
  }, []);

  const calcularEdad = (fecha,fecha_nac) =>{
    return  fecha.diff(fecha_nac, 'years');
  }

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    //form.empresa_id = empresa_id;
    //form.usuario_id = usuario_id;
   
    estudianteService
      .update(estudiante.id,form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateEstudiante();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  const verificarEdad = (value) =>{
  let edad = calcularEdad(moment(),moment(value.target.value))
  edad <18 ? setViewParentesco(true) : setViewParentesco(false)
  }

  return (
    <Modal width={1024} visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
      
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>

         

            <Col span={8}>
              <Form.Item
                label="Lugar expedición de Documento: "
                name="exp_documento"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Expedición de Documento",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                label="Discapacidad: "
                name="discapacidad"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Discapacidad",
                  },
                ]}
              >
               <Select>
                <Select.Option value="No Aplica">No Aplica</Select.Option>
                <Select.Option value="Sordera Profunda">Sordera Profunda</Select.Option>
                <Select.Option value="Hipoacusia">Hipoacusia</Select.Option>
                <Select.Option value="Ceguera">Ceguera</Select.Option>
                <Select.Option value="Baja Visión">Baja Visión</Select.Option>
                <Select.Option value="Sordoceguera">Sordoceguera</Select.Option>
                <Select.Option value="Intelectual">Intelectual</Select.Option>
                <Select.Option value="Psicosocial">Psicosocial</Select.Option>
                <Select.Option value="Múltiple">Múltiple</Select.Option>
                <Select.Option value="Física o Motora">Física o Motora</Select.Option>
                <Select.Option value="Parálisis Cerebral">Parálisis Cerebral</Select.Option>
                <Select.Option value="Lesión Neuromuscular">Lesión Neuromuscular</Select.Option>
                <Select.Option value="Deficiencia Cognitiva (Retardo En El Desarrollo)">Deficiencia Cognitiva (Retardo En El Desarrollo)</Select.Option>
               </Select>
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                label="Medio de Transporte: "
                name="medio_transporte"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Medio de Transporte",
                  },
                ]}
              >
               <Select>
                <Select.Option value="Público Bus o Buseta">Público Bus o Buseta</Select.Option>
                <Select.Option value="Público Taxi">Público Taxi</Select.Option>
                <Select.Option value="Particular Automóvil">Particular Automóvil</Select.Option>
                <Select.Option value="Particular Motocicleta">Particular Motocicleta</Select.Option>
                <Select.Option value="Particular Bicicleta">Particular Bicicleta</Select.Option>
                <Select.Option value="Particular Otro">Particular Otro</Select.Option>
                <Select.Option value="Peatón">Peatón</Select.Option>
                <Select.Option value="Moto">Moto</Select.Option>
                <Select.Option value="Ruta Escolar">Ruta Escolar</Select.Option>
                <Select.Option value="A Pie">A Pie</Select.Option>
                <Select.Option value="Otro">Otro</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Ocupación:  "
                name="ocupacion"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Ocupación",
                  },
                ]}
              >
               <Select>
                <Select.Option value="Empleado">Empleado</Select.Option>
                <Select.Option value="Estudiante básica">Estudiante básica</Select.Option>
                <Select.Option value="Estudiante superior">Estudiante superior</Select.Option>
                <Select.Option value="Desempleado">Desempleado</Select.Option>
                <Select.Option value="Independiente">Independiente</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Zona:  "
                name="zona"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Zona",
                  },
                ]}
              >
               <Select>
                <Select.Option value="Rural">Rural</Select.Option>
                <Select.Option value="Urbana">Urbana</Select.Option>
                <Select.Option value="Urbana - rural">Urbana - rural</Select.Option>
               
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="EPS:  "
                name="eps"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese EPS",
                  },
                ]}
              >
             <Input/>
              </Form.Item>
            </Col>

          

            <Col span={8}>
              <Form.Item
                label="Aseguradora:  "
                name="aseguradora"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Aseguradora",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Tipo de sangre:  "
                name="sangre"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Tipo de sangre",
                  },
                ]}
              >
                <Select>
                <Select.Option value="A+">A+</Select.Option>
                <Select.Option value="A-">A-</Select.Option>
                <Select.Option value="B+">B+</Select.Option>
                <Select.Option value="B-">B-</Select.Option>
                <Select.Option value="O+">O+</Select.Option>
                <Select.Option value="O-">O-</Select.Option>
                <Select.Option value="AB+">AB+</Select.Option>
                <Select.Option value="AB-">AB-</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="No. de hijos:  "
                name="hijos"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese No. de hijos",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Estado Civil:  "
                name="estado_civil"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Estado Civil",
                  },
                ]}
              >
               <Select>
                <Select.Option value="Casado(a)">Casado(a)</Select.Option>
                <Select.Option value="Divorciado(a)">Divorciado(a)</Select.Option>
                <Select.Option value="Separado(a)">Separado(a)</Select.Option>
                <Select.Option value="Religioso(a)">Religioso(a)</Select.Option>
                <Select.Option value="Soltero(a)">Soltero(a)</Select.Option>
                <Select.Option value="Viudo(a)">Viudo(a)</Select.Option>
                <Select.Option value="Unión libre">Unión libre</Select.Option>
                </Select>
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                label="Estrato:  "
                name="estrato"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Estrato",
                  },
                ]}
              >
                <Select>
                <Select.Option value="0">0</Select.Option>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
                <Select.Option value="4">4</Select.Option>
                <Select.Option value="5">5</Select.Option>
                <Select.Option value="6">6</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Nivel de formación:  "
                name="nivel_formacion"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Nivel de formación",
                  },
                ]}
              >
               <Select>
                <Select.Option value="Preescolar">Preescolar</Select.Option>
                <Select.Option value="Básica primaria">Básica primaria</Select.Option>
                <Select.Option value="Básica secundaria">Básica secundaria</Select.Option>
                <Select.Option value="Media">Media</Select.Option>
                <Select.Option value="Pregrado">Pregrado</Select.Option>
                <Select.Option value="Postgrado">Postgrado</Select.Option>
                <Select.Option value="Sin estudios">Sin estudios</Select.Option>
                <Select.Option value="Sin formación">Sin formación</Select.Option>
                </Select>
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                label="SISBEN:"
                name="sisben"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese SISBEN",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>



            <Col span={8}>
              <Form.Item
                label="Multiculturalidad:"
                name="multiculturalidad"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Multiculturalidad",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                label="ARS:"
                name="ars"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese ARS",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                label="Libreta Militar: "
                name="libreta"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Libreta Militar",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>




          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default EstudianteEditAdicionalModal;
