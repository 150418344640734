import React, {useState,useEffect} from 'react';
import {useLocation,Link} from 'react-router-dom'
import examenService from '../../services/examen.service';
import matriculaExamenService from '../../services/matricula_examen.service';
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Col, Row, Collapse, Space, Table, Tooltip, Modal, Button, message,Spin, Input  } from "antd";
import moment from 'moment';
import {LogoutOutlined,LineChartOutlined, CheckCircleOutlined, SearchOutlined } from '@ant-design/icons'
import RetiroModal from '../../components/modals/RetiroModal';
import SearchEstudianteModal from '../../components/modals/SearchEstudianteModal';
import { useAuth } from '../../context/auth.context';
const { Panel } = Collapse;

const VerExamen = () => {
    let location = useLocation();
    let myExamen = location.state.examen
    const [examen,setExamen] = useState(null);
    const [estudiantes,setEstudiantes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewHistorialModal,setViewHistorialModal] = useState(false);
    const [matriculaSelected,setMatriculaSelected] = useState();
    const [viewEstudianteModal,setViewEstudianteModal] = useState(false);
    const {role} = useAuth()
    useEffect(() => {
        getExamen()
      }, []);

    const getExamen = () => {
        setLoading(true);
        examenService
          .get(myExamen.id)
          .then((response) => {
            let filterExamen = response.data[0];
            console.log("AQUI",response.data[0]);
            setExamen(filterExamen);
            let examensort = filterExamen.MatriculaExamens
            examensort.sort((a, b) => moment(b.fecha) - moment(a.fecha));
            setEstudiantes(examensort)
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const viewHistomodal = (record)=>{
        setViewHistorialModal(true)
        setMatriculaSelected(record.id)
      }


   function formatName (record){
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }


      const columns = [
        {
            title: "Nombres",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Nombre' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = formatName(record.Estudiante)
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            render: (record) => <Link to={"/ver-estudiante"}
            state={{estudiante: record.Estudiante}} >  {formatName(record.Estudiante)}  </Link>,
          },

          {
            title: "No. Identificación",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese No. ID' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.Estudiante.numId
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            render: (record) => <div>{record.Estudiante.numId}</div>,
          },

           {
            title: "Fecha de Presentación",
            key: "fecha",
            dataIndex: "fecha",
            render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY"); return ( <div>{myFecha} </div> )}
          },


          {
            title: "Tipo Examen",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Tipo Examen' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.tipo
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            render: (record) => <div>{record.tipo}</div>,
          },

          {
            title: "Modo",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Modo' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.modo
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            render: (record) => <div>{record.modo}</div>,
          },


          {
            title: "Mes",
            key: "mes",
            dataIndex: "mes",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Mes' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = moment(record).format("MMM")
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            render: (fecha) => {let myFecha = moment(fecha).format("MMM"); return ( <div>{myFecha} </div> )}
          },

          {
            title: "Hora",
            render: (record) => {let myFecha = moment(record.fecha + " " + record.hora).format("hh:mm a"); return ( <div>{myFecha}</div> )}
           // render: (record) =>  <div>{record.hora}</div>
          },



          {
            title: "Estado",
            render: (record) => <div>{record.estado}</div>,
          },

          {
            title: "Opciones",
            render: (record) => {if(record.estado == "Activo" && (role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") ){
              return <>
              <Tooltip title="Retirar">
              <span className='btn_option' onClick={()=>viewHistomodal(record)}>
                <LogoutOutlined />
                </span>
                </Tooltip> 
                
                </> 
                }else{
                  return <>
                 
                 </>
                }}
          },

         
      ]

      const searchEstudiante = () =>{
        setViewEstudianteModal(true)
      }

      const saveMatricula = (form) =>{
        setLoading(true);
          matriculaExamenService
            .create(form)
            .then((response) => {
              console.log(response.data);
              setLoading(false);
              setViewEstudianteModal(false);
              getExamen();
              message.success("Guardado Correctamente");
            })
            .catch((e) => {
              setLoading(false);
              message.error("No se ha podido guardar");
              console.log(e);
            });
        }

        if (loading)
        return (
          <div className="spin_container">
            <Spin className="spin" size="large" />
          </div>
        );
    return (
        <MyLayout>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Académico</Breadcrumb.Item>
          <Breadcrumb.Item>Examen </Breadcrumb.Item>
        </Breadcrumb>

        {examen && (
            <>
        <Row>

            <Col span={24}>
            <Row>
             <Col span={24}>
                <div className='info'>
             <div className='nombre'>{examen.nombre}</div>
            
             </div> </Col>
                        <Col span={12} className="columna">
                        
                            <div><strong>Codigo:</strong> {examen.id} </div>
                            <div><strong>Fecha Creación:</strong> {moment(examen.createdAt).format("DD-MM-YYYY")}  </div>
                           
                        </Col>

                        <Col span={12} className="columna">
                       
                        <div><strong>Estado:</strong> {examen.estado} </div>
                        </Col>
                     </Row>
            </Col>
           

            <Col span={24}>
            <Space direction="vertical" className='space'>
                <Collapse collapsible="header" defaultActiveKey={['1']}>
                    <Panel header="Estudiantes" key="1">
                    {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
                      <Button type="primary" onClick={searchEstudiante}>Agregar Estudiante</Button>
                    )}
                    {estudiantes && (
                        <Table dataSource={estudiantes} columns={columns}></Table>
                    )}
                    </Panel>
                </Collapse>
            </Space>
            </Col>
      
        </Row>
        </>
)}
      {matriculaSelected && (
          <RetiroModal 
          matricula_id={matriculaSelected}
          updateLoading={setLoading}
          modo='Examen'
          visible={viewHistorialModal} 
          updateVisible={setViewHistorialModal}
          updateExamen = {getExamen}
          >

          </RetiroModal>
          )}

          {viewEstudianteModal && (

          <SearchEstudianteModal updateVisible={setViewEstudianteModal} updateLoading={setLoading} saveMatricula={saveMatricula} modo='Examen' examen_id={myExamen.id}  visible={viewEstudianteModal} >
          {myExamen}
          </SearchEstudianteModal>
          )}

        </MyLayout>
    );
};

export default VerExamen;