import React, { useEffect, useState } from 'react';
import MyLayout from '../../components/MyLayout';
import {Table, Form, Select, DatePicker, Button, Col, Row, Card, Modal, Spin} from 'antd'
import moment from 'moment';
import reportesService from '../../services/reportes.service';
import { PDFViewer } from '@react-pdf/renderer';
import ReporteIngresosPDF from '../PDF/ReporteIngresosPDF';
const { RangePicker } = DatePicker;
const ReporteIngresos = () => {

    const [pagos,setPagos] = useState()
    const [viewPDF,setViewPDF] = useState(false)
    const [parametros,setParametros] = useState()
    const [loading, setLoading] = useState(false);

    const loadData =(values) =>{
      setLoading(true)
        console.log(values)
        let listaMetodo = ""
        for(let item of values.metodo){
          listaMetodo += item + ", " 
        }
        console.log(listaMetodo)

        values.listaMetodo = listaMetodo
        reportesService.ingresos(values).then(res=>{
            console.log(res.data)
            setViewPDF(true)
            setPagos(res.data)
            setParametros(values)
            setLoading(false)
        })
    }

    if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
    return (
        <MyLayout>
             <Card>
           <h4>Reporte Ingresos</h4>

           <Form  name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off" onFinish={loadData}
         >
                <Form.Item label="Fechas" name="fechas" rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fechas",
                    },
                  ]}>
            <RangePicker  placeholder={['Fecha Inicial','Fecha Final']} />
            </Form.Item>


            <Form.Item  label="Forma de Pago" name="metodo" rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Forma de pago",
                    },
                  ]}>
                <Select mode="multiple">
                {/* <Select.Option value="TODOS">TODOS</Select.Option> */}
                    <Select.Option value="EFECTIVO">EFECTIVO</Select.Option>
                    <Select.Option value="NEQUI">NEQUI</Select.Option>
                    <Select.Option value="DAVIPLATA">DAVIPLATA</Select.Option>
                    <Select.Option value="CUENTA BANCOLOMBIA">CUENTA BANCOLOMBIA</Select.Option>
                    <Select.Option value="AHORRO A LA MANO">AHORRO A LA MANO</Select.Option>
                    {/* <Select.Option value="CONSIGNACION">TODA CONSIGNACION</Select.Option> */}
                </Select>
            </Form.Item>

                <Form.Item>
                <Button type="primary"  htmlType="submit" >Generar Reporte</Button>
                </Form.Item>
           </Form>
        </Card>


        {viewPDF && pagos && (
  <Modal visible={viewPDF}  width={1080} onCancel={()=>{setViewPDF(false)}}>
          <PDFViewer style={{width:"100%", height:"90vh"}}>
          <ReporteIngresosPDF parametros={parametros}>{pagos}</ReporteIngresosPDF>
          </PDFViewer>
          </Modal>
        )}

        </MyLayout>
    );
};

export default ReporteIngresos;