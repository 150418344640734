import http from "../utils/http-common";

class ReportesService {
   

    cuadre(data) {
      return http.post("/reportes/cuadre", data);
    }

    cuadreAcademico(data) {
      return http.post("/reportes/cuadreacademico", data);
    }

    ingresos(data) {
      return http.post("/reportes/ingresos", data);
    }

    estudiantes(data) {
      return http.post("/reportes/estudiantes", data);
    }
   
   
   
   
  }
  export default new ReportesService();