import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Spin,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import UsuarioService from "../../../services/usuario.service";
import workgroupService from "../../../services/workgroup.service";
import Password from "antd/lib/input/Password";

const UsuarioEditModal = (props) => {
    const [form] = Form.useForm();
    const [workgroups, setWorkgroups] = useState([]);
    const empresa_id = 1;
    const user = props.usuario

  useEffect(() => {
    listWorkgroup();
    form.setFieldsValue(user)
  }, []);

 


  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.empresa_id = empresa_id;
   
    UsuarioService.update(user.id,form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListUsuarios();
        message.success("Actualizado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido actualizar");
        console.log(e);
      });
  };

  const listWorkgroup = () => {
    workgroupService
      .getAll()
      .then((response) => {
        let filterWorkgroups = response.data;
        console.log(response.data);
        filterWorkgroups = response.data.filter(
          (item) => item.empresa_id == empresa_id
        );
        setWorkgroups(filterWorkgroups);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Nombre: "
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Correo: "
                name="correo"
                rules={[
                  { required: true, message: "Por favor ingrese Correo" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Tipo de Usuario: "
                name="tipo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de Usuario",
                  },
                ]}
              >
                <Select>
                  {/* <Select.Option value="Superadministrador">Superadministrador</Select.Option> */}
                  <Select.Option value="Docente">Docente</Select.Option>
                  <Select.Option value="Estudiante">Estudiante</Select.Option>
                  <Select.Option value="Administrativo">Administrativo</Select.Option>
                  <Select.Option value="Seguimiento">Seguimiento</Select.Option>
                  <Select.Option value="Cumplimiento">Cumplimiento</Select.Option>
                  <Select.Option value="Coordinador Académico">Coordinador Académico</Select.Option>
                  <Select.Option value="Marketing">Marketing</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Contraseña: "
                name="password"
                rules={[
                  { required: true, message: "Por favor ingrese Contraseña" },
                ]}
              >
                <Password  />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Grupo de Trabajo: "
                name="workgroup"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Grupo de Trabajo",
                  },
                ]}
              >
                <Select>
                  {workgroups.map((item) => (
                    <Select.Option key={item.nombre}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default UsuarioEditModal;
