import http from "../utils/http-common";

class InasistenciaService {
    getAll() {
      return http.get("/inasistencias");
    }
    get(id) {
      return http.get(`/inasistencias/${id}`);
    }

    getForain(id) {
      return http.get(`/inasistencias/forain/${id}`);
    }

    create(data) {
      return http.post("/inasistencias", data);
    }
    update(id, data) {
      return http.put(`/inasistencias/${id}`, data);
    }
    delete(id) {
      return http.delete(`/inasistencias/${id}`);
    }
   
  }
  export default new InasistenciaService();