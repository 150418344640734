import http from "../utils/http-common";

class AccionService {
    getAll() {
      return http.get("/accion");
    }
    get(id) {
      return http.get(`/accion/${id}`);
    }

    getForain(id) {
      return http.get(`/accion/forain/${id}`);
    }

    create(data) {
      return http.post("/accion", data);
    }
    update(id, data) {
      return http.put(`/accion/${id}`, data);
    }
    delete(id) {
      return http.delete(`/accion/${id}`);
    }
   
  }
  export default new AccionService();