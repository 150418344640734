import React, {useState, useEffect} from 'react';
import { Breadcrumb, Table, Button, Spin, Input } from "antd";
import MyLayout from "../../components/MyLayout";
import periodoService from "../../services/periodo.service";
import PeriodoModal from "../../components/modals/PeriodoModal";
import moment from 'moment'


const Periodo = () => {
    const [viewNewPeriodo, setViewNewPeriodo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [periodos, setPeriodos] = useState([]);
    const empresa_id = 1;

    useEffect(() => {
     
        listPeriodos();
      }, []);


      const listPeriodos = () => {
        setLoading(true);
        periodoService
          .getForain(empresa_id)
          .then((response) => {
            let filterPeriodo = response.data
            console.log("DATA",response.data);
            setPeriodos(filterPeriodo);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

    const columns = [

        {
            title: "Orden",
            dataIndex: "orden",
            key: "orden",
            // render: (text) => <a>{text}</a>,
          },
        
        {
            title: "Periodo",
            dataIndex: "nombre",
            key: "nombre",
            // render: (text) => <a>{text}</a>,
          },
       
         
          {
            title: "Fecha de Inicio",
            // key: "fechaInicial",
            // dataIndex: "fechaInicial",
            render: (record) => {let myFecha = moment(record.fechaInicial).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Fecha Fin",
            // key: "fechaFinal",
            // dataIndex: "fechaFinal",
            render: (record) => {let myFecha = moment(record.fechaFinal).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Opciones",
           
            key: "action",
             //render: (medio) => <div>{medio.Medio.nombre}</div>,
          },
      ];

      
      const newPeriodo = () => {
        setViewNewPeriodo(true);
      };


  if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Institucional</Breadcrumb.Item>
        <Breadcrumb.Item>Periodos</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newPeriodo}>
        Crear Periodo
      </Button>

      <PeriodoModal
        visible={viewNewPeriodo}
        updateVisible={setViewNewPeriodo}
        updateLoading={setLoading}
        updateListPeriodo={listPeriodos}
      ></PeriodoModal>

      

      <Table
        columns={columns}
        dataSource={periodos}
        pagination={{ pageSize: 4 }}
      />
    </MyLayout>
    );
};

export default Periodo;