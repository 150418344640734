import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom'
import { Breadcrumb, Table, Button, Spin, Input, Tooltip, Modal, message } from "antd";
import MyLayout from "../../components/MyLayout";
import constanciaService from "../../services/constancia.service";
import moment from 'moment'
import {SearchOutlined} from '@ant-design/icons';
import myIcon from '../../services/icono.service'
import ConstanciaModal from '../../components/modals/ConstanciaModal';
import { useAuth } from '../../context/auth.context';
import { Editor } from '@tinymce/tinymce-react';
import {  FilePdfOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFViewer
} from "@react-pdf/renderer";
import Html from 'react-pdf-html';

import utf8 from "utf8";

const Constancia = () => {
    const [viewNewConstancia, setViewNewConstancia] = useState(false);
    const [loading, setLoading] = useState(false);
    const [constancias, setConstancias] = useState([]);
    const {role} = useAuth()
    const empresa_id = 1;
    const editorRefConstancia = useRef(null);
    const editorRefCertificado = useRef(null);
    const [viewEditorConstancia,setViewEditorConstancia] = useState(false)
    const [viewEditorCertificado,setViewEditorCertificado] = useState(false)

   const [valueEditor,setValueEditor] = useState("")
   const [valueEditorB,setValueEditorB] = useState("")

   const [constanciaSelected,setConstanciaSelected] = useState()

   const [viewPDF,setViewPDF] = useState(false)

   const navigate = useNavigate();

   const styles = StyleSheet.create({
    body: {
      padding: 50,
      fontFamily: 'Roboto'
      
    },

    titulo:{
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 'bold',
      textAlign:'center'
    },

    textoCentrado:{
      fontFamily: 'Roboto',
      fontSize: 12,
      textAlign:'center'
    },

    textoJustificado:{
      fontFamily: 'Roboto',
      fontSize: 12,
      textAlign:'justify',
      border:'1px solid black'
    },
  })

    const updateConstancia = () => {

    

      if (editorRefConstancia.current) {
        let form = {tipo:"CONSTANCIA", valor:editorRefConstancia.current.getContent()}
       
        console.log(editorRefConstancia.current.getContent());
        
        constanciaService.update(constanciaSelected.id,form).then(res=>{
          message.success("Se ha actualizado la constancia")
          setViewEditorConstancia(false)
          listConstancias()
        }).catch(error=>{
          message.error("Ha ocurrido un error")
        })
      }
    };

    const updateCertificado = () => {
      if (editorRefCertificado.current) {
        let form = {tipo:"CERTIFICADO", valor:editorRefCertificado.current.getContent()}
        console.log(editorRefCertificado.current.getContent());

        constanciaService.update(constanciaSelected.id,form).then(res=>{
          message.success("Se ha actualizado el certificado")
          setViewEditorCertificado(false)
          listConstancias()
        }).catch(error=>{
          message.error("Ha ocurrido un error")
        })
      }
    };

    useEffect(() => {
     
        listConstancias();
      }, []);

      const setCertificado = (data) =>{
        console.log("Certificado",data)
        setValueEditorB(data.certificado)
        setConstanciaSelected(data)
        setViewEditorCertificado(true)
     
      }

      const setConstancia = (data) =>{
        console.log("Constancia",data)
        setValueEditor(data.constancia)
        setConstanciaSelected(data)
        setViewEditorConstancia(true)
        
      }

      const imprimirPDF = (data) =>{
        // setViewPDF(true)
        // setConstanciaSelected(data)

        navigate("/ver-constancia",{ state: { constancia: data } });
        
      }


      const listConstancias = () => {
        setLoading(true);
        constanciaService
        .getAll()
          .then((response) => {
            let filterConstancias = response.data;
          
            // filterMedios = response.data.filter(
            //   (item) => item.empresa_id == empresa_id
            // );
            setConstancias(filterConstancias);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

    const columns = [
        
        

          {
            title: "Estudiante",
            render: (record) => <Link to={"/ver-estudiante"}
            state={{estudiante: record.Estudiante}}>{record.Estudiante.primerNombre } {record.Estudiante.segundoNombre } {record.Estudiante.primerApellido } {record.Estudiante.segundoApellido }</Link>,
          },

          {
            title: "Fecha",
            render: (record) => {let myFecha = moment(record.fecha).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
          },

          
          {
            title: "Observaciones",
            // render: (record) => {let myFecha = moment(record.hora).format("hh:mm:ss a"); return ( <div>{myFecha}</div> )}
            render: (record) =>  <div>{record.observaciones}</div>
          },

         
        

          {
            title: "Fecha Registro",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Opciones",
           
            key: "action",
             render: (record) => <div>

<Tooltip title="Editar Constancia">
                <Button style={{marginRight:'10px'}} onClick={()=>setConstancia(record)}  type='primary'>CO</Button>
                </Tooltip>


              <Tooltip title="Editar Certificado">
              <Button style={{marginRight:'10px'}} onClick={()=>setCertificado(record)} type='primary'>CE</Button>
              </Tooltip> 

            

                <Tooltip title="Imprimir">
                <Button style={{marginRight:'10px'}} type='primary' onClick={()=>imprimirPDF(record)}><FilePdfOutlined ></FilePdfOutlined></Button>
                </Tooltip>

                </div>,
          },
      ];

      
      const newConstancia = () => {
        setViewNewConstancia(true);
      };


  if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Institucional</Breadcrumb.Item>
        <Breadcrumb.Item>Constancias</Breadcrumb.Item>
      </Breadcrumb>


      



{viewEditorCertificado && (
      <Modal visible={viewEditorCertificado} width={1024} title="Editor Certificado" onCancel={()=>{setViewEditorCertificado(false)}} onOk={updateCertificado} okText="Actualizar">
<Editor
        apiKey='bk7illt2q2z6ubi0ng7dk1bgikhu87rfu5eojzzdw015vhfk'
        onInit={(evt, editor) => editorRefCertificado.current = editor}
        initialValue={valueEditorB}
        init={{
          height: 500,
          entity_encoding : "raw",
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />

      </Modal>
    )}


    {viewEditorConstancia && (
      <Modal visible={viewEditorConstancia} width={1024} title="Editor Constancia" onCancel={()=>{setViewEditorConstancia(false)}} onOk={updateConstancia} okText="Actualizar">
<Editor
        apiKey='bk7illt2q2z6ubi0ng7dk1bgikhu87rfu5eojzzdw015vhfk'
        onInit={(evt, editor) => editorRefConstancia.current = editor}
        initialValue={valueEditor}
        init={{
          height: 500,
          menubar: false,
          entity_encoding : "raw",
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />

      </Modal>
    )}


    {viewPDF && (
      <Modal visible={viewPDF} onCancel={()=>{setViewPDF(false)}} width={1080}>
         <PDFViewer style={{width:"100%", height:"90vh"}}>
       <Document>
       <Page size="letter" orientation='portrait' style={styles.body}>
       <View >
       <Text style={[styles.titulo]}>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS</Text>
       <Text style={[styles.titulo]}>INSTITUCIÓN DE EDUCACIÓN PARA EL TRABAJO Y DESARROLLO HUMANO</Text>
       <Text style={[styles.titulo]}>RESOLUCIÓN DE LICENCIA DE FUNCIONAMIENTO Y REGISTRO DE PROGRAMAS DE EFICIENCIA EN INGLES</Text>
       <Text style={[styles.titulo]}>No. 1832-27-07-2015 EMANADA DE LA SECRETARIA DE EDUCACIÓN DE PASTO- NARIÑO</Text>
      
        </View>

        <View >
        <Text style={[styles.textoCentrado]}>{"\n"}{"\n"}CONSTANCIA</Text>

        </View>


        <View >
      <Html style={[styles.textoJustificado]}>{constanciaSelected.constancia}</Html> 
       
        </View>

        </Page>

      <Page size="letter" orientation='portrait' style={styles.body}>
      <View >

      <Text style={[styles.titulo]}>RESOLUCIÓN DE LICENCIA DE FUNCIONAMIENTO Y REGISTRO DE PROGRAMAS DE EFICIENCIA EN INGLES</Text>
       <Text style={[styles.titulo]}>No. 1832-27-07-2015 EMANADA DE LA SECRETARIA DE EDUCACIÓN DE PASTO- NARIÑO{"\n"}</Text>
       

        </View>

        <View >
        <Text style={[styles.textoCentrado]}>{"\n"}Expide el siguiente certificado de formación académica a:</Text>

        </View>

        <View>
        <Html style={[styles.textoJustificado]}>{constanciaSelected.certificado}</Html> 
        </View>

      </Page>

        </Document>
        </PDFViewer>
        </Modal>

    )}
      


{(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
      <Button type="primary" onClick={newConstancia}>
        Crear Constancia
      </Button>
      )}

      <ConstanciaModal
        visible={viewNewConstancia}
        updateVisible={setViewNewConstancia}
        updateLoading={setLoading}
        updateListConstancia={listConstancias}
      ></ConstanciaModal>


    

      <Table
        columns={columns}
        dataSource={constancias}
        pagination={{ pageSize: 4 }}
      />



    </MyLayout>
    );
};

export default Constancia;