import React, { useState, useEffect, useRef } from "react";
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Layout, Card, Button, Row, Col,Spin,Popover, Segmented  } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import embudoService from "../../services/embudo.service";
import EmbudoModal from "../../components/modals/EmbudoModal";
import Lead from "../../components/Lead/Lead";
import { WhatsAppOutlined, FacebookOutlined,ClockCircleOutlined } from "@ant-design/icons";
import faseService from "../../services/fase.service";
import LeadModal from "../../components/modals/LeadModal";
import "./Embudo.css";
import {DragDropContext, Droppable,Draggable} from 'react-beautiful-dnd'
import leadService from "../../services/lead.service";
import {Link} from 'react-router-dom'
import ListEmbudo from "../../components/ListEmbudo";
const { Sider } = Layout;
const Embudo = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [viewNewEmbudo, setViewNewEmbudo] = useState(false);
  const [viewNewLead, setViewNewLead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [embudos, setEmbudos] = useState([]);

  const [fases, setFases] = useState([]);
  const [embudoSelected, setEmbudoSelected] = useState(null);

  const [numberWidth, setNumberWidth] = useState(24);
  const [faseSelected, setFaseSelected] = useState(0);

  const empresa_id = 1;


  useEffect(() => {
    listEmbudos();
  }, []);

  const listEmbudos = () => {
    setLoading(true);
    embudoService
      .getAll()
      .then((response) => {
        let filterEmbudos = response.data;
        console.log(response.data);
        filterEmbudos = response.data.filter(
          (item) => item.empresa_id == empresa_id
        );
        setEmbudos(filterEmbudos);
      
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };



  const changeView = () => {
    if (collapsed) {
      setCollapsed(false);
      setNumberWidth(24);
    } else {
      setCollapsed(true);
      setNumberWidth(18);
    }
  };

  const newEmbudo = () => {
    setViewNewEmbudo(true);
  };

  const newLead = (fase) =>{
    setViewNewLead(true)
    setFaseSelected(fase)
  }

  const selectedEmbudo = (embudo) => {
    setLoading(true)
   if(embudo.nombre != "Crear"){
    setEmbudoSelected(embudo);
    listarFases(embudo.id);
    setLoading(false)
  }
  };

  const listarFases = (id) => {
    
    console.log(id);
    faseService
      .getForain(id)
      .then((response) => {
        setFases(response.data);
        console.log(response.data);
       
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  function calcularPresupuesto(leads) {
    var total = 0;
    for (let item of leads) {
      total += item.presupuesto;
    }

    return total;
  }

  function calcularClientes(fase, leads) {
    let total = 0;
    for (let item of leads) {
      if (item.fase_id == fase) {
        total++;
      }
    }

    return total;
  }



  const nuevosLeads = (faseInicialId, fase_id,lead_id) =>{

    let encontrado;
    
    const deleteLead = fases.map((fase)=>{
      if (fase.id == faseInicialId) {
        let myLead = fase.Leads.filter(item=>item.id == lead_id)[0]
        encontrado = myLead
        let index = fase.Leads.indexOf(myLead)
        fase.Leads.splice(index,1)

        const updatedItem = {
          ...fase,
        };
        console.log("delete",updatedItem)

        return updatedItem;
      }
      return fase;
    });

    const addLead = deleteLead.map((fase)=>{
      if (fase.id == fase_id) {
        fase.Leads.push(encontrado)

        const updatedItem = {
          ...fase,
        };
        console.log("add",updatedItem)

        return updatedItem;
      }
      return fase;
    });

   
    setFases(addLead);
   let data = {
    fase_id,lead_id
   }

   leadService.dynamicPost(data,'cambiofase').then((response) => {
    console.log(response.data);
    listarFases(embudoSelected.id)
    setLoading(false);
  })
  .catch((e) => {
    console.log(e);
    setLoading(false);
  });
  }


  const setOrder = (result) => {
    console.log(result)
    const { source, destination } = result;
    if (!destination) {
      console.log("SIn destino")
      return;

    }
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      console.log("Mismo destino")
      return;
    }

    console.log("Actualizar")

    nuevosLeads(source.droppableId,destination.droppableId,result.draggableId)
    
  }

  // if (loading)
  //     return (
  //       <div className="spin_container">
  //         <Spin className="spin" size="large" />
  //       </div>
  //     );

  return (
    <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>CRM</Breadcrumb.Item>
        <Breadcrumb.Item>Embudos</Breadcrumb.Item>
      </Breadcrumb>

     
      <ListEmbudo embudos={embudos} selectEmbudo={selectedEmbudo} newEmbudo={setViewNewEmbudo}></ListEmbudo> 


      {/* <Button onClick={changeView}>
        <MenuFoldOutlined />
      </Button> */}

      <Row>
        {/* {collapsed && (
          <Col span={6}>
            <div className="Sider">
              <Card>
                <Button type="primary" onClick={newEmbudo}>
                  Nuevo Embudo
                </Button>
              </Card>

              {embudos.map((item) => (
                <Card key={item.id}>
                  <a
                    onClick={() => {
                      selectedEmbudo(item);
                    }}
                  >
                    {item.nombre}
                  </a>
                </Card>
              ))}
            </div>
          </Col>
        )} */}

        {embudoSelected && (
          <DragDropContext onDragEnd={setOrder}>
          <Col span={numberWidth} className="col-fases">
            <div className="row-embudo">
              {fases.map((item) => (
                <Droppable droppableId={item.id + ""} className="fase" >
                  {(provided)=>{ return (<div {...provided.droppableProps} ref={provided.innerRef} >
               
                  <div className="col-embudo" key={item.id} >
                    <div style={{color:item.color}}>{item.nombre}</div>
                    {/* <div className="totalPresupuesto">
                      $ {calcularPresupuesto(item.Leads)}
                    </div> */}

                    <div className="clientesPotenciales">
                      Clientes Potenciales:{" "}
                      {calcularClientes(item.id, item.Leads)}
                    </div>
                  </div>

                  {item.nombre == "Presentación" && (
                    <Card className="contactoInicial"><Button onClick={()=>newLead(item)}>Contacto Rapido</Button></Card>
                  )}

                 
                  {item.Leads.map((lead,index) => (
                     <Draggable key={lead.id} draggableId={lead.id + ""} index={index}>
                      {(provided)=>{  return(
                        
                        <Card  {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps} >
                        
                        <Lead data={lead} ></Lead>
                       

                      </Card>
                      //<div {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps}>{lead.nombre}</div>
                        )}}
                      
                    </Draggable>
                  ))}
                  </div>)}}
                
                </Droppable>
              ))}
            </div>
          </Col>
          </DragDropContext>
        )}
     
      </Row>

      <EmbudoModal
        visible={viewNewEmbudo}
        updateVisible={setViewNewEmbudo}
        updateLoading={setLoading}
        updateListEmbudo={listEmbudos}
      ></EmbudoModal>

<LeadModal
        visible={viewNewLead}
        updateVisible={setViewNewLead}
        updateLoading={setLoading}
        fase_id = {faseSelected.id}
        updateListLead={()=>listarFases(embudoSelected.id)}
      ></LeadModal>
    </MyLayout>
  );
};

export default Embudo;
