import http from "../utils/http-common";

class FaseService {
    getAll() {
      return http.get("/fases");
    }
    get(id) {
      return http.get(`/fases/${id}`);
    }

    getForain(id) {
        return http.get(`/fases/forain/${id}`);
      }
    create(data) {
      return http.post("/fases", data);
    }
    update(id, data) {
      return http.put(`/fases/${id}`, data);
    }
    delete(id) {
      return http.delete(`/fases/${id}`);
    }

    changeFase(data) {
      return http.post("/fases/change", data);
    }
   
  }
  export default new FaseService();