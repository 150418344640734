import React, { useEffect,useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import moment from "moment";
import logo from "./logo";
import regular from './fonts/Roboto-Regular.ttf'
import bold from './fonts/Roboto-Bold.ttf'

Font.register({ family: 'Roboto', fonts:[{src:regular},{src:bold, fontWeight: 'bold'}] });



const styles = StyleSheet.create({
  body: {
    padding: 25,
    fontFamily: 'Roboto'
    
  },


  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:20
  },

  table2: {
    display: "table",
    width: "auto",
    borderRightWidth: 0,
    borderBottomWidth: 0,
   
  },

  tableRow2: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol2: {
    width: "80%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol3: {
    width: "20%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol4: {
    width: "50%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    
  },

  tableCell2: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },

  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
 
  },

  tableCol5: {
    width: "25%",
    borderLeftWidth: 0,
    borderTopWidth: 0,
   
  },

  tableCell: {
   
    marginTop: 5,
    fontSize: 10,
    

  },
  logo: {
    width: 50,
    height: 70,
    textAlign: "center",
  },

  footer:{
    position: 'absolute',
    bottom: 30,
    textAlign: 'center',
    fontSize: 8,
    left: 0,
    right: 0,
  },
  footer2:{
    position: 'absolute',
    bottom: 10,
    textAlign: 'center',
    fontSize: 8,
    left: 0,
    right: 0,
  },

  titulo:{
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    
  },

  subtitulo:{
    fontFamily: 'Roboto',
    fontSize: 12,
    marginTop: 15,
    marginBottom: 7,
    fontWeight: 'bold',
    textDecoration: 'underline'

  },

  negrita:{
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    
   
  },

  total:{
    textAlign: 'right',
    marginTop: 15,
  },

});

const formatName = (record)=>{
  let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
  nombre = nombre.toUpperCase()
  return nombre
}

const FichaEstudiantePDF = (props) => {
  const estudiante = props.children;
  const fotoPerfil = props.fotoPerfil

//   toDataUrl(fotoPerfil, function(myBase64) {
//     console.log(myBase64); // myBase64 is the base64 string
// });


  return (
    <Document>
      <Page size="letter" orientation='portrait' style={styles.body}>

      

      <View style={styles.table2}>
      <View style={styles.tableRow2}>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell2}>
              <Image style={[styles.logo]} src={logo}></Image>
              </Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={[styles.tableCell2,styles.titulo]}>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS</Text>

          

              <View style={styles.table2}>
      <View style={styles.tableRow2}>
<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Código: 0 </Text>
</View>
<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Versión: 1 </Text>
</View>

<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Fecha Versión : 1/04/2016</Text>
</View>

<View style={styles.tableCol5}>
<Text style={styles.tableCell2}>Página: 1 </Text>
</View>

</View>
</View>



              <Text style={[styles.tableCell2,styles.titulo]}>FICHA DE ESTUDIANTE</Text>

             
            </View>
        </View>
      </View>

   
      <View style={styles.table2}>
      <View style={styles.tableRow}>
      <View style={styles.tableCol}>
      <Text style={[styles.tableCell,styles.titulo]}>

      {/* <Image style={[styles.logo]} src={fotoPerfil}></Image> */}

        {formatName(estudiante)}</Text>

        <Text style={[styles.tableCell,styles.subtitulo]}> Información Principal</Text>
      </View>


      <View style={styles.tableCol}>
      </View>
      </View>
      </View>

        <View style={styles.table2}>
          <View style={styles.tableRow}>


            <View style={styles.tableCol}>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Código Estudiante:</Text>  {estudiante.id}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Tipo de documento:</Text>  {estudiante.tipoId}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Sexo: </Text> {estudiante.sexo}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Telefono: </Text> {estudiante.telefono}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Correo: </Text> {estudiante.correo}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Lugar de residencia: </Text> {estudiante.residencia}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Fecha de nacimiento: </Text> {estudiante.fechaNacimiento}</Text>
            
            </View>

            <View style={styles.tableCol}>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>No. de documento: </Text> {estudiante.numId}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Celular: </Text> {estudiante.celular}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Direccion: </Text> {estudiante.direccion}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Barrio: </Text> {estudiante.barrio}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Lugar de nacimiento: </Text> {estudiante.lugarNacimiento}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Nombre del contacto Secundario/Parentesco: </Text> {estudiante.nombreContacto} {estudiante.parentescoContacto}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Celular Contacto: </Text> {estudiante.celularContacto} </Text>
            </View>

           

          </View>

        
    
        </View>


      <View style={styles.table2}>
      <View style={styles.tableRow}>
      <View style={styles.tableCol}>
     

        <Text style={[styles.tableCell,styles.subtitulo]}> Información Adicional</Text>
      </View>


      <View style={styles.tableCol}>
      </View>
      </View>
      </View>

        <View style={styles.table2}>
          <View style={styles.tableRow}>


            <View style={styles.tableCol}>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Expedición de Documento:</Text>  {estudiante.exp_documento}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Discapacidad:</Text>  {estudiante.discapacidad}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Medio de Transporte: </Text> {estudiante.medio_transporte}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Ocupación: </Text> {estudiante.ocupacion}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Zona: </Text> {estudiante.zona}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>EPS: </Text> {estudiante.eps}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Aseguradora: </Text> {estudiante.aseguradora}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Tipo de sangre: </Text> {estudiante.sangre}</Text>
            
            </View>

            <View style={styles.tableCol}>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>No. de hijos: </Text> {estudiante.hijos}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Estado Civil: </Text> {estudiante.estado_civil}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Estrato: </Text> {estudiante.estrato}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Nivel de formación: </Text> {estudiante.nivel_formacion}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>SISBEN: </Text> {estudiante.sisben}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Multiculturalidad:</Text> {estudiante.multiculturalidad}</Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>ARS: </Text> {estudiante.ars} </Text>
            <Text style={[styles.tableCell]}>  <Text style={[styles.negrita]}>Libreta Militar: </Text> {estudiante.libreta}</Text>
            </View>

           

          </View>

        
    
        </View>


        
       



         
        <Text style={[styles.footer]}>Brighton - Centro de Formación en Inglés 
        </Text>
        
          <Text style={[styles.footer2]} > Fecha de impresión: {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}</Text>
           
       

      </Page>
    </Document>
  );
};


function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
          callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export default FichaEstudiantePDF;
