import React, { useState, useEffect } from "react";
import MyLayout from "../../components/MyLayout";
import { Space, Table, Tag, Breadcrumb, Button, Spin, Modal, message, Input } from "antd";
import UsuarioModal from "../../components/modals/UsuarioModal";
import usuarioService from "../../services/usuario.service";
import {DeleteOutlined, SearchOutlined, EditOutlined} from '@ant-design/icons';
import UsuarioEditModal from "../../components/modals/Edit/UsuarioEditModal";
const {confirm} = Modal;
const Usuario = () => {
  const [viewNewUsuario, setViewNewUsuario] = useState(false);
  const [viewEditUsuario, setViewEditUsuario] = useState(false);
  const [userSelected, setUserSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const empresa_id = 1;

  useEffect(() => {
    listUsuarios();
  }, []);

  const deleteUser = (record) =>{
    confirm({
      title:"Desea eliminar usuario?",
      okText:"Si",
      okType:'danger',
      cancelText:"No",
      onOk(){
        usuarioService.delete(record.id).then((response) => {
          console.log(response.data)
          listUsuarios()
          message.success('Usuario Eliminado')
        })
        .catch((e) => {
          console.log(e);
          message.error('Error al eliminar')
        });
      }})
  }

  const editUser = (record) => {
    setViewEditUsuario(true)
    setUserSelected(record)
  }

  const listUsuarios = () => {
    setLoading(true);
    usuarioService
      .getAll()
      .then((response) => {
        let filterUsuarios = response.data;
        console.log(response.data);
        filterUsuarios = response.data.filter(
          (item) => item.empresa_id == empresa_id
        );
        setUsuarios(filterUsuarios);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const newUsuario = (usuario) => {
    setViewNewUsuario(true);
  };

  const columns = [
    {
      title: "Nombres",
      //dataIndex: "nombre",
      filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
        return <Input autoFocus placeholder='Ingrese Nombre' 
        value = {selectedKeys[0]} 
        onChange={(e)=>{
          setSelectedKeys(e.target.value?[e.target.value]:[])
         confirm({closeDropdown:false})
        }}
        onPressEnter={()=>{
          confirm()
        }} 
        
        onBlur={()=>{
          confirm()
        }} >
        
        </Input>
      },
      filterIcon:()=>{
        return <SearchOutlined />
      },
      onFilter:(value,record)=>{
        let nombre = record.nombre
        return nombre.toLowerCase().includes(value.toLowerCase())
      },
      key: "nombres",
      render: (record) => <div>{record.nombre}</div>
    },

    {
      title: "Correo",
      //dataIndex: "nombre",
      filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
        return <Input autoFocus placeholder='Ingrese Correo' 
        value = {selectedKeys[0]} 
        onChange={(e)=>{
          setSelectedKeys(e.target.value?[e.target.value]:[])
         confirm({closeDropdown:false})
        }}
        onPressEnter={()=>{
          confirm()
        }} 
        
        onBlur={()=>{
          confirm()
        }} >
        
        </Input>
      },
      filterIcon:()=>{
        return <SearchOutlined />
      },
      onFilter:(value,record)=>{
        let nombre = record.correo
        return nombre.toLowerCase().includes(value.toLowerCase())
      },
      key: "correo",
      render: (record) => <div>{record.correo}</div>
    },

    {
      title: "Tipo de Usuario",
      filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
        return <Input autoFocus placeholder='Ingrese Tipo de usuario' 
        value = {selectedKeys[0]} 
        onChange={(e)=>{
          setSelectedKeys(e.target.value?[e.target.value]:[])
         confirm({closeDropdown:false})
        }}
        onPressEnter={()=>{
          confirm()
        }} 
        
        onBlur={()=>{
          confirm()
        }} >
        
        </Input>
      },
      filterIcon:()=>{
        return <SearchOutlined />
      },
      onFilter:(value,record)=>{
        let nombre = record.tipo
        return nombre.toLowerCase().includes(value.toLowerCase())
      },
      render: (record) => <div>{record.tipo}</div>
      //render: (text) => <a>{text}</a>,
    },

    {
      title: "Grupo de Trabajo",
      dataIndex: "workgroup",
      key: "3",
      //render: (text) => <a>{text}</a>,
    },

    {
      title: "Opciones",
      render: (record) => <div><Button type='primary' onClick={()=>{editUser(record)}}><EditOutlined/></Button>
      <Button type='danger' onClick={()=>{deleteUser(record)}}><DeleteOutlined/></Button>
      </div>,
    },
  ];

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
  return (
    <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Ajustes</Breadcrumb.Item>
        <Breadcrumb.Item>Usuarios</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newUsuario}>
        Crear Usuario
      </Button>

      <UsuarioModal
        visible={viewNewUsuario}
        updateVisible={setViewNewUsuario}
        updateLoading={setLoading}
        updateListUsuarios={listUsuarios}
      ></UsuarioModal>

      {viewEditUsuario && userSelected && (
        <UsuarioEditModal visible={viewEditUsuario} usuario={userSelected}  updateLoading={setLoading} updateListUsuarios={listUsuarios}
         updateVisible={setViewEditUsuario}>

        </UsuarioEditModal>
      )}

      <Table
        columns={columns}
        dataSource={usuarios}
        pagination={{ pageSize: 5 }}
      />
    </MyLayout>
  );
};

export default Usuario;
