import React, {useState, useEffect} from "react"

import {
  Breadcrumb,
  Table,
  Button,
  Spin,
  Input,
  Modal,
  message,
  Form,
  Row,
  Col,
  Select,
  TimePicker,
  Tooltip,
} from "antd"
import {SearchOutlined} from "@ant-design/icons"
import {Content} from "antd/lib/layout/layout"
import estudianteService from "../../services/estudiante.service"
import moment from "moment"
import MatriculaExamenModal from "../modals/MatriculaExamenModal"
import {useAuth} from "../../context/auth.context"
import calendarioService from "../../services/calendario.service"
import tarifaService from "../../services/tarifa.service"
import cursoService from "../../services/curso.service"
import {StarFilled} from "@ant-design/icons"

const {confirm} = Modal

const SearchEstudianteModal = (props) => {
  const [estudiantes, setEstudiantes] = useState([])
  const [loading, setLoading] = useState(false)
  const [viewModalExamenInternacional, setViewModalExamenInternacional] =
    useState(false)
  const [estudianteSelected, setEstudianteSelected] = useState()
  const [viewModalTRM, setViewModalTRM] = useState(false)
  const [formTrm] = Form.useForm()
  const [horario, setHorario] = useState()
  const modo = props.modo
  const totalMatriculas = props.totalMatriculas

  const nombreExamen = props.children.nombre
  const curso = props.children
  // const tipo = props.tipo
  const {user, role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id
  const [clases, setClases] = useState()
  const [viewModalOpciones, setViewModalOpciones] = useState(false)
  const [cursoSelected, setCursoSelected] = useState()
  const [cursoConsulta, setCursoConsulta] = useState()
  const [formOpciones] = Form.useForm()
  const [formOpcionesExamen] = Form.useForm()
  const [matricula, setMatricula] = useState()
  const [inscripcion, setInscripcion] = useState()
  const [fechaMatricula, setFechaMatricula] = useState()
  const [fechaPresentacion, setFechaPresentacion] = useState()
  const [horaPresentacion, setHoraPresentacion] = useState()
  const [tipo, setTipo] = useState()
  const [modoE, setModoE] = useState()
  const [viewModalExamen, setViewModalExamen] = useState(false)
  const [tarifaCurso, setTarifaCurso] = useState()
  const [tarifa24, setTarifa24] = useState()
  const [tarifa20, setTarifa20] = useState()
  const [valorCobrar, setValorCobrar] = useState(0)

  const [viewValorMatricula, setViewValorMatricula] = useState(true)

  const [tipos, setTipos] = useState([
    {tipo: "General"},
    {tipo: "Teachers"},
    {tipo: "Advanced"},
    ,
    {tipo: "Académico"},
  ])
  const [modos, setModos] = useState([{modo: "Remoto"}, {modo: "Presencial"}])

  function formatName(record) {
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${
      record.primerApellido
    } ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const calculateCategory = (dateString) => {
    const edad = moment().diff(dateString, "years", true).toFixed(0)

    if (edad > 0 && edad <= 12) {
      return "Niño"
    }
    if (edad > 12 && edad <= 18) {
      return "Adolescente"
    }
    if (edad > 18) {
      return "Adulto"
    }
  }

  const validarTipo = (tipo) => {
    if (tipo == "Niño") {
      return "text-center bg-purple-500 rounded px-1"
    } else if (tipo == "Adolescente") {
      return " text-center bg-yellow-500 rounded px-1"
    } else if (tipo == "Adulto") {
      return "text-center bg-green-500 rounded px-1"
    }
  }

  const calculateAge = (dateString) => {
    return moment().diff(dateString, "years", true).toFixed(0)
  }

  const columns = [
    {
      title: "Nombre",
      //dataIndex: "nombre",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Nombre"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        let nombre =
          record.primerNombre +
          " " +
          record.segundoNombre +
          " " +
          record.primerApellido +
          " " +
          record.segundoApellido
        return nombre.toLowerCase().includes(value.toLowerCase())
      },

      key: "primerNombre",
      render: (record) => {
        if (record.becado == "No") {
          return <div>{formatName(record)}</div>
        } else {
          return (
            <div>
              {formatName(record)}{" "}
              <Tooltip title="Becado">
                {" "}
                <StarFilled style={{color: "#BFB915", cursor: "pointer"}} />
              </Tooltip>{" "}
            </div>
          )
        }
      },
    },
    {
      title: "No. Identificación",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese No. ID"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.numId.includes(value)
      },
      key: "numId",
      render: (record) => <div>{record.numId}</div>,
    },
    {
      title: "Celular",
      dataIndex: "celular",
      key: "celular",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Categoria",
      key: "fechaNacimiento",
      dataIndex: "fechaNacimiento",
      render: (fecha) => {
        const formats = ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"]
        const date = moment(fecha, formats, true) // Intenta parsear con los formatos
        console.log(date)
        return (
          <div className={`${validarTipo(calculateCategory(date))}`}>
            <Tooltip title={calculateAge(date)}>
              {calculateCategory(date)}
            </Tooltip>
          </div>
        )
      },
    },

    {
      title: "Fecha Registro",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (fecha) => {
        let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a")
        return <div>{myFecha}</div>
      },
    },

    {
      title: "Opciones",

      key: "action",
      render: (record) => {
        if (modo != "Examen") {
          return (
            <Button
              onClick={() => {
                verOpciones(record)
              }}
            >
              Matricular
            </Button>
          )
        } else {
          return (
            <Button
              onClick={() => {
                verOpcionesExamen(record)
              }}
            >
              Matricular
            </Button>
          )
        }
      },
    },
  ]

  useEffect(() => {
    listEstudiantes()
    listTarifas()
    getCurso()
  }, [])

  const listTarifas = () => {
    setLoading(true)
    tarifaService
      .getForain(empresa_id)
      .then((response) => {
        let filterTarifa = response.data

        let tipoProducto = "NORMAL"
        if (curso.tipo == "Personalizado") {
          tipoProducto = "PERSONALIZADO"
        }

        let filterCurso = filterTarifa.filter(
          (item) =>
            item.tipo == curso.modo.toUpperCase() &&
            item.caracteristica == tipoProducto
        )[0]
        let filter24 = filterTarifa.filter(
          (item) => item.tipo == "HORA" && item.caracteristica == "24 HORAS"
        )[0]
        let filter20 = filterTarifa.filter(
          (item) => item.tipo == "HORA" && item.caracteristica == "20 HORAS"
        )[0]
        console.log(tipoProducto)

        setTarifaCurso(filterCurso)
        setTarifa20(filter20)
        setTarifa24(filter24)

        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const getCurso = () => {
    setLoading(true)
    cursoService
      .get(curso.id)
      .then((response) => {
        let filterCurso = response.data[0]

        setCursoConsulta(filterCurso)

        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const cancelOpciones = () => {
    setViewModalOpciones(false)
  }

  const cancelOpcionesExamen = () => {
    setViewModalExamen(false)
  }

  const listEstudiantes = () => {
    setLoading(true)
    estudianteService
      .getStatus("Activo")
      .then((response) => {
        let filterEstudiante = response.data

        setEstudiantes(filterEstudiante)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const verOpciones = (estudiante) => {
    setEstudianteSelected(estudiante)
    calendarioService.getForain(props.curso_id).then((res) => {
      let horario = res.data
      setClases(null)
      setHorario(null)
      setViewModalOpciones(true)
      setCursoSelected(curso)

      let momentInicio = moment(curso.horaInicial, "HH:mm:ss")
      let momentFinal = moment(curso.horaFinal, "HH:mm:ss")

      let diferenciaHoras = momentFinal.diff(momentInicio, "hours", true)

      let hoy = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      let fechaCurso = moment(curso.fechaFinal).format("YYYY-MM-DD 23:59:59")
      let contador = 0

      for (let item of horario) {
        let fechaHorario = moment(item.start).format("YYYY-MM-DD 23:59:59")
        let diferencia = moment(fechaHorario).diff(hoy, "days", true)
        if (diferencia < 0) {
          contador++
        }
      }

      console.log(cursoConsulta.horas)
      console.log(tarifaCurso.valor)
      console.log(tarifa20.valor)
      if (cursoConsulta.horas >= 20 && cursoConsulta.horas < 24) {
        if (totalMatriculas <= 2) {
          setValorCobrar(
            tarifaCurso.valor -
              (tarifaCurso.valor / cursoConsulta.horas) *
                contador *
                diferenciaHoras
          )
          formOpciones.setFieldsValue({
            valor_matricula:
              tarifaCurso.valor -
              (tarifaCurso.valor / cursoConsulta.horas) *
                (contador * diferenciaHoras),
          })
        } else {
          setValorCobrar(
            tarifaCurso.valor -
              (tarifaCurso.valor / cursoConsulta.horas) *
                contador *
                diferenciaHoras
          )
          formOpciones.setFieldsValue({
            valor_matricula:
              tarifaCurso.valor -
              (tarifaCurso.valor / cursoConsulta.horas) *
                (contador * diferenciaHoras),
          })
        }
      }

      if (cursoConsulta.horas >= 24) {
        setValorCobrar(
          tarifaCurso.valor - tarifa24.valor * contador * diferenciaHoras
        )
        formOpciones.setFieldsValue({
          valor_matricula:
            tarifaCurso.valor - tarifa24.valor * (contador * diferenciaHoras),
        })
      }

      setClases("Han pasado " + contador + " clases desde el inicio del curso")
    })
  }

  const verOpcionesExamen = (estudiante) => {
    setEstudianteSelected(estudiante)
    setViewModalExamen(true)
  }

  const handleCancel = () => {
    props.updateVisible(false)
  }

  const updateVisibleExamen = () => {
    setViewModalExamenInternacional(false)
  }

  const matricular = (values) => {
    setViewModalExamen(false)

    setTipo(values.tipo)
    setModoE(values.modo)
    if (modo != "Examen") {
      setMatricula(values.matricula)
      setInscripcion(values.inscripcion)
    } else {
      setFechaPresentacion(values.fecha)
      setHoraPresentacion(values.hora._d)
    }
    setFechaMatricula(values.createdAt + " 23:59:59")

    if (nombreExamen == "TOEFL") {
      setViewModalTRM(true)
    } else {
      const form = {}
      if (modo == "Curso") {
        form.curso_id = props.curso_id
      }
      if (modo != "Entrenamiento" && modo != "Curso") {
        form.examen_id = props.examen_id
      }

      if (modo == "Entrenamiento") {
        form.curso_id = props.curso_id

        confirm({
          title: "Desea matricular a examen Internacional?",
          okText: "Si",
          okType: "danger",
          cancelText: "No",
          onOk() {
            props.updateInternacional(true)
            // setViewModalExamenInternacional(true)
          },
          onCancel() {
            props.updateVisible(false)
          },
        })
      }

      form.estudiante_id = estudianteSelected.id
      form.empresa_id = empresa_id
      form.usuario_id = usuario_id
      form.tipo = values.tipo
      form.modo = values.modo
      if (values.valor_matricula) {
        form.valor_matricula = values.valor_matricula
      }

      if (modo != "Examen") {
        form.matricula = values.matricula
        form.inscripcion = values.inscripcion
      } else {
        form.fecha = values.fecha
        form.hora = values.hora
      }

      form.createdAt = values.createdAt + " 23:59:59"
      props.saveMatricula(form)
      setLoading(false)
    }
  }

  const handleCancelTrm = () => {
    setViewModalTRM(false)
  }

  const onFinish = (values) => {
    const form = {}
    form.valorTRM = values.valor
    if (modo == "Curso") {
      form.curso_id = props.curso_id
    }
    if (modo != "Entrenamiento" && modo != "Curso") {
      form.examen_id = props.examen_id
    }

    if (modo == "Entrenamiento") {
      form.curso_id = props.curso_id

      confirm({
        title: "Desea matricular a examen Internacional?",
        okText: "Si",
        okType: "danger",
        cancelText: "No",
        onOk() {
          setViewModalExamenInternacional(true)
        },
        onCancel() {
          props.updateVisible(false)
        },
      })
    }

    form.tipo = tipo
    form.modo = modoE
    form.estudiante_id = estudianteSelected.id
    form.empresa_id = empresa_id
    form.usuario_id = usuario_id
    form.matricula = matricula
    form.inscripcion = inscripcion
    form.createdAt = fechaMatricula
    form.fecha = fechaPresentacion
    form.hora = horaPresentacion

    props.saveMatricula(form)
    setViewModalTRM(false)
    // setViewModalExamen(false)

    setLoading(false)
  }

  const validarSiCiclo = (value) => {
    if (value == "No") {
      setViewValorMatricula(false)
    } else {
      setViewValorMatricula(true)
    }
  }

  return (
    <>
      <Modal
        width={1024}
        visible={props.visible}
        onOk={handleCancel}
        onCancel={handleCancel}
      >
        {loading && (
          <div className="spin_container">
            <Spin className="spin" size="large" />
          </div>
        )}

        {!loading && (
          <Content>
            <h2>Estudiantes</h2>
            <Table
              columns={columns}
              dataSource={estudiantes}
              pagination={{pageSize: 4}}
            />

            {viewModalExamenInternacional && (
              <MatriculaExamenModal
                visible={viewModalExamenInternacional}
                estudiante_id={estudianteSelected.id}
                updateVisible={updateVisibleExamen}
              ></MatriculaExamenModal>
            )}

            {viewModalTRM && !loading && (
              <Modal
                visible={viewModalTRM}
                onOk={formTrm.submit}
                onCancel={handleCancelTrm}
              >
                <Form
                  name="basic"
                  layout="vertical"
                  labelCol={{span: 24}}
                  wrapperCol={{span: 20}}
                  autoComplete="off"
                  onFinish={onFinish}
                  form={formTrm}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Ingrese valor TRM del dia: "
                        name="valor"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese Valor Trm del dia",
                          },
                        ]}
                      >
                        <Input type="number"></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            )}

            {viewModalOpciones &&
              cursoSelected &&
              clases &&
              modo != "Examen" && (
                <Modal
                  title="Opciones de matrícula"
                  visible={viewModalOpciones}
                  okText="Matricular"
                  onOk={formOpciones.submit}
                  onCancel={cancelOpciones}
                >
                  {clases && (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          margin: "10px",
                          color: "darkred",
                        }}
                      >
                        {clases}
                      </div>
                    </>
                  )}

                  <Form
                    form={formOpciones}
                    layout="vertical"
                    onFinish={matricular}
                  >
                    <Form.Item
                      label="Cobrar valor del ciclo?"
                      name="matricula"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingrese confirmación de Matrícula",
                        },
                      ]}
                    >
                      <Select onChange={validarSiCiclo}>
                        <Select.Option value="Si">Si</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                      </Select>
                    </Form.Item>

                    {viewValorMatricula && (
                      <Form.Item label="Valor Matrícula" name="valor_matricula">
                        <Input />
                      </Form.Item>
                    )}

                    <Form.Item
                      label="Cobrar Inscripción?"
                      name="inscripcion"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingrese confirmación de Inscripción",
                        },
                      ]}
                    >
                      <Select>
                        <Select.Option value="Si">Si</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Fecha de matricula"
                      name="createdAt"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese fecha matricula",
                        },
                      ]}
                    >
                      <Input type="date"></Input>
                    </Form.Item>

                    {/* <Form.Item label="Valor Inscripción" name='valor_inscripcion'>
                  <Input></Input>
                </Form.Item> */}
                  </Form>
                </Modal>
              )}

            {viewModalExamen && (
              <Modal
                title="Opciones de matrícula"
                visible={viewModalExamen}
                okText="Matricular"
                onOk={formOpcionesExamen.submit}
                onCancel={cancelOpcionesExamen}
              >
                <Form
                  form={formOpcionesExamen}
                  layout="vertical"
                  onFinish={matricular}
                >
                  <Form.Item
                    label="Fecha de Presentación: "
                    name="fecha"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese Fecha",
                      },
                    ]}
                  >
                    <Input type="date"></Input>
                  </Form.Item>

                  <Form.Item
                    label="Tipo: "
                    name="tipo"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingrese Tipo",
                      },
                    ]}
                  >
                    <Select>
                      {tipos.map((item) => {
                        return (
                          <Select.Option value={item.tipo}>
                            {item.tipo}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Modo: "
                    name="modo"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese Modo",
                      },
                    ]}
                  >
                    <Select>
                      {modos.map((item) => {
                        return (
                          <Select.Option value={item.modo}>
                            {item.modo}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Hora de Presentación: "
                    name="hora"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese Hora",
                      },
                    ]}
                  >
                    <TimePicker
                      use12Hours
                      format="hh:mm A"
                      placeholder="Hora de Presentación"
                    ></TimePicker>
                  </Form.Item>
                </Form>
              </Modal>
            )}
          </Content>
        )}
      </Modal>
    </>
  )
}

export default SearchEstudianteModal
