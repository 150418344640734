import React, {useEffect, useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  message,
  Select
} from "antd";
import { Content } from "antd/lib/layout/layout";
import nivelService from "../../services/nivel.service";


const NivelModal = (props) => {


  const [form] = Form.useForm();
  const [sedes, setSedes] = useState([]);
  const [loading, setLoading] = useState(false);
  const empresa_id = 1;
  const [tipos,setTipos] = useState(['Eficiencia en inglés','Bkids','Por Habilidades'])
  const niveles =[
    {nivel:"Elemental A1",tipo:'Eficiencia en inglés'},
    {nivel:"Pre intermedio A2",tipo:'Eficiencia en inglés'},
    {nivel:"Intermedio B1",tipo:'Eficiencia en inglés'},
    {nivel:"Intermedio Alto B2",tipo:'Eficiencia en inglés'},
    {nivel:"Avanzado C1",tipo:'Eficiencia en inglés'},
    {nivel:"Avanzado alto C2",tipo:'Eficiencia en inglés'},
    {nivel:"Starters Pre A1",tipo:'Bkids'},
    {nivel:"Movers A1",tipo:'Bkids'},
    {nivel:"Flyers A2",tipo:'Bkids'},
    {nivel:"Por Habilidades",tipo:'Por Habilidades'},
  ]
  const [filterNiveles,setFilterNiveles] = useState([])
  useEffect(() => {
    form.resetFields()
  }, []);

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const filterNivel = (value) =>{
    setFilterNiveles(niveles.filter(item=>item.tipo == value))
  }

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.empresa_id = empresa_id;
    nivelService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListNivel();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>

          <Col span={24}>
              <Form.Item
                label="Código: "
                name="codigo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Código",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Tipo de Curso: "
                name="tipo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de Curso",
                  },
                ]}
              >
              <Select onChange={filterNivel}>
                {tipos.map((item=>{
                  return <Select.Option value={item}>{item}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Nivel: "
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nivel",
                  },
                ]}
              >
                 <Select>
                {filterNiveles.map((item=>{
                  return <Select.Option value={item.nivel}>{item.nivel}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Ciclo: "
                name="ciclo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Ciclo",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

           
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default NivelModal;
