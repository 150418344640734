import { Button, Checkbox, Form, Input, Modal, Spin } from 'antd';
import React, {useState} from 'react';
import usuarioService from "../../services/usuario.service";
import { useAuth } from '../../context/auth.context';
import { useNavigate } from 'react-router-dom';
import {openNotification} from "../../services/pushNotification.service"
import './Login.css'
const Login = () => {

    const navigate = useNavigate()
    const {saveUser,saveRole,user} = useAuth()
    const [roles,setRoles] = useState()
    const [viewSelectRoles,setViewSelectRoles] = useState(false)
    const [loading,setLoading] = useState(false)

    const onFinish = (values) => {
      setLoading(true)
        usuarioService.login(values)
        .then((response) => {
          console.log(response.data)
          if(response.data.length==1){
          if(response.data[0]){
            console.log(response.data[0]);
           
           saveUser(response.data[0])
           saveRole(response.data[0].tipo)
           setLoading(false)

            navigate("/")
            openNotification('success',"Bienvenido "+response.data[0].nombre, "")
          }
        } else if(response.data.length>=2){
          let myRoles = []
          for(let item of response.data){
            myRoles.push(item)
          }
          
         // saveUser(response.data[0])
          setRoles(myRoles)
          setViewSelectRoles(true)
          setLoading(false)

        }
        })
        .catch(function(error) {
          console.log(error);
          setLoading(false)
          openNotification('error',"Ups!", "El usuario o password no son válidos")
        });
      };

      const selectRole = (user)=>{
        saveUser(user)
        saveRole(user.tipo)
        navigate("/")
        openNotification('success',"Bienvenido "+user.nombre, "")
     
      }
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setLoading(false)
      };

      if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
      <>
      <div className='login'>
        <div className='form'>
        <img className='logo_inicial' src="./assets/img/logo.png" alt="" />
        <Form className='form-container'
      name="basic"
      layout='vertical'
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Mail"
        name="correo"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese su Usuario!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese su Password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

   

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Login
        </Button>
      </Form.Item>
    </Form>
    </div>
    </div>

    {viewSelectRoles && (
      <Modal visible={viewSelectRoles} footer={false}>
        <h3>Seleccione el Rol</h3>
        <div span={12}>
          {roles.map((item=>{
            return <Button onClick={()=>selectRole(item)} style={{margin:'20px'}} type='primary'>{item.tipo}</Button>
          }))}
          </div>
      </Modal>
    )}
    </>
    );
   
};

export default Login;