import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import {
  Breadcrumb,
  Table,
  Button,
  Spin,
  Input,
  Tooltip,
  Modal,
  Select,
} from "antd"
import MyLayout from "../../components/MyLayout"
import cursoService from "../../services/curso.service"
import CursoModal from "../../components/modals/CursoModal"
import CursoEditModal from "../../components/modals/Edit/CursoEditModal"
import ParametroEditModal from "../../components/modals/Edit/ParametroEditModal"
import SiguienteNivelModal from "../../components/modals/SiguienteNivelModal"
import moment from "moment"
import "moment/locale/es"
import {
  EditOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
  SearchOutlined,
  OrderedListOutlined,
  ToTopOutlined,
  RotateLeftOutlined,
} from "@ant-design/icons"
import "./Curso.css"
import {useAuth} from "../../context/auth.context"
const {confirm} = Modal

const Curso = () => {
  const [viewNewCurso, setViewNewCurso] = useState(false)
  const [viewEditCurso, setViewEditCurso] = useState(false)
  const [viewParametrosCurso, setViewParametrosCurso] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cursos, setCursos] = useState([])
  const [cursoSelected, setCursoSelected] = useState()
  const {role} = useAuth()
  const [viewNivelCurso, setViewNivelCurso] = useState(false)
  const empresa_id = 1
  const [estado, setEstado] = useState("Activo")

  const [filter, setFilter] = useState("Activo")
  const [pagination, setPagination] = useState({
    current: 1, // Página actual
    pageSize: 4, // Tamaño de página
  })

  const handlePaginationChange = (current, pageSize) => {
    setPagination({current, pageSize})
  }

  const storageKey = "cursosPagination"
  const storageFilter = "cursosFilter"
  let savedFilter

  useEffect(() => {
    const savedPagination = JSON.parse(localStorage.getItem(storageKey))
    if (JSON.parse(localStorage.getItem(storageFilter))) {
      savedFilter = JSON.parse(localStorage.getItem(storageFilter))
      setFilter(savedFilter)
      setEstado(savedFilter)
      console.log(savedFilter)
      listCursosEstado(savedFilter)
    } else {
      localStorage.setItem(storageFilter, JSON.stringify(filter))
      listCursosEstado(estado)
    }

    if (savedPagination) {
      setPagination(savedPagination)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(pagination))
  }, [pagination])

  useEffect(() => {
    localStorage.setItem(storageFilter, JSON.stringify(filter))
  }, [filter])

  const listCursos = () => {
    setLoading(true)
    cursoService
      .getForain(empresa_id)
      .then((response) => {
        let filterCursos = response.data
        filterCursos = response.data.filter((item) => item.estado != "Inactivo")
        console.log(filterCursos)
        setCursos(filterCursos)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const listCursosEstado = (myEstado) => {
    console.log(estado)
    setLoading(true)
    cursoService
      .getForainEstado(empresa_id, myEstado)
      .then((response) => {
        let filterCursos = response.data
        //     filterCursos = response.data.filter(
        //   (item) => item.estado != "Inactivo"
        // );
        console.log(filterCursos)
        setCursos(filterCursos)
        //setEstado(myEstado)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const validarHorario = (record) => {
    if (record.horaInicial && record.diasHorario) {
      let cadenaHorario = ""
      let dias = JSON.parse(record.diasHorario)
      for (let item of dias) {
        cadenaHorario += item.substr(0, 3) + ", "
      }

      cadenaHorario +=
        "- " +
        moment(record.fechaInicial + " " + record.horaInicial).format(
          "hh:mm a"
        ) +
        " a " +
        moment(record.fechaInicial + " " + record.horaFinal).format("hh:mm a")

      return cadenaHorario
    } else {
      return "No hay calendario asignado"
    }
  }
  //   if(horario.length>0){

  //   let cadenaHorario = ""
  //   let myHorario = horario.sort((a, b) => {
  //     return parseInt(a.dia) - parseInt(b.dia);
  // });
  //   const dias = ['Domi','Lun','Mar','Mie','Jue','Vie','Sab']
  //   let diasFinal = []
  //   for(let hora of myHorario){
  //     let buscar = diasFinal.findIndex(item=>item.dia == hora.dia)

  //     if(buscar == -1){
  //       diasFinal.push({dia:parseInt(hora.dia),diaFinal:dias[hora.dia]})
  //     }
  //   }

  //   for(let item of diasFinal){
  //     cadenaHorario += `${item.diaFinal}, `
  //   }

  //   cadenaHorario += ` - ${moment(myHorario[0].start).format('hh:mm a')} a ${moment(myHorario[0].end).format('hh:mm a')}`

  //   return cadenaHorario
  // }
  // else {
  //   return "No hay calendario asignado"
  // }

  const reactivarCurso = (record) => {
    confirm({
      title: "Desea reactivar el curso?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cursoService
          .reactivar(record.id)
          .then((response) => {
            console.log(response)
            listCursos()
          })
          .catch((e) => {
            console.log(e)
          })
      },
    })
  }

  const validarTipo = (tipo) => {
    if (tipo == "Niños") {
      return "text-center bg-purple-500 rounded px-1"
    } else if (tipo == "Adolescentes") {
      return " text-center bg-yellow-500 rounded px-1"
    } else if (tipo == "Adultos") {
      return "text-center bg-green-500 rounded px-1"
    }
  }

  const columns = [
    {
      title: "Codigo",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Codigo"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        let nombre = record.id.toString()
        return nombre.includes(value.toLowerCase())
      },
      key: "id",
      render: (record) => <div>{record.id}</div>,
    },
    // render: (text) => <a>{text}</a>,

    {
      title: "Curso/Nivel",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Curso"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        let nombre = record.nivel + " " + record.ciclo
        return nombre.toLowerCase().includes(value.toLowerCase())
      },

      render: (record) => (
        <>
          <Link to={"/ver-curso"} state={{curso: record}}>
            <div>
              {/* <div>{record.curso} -</div> */}
              <div>{record.nivel} -</div>
              <div>{record.ciclo} </div>
            </div>
          </Link>
          {record.estado == "Activo" && (
            <div className="finaliza">
              Finaliza {moment(record.fechaFinal).endOf("day").fromNow()}
            </div>
          )}

          {record.estado == "Finalizado" && (
            <div className="finaliza">
              Finalizó {moment(record.fechaFinal).endOf("day").fromNow()}
            </div>
          )}
        </>
      ),
    },

    {
      title: "Categoría",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Categoría"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        if (record.tipo_publico !== null) {
          let nombre = record.tipo_publico.toLowerCase()
          return nombre.includes(value.toLowerCase())
        }
      },
      key: "tipo_publico",
      render: (record) => (
        <div className={`${validarTipo(record.tipo_publico)}`}>
          {record.tipo_publico}
        </div>
      ),
    },

    {
      title: "Fecha",
      render: (record) => {
        let myFechaInicial = moment(record.fechaInicial).format("DD/MM/YYYY")
        let myFechaFinal = moment(record.fechaFinal).format("DD/MM/YYYY ")
        return (
          <div className="fecha">
            <div>Desde: {myFechaInicial}</div> <div>Hasta: {myFechaFinal}</div>
          </div>
        )
      },
    },

    {
      title: "Jornada",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Jornada"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        let nombre = record.jornada
        return nombre.toLowerCase().includes(value.toLowerCase())
      },

      key: "jornada",
      render: (record) => <div>{record.jornada}</div>,
    },

    {
      title: "Modalidad",
      render: (record) => {
        return (
          <>
            <div>{record.modo}</div>
            <div>{record.presentacion}</div>
          </>
        )
      },
    },

    {
      title: "Docente",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Nombre de docente"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        let nombre =
          record.Docente.primerNombre + " " + record.Docente.primerApellido
        return nombre.toLowerCase().includes(value.toLowerCase())
      },
      render: (record) => (
        <div>
          {record.Docente.primerNombre} {record.Docente.primerApellido}{" "}
        </div>
      ),
    },

    {
      title: "Horario",
      render: (record) => (
        <div className="fecha">{validarHorario(record)} </div>
      ),
    },

    {
      title: "Estado",
      // dataIndex: "estado",
      // key: "estado",
      render: (record) => (
        <div
          className={record.estado == "Finalizado" ? "finalizado" : "actived"}
        >
          {record.estado}
        </div>
      ),
    },

    {
      title: "Fecha Registro",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (fecha) => {
        let myFecha = moment(fecha).format("DD/MM/YYYY")
        return <div>{myFecha} </div>
      },
    },

    {
      title: "Creado por",
      render: (record) => {
        if (record.Usuario) {
          return <div>{record.Usuario.nombre}</div>
        }
      },
    },

    {
      title: "Opciones",

      key: "action",
      render: (record) => {
        if (
          role == "Superadministrador" ||
          role == "Administrativo" ||
          role == "Seguimiento" ||
          role == "Cumplimiento"
        )
          return (
            <>
              <Tooltip title="Editar">
                {" "}
                <span onClick={() => editCurso(record)} className="btn_option">
                  <EditOutlined />
                </span>
              </Tooltip>
              <Tooltip title="Editar Parámetros">
                {" "}
                <span
                  onClick={() => parametrosCurso(record)}
                  className="btn_option"
                >
                  <OrderedListOutlined />
                </span>
              </Tooltip>
              <Tooltip title="Horario">
                {" "}
                <Link to={"/ver-calendario"} state={{curso: record}}>
                  <span className="btn_option">
                    <CalendarOutlined />
                  </span>
                </Link>{" "}
              </Tooltip>
              {record.estado == "Activo" && (
                <Tooltip title="Finalizar">
                  {" "}
                  <span
                    className="btn_option"
                    onClick={() => finalizarCurso(record)}
                  >
                    <CheckCircleOutlined />
                  </span>
                </Tooltip>
              )}

              {record.estado == "Finalizado" && (
                <Tooltip title="Reactivar Curso">
                  {" "}
                  <span
                    className="btn_option"
                    onClick={() => reactivarCurso(record)}
                  >
                    <RotateLeftOutlined />
                  </span>
                </Tooltip>
              )}

              <Tooltip title="Eliminar">
                <span
                  className="btn_option"
                  onClick={() => inactivarCurso(record)}
                >
                  <DeleteOutlined />
                </span>
              </Tooltip>
              {record.estado != "Inactivo" && (
                <Tooltip title="Pasar a otro nivel">
                  <span
                    className="btn_option"
                    onClick={() => otroNivel(record)}
                  >
                    <ToTopOutlined />
                  </span>
                </Tooltip>
              )}
            </>
          )
      },
    },
  ]

  const newCurso = () => {
    setViewNewCurso(true)
  }

  const editCurso = (record) => {
    setViewEditCurso(true)
    setCursoSelected(record)
  }

  const parametrosCurso = (record) => {
    setCursoSelected(record)
    setViewParametrosCurso(true)
  }

  const otroNivel = (record) => {
    setViewNivelCurso(true)
    setCursoSelected(record)
  }

  const filtrarEstado = (valor) => {
    setEstado(valor)
    localStorage.setItem(storageFilter, JSON.stringify(valor))
    listCursosEstado(valor)
  }

  const finalizarCurso = (record) => {
    confirm({
      title: "Desea finalizar el curso?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cursoService
          .finalizar(record.id)
          .then((response) => {
            console.log(response)
            listCursos()
          })
          .catch((e) => {
            console.log(e)
          })
      },
    })
  }

  const inactivarCurso = (record) => {
    confirm({
      title: "Desea eliminar el curso?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cursoService
          .inactive(record.id)
          .then((response) => {
            console.log(response)
            listCursos()
          })
          .catch((e) => {
            console.log(e)
          })
      },
    })
  }

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    )
  return (
    <MyLayout>
      <Breadcrumb style={{margin: "16px 0"}}>
        <Breadcrumb.Item>Academico</Breadcrumb.Item>
        <Breadcrumb.Item>Cursos</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newCurso}>
        Crear Curso
      </Button>

      <div className="filtro">
        {" "}
        Filtrar:{" "}
        <Select
          defaultValue={estado}
          onChange={filtrarEstado}
          style={{width: 200}}
        >
          <Select.Option value="Activo">Activo</Select.Option>
          <Select.Option value="Finalizado">Finalizado</Select.Option>
        </Select>
      </div>

      {viewNewCurso && (
        <CursoModal
          visible={viewNewCurso}
          updateVisible={setViewNewCurso}
          updateLoading={setLoading}
          updateListCurso={listCursos}
        ></CursoModal>
      )}

      {cursoSelected && viewEditCurso && (
        <CursoEditModal
          visible={viewEditCurso}
          updateVisible={setViewEditCurso}
          updateLoading={setLoading}
          updateListCurso={listCursos}
        >
          {cursoSelected}
        </CursoEditModal>
      )}

      {cursoSelected && viewNivelCurso && (
        <SiguienteNivelModal
          visible={viewNivelCurso}
          updateVisible={setViewNivelCurso}
          updateLoading={setLoading}
          updateListCurso={listCursos}
        >
          {cursoSelected}
        </SiguienteNivelModal>
      )}

      {cursoSelected && viewParametrosCurso && (
        <ParametroEditModal
          visible={viewParametrosCurso}
          updateVisible={setViewParametrosCurso}
          updateLoading={setLoading}
          updateListCurso={listCursos}
        >
          {cursoSelected}
        </ParametroEditModal>
      )}

      <Table
        columns={columns}
        dataSource={cursos}
        pagination={{
          ...pagination,
          onChange: handlePaginationChange,
        }}
      />
    </MyLayout>
  )
}

export default Curso
