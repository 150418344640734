import React, {useState,useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom'
import docenteService from '../../services/docente.service';
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Col, Row, Collapse, Space,Divider,Tooltip,Modal,Button, Table, Form, Input, Select, message, Spin} from "antd";
import CursoDocente from '../../components/CursoDocente';
import Webcam from 'react-webcam'
import storage from "../../services/firebase"
import moment from 'moment';

import {ref, uploadBytesResumable,getDownloadURL} from "firebase/storage"
import DocenteEditModal from '../../components/modals/Edit/DocenteEditModal';
import archivo_docenteService from '../../services/archivo_docente.service';
import ArchivoDocenteModal from '../../components/modals/ArchivoDocenteModal';
import novedadService from '../../services/novedad_docente.service'
import { NumericFormat } from 'react-number-format';
import {DeleteOutlined,UploadOutlined, SearchOutlined, EditOutlined} from '@ant-design/icons';
import "./VerDocente.css"
import { useAuth } from '../../context/auth.context';

const { Panel } = Collapse;

const VerDocente = () => {
  const { TextArea } = Input;
  
    let location = useLocation();
    let myDocente = location.state.docente
    const [docente,setDocente] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cursosAsignados,setCursosAsignados] = useState([]);
    const [entrenamientosAsignados,setEntrenamientosAsignados] = useState([]);
    const [cursosFinalizados,setCursosFinalizados] = useState([]);
    const [entrenamientosFinalizados,setEntrenamientosFinalizados] = useState([]);
    const [viewWebcam, setViewWebcam] = useState(false);
    const [viewModalEditDocente, setViewModalEditDocente] = useState(false);
    const webRef = useRef(null)
    const [fotoPerfil,setFotoPerfil] = useState();
    const [archivos,setArchivos] = useState([]);
    const [viewArchivoModal,setViewArchivoModal] = useState(false);
    const {role} = useAuth()
    const [novedades,setNovedades] = useState([]);
    const [viewModalNovedad, setViewModalNovedad] = useState(false);
    const [formNovedad] = Form.useForm();
    const [modalFoto,setModalFoto] = useState(false);
    const [file,setFile] = useState()
    const [loadingFoto, setLoadingFoto] = useState(false);
    const [viewEditNovedad, setViewEditNovedad] = useState(false)
    const [formEditNovedad] = Form.useForm();
    const [novedadSelected,setNovedadSelected] = useState();
    const {confirm} = Modal;
    useEffect(() => {
        getDocente()
        getFiles()
        getNovedades()
      }, []);

      const columnsArchivo = [
        {
          title: "Tipo de archivo",
          render: (record) => <div>{record.descripcion}</div>,
        },
    
    
        {
          title: "Estado",
          render: (record) => <div>{record.estado}</div>,
        },
    
        {
          title: "Descargar Aqui",
          render: (record) => <Button onClick={()=>{descargar(record.url)}}>Descargar </Button>,
        },
    
        {
          title: "Fecha de subida",
    
          render: (record) => {let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
        },
      ]
    

      const novedadesColumns = [

        {
          title: "Fecha",
    
          render: (record) => {let myFecha = moment(record.fecha).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
        },

        {
          title: "Mes",
          filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
            return <Input autoFocus placeholder='Ingrese Mes' 
            value = {selectedKeys[0]} 
            onChange={(e)=>{
              setSelectedKeys(e.target.value?[e.target.value]:[])
             confirm({closeDropdown:false})
            }}
            onPressEnter={()=>{
              confirm()
            }} 
            
            onBlur={()=>{
              confirm()
            }} >
            
            </Input>
          },
          filterIcon:()=>{
            return <SearchOutlined />
          },
          onFilter:(value,record)=>{
            let nombre = moment(record.fecha).format("MMM")
            return nombre.toLowerCase().includes(value.toLowerCase())
          },
          render: (record) => {let myFecha = moment(record.fecha).format("MMM"); return ( <div>{myFecha}</div> )}
        },

        {
          title: "Año",
    
          render: (record) => {let myFecha = moment(record.fecha).format("YYYY"); return ( <div>{myFecha}</div> )}
        },

        {
          title: "Tipo",
          render: (record) => <div>{record.tipo}</div>,
        },

        {
          title: "Comentario",
          render: (record) => <div>{record.comentario}</div>,
        },
    
    
        {
          title: "Horas",
          render: (record) => <div>{record.horas}</div>,
        },

        {
          title: "Valor Hora",
          render: (record) => <div>$ <NumericFormat value={record.valorHora} displayType="text" allowLeadingZeros thousandSeparator="," /> </div>,
        },

        {
          title: "Total",
          render: (record) => <div>$ <NumericFormat value={record.horas * record.valorHora} displayType="text" allowLeadingZeros thousandSeparator="," /> </div>,
        },
    
      
    
        {
          title: "Fecha de registro",
          render: (record) => {let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
        },

        {
          title: "Opciones",
          render: (record) =>
          { if(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") {
         return <>
        
         <div><Button type='primary' onClick={()=>{editarNovedad(record)}}><EditOutlined/></Button></div>
         <div><Button type='danger' onClick={()=>{deleteNovedad(record)}}><DeleteOutlined/></Button></div>
         </>
        }}
        }
      ]
    


      const subirArchivo = () =>{
        setViewArchivoModal(true)
      }

      const getFiles = () =>{
        archivo_docenteService.get(myDocente.id).then(res=>{
          setArchivos(res.data)
        })
      }


      const deleteNovedad = (record) =>{
        confirm({
          title:"Desea eliminar la novedad?",
          okText:"Si",
          okType:'danger',
          cancelText:"No",
          onOk(){
            setLoading(true)
              novedadService.delete(record.id)
              .then((response) => {
                console.log(response)
                setLoading(false)
                getNovedades()
                message.success("Eliminado Correctamente")
                
              })
              .catch((e) => {
                console.log(e);
                setLoading(false)
              });

          }})
      }

      const editarNovedad = (record) =>{
        setNovedadSelected(record)
        formEditNovedad.setFieldsValue(record)
        setViewEditNovedad(true)
      }

      const getNovedades = () =>{
        novedadService.getForain(myDocente.id).then(res=>{
          setNovedades(res.data)
        })
      }

      const descargar = (url) =>{
        window.open(url)
      }

    const getDocente = () => {
        setLoading(true);
        docenteService
          .get(myDocente.id)
          .then((response) => {
            let filterDocente = response.data[0];
            console.log(response.data[0]);
            setDocente(filterDocente);
            setFotoPerfil(filterDocente.url)
            let cursos = [];
              let entrenamientos=[];
              let cursosFinalizados = [];
              let entrenamientosFinalizados=[];
            if(filterDocente.Cursos.length>0){
             
              for(let item of filterDocente.Cursos){
                if(item.estado == "Activo"){
                if(item.modo == "Curso"){
                
                  cursos.push(item)
                }else{
                  entrenamientos.push(item)
                }
              }
              else{
                if(item.modo == "Curso" && item.estado == "Finalizado"){
                
                  cursosFinalizados.push(item)
                }else{
                  entrenamientosFinalizados.push(item)
                }
              }
              }
            }
            setCursosAsignados(cursos)
            setEntrenamientosAsignados(entrenamientos)
            setCursosFinalizados(cursosFinalizados)
            setEntrenamientosFinalizados(entrenamientosFinalizados)
            console.log(cursos)

            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const viewCam = () =>{
        setViewWebcam(true)
      }

      const viewNovedadModal =() =>{
        formNovedad.resetFields()
        setViewModalNovedad(true)
      }

      const cancelCam = () =>{
        setViewWebcam(false)
      }

      const saveNovedad = (values) =>{
        let form = values
        form.docente_id = myDocente.id
        novedadService.create(form).then(res=>{
          console.log(res.data)
          setViewModalNovedad(false)
          getNovedades()
        })
      }

      const viewEditDocente = () =>{
        setViewModalEditDocente(true)
      }

      const formatName = (record)=>{
        let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
        nombre = nombre.toUpperCase()
        return nombre
      }

      const capturaFoto = async () =>{
        setLoadingFoto(true)
        let img = webRef.current.getScreenshot()
        //img = img.replace("data:image/webp;base64,", "");
      
     
        setViewWebcam(false)
   
        let file = await base64ToBlob(img);

        const storageRef = ref(storage, `/docentes/${docente.numId}/FOTO-${docente.numId}`)

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            ); 
            },
            (err) => console.log(err),
            () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            let form = {}
            form.docente_id = docente.id
            form.url = url
            console.log(form)
            docenteService.foto(form).then(res=>{
              console.log(res)
              setFotoPerfil(img)
              setLoadingFoto(false)
              message.success("Foto Cargada")
            }).catch(error=>{
              setLoadingFoto(false)
            })
            });
            }
            );

        }
      

         async function base64ToBlob(url) {
          let res = await fetch(url);
          let blob = await res?.blob({type:'image/jpeg'});
          return blob;
        }


        const viewModalFoto = () =>{
          setModalFoto(true)
             }

             const editNovedad = (values) =>{
                console.log(values)
                setLoading(true)
                novedadService.update(novedadSelected.id,values).then(res=>{
                  setLoading(false)
                  setViewEditNovedad(false)
                  formEditNovedad.resetFields()
                  message.success('Novedad Actualizada')
                  getNovedades()
                }).catch(error=>{
                  message.error("No se ha podido Actualizar")
                })
                
             }


             const selectFile = (e) =>{
              setFile(e.target.files[0])
            }


            const cargarArchivo = () =>{

              if(file){
                setFotoPerfil(null)
                setLoadingFoto(true)
                const storageRef = ref(storage, `/docentes/${docente.numId}/FOTO-${docente.numId}`)
                setModalFoto(false)
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                    const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    ); 
                    },
                    (err) => console.log(err),
                    () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                    let form = {}
                    form.docente_id = docente.id
                    form.url = url
                    console.log(form)
                    docenteService.foto(form).then(res=>{
                      console.log(res)
                      setFile(null)
              message.success("Foto Cargada")
              getDocente()
                      setLoadingFoto(false)
                    }).catch(error=>{
                      setLoadingFoto(false)
                    })
                    });
                    }
                    );
              } else{
                message.error("No existe el archivo")
              }
            }
    

    return (
        <MyLayout>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Institucional</Breadcrumb.Item>
          <Breadcrumb.Item>Docente </Breadcrumb.Item>
        </Breadcrumb>

        {docente && (
            <>
        <Row>
            <Col span={24}>
                <div className='info'>

                {!fotoPerfil && !loadingFoto && (
                 <Tooltip title='Click para cargar foto'> <div className='foto' onClick={viewCam}></div></Tooltip>
                )}

                {loadingFoto &&  (
                     <div className='foto' >
                     <Spin className="spin" size="small" />
                   </div>
                  )}


                {fotoPerfil &&  !loadingFoto && (
                  <Tooltip title='Click para cambiar foto'> <div  onClick={viewCam} ><img  className='myFoto' src={fotoPerfil} alt="" /></div></Tooltip>
                )}


                <div className='nombre'>{formatName(docente)}   
                <div className='numId'> {docente.tipoId} No. {docente.numId} </div>
                <div>
                  <Tooltip title="Cargar foto"><Button onClick={viewModalFoto}><UploadOutlined/></Button></Tooltip>
                   
                  </div>
                </div>
              
                </div>
            </Col>

            <Col span={24}>
            <Space direction="vertical" className='space'>
                <Collapse collapsible="header" defaultActiveKey={['1']}>
                    <Panel header="Información personal" key="1">
                     <Row>
                        <Col span={12} className="columna">
                            <div><strong>Nombres:</strong> {docente.primerNombre} {docente.segundoNombre}</div>
                            <div><strong>Identificación:</strong> {docente.numId} </div>
                            <div><strong>Teléfono:</strong> {docente.telefono} </div>
                            <div><strong>Correo electrónico:</strong> {docente.correo} </div>
                            <div><strong>Lugar de residencia:</strong> {docente.residencia} </div>
                            <div><strong>Fecha de nacimiento:</strong> {docente.fechaNacimiento} </div>
                            <div><strong>Tipo: </strong> {docente.rango} </div>
                        </Col>

                        <Col span={12} className="columna">
                        <div><strong>Apellidos:</strong> {docente.primerApellido} {docente.segundoApellido}</div>
                        <div><strong>Sexo:</strong> {docente.sexo} </div>
                        <div><strong>Celular:</strong> {docente.celular}</div>
                        <div><strong>Dirección:</strong> {docente.direccion} </div>
                        <div><strong>Barrio:</strong> {docente.barrio} </div>
                        <div><strong>Lugar de nacimiento:</strong> {docente.lugarNacimiento} </div>
                        <div><strong>Estado:</strong> {docente.estado} </div>
                        {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
                        <div> <Button type='primary' onClick={viewEditDocente}>Editar</Button></div>
                        )}
                        </Col>

                       
                     </Row>
                    </Panel>
                </Collapse>


                <Collapse collapsible="header" >
                    <Panel header="Asignación académica" key="2">
                     <Row>
                      

<Divider>Cursos</Divider>

            {/* <Button type='primary' onClick={()=>newMatricula("Curso")}>Matricular</Button> */}

            {cursosAsignados.length==0 && (
                       <Col span={24}>
                       <div className='messageNo'> No tiene cursos Matriculados </div> 
                      
                       </Col>
                       )}

                  {cursosAsignados.length>0 && (
                       <Col span={24}>
                        {cursosAsignados.map(item=>{
                          return <CursoDocente updateEstudiante={getDocente}>{item}</CursoDocente>
                        })}
                       </Col>
                       )} 

<Divider>Entrenamientos</Divider>
                   

                {/* <Button type='primary' onClick={()=>newMatricula("Entrenamiento")}>Matricular</Button> */}

                {entrenamientosAsignados.length==0 && (
                       <Col span={24}>
                     <div className='messageNo'>No tiene Entrenamientos Matriculados </div>  
                    
                       </Col>
                         )}

{entrenamientosAsignados.length>0 && (
                       <Col span={24}>
                        {entrenamientosAsignados.map(item=>{
                          return <CursoDocente updateEstudiante={getDocente} >{item}</CursoDocente>
                        })}
                       </Col>
                       )} 
                     </Row>
                    </Panel>
                </Collapse>

              <Collapse collapsible="header">
              <Panel header="Cursos/entrenamientos finalizados" key="3">
              <Row>
                      

                      <Divider>Cursos</Divider>
                      
                                  {/* <Button type='primary' onClick={()=>newMatricula("Curso")}>Matricular</Button> */}
                      
                                  {cursosFinalizados.length==0 && (
                                             <Col span={24}>
                                             <div className='messageNo'> No tiene cursos Finalizados </div> 
                                            
                                             </Col>
                                             )}
                      
                                        {cursosFinalizados.length>0 && (
                                             <Col span={24}>
                                              {cursosFinalizados.map(item=>{
                                                return <CursoDocente updateEstudiante={getDocente}>{item}</CursoDocente>
                                              })}
                                             </Col>
                                             )} 
                      
                      <Divider>Entrenamientos</Divider>
                                         
                      
                                      {/* <Button type='primary' onClick={()=>newMatricula("Entrenamiento")}>Matricular</Button> */}
                      
                                      {entrenamientosFinalizados.length==0 && (
                                             <Col span={24}>
                                           <div className='messageNo'>No tiene Entrenamientos Finalizados </div>  
                                          
                                             </Col>
                                               )}
                      
                      {entrenamientosFinalizados.length>0 && (
                                             <Col span={24}>
                                              {entrenamientosFinalizados.map(item=>{
                                                return <CursoDocente updateEstudiante={getDocente} >{item}</CursoDocente>
                                              })}
                                             </Col>
                                             )} 
                                           </Row>
                </Panel>
                </Collapse>

                <Collapse collapsible="header">
                <Panel header="Archivos del docente" key="4">

                {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
<Button type="primary" onClick={subirArchivo}>Subir Archivo</Button>
                )}

{archivos && (
  <Table dataSource={archivos} columns={columnsArchivo}></Table>
)}
</Panel>
</Collapse>



<Collapse><Panel header="Novedades" key="5">
{(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
<Button type="primary" onClick={viewNovedadModal}>Crear Novedad</Button>
                )}

                    <Table columns={novedadesColumns} dataSource={novedades}></Table>
                    </Panel></Collapse>


<Collapse><Panel header="Liquidaciones" key="6">
                    
                    </Panel></Collapse>
                
            </Space>
            </Col>
      
        </Row>
        </>
)}
      
      {viewWebcam && (
  <Modal visible={viewWebcam} onCancel={cancelCam} onOk={capturaFoto} okText="Capturar">
    <Webcam height={300} ref={webRef}/>
  </Modal>
)}


{viewModalEditDocente && (
  <DocenteEditModal visible={viewModalEditDocente}  updateVisible={setViewModalEditDocente}
  updateLoading={setLoading} updateDocente={getDocente}>{docente}</DocenteEditModal>
)}


{viewArchivoModal && (
            <ArchivoDocenteModal visible={viewArchivoModal}  updateLoading={setLoading}  updateVisible={setViewArchivoModal} updateDocente = {getFiles}>
              {myDocente}
            </ArchivoDocenteModal>
          )}

{viewModalNovedad && (
            <Modal visible={viewModalNovedad} title="Crear Novedad" okText="Crear Novedad" onCancel={()=>{setViewModalNovedad(false)}} onOk={formNovedad.submit} >
                 <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={saveNovedad}
form={formNovedad}
        >
          <Row>

          <Col span={24}>
              <Form.Item
                label="Tipo de Novedad: "
                name="tipo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de Novedad",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Agregar">Agregar</Select.Option>
                  <Select.Option value="Quitar">Quitar</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Fecha: "
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha",
                  },
                ]}
              >
                <Input  type='date'/>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="No. de Horas: "
                name="horas"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Horas",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Valor de hora: "
                name="valorHora"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Valor de horas",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Descripción de la novedad: "
                name="comentario"
                rules={[
                  {
                    required: true,
                    message: "Descripción de la novedad",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
            
          </Row>
        </Form>
            </Modal>
          )}


{modalFoto && (
          <Modal  visible={modalFoto} title="Cargar Archivo" onCancel={()=>setModalFoto(false)} onOk={cargarArchivo}>
              <Input type="file" accept="image/*"  onChange={(e)=>{selectFile(e)}}></Input>

          </Modal>
        )}

        {viewEditNovedad && (

          <Modal visible={viewEditNovedad} title="Editar Novedad" okText="Editar Novedad" onCancel={()=>{setViewEditNovedad(false)}} onOk={formEditNovedad.submit} >
         
         {! loading && (
           <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={editNovedad}
form={formEditNovedad}
        >
          <Row>

          <Col span={24}>
              <Form.Item
                label="Tipo de Novedad: "
                name="tipo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de Novedad",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Agregar">Agregar</Select.Option>
                  <Select.Option value="Quitar">Quitar</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Fecha: "
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha",
                  },
                ]}
              >
                <Input  type='date'/>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="No. de Horas: "
                name="horas"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Horas",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Valor de hora: "
                name="valorHora"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Valor de horas",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Descripción de la novedad: "
                name="comentario"
                rules={[
                  {
                    required: true,
                    message: "Descripción de la novedad",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
            
          </Row>
        </Form>
        )}

        {loading && (
           <div className="spin_container">
           <Spin className="spin" size="large" />
         </div>
        )}
          </Modal>
        )}

        </MyLayout>
    );
};

export default VerDocente;