import http from "../utils/http-common";

class CategoriaBVService {
    getAll() {
      return http.get("/categoriabv");
    }
    get(id) {
      return http.get(`/categoriabv/${id}`);
    }

    getForain(id) {
      return http.get(`/categoriabv/forain/${id}`);
    }

    create(data) {
      return http.post("/categoriabv", data);
    }
    update(id, data) {
      return http.put(`/categoriabv/${id}`, data);
    }
    delete(id) {
      return http.delete(`/categoriabv/${id}`);
    }
   
  }
  export default new CategoriaBVService();