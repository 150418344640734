import http from "../utils/http-common";

class SubparametroService {
    getAll() {
      return http.get("/subparametro");
    }
    get(id) {
      return http.get(`/subparametro/${id}`);
    }


    getForain(id) {
      return http.get(`/subparametro/forain/${id}`);
    }

    create(data) {
      return http.post("/subparametro", data);
    }
    update(id, data) {
      return http.put(`/subparametro/${id}`, data);
    }
    delete(id) {
      return http.delete(`/subparametro/${id}`);
    }
   
  }
  export default new SubparametroService();