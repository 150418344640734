import React, {useState, useEffect} from 'react';

import { Breadcrumb, Table, Button, Spin, Input, Modal, message } from "antd";
import MyLayout from "../../../components/MyLayout";
import { Content } from "antd/lib/layout/layout";
import cursoService from "../../../services/curso.service";
import moment from 'moment';
import matriculaService from "../../../services/matricula.service";
import {EditOutlined,EyeInvisibleOutlined,DeleteOutlined,CheckCircleOutlined,CalendarOutlined, SearchOutlined} from '@ant-design/icons';
import { useAuth } from '../../../context/auth.context';
const MatriculaEditModal = (props) => {
    const [cursos, setCursos] = useState([]);
    const [loading, setLoading] = useState(false);
    const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;

    const matricula_id = props.matricula_id
   

    const columns = [

        {
            title: "Codigo",
            dataIndex: "id",
            key: "id",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Fecha",
            render: (record) => {
                let myFechaInicial = moment(record.fechaInicial).format("DD/MM/YYYY"); 
                let myFechaFinal = moment(record.fechaFinal).format("DD/MM/YYYY "); 
                return ( <div><div>Desde: {myFechaInicial}</div> <div>Hasta: {myFechaFinal}</div></div> )
            }
          },


          {
            title: "Curso/Nivel",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Curso' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.nivel + " " + record.ciclo
              return nombre.toLowerCase().includes(value.toLowerCase())
            },

            render: (record) => 
            <>
           <div>
            <div>{record.curso} -</div>
            <div>{record.nivel} -</div>
            <div>{record.ciclo} </div>
            </div>
            {record.estado == "Activo" && (
             <div className='finaliza'>Finaliza  {moment(record.fechaFinal).endOf('day').fromNow()}</div>
             )
            }

          {record.estado == "Finalizado" && (
             <div className='finaliza'>Finalizó  {moment(record.fechaFinal).endOf('day').fromNow()}</div>
             )
            }
           
            </>
          },

          {
            title: "Jornada",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Jornada' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.jornada
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            
            key: "jornada",
            render: (record) => <div>{record.jornada}</div>
          },


          {
            title: "Docente",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Nombre de docente' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.Docente.primerNombre + " " + record.Docente.primerApellido
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            render: (record) => <div>{record.Docente.primerNombre} {record.Docente.primerApellido} </div>,
          },
        

          {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            // render: (text) => <a>{text}</a>,
          },
       
         

          {
            title: "Opciones",
           
            key: "action",
            render: (record) => <Button type='primary' onClick={()=>{trasladar(record)}}>Trasladar</Button>,
          },
      ];


    useEffect(() => {
        listCursos()
        
      }, []);


      const listCursos = () => {
        setLoading(true);
        cursoService
          .getForain(empresa_id)
          .then((response) => {
            let filterCursos = response.data;
            console.log(response.data);
         
            filterCursos = response.data.filter(
              (item) => item.estado == "Activo" 
            );
            setCursos(filterCursos);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const handleCancel = () => {
        props.updateVisible(false);
      };

      const trasladar = (curso) =>{
         props.updateLoading(true);
         setLoading(true)
        const form = {}
        form.curso_id = curso.id 
        form.matricula_id = matricula_id
        form.empresa_id = empresa_id;
        form.usuario_id = usuario_id;
        console.log(form)
        matriculaService
          .traslado(form)
          .then((response) => {
            console.log(response.data);
            props.updateLoading(false);
            props.updateVisible(false);
            setLoading(false)
            props.updateCurso();
            message.success("Guardado Correctamente");
          })
          .catch((e) => {
            props.updateLoading(false);
            setLoading(false)
            message.error("No se ha podido guardar");
            console.log(e);
          });
      }

    return (
        <Modal width={1024} visible={props.visible} onOk={handleCancel} onCancel={handleCancel} >
          {loading && (
             <div className="spin_container">
             <Spin className="spin" size="large" />
           </div>
          )}

          {!loading && (
        <Content>
            <h2>Cambiar de curso</h2>
             <Table
        columns={columns}
        dataSource={cursos}
        pagination={{ pageSize: 4 }}
      />
        </Content>
        )}
        </Modal>
    );
};

export default MatriculaEditModal;