import React from 'react';

const Embudo = (props) => {

    const selectEmbudo = (item) =>{
       
        props.selectEmbudo(item)
    }

    return (
        <div className='embudo' onClick={()=>selectEmbudo(props.embudo)} >
           {props.embudo.nombre} 
           </div>
    );
};

export default Embudo;