import React, {useState} from 'react';
import MyLayout from '../../components/MyLayout';
import {Input,Button,message, Spin, Card, Row,Col } from 'antd'
import cargaService from '../../services/carga.service'
const Carga = () => {
    const [file,setFile] = useState()
    const [loading,setLoading] = useState(false)

    const selectFile = (file) =>{
        let fileList = file.target.files
        setFile(fileList[0])
    }

    const uploadEstudiantes = () =>{
        setLoading(true)
        console.log(file)
        if(file){
        let form = new FormData()
        form.append('file',file)

        cargaService.create(form,'estudiante').then(res=>{
                console.log(res.data)
                message.success("cargado Correctamente");
                setLoading(false)
        }).cath(error=>{
            message.error(error)
            setLoading(false)
        })
    } else{
        console.log('No existe el archivo')
    }
    }

    const uploadDocentes = () =>{
        setLoading(true)
        console.log(file)
        if(file){
        let form = new FormData()
        form.append('file',file)

        cargaService.create(form,'docente').then(res=>{
                console.log(res.data)
                message.success("cargado Correctamente");
                setLoading(false)
        }).cath(error=>{
            message.error(error)
            setLoading(false)
        })
    } else{
        console.log('No existe el archivo')
    }
    }

    const uploadCursos = () =>{
        setLoading(true)
        console.log(file)
        if(file){
        let form = new FormData()
        form.append('file',file)

        cargaService.create(form,'curso').then(res=>{
                console.log(res.data)
                message.success("cargado Correctamente");
                setLoading(false)
        }).cath(error=>{
            message.error(error)
            setLoading(false)
        })
    } else{
        console.log('No existe el archivo')
    }
    }

    const uploadCreditos = () =>{
        setLoading(true)
        console.log(file)
        if(file){
        let form = new FormData()
        form.append('file',file)

        cargaService.create(form,'creditos').then(res=>{
                console.log(res.data)
                message.success("cargado Correctamente");
                setLoading(false)
        }).cath(error=>{
            message.error(error)
            setLoading(false)
        })
    } else{
        console.log('No existe el archivo')
    }
    }

    const uploadPagos = () =>{
        setLoading(true)
        console.log(file)
        if(file){
        let form = new FormData()
        form.append('file',file)

        cargaService.create(form,'pagos').then(res=>{
                console.log(res.data)
                message.success("cargado Correctamente");
                setLoading(false)
        }).cath(error=>{
            message.error(error)
            setLoading(false)
        })
    } else{
        console.log('No existe el archivo')
    }
    }

    const matriculas = () =>{
        setLoading(true)
        console.log(file)
        if(file){
        let form = new FormData()
        form.append('file',file)

        cargaService.create(form,'matriculasq10').then(res=>{
                console.log(res.data)
                message.success("cargado Correctamente");
                setLoading(false)
        }).cath(error=>{
            message.error(error)
            setLoading(false)
        })
    } else{
        console.log('No existe el archivo')
    }
    }

    const evaluaciones = () =>{
        setLoading(true)
        console.log(file)
        if(file){
        let form = new FormData()
        form.append('file',file)

        cargaService.create(form,'evaluacionesq10').then(res=>{
                console.log(res.data)
                message.success("cargado Correctamente");
                setLoading(false)
        }).cath(error=>{
            message.error(error)
            setLoading(false)
        })
    } else{
        console.log('No existe el archivo')
    }
    }


    if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
    return (
        <MyLayout>
            <Card className='centrar'>
            <h3>Estudiantes</h3>
            <Row>
            <Col span={12}>
               
           <Input type="file" onChange={selectFile}></Input> 
           </Col>

           <Col span={12}>
            <Button type='primary' onClick={uploadEstudiantes}>Cargar</Button>
           </Col>
           </Row>

           </Card>

           <Card className='centrar'>
            <h3>Docentes</h3>
            <Row>
            <Col span={12}>
            
           <Input  type="file" onChange={selectFile}></Input> 
           </Col>

           <Col span={12}>
            <Button type='primary' onClick={uploadDocentes}>Cargar</Button>
           </Col>
           </Row>
           </Card>

           <Card className='centrar'>
            <h3>Cursos</h3>
            <Row>
            <Col span={12}>
            
           <Input type="file" onChange={selectFile}></Input> 
           </Col>

           <Col span={12}>
            <Button type='primary' onClick={uploadCursos}>Cargar</Button>
           </Col>
           </Row>
           </Card>

           <Card className='centrar'>
            <h3>Creditos</h3>
            <Row>
            <Col span={12}>
            
           <Input type="file" onChange={selectFile}></Input> 
           </Col>

           <Col span={12}>
            <Button type='primary' onClick={uploadCreditos}>Cargar</Button>
           </Col>
           </Row>
           </Card>

           <Card className='centrar'>
            <h3>Pagos</h3>
            <Row>
            <Col span={12}>
            
           <Input type="file" onChange={selectFile}></Input> 
           </Col>

           <Col span={12}>
            <Button type='primary' onClick={uploadPagos}>Cargar</Button>
           </Col>
           </Row>
           </Card>

           <Card className='centrar'>
            <h3>Matriculas</h3>
            <Row>
            <Col span={12}>
            
           <Input type="file" onChange={selectFile}></Input> 
           </Col>

           <Col span={12}>
            <Button type='primary' onClick={matriculas}>Cargar</Button>
           </Col>
           </Row>
           </Card>

           <Card className='centrar'>
            <h3>Evaluaciones</h3>
            <Row>
            <Col span={12}>
            
           <Input type="file" onChange={selectFile}></Input> 
           </Col>

           <Col span={12}>
            <Button type='primary' onClick={evaluaciones}>Cargar</Button>
           </Col>
           </Row>
           </Card>
        </MyLayout>
    );
};

export default Carga;