import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  TimePicker,
} from "antd";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";
import examenService from "../../services/examen.service";
import { useAuth } from '../../context/auth.context';
const ExamenModal = (props) => {
    const examenes = [{nombre:'OOPT'},{nombre:'APTIS'},{nombre:'IELTS'},{nombre:'TOEFL'}]
    const tiposExamen = [{nombre:'APTIS',tipo:'General'},{nombre:'APTIS',tipo:'Teachers'},{nombre:'APTIS',tipo:'Advanced'},
    ,{nombre:'IELTS',tipo:'General'},{nombre:'IELTS',tipo:'Académico'}]
    const modosExamen = [{nombre:'General',modo:'Presencial'},{nombre:'General',modo:'Remoto'},
    {nombre:'Teachers',modo:'Presencial'},{nombre:'Advanced',modo:'Presencial'},{nombre:'Académico',modo:'Presencial'}]

    const [loading, setLoading] = useState(false);
    const [tipos,setTipos] = useState([])
    const [modos,setModos] = useState([])
  const [form] = Form.useForm();
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  
  useEffect(() => {
    form.resetFields()
  }, []);


  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    console.log(values)
   
    const form = values;
    form.empresa_id = empresa_id
    form.usuario_id = usuario_id
   // form.hora = moment(values.hora._d).format('HH:mm:ss')
    console.log(form)
    props.updateLoading(true);
    console.log("ENVIAR",form)
    examenService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListExamenes();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  const validarExamen = (value)=>{
    let filtroTipo = tiposExamen.filter(item=>item.nombre == value)
    if(value == 'OOPT' || value == 'TOEFL'){
    //  form.setFieldsValue({tipo:null})
    //  form.setFieldsValue({tipo:'Normal',modo:'Presencial'})
      setTipos([])
    }else{
    //  form.setFieldsValue({tipo:null})
      form.setFieldsValue({modo:null})
      setTipos(filtroTipo)
    }
    
    
  }

  const validarTipo = (value)=>{
    let filtroModo = modosExamen.filter(item=>item.nombre == value)
    setModos(filtroModo)
  }

    return (
        <Modal width={1024} visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
        <Content>
        
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row>
  
           
  
              <Col span={8}>
                <Form.Item
                  label="Exámen: "
                  name="nombre"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Exámen",
                    },
                  ]}
                >
                 <Select onChange={validarExamen}>
                  {examenes.map((item=>{
                    return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                  }))}
                 </Select>
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item
                  label="Tipo: "
                  name="tipo"
                  rules={[
                    {
                      required: false,
                      message: "Por favor ingrese Tipo",
                    },
                  ]}
                >
                 <Select onChange={validarTipo}>
                  {tipos.map((item=>{
                    return <Select.Option value={item.tipo}>{item.tipo}</Select.Option>
                  }))}
                 </Select>
                </Form.Item>
              </Col> */}

              {/* <Col span={8}>
                <Form.Item
                  label="Modo: "
                  name="modo"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Modo",
                    },
                  ]}
                >
                 <Select>
                  {modos.map((item=>{
                    return <Select.Option value={item.modo}>{item.modo}</Select.Option>
                  }))}
                 </Select>
                </Form.Item>
              </Col> */}

              {/* <Col span={8}>
                <Form.Item
                  label="Fecha de Presentación: "
                  name="fecha"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha",
                    },
                  ]}
                >
                 <Input type="date"></Input>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Hora de Presentación: "
                  name="hora"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Hora",
                    },
                  ]}
                >
                 <TimePicker use12Hours format="h:mm A" placeholder="Hora de Presentación"></TimePicker>
                </Form.Item>
              </Col> */}

            </Row>
          </Form>
        </Content>
      </Modal>
    );
};

export default ExamenModal;