import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import docenteService from "../../services/docente.service";
import rangoDocenteService from "../../services/rango_docente.service";
import { useAuth } from '../../context/auth.context';
const DocenteModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [rangos, setRangos] = useState([]);
  const [form] = Form.useForm();
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  
  useEffect(() => {
    form.resetFields()
    listRangoDocente()
  }, []);

  const listRangoDocente = () => {
    setLoading(true);
    rangoDocenteService
      .getForain(empresa_id)
      .then((response) => {
        let filterRango = response.data;
        console.log(response.data);
        // filterMedios = response.data.filter(
        //   (item) => item.empresa_id == empresa_id
        // );
        setRangos(filterRango);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };


  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.empresa_id = empresa_id;
    form.usuario_id = usuario_id;
   
    docenteService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListDocente();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  return (
    <Modal width={1024} visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
      
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>

         

            <Col span={8}>
              <Form.Item
                label="Primer Nombre: "
                name="primerNombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Primer Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                label="Segundo Nombre: "
                name="segundoNombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Segundo Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Primer Apellido: "
                name="primerApellido"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Primer Apellido",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Segundo Apellido: "
                name="segundoApellido"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Segundo Apellido",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Tipo Id: "
                name="tipoId"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de ID",
                  },
                ]}
              >
                <Select>
                    <Select.Option value="Cédula de ciudadanía">Cédula de ciudadanía</Select.Option>
                    <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                    <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                    <Select.Option value="Registro civil de nacimiento">Registro civil de nacimiento</Select.Option>
                    <Select.Option value="DNI">DNI</Select.Option>
                    <Select.Option value="Pasaporte">Pasaporte</Select.Option>
                    <Select.Option value="NUIP">NUIP</Select.Option>
                    
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="No. ID: "
                name="numId"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese No. de ID",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Sexo: "
                name="sexo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Sexo",
                  },
                ]}
              >
                <Select>
                    <Select.Option value="Masculino">Masculino</Select.Option>
                    <Select.Option value="Femenino">Femenino</Select.Option>
                </Select>
              </Form.Item>
            </Col>
        
            <Col span={8}>
              <Form.Item
                label="Correo: "
                name="correo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Correo",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Teléfono: "
                name="telefono"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Teléfono",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Celular: "
                name="celular"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Celular",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Fecha de Nacimiento: "
                name="fechaNacimiento"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha de Nacimiento",
                  },
                ]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Lugar de Nacimiento "
                name="lugarNacimiento"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Lugar de Nacimiento",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Dirección "
                name="direccion"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Dirección",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Lugar de residencia "
                name="residencia"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese lugar de residencia",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Barrio "
                name="barrio"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese barrio",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Rango "
                name="rango"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese rango",
                  },
                ]}
              >
                <Select>
                  {rangos.map((item=>{
                    return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                  }))}
                </Select>
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default DocenteModal;
