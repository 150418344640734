import http from "../utils/http-common";

class EvaluacionEstudianteService {
    getAll() {
      return http.get("/evaluacion/estudiante");
    }
    get(id) {
      return http.get(`/evaluacion/estudiante/${id}`);
    }

    getForain(id) {
      return http.get(`/evaluacion/estudiante/forain/${id}`);
    }

    getHistorial(id) {
      return http.get(`/evaluacion/estudiante/historial/${id}`);
    }

    create(data) {
      return http.post("/evaluacion/estudiante", data);
    }
    update(id, data) {
      return http.put(`/evaluacion/estudiante/${id}`, data);
    }
    delete(id) {
      return http.delete(`/evaluacion/estudiante/${id}`);
    }
   
  }
  export default new EvaluacionEstudianteService();