import http from "../utils/http-common";

class LeadService {
    getAll() {
      return http.get("/leads");
    }
    get(id) {
      return http.get(`/leads/${id}`);
    }

    getForain(id) {
        return http.get(`/leads/forain/${id}`);
      }
    create(data) {
      return http.post("/leads", data);
    }
    update(id, data) {
      return http.put(`/leads/${id}`, data);
    }
    delete(id) {
      return http.delete(`/leads/${id}`);
    }

    getStatus(query) {
      return http.post("/leads/status", query);
    }

    dynamicPost(data,url) {
      return http.post("/leads/"+url, data);
    }
   
  }
  export default new LeadService();