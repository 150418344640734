import React, {useState,useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom'
import estudianteService from '../../services/estudiante.service';
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Col, Row, Collapse, Space, Button, Divider,Table, Tooltip, Modal, Spin, Badge, message, Input,Form, Select } from "antd";
import moment from 'moment';
import MatriculaModal from '../../components/modals/MatriculaModal';
import "./VerEstudiante.css"
import CursoEstudiante from '../../components/CursoEstudiante';
import obligacionesService from '../../services/obligaciones.service';
import {  DollarOutlined, OrderedListOutlined, EditOutlined,FilePdfOutlined, MailOutlined, UploadOutlined} from '@ant-design/icons';
import NewPagoModal from '../../components/modals/NewPagoModal';
import { NumericFormat } from 'react-number-format';
import ListPagoModal from '../../components/modals/ListPagoModal';
import Webcam from 'react-webcam'
import storage from "../../services/firebase"
import {ref, uploadBytesResumable,getDownloadURL} from "firebase/storage"
import EstudianteEditModal from '../../components/modals/Edit/EstudianteEditModal';
import ObligacionEditModal from '../../components/modals/Edit/ObligacionEditModal';
import ArchivoEstudianteModal from '../../components/modals/ArchivoEstudianteModal';
import archivo_estudianteService from '../../services/archivo_estudiante.service';
import matriculasq10Service from '../../services/matriculaq10.service'
import Ciclosq10Modal from '../../components/modals/Ciclosq10Modal';
import FichaEstudiantePDF from '../PDF/FichaEstudiantePDF'
import { PDFViewer } from '@react-pdf/renderer';
import { useNavigate } from "react-router-dom";
import EstudianteEditAdicionalModal from '../../components/modals/Edit/EstudianteEditAdicionalModal';
import { useAuth } from '../../context/auth.context';
import DocPDF from '../../pages/PDF/DocPDF';
import saldoService from "../../services/saldo.service"
import NewPagoSaldoModal from '../../components/modals/NewPagoSaldoModal';
import {DeleteOutlined} from '@ant-design/icons';
const { Panel } = Collapse;
const {confirm} = Modal;

const VerEstudiante = () => {
    let location = useLocation();
    let myEstudiante = location.state.estudiante
    const [estudiante,setEstudiante] = useState(null);
    const [cursosMatriculados,setCursosMatriculados] = useState([]);
    const [entrenamientosMatriculados,setEntrenamientosMatriculados] = useState([]);
    const [viewNewMatricula, setViewMatricula] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingFoto, setLoadingFoto] = useState(false);
    const [tipoCurso, setTipoCurso] = useState();
    const [obligaciones,setObligaciones] = useState([]);
    const [anulaciones,setAnulaciones] = useState([]);
    const [viewNewPago, setViewNewPago] = useState(false);
    const [viewNewPagoSaldo, setViewNewPagoSaldo] = useState(false);
    const [obligacionSelected, setObligacionSelected] = useState(false);
    const [viewListaPagos, setViewListaPagos] = useState(false);
    const [viewEditObligacion, setViewEditObligacion] = useState(false);
    const [viewWebcam, setViewWebcam] = useState(false);
    const webRef = useRef(null)
    const [fotoPerfil,setFotoPerfil] = useState();
    const [viewModalEditEstudiante, setViewModalEditEstudiante] = useState(false);
    const [archivos,setArchivos] = useState([]);
    const [viewArchivoModal,setViewArchivoModal] = useState(false);
    const [matriculasq10,setMatriculasq10] = useState([]);
    const [ciclosQ10,setCiclosQ10] = useState([]);
    const [viewModalCiclos,setViewModalCiclos] = useState(false);
    const [viewFicha,setViewFicha] = useState(false)
    const [viewModalEditEstudianteAdicional, setViewModalEditEstudianteAdicional] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [viewPDF, setViewPDF] = useState(false);
    const {user,role} = useAuth()
    const [obligacion,setObligacion] = useState([]);
    const [pagoSelected,setPagoSelected] = useState([]);
    const navigate = useNavigate();
    const [modalFoto,setModalFoto] = useState(false);
    const [file,setFile] = useState()
    const [saldosFavor,setSaldosFavor] = useState([]);
    const [saldosTotal,setSaldoTotal] = useState(0);
    const [viewModalSaldo,setViewModalSaldo] = useState(false);
    const [formNewSaldo] = Form.useForm()
    const columns = [
      {
      title: "Codigo",
      key: 'Id',
      render: (record) => <div>OF-{record.rta.codigo_pagare}</div>
    },

    {
      title: "Periodo",
      render: (record) => <div>{record.rta.periodo}</div>
    },

    {
      title: "Cuotas",
      render: (record) => <div>{record.rta.cuotas}</div>
    },

    {
      title: "Concepto",
      render: (record) =>
      { if(record.rta.concepto == 'MATRICULA' && record.rta.inscripcion == "Si"){
       return <div>
        <div> <span className='tipo'>Inscripción</span> {record.data.Curso.curso} - {record.data.Curso.nivel} - {record.data.Curso.ciclo} </div>
       </div>
       }
       if(record.rta.concepto == 'MATRICULA' && record.rta.inscripcion == "No"){
        return <div>
         <div> <span className='tipo'>Matricula</span>  {record.data.Curso.curso} - {record.data.Curso.nivel} - {record.data.Curso.ciclo} </div>
        </div>
        }
       
       if(record.rta.concepto == 'MATRICULA EXAMEN'){
        return <div>
        <div> <span className='tipo'>Exámen</span> {record.data.Examen.nombre} {record.data.Examen.tipo} {record.data.Examen.modo}   </div>
       </div>
       }
       if(record.rta.concepto == 'TUTORIA'){
        return <div>
        <div> <span className='tipo'>Tutoría</span>  {record.data.tema} del { moment(record.data.fecha).format("DD/MM/YYYY") }  </div>
       </div>
       }
       
       if(record.rta.concepto != 'TUTORIA' && record.rta.concepto != 'MATRICULA EXAMEN' && record.rta.concepto != 'MATRICULA'){
        return <div>{record.rta.concepto}</div>
       }
       }
    },

    {
      title: "Valor",
       render: (record) => <div>$ <NumericFormat value={record.rta.valor} displayType="text" allowLeadingZeros thousandSeparator="," /> {record.rta.moneda}</div>,
    },

    {
      title: "Total Pagos",
       render: (record) => <div>$ <NumericFormat value={record.rta.valor - record.saldo} displayType="text" allowLeadingZeros thousandSeparator="," /> </div>,
    },

    {
      title: "Saldo",
      render: (record) => <div>$ <NumericFormat value={record.saldo} displayType="text" allowLeadingZeros thousandSeparator="," /> {record.rta.moneda}</div>,
    },

    

    {
      title: "Fecha",

      render: (record) => {let myFecha = moment(record.rta.fecha).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
    },

    {
      title: "Estado",
      render: (record) => <div className={record.rta.estado == "Activo" ? "activo" : "inactivo"}><Tooltip title={record.rta.observaciones}>{record.rta.estado}</Tooltip></div>
    },

    {
      title: "Opciones",
     
      key: "action",
       render: (record) => <>
      
       <Tooltip title="Lista de Pagos"><Button onClick={()=>{listaPagos(record)}}><OrderedListOutlined /></Button> </Tooltip>
       {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
       <Tooltip title="Editar Obligación"><Button onClick={()=>{editObligacion(record)}}><EditOutlined /></Button> </Tooltip>
       )}
       </>
        
    },

  ]

  const columnsArchivo = [
    {
      title: "Tipo de archivo",
      render: (record) => <div>{record.descripcion}</div>,
    },


    {
      title: "Estado",
      render: (record) => <div>{record.estado}</div>,
    },

    {
      title: "Descargar Aqui",
      render: (record) => <Button onClick={()=>{descargar(record.url)}}>Descargar </Button>,
    },

    {
      title: "Fecha de subida",

      render: (record) => {let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
    },
  ]

  const columnsQ10 = [
    {
      title: "Nivel",
      render: (record) => <div>{record.nivel}</div>,
    },


    {
      title: "Jornada",
      render: (record) => <div>{record.jornada}</div>,
    },


    {
      title: "Periodo",
      render: (record) => <div>{record.periodo}</div>,
    },

    {
      title: "Estado",
      render: (record) => <div>{record.estado}</div>,
    },



    {
      title: "Fecha matricula",

      render: (record) => {let myFecha = moment(record.fecha_matricula).format("DD/MM/YYYY"); return ( <div>{myFecha}</div> )}
    },

    // {
    //   title: "Opciones",
    //   render: (record) => <div><Button onClick={()=>verCiclos(record)}>Ver Ciclos</Button></div>,
    // },
  ]

  const columnsSaldos = [
    {
      title: "Codigo",
      key: 'Id',
      render: (record) => 
      <>
      {record.tipo == "INGRESO" && (
          <div>SF-{record.codigo}</div>
      )}
      </>
    
    },
    {
      title: "Tipo",
      key: 'tipo',
      render: (record) => <div>{record.tipo}</div>
    },
    {
      title: "Valor",
      key: 'valor',
      render: (record) => <div>$ <NumericFormat value={record.valor} displayType="text" allowLeadingZeros thousandSeparator="," /></div>
    },

    {
      title: "Concepto",
      key: 'concepto',
      render: (record) => <div>{record.concepto}</div>
    },

    {
      title: "Observaciones",
      key: 'Id',
      render: (record) => <div>{record.observaciones}</div>
    },

    {
      title: "Fecha",

      render: (record) => {let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
    },

    {
      title: "Opciones",
      
      render: (record) => <>
      {role == "Superadministrador" && (
        <div><Button type='danger' onClick={()=>{deleteSaldo(record)}}><DeleteOutlined ></DeleteOutlined></Button></div>
      )}
      { record.Pago && record.tipo == "INGRESO" && (
        <div><Button type='primary' onClick={()=>{verReciboAFavor(record)}}><FilePdfOutlined ></FilePdfOutlined></Button></div>
        )}
    </>
    },

  ]

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
     let obligacionesReales = []
     console.log("Obligaciones",obligaciones)
    
    for(let item of newSelectedRowKeys){
      let filtro = obligaciones.filter(ob=>ob.rta.codigo_pagare == item)
      if(filtro[0].saldo>0){
        obligacionesReales.push(filtro[0].rta.codigo_pagare)
      }
      
     }

     console.log("OBLIGACIONES REALES",obligacionesReales)
    setSelectedRowKeys(obligacionesReales);
  };

  const verReciboAFavor = (record) =>{
    if(record.Pago){
      setPagoSelected(record.Pago)
      setViewPDF(true)
    }
   
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

    useEffect(() => {
        getEstudiante()
        getObligaciones()
        getFiles()
        getMatriculasq10()
        getSaldos()
      }, []);


      const viewModalFicha = () =>{
        setViewFicha(true)
      }


      const verCiclos = (record) =>{
        setCiclosQ10(record.Evaluacionq10s)
        setViewModalCiclos(true)
      }


      const subirArchivo = () =>{
        setViewArchivoModal(true)
      }

      const getFiles = () =>{
        archivo_estudianteService.get(myEstudiante.id).then(res=>{
          setArchivos(res.data)
        })
      }

      const getMatriculasq10 = () =>{
        matriculasq10Service.get(myEstudiante.id).then(res=>{
          console.log(res.data)
          setMatriculasq10(res.data)
        })
      }

      const deleteSaldo = (record) =>{
        confirm({
          title:"Desea Borrar este registro?",
          okText:"Si",
          okType:'danger',
          cancelText:"No",
          onOk(){
           saldoService.delete(record.id).then(res=>{
            console.log(res.data)
            message.success("Registro Eliminado")
            getSaldos()
           }).catch(error=>{
            console.log(error)
            message.success("No se pudo eliminar el registro")
           })
            
          },onCancel(){
            
          }})
      }

      const descargar = (url) =>{
        window.open(url)
      }

    const getEstudiante = () => {
        setLoading(true);
        estudianteService
          .get(myEstudiante.id)
          .then((response) => {
            let filterEstudiante = response.data[0];
            setFotoPerfil(filterEstudiante.url)
            setEstudiante(filterEstudiante);
            let cursos = [];
              let entrenamientos=[];
            if(filterEstudiante.Matriculas.length>0){
             
              for(let item of filterEstudiante.Matriculas){
                if(item.Curso.modo == "Curso"){
                  cursos.push(item)
                }else{
                  entrenamientos.push(item)
                }
              }
            }
            setCursosMatriculados(cursos)
            setEntrenamientosMatriculados(entrenamientos)
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const getObligaciones = () =>{
        setLoading(true)
        obligacionesService.getType('Estudiante',myEstudiante.id).then((response) => {
          let obligaciones = response.data.filter(item=>item.rta.estado == "Activo");
          let anulaciones = response.data.filter(item=>item.rta.estado == "Anulado");
          console.log(obligaciones)
         
          setObligaciones(obligaciones)
          setAnulaciones(anulaciones)
          getSaldos();
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
      }

      const getSaldos = () =>{
        setLoading(true)
        saldoService.get(myEstudiante.id).then((response) => {
         
          console.log("SALDOS",response.data)
        setSaldosFavor(response.data[0])
        setSaldoTotal(response.data[1])
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
      }

      const calcularEdad = (fecha,fecha_nac) =>{
        console.log(fecha.diff(fecha_nac, 'years'))
        return  fecha.diff(fecha_nac, 'years');
      }

      const viewModalFoto = () =>{
   setModalFoto(true)
      }

      const newMatricula = (tipo) => {
        setViewMatricula(true);
        setTipoCurso(tipo)
      };

      const newPago = () =>{
       
        setViewNewPago(true)
        //setObligacionSelected(record.rta)
      }

      const newPagoSaldoFavor = () =>{
       
        setViewNewPagoSaldo(true)
        //setObligacionSelected(record.rta)
      }

      const listaPagos = (record)=>{
        setObligacionSelected(record.rta)
        setViewListaPagos(true)
      }

      const editObligacion = (record)=>{
        setObligacionSelected(record.rta)
        setViewEditObligacion(true)
      }
      
      const viewCam = () =>{
        setViewWebcam(true)
      }

      const cancelCam = () =>{
        setViewWebcam(false)
      }


      const formatName = (record)=>{
        let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
        nombre = nombre.toUpperCase()
        return nombre
      }

      const capturaFoto = async () =>{
        setLoadingFoto(true)
        let img = webRef.current.getScreenshot()
        //img = img.replace("data:image/webp;base64,", "");
     
        
        setViewWebcam(false)
   
        let file = await base64ToBlob(img);

        const storageRef = ref(storage, `/estudiantes/${estudiante.numId}/FOTO-${estudiante.numId}`)

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            ); 
            },
            (err) => console.log(err),
            () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            let form = {}
            form.estudiante_id = myEstudiante.id
            form.url = url
            console.log(form)
            estudianteService.foto(form).then(res=>{
              console.log(res)
              setFotoPerfil(img)
              setLoadingFoto(false)
              message.success("Foto Cargada")
            }).catch(error=>{
              setLoadingFoto(false)
            })
            });
            }
            );

        }
      

         async function base64ToBlob(url) {
          let res = await fetch(url);
          let blob = await res?.blob({type:'image/jpeg'});
          return blob;
        }

        const viewEditEstudiante = () =>{
          setViewModalEditEstudiante(true)
        }

        const viewEditEstudianteAdicional = () =>{
          setViewModalEditEstudianteAdicional(true)
        }

        const irHistorialAcademico = (record)=>{
          navigate("/historial-academico",{ state: { estudiante: record } });
        }

        const sendEstadoCuenta = () =>{
        
          confirm({
            title:"Desea enviar estado de cuenta al correo electrónico?",
            okText:"Si",
            okType:'danger',
            cancelText:"No",
            onOk(){
              setLoading(true)
              obligacionesService.sendMail(myEstudiante.id)
                .then((response) => {
                  console.log(response)
                  message.success("Correo Enviado Correctamente")
                  setLoading(false)
                })
                .catch((e) => {
                  console.log(e);
                  setLoading(false)
                  message.error("No se ha podido enviar el correo")
                });
    
            }})
        }


        const selectFile = (e) =>{
          setFile(e.target.files[0])
        }

        const openModalSaldo = () =>{
          setViewModalSaldo(true)
        }

        const guardarSaldo = (values) =>{
          
          let form = values
          form.usuario_id = user.id
          form.estudiante_id = myEstudiante.id
          form.tipo = "INGRESO"
          console.log(form)
          saldoService.create(form).then(response=>{
            console.log(response.data)
            message.success("Guardado Correctamente")
            setViewModalSaldo(false)
            getSaldos()
          }).catch(error=>{
            console.log(error)
            message.error("Ha ocurrido un error")
          })
        }

        const cargarArchivo = () =>{

          if(file){
            setFotoPerfil(null)
            setLoadingFoto(true)
            const storageRef = ref(storage, `/estudiantes/${estudiante.numId}/FOTO-${estudiante.numId}`)
            setModalFoto(false)
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            ); 
            },
            (err) => console.log(err),
            () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            let form = {}
            form.estudiante_id = myEstudiante.id
            form.url = url
            console.log(form)
            estudianteService.foto(form).then(res=>{
              console.log(res)
              setFile(null)
              message.success("Foto Cargada")
              getEstudiante()
           
              setLoadingFoto(false)
            }).catch(error=>{
              setLoadingFoto(false)
            })
            });
            }
            );
          } else{
            message.error("No existe el archivo")
          }
        }

       

     
        // if (loading)
        // return (
        //   <div className="spin_container">
        //     <Spin className="spin" size="large" />
        //   </div>
        // );
    return (
        <MyLayout>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Institucional</Breadcrumb.Item>
          <Breadcrumb.Item>Estudiante </Breadcrumb.Item>
        </Breadcrumb>

        {estudiante && (
            <>
        <Row>
            <Col span={24}>
                <div className='info'>

                {!fotoPerfil && !loadingFoto && (
                 <Tooltip title='Click para cargar foto'> <div className='foto' onClick={viewCam}></div></Tooltip>
                )}

                
                {loadingFoto && (
                     <div className='foto' >
                     <Spin className="spin" size="small" />
                   </div>
                  )}

                {fotoPerfil && !loadingFoto &&(
                  <Tooltip title='Click para cambiar foto'> 
                  <div onClick={viewCam} ><img  className='myFoto' src={fotoPerfil} alt="" /></div></Tooltip>
                )}


            
                

                <div className='nombre'>{formatName(estudiante)}
                  <div className='numId'> {estudiante.tipoId} No. {estudiante.numId} </div>
                  {/* <div className='numId'>{calcularEdad(moment(),moment(estudiante.fechaNacimiento))} Años</div> */}
                  <div>
                  <Tooltip title="Cargar foto"><Button onClick={viewModalFoto}><UploadOutlined/></Button></Tooltip>
                    <Tooltip title="Generar ficha de estudiante"><Button onClick={viewModalFicha}><FilePdfOutlined/></Button></Tooltip>
                  <Tooltip title="Generar historial de notas"><Button onClick={()=>{irHistorialAcademico(estudiante)}}><OrderedListOutlined/></Button></Tooltip>
                  </div>
                  </div>
              
                </div>
            </Col>

            <Col span={24}>
            <Space direction="vertical" className='space'>
                <Collapse collapsible="header" defaultActiveKey={['1']}>
                    <Panel header="Información personal" key="1">
                     <Row>
                        <Col span={12} className="columna">
                            <div><strong>Código:</strong> {estudiante.id}</div>
                            <div><strong>Nombres:</strong> {estudiante.primerNombre} {estudiante.segundoNombre}</div>
                            <div><strong>Identificación:</strong> {estudiante.numId} </div>
                            <div><strong>Teléfono:</strong> {estudiante.telefono} </div>
                            <div><strong>Correo electrónico:</strong> {estudiante.correo} </div>
                            <div><strong>Lugar de residencia:</strong> {estudiante.residencia} </div>
                            <div><strong>Fecha de nacimiento:</strong> {estudiante.fechaNacimiento} </div>
                            <div><strong>Nombre contacto Secundario:</strong> {estudiante.nombreContacto} - {estudiante.parentescoContacto}</div>
                            <div><strong>Estado:</strong> {estudiante.estado} </div>
                        </Col>

                        <Col span={12} className="columna">
                        <div><strong>Apellidos:</strong> {estudiante.primerApellido} {estudiante.segundoApellido}</div>
                        <div><strong>Sexo:</strong> {estudiante.sexo} </div>
                        <div><strong>Celular:</strong> {estudiante.celular}</div>
                        <div><strong>Dirección:</strong> {estudiante.direccion} </div>
                        <div><strong>Barrio:</strong> {estudiante.barrio} </div>
                        <div><strong>Lugar de nacimiento:</strong> {estudiante.lugarNacimiento} </div>
                        <div><strong>Celular contacto Secundario:</strong> {estudiante.celularContacto} </div>
                        <div><strong>Becado:</strong> {estudiante.becado} </div>
                       
                        {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
                        <div> <Button type='primary' onClick={viewEditEstudiante}>Editar</Button></div>
                        )}
                        </Col>
                     </Row>
                    </Panel>
                </Collapse>

                <Collapse collapsible="header" defaultActiveKey={['1']}>
                    <Panel header="Información adicional" key="2">
                     <Row>
                        <Col span={12} className="columna">
                            <div><strong>Expedición de Documento:</strong> {estudiante.exp_documento} </div>
                            <div><strong>Discapacidad:</strong> {estudiante.discapacidad} </div>
                            <div><strong>Medio de Transporte:</strong> {estudiante.medio_transporte} </div>
                            <div><strong>Ocupación:</strong> {estudiante.ocupacion} </div>
                            <div><strong>Zona:</strong> {estudiante.zona} </div>
                            <div><strong>EPS:</strong> {estudiante.eps} </div>
                            <div><strong>Multiculturalidad:</strong> {estudiante.multiculturalidad} </div>
                            <div><strong>Tipo de sangre:</strong> {estudiante.sangre} </div>
                        </Col>

                        <Col span={12} className="columna">
                        <div><strong>Aseguradora:</strong> {estudiante.aseguradora}</div>
                        <div><strong>Hijos:</strong> {estudiante.hijos} </div>
                        <div><strong>Estado Civil:</strong> {estudiante.estado_civil}</div>
                        <div><strong>Estrato:</strong> {estudiante.estrato} </div>
                        <div><strong>Nivel de formación:</strong> {estudiante.nivel_formacion} </div>
                        <div><strong>SISBEN:</strong> {estudiante.sisben} </div>
                        <div><strong>ARS:</strong> {estudiante.ars} </div>
                        <div><strong>Libreta Militar:</strong> {estudiante.libreta} </div>
                        {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
                        <div> <Button type='primary' onClick={viewEditEstudianteAdicional}>Editar</Button></div>
                        )}
                        </Col>
                     </Row>
                    </Panel>
                </Collapse>

                <Collapse collapsible="header" >
                    <Panel header="Información Académica" key="3">
                     <Row>
                      

<Divider>Cursos</Divider>
{(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
            <Button type='primary' onClick={()=>newMatricula("Curso")}>Matricular a un curso</Button>
            )}
            {cursosMatriculados.length==0 && (
                       <Col span={24}>
                       <div className='messageNo'> No tiene cursos Matriculados </div> 
                      
                       </Col>
                       )}

                  {cursosMatriculados.length>0 && (
                       <Col span={24}>
                        {cursosMatriculados.map(item=>{
                          return <CursoEstudiante updateEstudiante={getEstudiante} lugar="Estudiante">{item}</CursoEstudiante>
                        })}
                       </Col>
                       )} 

<Divider>Entrenamientos</Divider>
                   
{(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
                <Button type='primary' onClick={()=>newMatricula("Entrenamiento")}>Matricular a un entrenamiento</Button>
                )}
                {entrenamientosMatriculados.length==0 && (
                       <Col span={24}>
                     <div className='messageNo'>No tiene Entrenamientos Matriculados </div>  
                    
                       </Col>
                         )}

{entrenamientosMatriculados.length>0 && (
                       <Col span={24}>
                        {entrenamientosMatriculados.map(item=>{
                          return <CursoEstudiante updateEstudiante={getEstudiante} >{item}</CursoEstudiante>
                        })}
                       </Col>
                       )} 
                     </Row>
                    </Panel>
                </Collapse>

                <Collapse collapsible="header">
                  {loading && (
                     <div className="spin_container">
                     <Spin className="spin" size="large" />
                   </div>
                  )}
                {!loading && (
                <Panel header="Obligaciones Financieras" key="4">

               
             

                {selectedRowKeys.length>0 && (role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
                <Tooltip title="Crear Pago"><Button onClick={()=>{newPago()}} type="primary">Crear Pago <DollarOutlined /></Button> </Tooltip>
                )}

{selectedRowKeys.length>0 && (role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
                <Tooltip title="Crear Pago con saldo a favor"><Button onClick={()=>{newPagoSaldoFavor()}} type="primary">Crear Pago con saldo a favor <DollarOutlined /></Button> </Tooltip>
                )}

                <Row>
                  <Col md={12}>
                  {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && obligaciones.length > 0 && (
                    <Button type='primary' onClick={sendEstadoCuenta}>Enviar Estado de cuenta <MailOutlined /> </Button>
                    
                )}
                  </Col>

                  <Col md={12}>
                  {saldosTotal && saldosTotal>0 && (
                  <div><b> Saldo Total Disponible:</b> $ <NumericFormat value={saldosTotal} displayType="text" allowLeadingZeros thousandSeparator="," /></div>
                  )}
                  </Col>
                </Row>







                  {obligaciones.length > 0 && (
                  <Table rowSelection={rowSelection} rowKey={(record) => record.rta.codigo_pagare} columns={columns} 
                  dataSource={obligaciones}  pagination={{ pageSize: 4 }} rowClassName={record => !record.saldo>0 ? "disabled-row": "red-row"} />   
                  )}
                </Panel>
                  )}
                </Collapse>


                <Collapse collapsible="header">
                <Panel header="Anulaciones" key="5">
               

                  {anulaciones.length > 0 && (
                  <Table  columns={columns} dataSource={anulaciones}  pagination={{ pageSize: 4 }} />   
                  )}


                </Panel>
                </Collapse>

                <Collapse collapsible="header">
                <Panel header="Saldos a favor" key="6">

                  <Button type='primary' onClick={openModalSaldo}>Ingresar Dinero</Button>
                  <br></br>
                  <br></br>
                  {saldosTotal && saldosTotal>0 && (
                  <div><b> Saldo Total Disponible:</b> $ <NumericFormat value={saldosTotal} displayType="text" allowLeadingZeros thousandSeparator="," /></div>
                  )}
                  
                   <br></br>
               
                  {saldosFavor.length > 0 && (
                  <Table  columns={columnsSaldos} dataSource={saldosFavor}  pagination={{ pageSize: 4 }} />   
                  )}

                </Panel>
                </Collapse>

<Collapse collapsible="header">
                <Panel header="Archivos" key="7">

                {(role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento") && (
<Button type="primary" onClick={subirArchivo}>Subir Archivo</Button>
                )}
{archivos && (
  <Table dataSource={archivos} columns={columnsArchivo}></Table>
)}
</Panel>
</Collapse>


<Collapse collapsible="header">
                <Panel header="Historial Matriculas q10" key="8">


                <Button onClick={()=>verCiclos(matriculasq10[0])}>Ver Ciclos</Button>
             

{matriculasq10 && (
  <Table dataSource={matriculasq10} columns={columnsQ10}></Table>
)}
</Panel>
</Collapse>

            </Space>
            </Col>
      
        </Row>
        </>
)}
      
      {myEstudiante && tipoCurso && viewNewMatricula && (
      <MatriculaModal
        estudiante_id = {myEstudiante.id}
        tipo = {tipoCurso}
        visible={viewNewMatricula}
        updateVisible={setViewMatricula}
        updateLoading={setLoading}
        updateListEstudiante={getEstudiante}
        updateListObligaciones={getObligaciones}
      ></MatriculaModal>
      )}


{ viewNewPago  && (
      <NewPagoModal
        estudiante_id = {myEstudiante.id}
        obligacionesF = {obligaciones}
        visible={viewNewPago}
        updateVisible={setViewNewPago}
        updateLoading={setLoading}
        updateListObligaciones={getObligaciones}
        updatePago = {setPagoSelected}
        updateViewPDF = {setViewPDF}
      >{selectedRowKeys}</NewPagoModal>
      )}


{ viewNewPagoSaldo  && (
      <NewPagoSaldoModal
        estudiante_id = {myEstudiante.id}
        obligacionesF = {obligaciones}
        visible={viewNewPagoSaldo}
        updateVisible={setViewNewPagoSaldo}
        updateLoading={setLoading}
        updateListObligaciones={getObligaciones}
        updatePago = {setPagoSelected}
        updateViewPDF = {setViewPDF}
      >{selectedRowKeys}</NewPagoSaldoModal>
      )}

{ viewListaPagos && obligacionSelected && (
      <ListPagoModal
        visible={viewListaPagos}
        updateVisible={setViewListaPagos}
        updateLoading={setLoading}
        updateListObligaciones={getObligaciones}
        estudiante = {estudiante}
      >{obligacionSelected}</ListPagoModal>
      )}

{viewWebcam && (
  <Modal visible={viewWebcam} onCancel={cancelCam} onOk={capturaFoto} okText="Capturar">
    <Webcam height={300} ref={webRef}/>
  </Modal>
)}


{viewModalEditEstudiante && (
  <EstudianteEditModal visible={viewModalEditEstudiante} 
  updateVisible={setViewModalEditEstudiante}  updateLoading={setLoading} updateEstudiante={getEstudiante}>

    {estudiante}
    </EstudianteEditModal>
)}

{viewModalEditEstudianteAdicional && (
  <EstudianteEditAdicionalModal visible={viewModalEditEstudianteAdicional} 
  updateVisible={setViewModalEditEstudianteAdicional}  updateLoading={setLoading} updateEstudiante={getEstudiante}>

    {estudiante}
    </EstudianteEditAdicionalModal>
)}

{ viewEditObligacion && obligacionSelected && (
  <ObligacionEditModal visible={viewEditObligacion}  updateLoading={setLoading}  updateListPagos={getObligaciones} updateVisible={setViewEditObligacion}>{obligacionSelected}</ObligacionEditModal>
)

}


{viewArchivoModal && (
            <ArchivoEstudianteModal visible={viewArchivoModal}  updateLoading={setLoading}  updateVisible={setViewArchivoModal} updateEstudiante = {getFiles}>
              {myEstudiante}
            </ArchivoEstudianteModal>
          )}


          {viewModalCiclos && (
            <Ciclosq10Modal visible={viewModalCiclos}  updateLoading={setLoading}  updateVisible={setViewModalCiclos}>{ciclosQ10}</Ciclosq10Modal>
          )}


{viewFicha && estudiante && (
  <Modal width={1080} visible={viewFicha} onCancel={()=>setViewFicha(false)}>
          <PDFViewer style={{width:"100%", height:"90vh"}}>
          <FichaEstudiantePDF fotoPerfil={fotoPerfil}>{estudiante}</FichaEstudiantePDF>
          </PDFViewer>
          </Modal>
        )}


{viewPDF && (
  <Modal width={1080} visible={viewPDF} onCancel={()=>setViewPDF(false)} onOk={()=>setViewPDF(false)}>
          <PDFViewer style={{width:"100%", height:"90vh"}}>
          <DocPDF  estudiante={estudiante}>{pagoSelected}</DocPDF>
          </PDFViewer>
          </Modal>
        )}


        {viewModalSaldo && (
          <Modal visible={viewModalSaldo} title="Ingresar Saldo a Favor" okText="Guardar Saldo" onCancel={()=>setViewModalSaldo(false)} onOk={formNewSaldo.submit} >
            <Form layout='vertical' form={formNewSaldo} onFinish={guardarSaldo}>
              <Form.Item label="Valor" name="valor"  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Valor",
                    },
                  ]}>
                <Input></Input>
              </Form.Item>


              <Form.Item
                label="Metodo de Pago: "
                name="metodo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Metodo de Pago",
                  },
                ]}
              >
                <Select>
                    <Select.Option value="EFECTIVO">EFECTIVO</Select.Option>
                    {/* <Select.Option value="CONSIGNACION">CONSIGNACION</Select.Option> */}
                    <Select.Option value="NEQUI">NEQUI</Select.Option>
                    <Select.Option value="DAVIPLATA">DAVIPLATA</Select.Option>
                    <Select.Option value="CUENTA BANCOLOMBIA">CUENTA BANCOLOMBIA</Select.Option>
                    <Select.Option value="AHORRO A LA MANO">AHORRO A LA MANO</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Observaciones" name="observaciones">
                <Input></Input>
              </Form.Item>

              

            </Form>
          </Modal>
        )}


        {modalFoto && (
          <Modal  visible={modalFoto} title="Cargar Archivo" onCancel={()=>setModalFoto(false)} onOk={cargarArchivo}>
              <Input type="file" accept="image/*"  onChange={(e)=>{selectFile(e)}}></Input>

          </Modal>
        )}
        

        </MyLayout>
    );
};

export default VerEstudiante;