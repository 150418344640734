import React from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import pagoService from "../../../services/pago.service";
import { NumericFormat } from 'react-number-format';
import { useAuth } from '../../../context/auth.context';

const PagoEditModal = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { user, role } = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  //   const obligacion_id = props.obligacion_id
  const pago = props.children
  console.log(pago)
  form.setFieldsValue(pago)
  const handleCancel = () => {
    props.updateVisible(false);
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;

    //form.empresa_id = empresa_id;
    // form.obligacion_id = obligacion_id
    //form.concepto = "PAGO DE " + obligacion.concepto 

    // form.moneda = obligacion.moneda
    // form.usuario_id = usuario_id
    // form.estado = 'Generado'
    console.log(form)
    pagoService
      .update(pago.id, form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListPagos();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };
  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="Valor del Pago: "
                name="valor"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Valor del Pago",
                  },
                ]}
              >
                <NumericFormat className="ant-input" thousandSeparator="," decimalScale={3} />

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Metodo de Pago: "
                name="metodo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Metodo de Pago",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="EFECTIVO">EFECTIVO</Select.Option>
                  {/* <Select.Option value="CONSIGNACION">CONSIGNACION</Select.Option> */}
                  <Select.Option value="NEQUI">NEQUI</Select.Option>
                  <Select.Option value="DAVIPLATA">DAVIPLATA</Select.Option>
                  <Select.Option value="CUENTA BANCOLOMBIA">CUENTA BANCOLOMBIA</Select.Option>
                  <Select.Option value="AHORRO A LA MANO">AHORRO A LA MANO</Select.Option>
                </Select>
              </Form.Item>
            </Col>



            <Col span={24}>
              <Form.Item
                label="Concepto: "
                name="concepto"
                rules={[
                  {
                    required: true,
                    message: "Ingrese Concepto",
                  },
                ]}
              >
                <Input />

              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Observaciones: "
                name="observaciones"
                rules={[
                  {
                    required: false,
                    message: "Ingrese observaciones",
                  },
                ]}
              >
                <TextArea></TextArea>

              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Estado: "
                name="estado"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Estado",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Generado">Generado</Select.Option>
                  <Select.Option value="Anulado">Anulado</Select.Option>

                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default PagoEditModal;
