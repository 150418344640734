import http from "../utils/http-common";

class NovedadService {
    getAll() {
      return http.get("/novedad/docente");
    }
    get(id) {
      return http.get(`/novedad/docente/${id}`);
    }

    getForain(id) {
        return http.get(`/novedad/docente/forain/${id}`);
      }

    create(data) {
      return http.post("/novedad/docente", data);
    }

    getPerfil(data) {
      return http.post("/novedad/docente/perfil", data);
    }

    foto(data) {
      return http.post("/novedad/docente", data);
    }

    update(id, data) {
      return http.put(`/novedad/docente/${id}`, data);
    }
    delete(id) {
      return http.delete(`/novedad/docente/${id}`);
    }
   
  }
  export default new NovedadService();