import http from "../utils/http-common";

class LiquidacionDocenteService {
    getAll() {
      return http.get("/liquidacion/docente");
    }
    get(id) {
      return http.get(`/liquidacion/docente/${id}`);
    }

    getForain(id) {
      return http.get(`/liquidacion/docente/forain/${id}`);
    }

    create(data) {
      return http.post("/liquidacion/docente", data);
    }
    update(id, data) {
      return http.put(`/liquidacion/docente/${id}`, data);
    }
    delete(id) {
      return http.delete(`/liquidacion/docente/${id}`);
    }

    pay(id) {
      return http.get(`/liquidacion/docente/pay/${id}`);
    }
   
  }
  export default new LiquidacionDocenteService();