import React, { useEffect, useRef, useState } from 'react';
import MyLayout from '../../components/MyLayout';
import {Card, Button} from 'antd';
import saldoService from '../../services/saldo.service';
const XLSX = require("sheetjs-style");
const ReporteSaldo = () => {

    const ref = useRef(null);
    const [data,setData] = useState([])

    useEffect(() => {
        loadData();
      }, []);


      const loadData = () =>{
        saldoService.getReporte().then(res=>{
            console.log(res.data)
            let filtro = res.data.filter(item=>item.saldo_final>0)
            setData(filtro)
        })
      }

    const reporte = React.useCallback(async () => {

    
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.table_to_sheet(ref.current);
          var wscols = []
          for(let i=0;i<6;i++){
            wscols.push({ wch: 20 })
          }
    
   
    
        ws["!cols"] = wscols;
    
        let range1 = ["A1","A2","A3","A4","B4","C4","D4","E4","F4"]
    
        for(let item of range1){
    
        ws[item].s = {
          font: {
            sz: 14,
            bold: true,
            color: { rgb: "#FFFFFF" },
          },
    
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
        };
    }
    
    
         
    
        XLSX.utils.book_append_sheet(wb, ws, "Reporte saldos a favor");
       
        XLSX.writeFile(wb, "Reporte saldos a favor.xlsx");
      });

    return (
        <MyLayout>
            <Card>
                <h1>Reporte de saldos a favor</h1>


                <table ref={ref} className='cuadre' style={{display:"none"}} >
        <thead>
          <tr>
          
            <th colspan='6'>CENTRO DE FORMACIÓN EN INGLES</th>
           
          </tr>
         
          <tr>
         
            <th colspan='6'>CONTROL ACADEMICO Y FINANCIERO DE ESTUDIANTES</th>
         
          </tr>

          <tr>
            <th colspan="6">SALDOS A FAVOR</th>
            
          </tr>
        </thead>
        <tbody>
            <tr>
                <td>Nombres</td>
                <td>No. de Id</td>
                <td>Celular</td>
                <td>Total Ingresos a favor</td>
                <td>Total Egresos</td>
                <td>Saldo Total a favor</td>
                
            </tr>
            
            {data.map((item=>{
                return <><tr>
                <td>{item.Estudiante.primerNombre} {item.Estudiante.segundoNombre} {item.Estudiante.primerApellido} {item.Estudiante.segundoApellido}</td>
                <td>{item.Estudiante.numId}</td>
                <td>{item.Estudiante.celular}</td>
                
                <td>{item.total_ingresos}</td>
                <td>{item.total_egresos}</td>
                <td>{item.saldo_final}</td>
               
            </tr>

          
          
            </> 
            }))}

 
           
        </tbody>
      </table>

                <Button onClick={reporte} type='primary'>Generar Reporte</Button>
            </Card>
        </MyLayout>
    );
};

export default ReporteSaldo;