import { Row, Col, Card, Button, Form, Input, Select, message, Spin } from 'antd';
import React, {useState} from 'react';
import formularioService from "../../services/formulario.service"

const OOPT = () => {
    const [terminos,setTerminos] = useState(true)
    const [guardado,setGuardado] = useState(false)
    const [loading,setLoading] = useState(false)
    const aceptar = () =>{
        setTerminos(false)
    }

    const guardarOOPT = (values) =>{
        setLoading(true)
        let form = values
        form.examen = "OOPT"
        form.nombres =  values.nombres.toUpperCase()
        form.apellidos =  values.apellidos.toUpperCase()
        formularioService.create(form).then(res=>{
            setGuardado(true)
            message.success("Registro guardado")
            setLoading(false)
        }).catch(error=>{
            message.error("No se pudo Guardar")
            setGuardado(false)
            setLoading(false)
        })
    }

    if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <Row>
            <Col xs={2} md={3}>
            </Col>

            <Col xs={20} md={18}>
              <Card >

              <img style={{width:'100%'}} src="./assets/img/menuoopt.jpeg" alt="" />

              <h1>Registro OOPT</h1>
                {terminos && !guardado && (
                <div>
                

                <p>
                Con el diligenciamiento del presente formulario, autorizo de manera expresa, consciente, informada y
previa a <strong>BRIGHTON CENTRO DE FORMACIÓN EN
INGLÉS,</strong> identificada con el NIT: 98391866-4, a capturar, almacenar y
verificar la autenticidad de mis datos y a conservarlos bajo su operación,
control o supervisión con el fin de utilizarlos para dar cumplimiento a
derechos y obligaciones de naturaleza comercial y gremial, y salvaguardar la
seguridad de <strong>BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS.</strong> Lo anterior, para los
fines establecidos en la Política de Tratamiento y Privacidad publicada en la
página web de la entidad www.brightonenglishcenter.edu.co, asimismo, autorizo a
BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS en la Ciudad de Pasto a transferir y
transmitir mis datos a terceros receptores de los mismos.</p>

<p>
En pleno conocimiento de
que no estoy obligado a permitir el tratamiento de mis datos sensibles,
autorizo, de manera voluntaria, expresa, consciente, informada y previa a 
<strong> BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS</strong> a que, en desarrollo de nuestra
relación, conozca, capture, almacene y verifique, la autenticidad de mis datos
personales sensibles como pudieran ser datos biométricos, origen racial,
opinión política, convicción religiosa y filosófica, pertenencia a sindicatos,
salud y sexualidad entre otros similares definidos por la ley, jurisprudencia y
decisiones administrativas. Lo anterior, de acuerdo con la política de
tratamiento de datos personales de la entidad a la que autorizo.</p>

<p>
Declaro que, BRIGHTON
CENTRO DE FORMACIÓN EN INGLÉS me ha informado de los derechos que me asisten
como titular de los datos suministrados, de acuerdo con el artículo 8 de la Ley
1581 de 2012, los cuales corresponden a: <ul><li>I,
Conocer, actualizar y rectificar los datos personales frente a BRIGHTON CENTRO
DE FORMACIÓN EN INGLÉS;</li> <li> II.
Solicitar prueba de la autorización otorgada;</li> <li>III, Ser informado por BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS,
previa solicitud, del uso que se les ha dado a mis datos;</li>  <li>IV. Presentar quejas ante la Superintendencia de Industria y
Comercio por infracciones frente a la Ley;</li>
<li>V. Revocar la autorización y/o solicitar la supervisión del dato cuando no
se hayan respetado los principios, derechos y garantías constitucionales y
legales.</li>
</ul>
Responsable del tratamiento:
BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS, NIT: 98391866-4, dirección: Cra 38 No.
19 – 26 Palermo, Pasto; Teléfono: 7362555. 
                </p>
                <card>
                    <h2>Acepto la política de tratamiento de datos</h2>
                    <br />
                <Button type='primary' onClick={aceptar}>Acepto</Button>
                </card>
                </div>
                )}

                {!terminos && !guardado && (
<>
                    <div style={{backgroundColor:"rgb(58, 187, 209)",padding:"5px"}}><h3>Datos personales</h3></div>
                    <Form style={{marginTop:'20px'}} layout='vertical' onFinish={guardarOOPT}>
                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Correo",
                    },
                  ]} label="Correo" name="correo">
                            <Input type="email"></Input>
                        </Form.Item>

                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Nombres",
                    },
                  ]} label="NOMBRES" name="nombres">
                            <Input ></Input>
                        </Form.Item>

                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Apellidos",
                    },
                  ]} label="APELLIDOS" name="apellidos">
                            <Input ></Input>
                        </Form.Item>

                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha Nacimiento",
                    },
                  ]} label="FECHA DE NACIMIENTO" name="fechaNacimiento">
                            <Input type='date' ></Input>
                        </Form.Item>

                        <Form.Item
                label="TIPO DE DOCUMENTO"
                name="tipoId"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de ID",
                  },
                ]}
              >
                <Select>
                <Select.Option value="Cédula de ciudadanía">Cédula de ciudadanía</Select.Option>
                    <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                    <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                    <Select.Option value="Pasaporte">Pasaporte</Select.Option>

                </Select>
              </Form.Item>


              <Form.Item
                label="NUMERO DE DOCUMENTO"
                name="numId"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese No. de Documento",
                  },
                ]}
              >
                <Input />
              </Form.Item>


              <Form.Item
                label="CELULAR"
                name="nombreContacto"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Numero de contacto",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha de presentación de la prueba",
                    },
                  ]} label="FECHA DE PRESENTACIÓN DE LA PRUEBA " name="fechaPrueba">
                            <Input type='date' ></Input>
                        </Form.Item>

                        <Form.Item
                label="Jornada en la cual desea presentar la prueba"
                name="jornada"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Jornada",
                  },
                ]}
              >
                <Select>
                <Select.Option value="Mañana">Mañana</Select.Option>
                    <Select.Option value="Tarde">Tarde</Select.Option>
                    

                </Select>
              </Form.Item>


              <Button type="primary" htmlType="submit">
          Enviar
        </Button>

                    </Form>

                    </>
                )}

                {guardado && (
                  <div style={{textAlign:'center'}}>  <h1>Registro Guardado</h1>  </div>
                )}

              </Card>
            </Col>

            <Col xs={2} md={3}>
            </Col>
        </Row>
    );
};

export default OOPT;