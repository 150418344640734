import http from "../utils/http-common";

class ArchivoEstudianteService {
    getAll() {
      return http.get("/archivo/estudiante");
    }
    get(id) {
      return http.get(`/archivo/estudiante/${id}`);
    }

    getForain(id) {
      return http.get(`/archivo/estudiante/forain/${id}`);
    }

    create(data) {
      return http.post("/archivo/estudiante", data);
    }
    update(id, data) {
      return http.put(`/archivo/estudiante/${id}`, data);
    }
    delete(id) {
      return http.delete(`/archivo/estudiante/${id}`);
    }
   
  }
  export default new ArchivoEstudianteService();