import { Row, Col, Card, Button, Form, Input, Select, message, Spin, Checkbox, Typography } from 'antd';
import React, {useState} from 'react';
import formularioService from "../../services/formulario.service"

const APTIS = () => {
    const [terminos,setTerminos] = useState(true)
    const [guardado,setGuardado] = useState(false)
    const [loading,setLoading] = useState(false)
    const aceptar = () =>{
        setTerminos(false)
    }

    const plainOptions = ['READING', 'WRITING', 'LISTENING','SPEAKING'];


    const guardarAPTIS = (values) =>{
        setLoading(true)
        let form = values
        form.skills = JSON.stringify(values.skills)
        form.examen = "APTIS"
        formularioService.create(form).then(res=>{
            setGuardado(true)
            message.success("Registro guardado")
            setLoading(false)
        }).catch(error=>{
            message.error("No se pudo Guardar")
            setGuardado(false)
            setLoading(false)
        })
    }

    if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <Row>
            <Col xs={2} md={3}>
            </Col>

            <Col xs={20} md={18}>
              <Card >

              <img style={{width:'100%'}} src="./assets/img/menuoopt.jpeg" alt="" />

              <h1>Registro APTIS</h1>
                {terminos && !guardado && (
                <div>
                

                <p>
                Certifico que: </p>

<p>1. La información diligenciada en este formulario es correcta, completa y verdadera.</p>

<p>2. Entiendo que debo presentar el documento identidad inscrito el día del examen para ingresar. La no presentación del documento no es una excusa válida para re programar el examen y el valor cancelado no será reembolsado. </p>
<p>3. Si otra persona trata de presentar el examen usando mi nombre (suplantación de identidad) con mi documento o presentando uno falso, tanto la persona como yo seremos denunciados ante las autoridades competentes y penalizados. En este caso, los resultados no serán procesados ni el dinero cancelado será reembolsado.</p>
<p>4. Entiendo que si intento hacer trampa, dar o recibir ayuda durante el examen, copiar el trabajo de otros, o remover materiales del examen, los resultados no serán procesados ni el dinero cancelado será reembolsado. </p>
<p>5. Entiendo que el material del examen está protegido bajo derechos de autor, por eso no debo reproducir, distribuir o revelar su contenido. </p>
<p>6. Me comprometo a cumplir con todas las regulaciones e instrucciones impartidas antes, durante y después del examen al que me estoy inscribiendo.</p>
<p>7. Comprendo que diligenciar este formulario no garantiza la inscripción, ni el cupo. Solo hasta que la Coordinación de Exámenes Internacionales verifique y apruebe mi foto y documento; reciba la orden de compra; pague de forma exitosa el costo del examen y reciba la citación, quedaré inscrito al examen. </p>
<p>8. Al ingresar mi nombre completo en el campo a continuación, estoy firmando digitalmente este formulario y esta firma tiene validez legal en Colombia.</p>
<p>9. Consiento y autorizo de manera expresa e inequívoca a Brighton Centro de Formación en Ingles, NIT 98391866-4, domiciliado en la Ciudad de Pasto - Nariño para que incluya y use la información que estoy suministrando de acuerdo a su Política de Tratamiento de Datos Personales. </p>
<p>10. Acepto términos y condiciones del examen al que me estoy inscribiendo, los cuales pueden actualizarse sin previo aviso. Como aparece en <a href='https://brightonenglishcenter.edu.co'>www.brightonenglishcenter.edu.co</a> sección exámenes.
“El BRITISH COUNCIL propende por las condiciones ideales para la presentación de APTIS en cuanto al buen estado y mantenimiento de la plataforma que se usa en este. Sin embargo, ocasionalmente, como sucede con cualquier tipo de tecnología, pueden generarse fallas técnicas inesperadas en el funcionamiento de la plataforma. Cuando este sea el caso,  Brighton Centro de Formación en Ingles se compromete a reprogramar el examen con la habilidad o habilidades que deba presentar nuevamente el candidato sin costo adicional en sus instalaciones de la sede Palermo entre semana en horario de 8:30 a.m. a 3 p.m. Sin embargo, ni Brighton Centro de Formación en Ingles ni el BRITISH COUNCIL pueden asumir los costos relacionados con el desplazamiento dentro o fuera de la Ciudad de Pasto que requiera hacer el candidato para presentarse nuevamente al Brighton Centro de Formación en Ingles y repetir su examen.”</p>
             
                <card>
                    <h2>Acepto la política de tratamiento de datos</h2>
                    <br />
                <Button type='primary' onClick={aceptar}>Acepto</Button>
                </card>
                </div>
                )}

                {!terminos && !guardado && (
<>
                    <div style={{backgroundColor:"rgb(58, 187, 209)",padding:"5px"}}><h3>Datos personales</h3></div>
                    <Form style={{marginTop:'20px'}} layout='vertical' onFinish={guardarAPTIS}>
                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Correo",
                    },
                  ]} label="Correo" name="correo">
                            <Input type="email"></Input>
                        </Form.Item>

                        <Form.Item name="nombres" rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Nombres",
                    },
                  ]} label="Candidate First and Middle Name" >
                    
                            <Input placeholder='Colocar su nombre completo. i.e: 
                      Si su nombre es Juan Pablo, colóquelo como: JUAN PABLO. 
                      Si no se tiene un segundo nombre, colocar únicamente el primero. i.e: 
                      Si su nombre es David, colóquelo como: DAVID.'></Input>
                        </Form.Item>

                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Apellidos",
                    },
                  ]} label="Candidate Last Name" name="apellidos">
                            <Input ></Input>
                        </Form.Item>


                        <Form.Item
                label="Candidate Address"
                name="direccion"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese dirección",
                  },
                ]}
              >

                
                <Input placeholder='i.e: Si su dirección es Calle 13 #02-23, colóquela así, sustituyendo el # por N: CALLE 13 N 02 23' />
              </Form.Item>

              <Form.Item
                label="Candidate Address - Country / Territory"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Territorio",
                  },
                ]}
              >

                <Input />
              </Form.Item>

              <Form.Item
                label="Candidate Address - City/Town"
                name="town"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Territorio",
                  },
                ]}
              >
               
                <Input placeholder='i.e: Si su ciudad de residencia es Pasto, colóquela así: PASTO' />
              </Form.Item>

              <Form.Item
                label="Candidate Address - Postcode"
                name="postcode"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese codigo postal",
                  },
                ]}
              >
               
                <Input />

                {/* <span>Si no conoce cuál es tu código postal, sigue este link: <a href="https://codigo-postal.co/colombia/">https://codigo-postal.co/colombia/</a></span> */}
              </Form.Item>

                        <Form.Item rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha Nacimiento",
                    },
                  ]} label="Date of Birth (dd/mm/yyyy)" name="fechaNacimiento">
                            <Input type='date' ></Input>
                        </Form.Item>


                        <Form.Item
                label="Gender (Male/Female): "
                name="genero"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Genero",
                  },
                ]}
              >
                <Select>
                <Select.Option value="FEMALE">FEMALE</Select.Option>
                    <Select.Option value="MALE">MALE</Select.Option>
                    <Select.Option value="OTHER">OTHER</Select.Option>

                </Select>
              </Form.Item>

              <Form.Item
                label="GDPR Consent Provided"
                name="consentimiento"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese consentimiento",
                  },
                ]}
              >

                
                <Select placeholder="Si eres menor de edad, necesitas diligenciar un formulario con el consentimiento de un adulto">
                <Select.Option value="Yes">Yes</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                   

                </Select>
              </Form.Item>


              <Form.Item
                label="Special Requirements"
                name="discapacidad"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese si el aspirante tiene alguna discapacidad física o mental",
                  },
                ]}
              >

               
                <Select placeholder="Informar si el aspirante tiene alguna discapacidad física o mental">
                <Select.Option value="Yes">Yes</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                   

                </Select>
              </Form.Item>

              <Form.Item
                label="Special Requirements"
                name="details"
                rules={[
                  {
                    required: false,
                    message: "Si la respuesta anterior fue 'yes', especificar el tipo de discapacidad",
                  },
                ]}
              >

               
                <Input placeholder="Si la respuesta anterior fue 'yes', especificar el tipo de discapacidad"></Input>
              </Form.Item>


              <Form.Item rules={[
                    {
                      required: true,
                      message: "Candidate Email Address",
                    },
                  ]} label="Candidate Email Address" name="correoCandidato">
                            <Input type="email"></Input>
                        </Form.Item>


                        <Form.Item
                label="Candidate Mobile Number"
                name="nombreContacto"
                rules={[
                  {
                    required: true,
                    message: "Candidate Mobile Number",
                  },
                ]}
              >
                <Input />

                
              </Form.Item>

                        <Form.Item
                label="Tipo Id: "
                name="tipoId"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo de ID",
                  },
                ]}
              >


                <Select>
                <Select.Option value="Cédula de ciudadanía">Cédula de ciudadanía</Select.Option>
                    <Select.Option value="Tarjeta de Identidad">Tarjeta de Identidad</Select.Option>
                    <Select.Option value="Cédula de Extranjería">Cédula de Extranjería</Select.Option>
                    <Select.Option value="Pasaporte">Pasaporte</Select.Option>

                </Select>
              </Form.Item>


              <Form.Item
                label="Candidate Id No "
                name="numId"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese No. de Documento",
                  },
                ]}
              >

              
                <Input placeholder='Número de cédula, tarjeta de identidad, etc, sin puntos. i.e: 108576345' />
              </Form.Item>


             

              <Form.Item rules={[
                    {
                      required: false,
                      message: "Por favor ingrese Fecha de vencimiento del doc",
                    },
                  ]} label="Candidate Id Expiry Date (dd/mm/yyyy)" name="fechaExpiracion">

                    {/* <span>Si su tipo de documento es pasaporte, señalar la fecha de vencimiento del mismo</span> */}
                            <Input type='date' ></Input>
                        </Form.Item>

                        <Form.Item
                label="TYPE OF APTIS "
                name="tipo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese tipo de exámen",
                  },
                ]}
              >
                <Select>
                <Select.Option value="APTIS General (Presencial)">APTIS General (Presencial)</Select.Option>
                    <Select.Option value="APTIS General (Remoto)">APTIS General (Remoto)</Select.Option>
                    <Select.Option value="APTIS Advanced">APTIS Advanced</Select.Option>
                    

                </Select>
              </Form.Item>

             

              {/* <Form.Item
                label="SKILLS TO TAKE"
                name="skillstake"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese skill o alguno de ellos",
                  },
                ]}
              >

               
                <Select placeholder="Especificar si en el examen tomará todos los skills, o tomará algunos de ellos">
                <Select.Option value="ALL SKILLS">ALL SKILLS</Select.Option>
                    <Select.Option value="ONE  OR MORE SKILLS">ONE  OR MORE SKILLS</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Si la respuesta anterior fue: 'ONE OR MORE SKILLS', por favor seleccione los skills que tomará"
                name="skills"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese skill o alguno de ellos",
                  },
                ]}
              >

               
                <Checkbox.Group options={plainOptions} ></Checkbox.Group>
              </Form.Item> */}


              <Form.Item rules={[
                    {
                      required: true,
                      message: "DATE OF PRESENTATION (dd/mm/yyyy)",
                    },
                  ]} label="DATE OF PRESENTATION (dd/mm/yyyy)" name="fechaPrueba">

                  
                            <Input type='date' ></Input>
                        </Form.Item>


                        <Form.Item
                label="Presentation day"
                name="jornada"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese dia de Presentación",
                  },
                ]}
              >

              
                <Select>
                <Select.Option value="Morning">Morning</Select.Option>
                    <Select.Option value="Afternoon">Afternoon</Select.Option>
                </Select>
              </Form.Item>


              <Button type="primary" htmlType="submit">
          Enviar
        </Button>

                    </Form>

                    </>
                )}

                {guardado && (
                  <div style={{textAlign:'center'}}>  <h1>Registro Guardado</h1>  </div>
                )}

              </Card>
            </Col>

            <Col xs={2} md={3}>
            </Col>
        </Row>
    );
};

export default APTIS;