
import React, { useState } from 'react';
import axios from 'axios';
import {Select, Input} from 'antd'
import {Link} from 'react-router-dom'
import './Finder.css'
const Finder = () => {

    const [query, setQuery] = useState('');
  const [results, setResults] = useState({ estudiantes: [], docentes: [] });
  const [loading,setLoading] = useState(false)
  const { Search } = Input;

  const handleSearch = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`https://crmback.onrender.com/api/estudiantes/finder/search?query=${query}`);
      setResults(null)
      console.log(response.data)
      setResults(response.data);
      setLoading(false)
    } catch (error) {
      console.error('Error al buscar:', error);
      setLoading(false)
    }
  };

  const formatName = (record)=>{
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const handleChange = (event) => {
    const { value } = event.target;

    setQuery(value);
   // handleSearch(value);

  };

 


    return (
      <div className='buscador'>
        <div>

      <Search
      allowClear
        type="text"
        placeholder="Buscar Persona"
        value={query}
        onChange={handleChange}
        onSearch={handleSearch}
        onPressEnter={handleSearch}
        className='inputBuscador'
        loading={loading}
      />
    </div>


   
       {query.length>0 && (results.estudiantes.length>0 || results.docentes.length>0) && (
      <div className='lista'>

      {results.estudiantes.length>0 && (
      <div >
        <div className='title'>Estudiantes</div>
        <ul >
        
          {results.estudiantes.map((estudiante) => (
            <li key={"Estudiante"+estudiante.id}>
               
           <div>
           
           <div className='name'>
           <Link to={"/ver-estudiante"}
           state={{estudiante: estudiante}} >  {formatName(estudiante)} 
           
            </Link>
           
           </div> 
          
           <div className='numId'>{estudiante.numId}</div>
          
           </div>
   

               
            </li>
          ))}
        </ul>
      </div>
      )}

{results.docentes.length>0 && (
      <div>
        <div className='title'>Docentes</div>
        <ul>
          {results.docentes.map((docente) => (
            <li key={"DOCENTE"+docente.id}>
            
            <div>
          
            <div className='name'>
            <Link to={"/ver-docente"}
           state={{docente: docente}}>{formatName(docente)}
           </Link>
          
           </div>
            
            <div className='numId'>{docente.numId}</div>
          
            </div>
      

                </li>
          ))}
        </ul>
      </div>
    
        )}
</div>
)}
   
    </div>
    );
};

export default Finder;