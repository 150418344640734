import React from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import faseService from "../../services/fase.service";
const { TextArea } = Input;

const FaseModal = (props) => {
  const [form] = Form.useForm();
  const empresa_id = 1;
  const {fase_id,lead_id, fase_actual} = props

  const handleCancel = () => {
    props.updateVisible(false);
    props.updateCurrent(fase_actual)
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.fase_id = fase_id
    form.lead_id = lead_id
    faseService
      .changeFase(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };
  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Comentario: "
                name="comentario"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Comentario",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default FaseModal;
