import http from "../utils/http-common";

class EstudianteService {
    getAll() {
      return http.get("/docentes");
    }
    get(id) {
      return http.get(`/docentes/${id}`);
    }

    getForain(id) {
        return http.get(`/docentes/forain/${id}`);
      }

    create(data) {
      return http.post("/docentes", data);
    }

    getPerfil(data) {
      return http.post("/docentes/perfil", data);
    }

    foto(data) {
      return http.post("/docentes/foto", data);
    }

    update(id, data) {
      return http.put(`/docentes/${id}`, data);
    }
    delete(id) {
      return http.delete(`/docentes/${id}`);
    }
   
  }
  export default new EstudianteService();