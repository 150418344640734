import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import "./Item.css"
import {Input, Spin, Button, Table, Select, message} from "antd"

import categoriaBV from "../../services/categoriabv.service"
import "./Padre.css"
import {SearchOutlined, CloseOutlined} from "@ant-design/icons"
import {useNavigate} from "react-router-dom"
import archivobvService from "../../services/archivobv.service"
const Padre = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const [busqueda, setBusqueda] = useState([])
  const [filtro, setFiltro] = useState(["TODOS"])
  const [buscado, setBuscado] = useState(false)
  const {Search} = Input
  const navigate = useNavigate()
  useEffect(() => {
    loadCategorias()
  }, [])

  const loadCategorias = () => {
    setLoading(true)
    categoriaBV.getAll().then((res) => {
      setData(res.data)
      setLoading(false)
    })
  }

  const irHome = () => {
    navigate("/")
  }

  const onSearch = (value, _e) => {
    setLoading(true)
    if (value == "" || value == null || filtro == null || filtro == "") {
      message.error("Ingrese un valor correcto de búsqueda")
      setLoading(false)
      return
    }

    setBuscado(true)
    archivobvService
      .buscar({nombre: value, filtro: filtro})
      .then((res) => {
        setLoading(false)
        setBusqueda(res.data)
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const descargar = (url) => {
    window.open(url)
  }

  const columns = [
    {
      title: "Recurso",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Nombre"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.nombre.toLowerCase().includes(value.toLowerCase())
      },
      render: (record) => <div>{record.nombre}</div>,
    },

    {
      title: "Tipo",
      render: (record) => <div>{record.tipo}</div>,
    },

    {
      title: "Nivel",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese Nivel"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.CategoriaBV.Padre.nombre
          .toLowerCase()
          .includes(value.toLowerCase())
      },
      render: (record) => (
        <div>
          <Link
            to={`/biblioteca/${record.CategoriaBV.id}`}
            state={{parent: record.CategoriaBV}}
          >
            {record.CategoriaBV.Padre.nombre}-{record.CategoriaBV.nombre}
          </Link>
        </div>
      ),
    },

    {
      title: "Ver archivo",
      render: (record) => {
        if (record.tipo == "LINK") {
          return (
            <div>
              <a href={record.url} target="_blank">
                Visitar Link
              </a>
            </div>
          )
        } else {
          return (
            <div>
              <Button
                onClick={() => {
                  descargar(record.url)
                }}
              >
                Descargar
              </Button>
            </div>
          )
        }
      },
    },

    {
      title: "Información",
      render: (record) => <div>{record.info}</div>,
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
        return (
          <Input
            autoFocus
            placeholder="Ingrese palabra clave"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              confirm({closeDropdown: false})
            }}
            onPressEnter={() => {
              confirm()
            }}
            onBlur={() => {
              confirm()
            }}
          ></Input>
        )
      },
      filterIcon: () => {
        return <SearchOutlined />
      },
      onFilter: (value, record) => {
        return record.info.toLowerCase().includes(value.toLowerCase())
      },
    },

    // {
    //   title: "Cargado por",
    //   render: (record) => {
    //     let nombreUsuario =
    //       record.Usuario && record.Usuario.nombre ? record.Usuario.nombre : "-"
    //     return <div>{nombreUsuario}</div>
    //   },
    //   filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
    //     return (
    //       <Input
    //         autoFocus
    //         placeholder="Ingrese nombre"
    //         value={selectedKeys[0]}
    //         onChange={(e) => {
    //           setSelectedKeys(e.target.value ? [e.target.value] : [])
    //           confirm({closeDropdown: false})
    //         }}
    //         onPressEnter={() => {
    //           confirm()
    //         }}
    //         onBlur={() => {
    //           confirm()
    //         }}
    //       ></Input>
    //     )
    //   },
    //   filterIcon: () => {
    //     return <SearchOutlined />
    //   },
    //   onFilter: (value, record) => {
    //     return record.Usuario && record.Usuario.nombre
    //       ? record.Usuario.nombre.toLowerCase().includes(value.toLowerCase())
    //       : "-"
    //   },
    // },

    {
      title: "Clasificación",
      render: (record) => <div>{record.clasificacion}</div>,
    },
  ]

  // if (loading)
  //   return (
  //     <div className="spin_container">
  //       <Spin className="spin" size="large" />
  //     </div>
  //   )
  return (
    <div>
      <div className="barra-nav">
        <img
          onClick={irHome}
          src="./assets/img/logo.png"
          width={"50px"}
          style={{background: "white", borderRadius: "50%", cursor: "pointer"}}
        ></img>
        <div>Biblioteca SIMON</div>
      </div>

      <div className="buscador ">
        <Select
          style={{width: 200}}
          size="large"
          placeholder="Clasificar"
          defaultValue="TODOS"
          mode="multiple"
          onChange={(value) => {
            setFiltro(value)
          }}
        >
          <Select.Option value="TODOS">TODOS</Select.Option>
          <Select.Option value="NIÑOS">NIÑOS</Select.Option>
          <Select.Option value="JOVENES">JOVENES</Select.Option>
          <Select.Option value="ADULTOS">ADULTOS</Select.Option>
        </Select>
        <Search
          placeholder="Buscar Recurso"
          onSearch={onSearch}
          enterButton
          style={{width: "40%"}}
          size="large"
        />
      </div>

      {!loading && data && (
        <div className="item-container">
          {busqueda.length == 0 &&
            data
              .filter((item) => item.padre_id === 0)
              .map((item) => (
                <Link to={`/biblioteca/${item.id}`} state={{parent: item}}>
                  <div className="item" key={item.id}>
                    {item.sigla}
                  </div>
                </Link>
              ))}
        </div>
      )}

      {!loading && busqueda && busqueda.length > 0 && (
        <div style={{padding: "50px"}}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "12px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setBusqueda([])
                setBuscado(false)
              }}
            >
              Eliminar búsqueda <CloseOutlined />
            </Button>
          </div>
          <Table
            dataSource={busqueda}
            columns={columns}
            pagination={{
              pageSize: 5,
            }}
          ></Table>
        </div>
      )}

      {!loading && buscado && busqueda.length == 0 && (
        <div
          style={{textAlign: "center", fontSize: "18px", fontStyle: "italic"}}
        >
          No se encontraron resultados
        </div>
      )}

      {loading && (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      )}
    </div>
  )
}

export default Padre
