import React, {useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  message,
  Button
} from "antd";
import { Content } from "antd/lib/layout/layout";
import preguntaService from "../../services/pregunta.service";

const PreguntaModal = (props) => {
  const [form] = Form.useForm();
  const [listaDatos,setListaDatos] = useState([]);
  const [viewMultiple,setViewMultiple] = useState(false);
  const empresa_id = 1;
  const handleCancel = () => {
    props.updateVisible(false);
  };

  const addItem = () =>{
    setListaDatos(listaDatos => [...listaDatos,{nombre:""}] );
  }

  const handleView = (value)=>{
    console.log(value)
    if(value == 'Múltiples opciones (Única respuesta)' || value == 'Múltiples opciones (Múltiple respuesta)'){
      setViewMultiple(true)
    }
    else{
      setViewMultiple(false)
      setListaDatos([])
    }
  }

  const setDataInput = (e,item) =>{
    let index = listaDatos.indexOf(item)
    listaDatos[index].nombre = e.target.value
    setListaDatos(listaDatos => [...listaDatos] );
  }

  const eliminarItem = (item) =>{
    let index = listaDatos.indexOf(item)
    listaDatos.splice(index,1)
    setListaDatos(listaDatos => [...listaDatos] );
  }

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.empresa_id = empresa_id;
    form.data = JSON.stringify(listaDatos);
    preguntaService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListPregunta();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };
  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 22 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Nombre: "
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Obligatorio: "
                name="obligatorio"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Obligatorio",
                  },
                ]}
              >
                <Select>
                    <Select.Option value="Si">Si</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Estado: "
                name="estado"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Obligatorio",
                  },
                ]}
              >
                <Select>
                    <Select.Option value="Activo">Activo</Select.Option>
                    <Select.Option value="Inactivo">Inactivo</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Tipo: "
                name="tipo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Tipo",
                  },
                ]}
              >
                <Select onChange={handleView}>
                    <Select.Option value="Múltiples opciones (Única respuesta)">Múltiples opciones (Única respuesta)</Select.Option>
                    <Select.Option value="Múltiples opciones (Múltiple respuesta)">Múltiples opciones (Múltiple respuesta)</Select.Option>
                    <Select.Option value="Casilla de redacción">Casilla de redacción</Select.Option>
                    <Select.Option value="Sí o No">Sí o No</Select.Option>
                    <Select.Option value="Fecha">Fecha</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
           
            { viewMultiple && (
              listaDatos.map((item)=>{
                return  <React.Fragment>
                <Col span={16}><Input onChange={(e)=>{setDataInput(e,item)}} value={item.nombre}></Input> </Col>
                <Col><Button onClick={(e)=>{eliminarItem(item)}}>Eliminar</Button></Col>
                </React.Fragment>
              })
            )}

            {viewMultiple && (
            <Button onClick={addItem}>Agregar</Button>
            )}
          </Row>
         
        </Form>
      </Content>
    </Modal>
  );
};

export default PreguntaModal;
