import React, {useState, useEffect, useRef} from "react"
import {Link} from "react-router-dom"
import {Breadcrumb, Table, Button, Spin} from "antd"
import MyLayout from "../../components/MyLayout"
import constanciaService from "../../services/constancia.service"
import moment from "moment"

import ConstanciaModal from "../../components/modals/ConstanciaModal"
import {useAuth} from "../../context/auth.context"

import {useNavigate} from "react-router-dom"
import {StyleSheet} from "@react-pdf/renderer"
import derechoService from "../../services/derecho.service"
import DerechoModal from "../../components/modals/DerechoModal"

const DerechoGrado = () => {
  const [viewNewDerechos, setViewNewDerechos] = useState(false)
  const [loading, setLoading] = useState(false)
  const [derechosGrado, setDerechosGrado] = useState([])
  const {role} = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    listDerechos()
  }, [])

  const listDerechos = () => {
    setLoading(true)
    derechoService
      .get(1)
      .then((response) => {
        console.log(response.data)
        let filteDerechos = response.data

        // filterMedios = response.data.filter(
        //   (item) => item.empresa_id == empresa_id
        // );
        setDerechosGrado(filteDerechos)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const columns = [
    {
      title: "Estudiante",
      render: (record) => (
        <Link to={"/ver-estudiante"} state={{estudiante: record.Estudiante}}>
          {record.Estudiante.primerNombre} {record.Estudiante.segundoNombre}{" "}
          {record.Estudiante.primerApellido} {record.Estudiante.segundoApellido}
        </Link>
      ),
    },

    {
      title: "Fecha",
      render: (record) => {
        let myFecha = moment(record.fecha).format("DD/MM/YYYY")
        return <div>{myFecha}</div>
      },
    },

    {
      title: "Fecha Registro",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (fecha) => {
        let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a")
        return <div>{myFecha}</div>
      },
    },

    // {
    //   title: "Opciones",

    //   key: "action",
    //   render: (record) => (
    //     <div>
    //       <Tooltip title="Editar Constancia">
    //         <Button
    //           style={{marginRight: "10px"}}
    //           onClick={() => setConstancia(record)}
    //           type="primary"
    //         >
    //           CO
    //         </Button>
    //       </Tooltip>

    //       <Tooltip title="Editar Certificado">
    //         <Button
    //           style={{marginRight: "10px"}}
    //           onClick={() => setCertificado(record)}
    //           type="primary"
    //         >
    //           CE
    //         </Button>
    //       </Tooltip>

    //       <Tooltip title="Imprimir">
    //         <Button
    //           style={{marginRight: "10px"}}
    //           type="primary"
    //           onClick={() => imprimirPDF(record)}
    //         >
    //           <FilePdfOutlined></FilePdfOutlined>
    //         </Button>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ]

  const newDerechos = () => {
    setViewNewDerechos(true)
  }

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    )
  return (
    <MyLayout>
      <Breadcrumb style={{margin: "16px 0"}}>
        <Breadcrumb.Item>Institucional</Breadcrumb.Item>
        <Breadcrumb.Item>Derechos de grado</Breadcrumb.Item>
      </Breadcrumb>

      {(role == "Superadministrador" ||
        role == "Administrativo" ||
        role == "Seguimiento" ||
        role == "Cumplimiento") && (
        <Button type="primary" onClick={newDerechos}>
          Crear Derechos de grado
        </Button>
      )}

      <DerechoModal
        visible={viewNewDerechos}
        updateVisible={setViewNewDerechos}
        updateLoading={setLoading}
        updateListConstancia={listDerechos}
      ></DerechoModal>

      <Table
        columns={columns}
        dataSource={derechosGrado}
        pagination={{pageSize: 4}}
      />
    </MyLayout>
  )
}

export default DerechoGrado
