import React, {useEffect, useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  message,
  Select
} from "antd";
import { Content } from "antd/lib/layout/layout";
import jornadaService from "../../services/jornada.service";
import sedeService from "../../services/sede.service";

const JornadaModal = (props) => {


  const [form] = Form.useForm();
  const [sedes, setSedes] = useState([]);
  const [loading, setLoading] = useState(false);
  const empresa_id = 1;

  useEffect(() => {
    listSedes()
    form.resetFields()
  }, []);

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const listSedes = () => {
    setLoading(true);
    sedeService
      .get(empresa_id)
      .then((response) => {
        let filterSedes = response.data;
        
        setSedes(filterSedes);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    props.updateLoading(true);
    const form = values;
    form.empresa_id = empresa_id;
    jornadaService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListJornada();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  return (
    <Modal visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>

          <Col span={24}>
              <Form.Item
                label="Sede: "
                name="sede_id"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Sede",
                  },
                ]}
              >
                <Select>
                  {sedes.map((item) => (
                    <Select.Option key={item.id}>{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>


            <Col span={24}>
              <Form.Item
                label="Nombre: "
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default JornadaModal;
