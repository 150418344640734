import React, {useState, useEffect} from 'react';
import {Link,useLocation} from 'react-router-dom'
import { Breadcrumb, Table, Button, Spin, Form, Input, Col, Row,Select, message, Divider,Timeline,Card , Steps, Modal, Badge ,  DatePicker, TimePicker } from "antd";
import MyLayout from "../../components/MyLayout";
import leadService from "../../services/lead.service";
import medioService from "../../services/medio.service";
import canalService from "../../services/canal.service";
import preguntaService from "../../services/pregunta.service";
import actividadService from "../../services/actividad.service";
import ActividadModal from '../../components/modals/ActividadModal';
import FaseModal from '../../components/modals/FaseModal';
import { ClockCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment'
import "./VerLead.css"
import HistorialFaseModal from '../../components/modals/HistorialFaseModal';
import LeadEstudianteModal from '../../components/modals/LeadEstudianteModal';
import faseService from '../../services/fase.service';
import { useNavigate } from "react-router-dom";
import ResultadoTareaModal from '../../components/modals/ResultadoTareaModal';


const { TextArea } = Input;
const { Step } = Steps;
const {confirm} = Modal;
const VerLead = () => {
    let location = useLocation();
    let myLead = location.state.lead
    const [lead,setLead] = useState(null);
    const [fases,setFases] = useState(null);
    const [medios, setMedios] = useState([]);
    const [preguntas, setPreguntas] = useState([]);
    const [respuestas, setRespuestas] = useState([]);
    const [canales, setCanales] = useState([]);
    const [loading, setLoading] = useState(false);
    const [current,setCurrent] = useState()
    const [DBcurrent,setDBCurrent] = useState()
    const [viewModalFase,setViewModalFase] = useState(false)
    const [viewModalHistorial,setViewModalHistorial] = useState(false)
    const [viewModalEstudiante,setViewModalEstudiante] = useState(false)
    const [faseSelected,setFaseSelected] = useState(1)
    const [form] = Form.useForm();
    const [viewNewActividad, setViewNewActividad] = useState(false);
  const [actividadesProgramadas, setActividadesProgramadas] = useState([]);
  const [actividadesFinalizadas, setActividadesFinalizadas] = useState([]);
  const [viewPreguntas, setViewPreguntas] = useState(false);
  const [viewModalResultado, setViewModalResultado] = useState(false);
  const [tareaSelected, setTareaSelected] = useState(false);

  const [viewEditActividad, setViewEditActividad] = useState(false);
  const [actividadSelected, setActividadSelected] = useState(false);
  const [formEditActividad] = Form.useForm()

  const navigate = useNavigate();
  const columns = [
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "tipo",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Hora",
      dataIndex: "hora",
      key: "tipo",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Descripcion",
      dataIndex: "descripcion",
      key: "descripcion",
      // render: (text) => <a>{text}</a>,
    },

    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      // render: (text) => <a>{text}</a>,
    },
  ]

    const empresa_id = 1;
    const usuario_id = 1;
    useEffect(() => {
        getLead();
        listMedios();
        listActividades();
        listCanales()
       
        
      }, []);

      const listMedios = () => {
        setLoading(true);
        medioService
          .getAll()
          .then((response) => {
            let filterMedios = response.data;
           
            filterMedios = response.data.filter(
              (item) => item.empresa_id == empresa_id
            );
            setMedios(filterMedios);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const listCanales = () => {
        setLoading(true);
      canalService
          .getAll()
          .then((response) => {
            let filterCanales = response.data;
      
            filterCanales = response.data.filter(
              (item) => item.empresa_id == empresa_id
            );
            setCanales(filterCanales);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const newActividad = () =>{
        setViewNewActividad(true) 
      }

      const listActividades = () => {
        setLoading(true);
        actividadService
          .get(myLead.id)
          .then((response) => {
            console.log(response.data)
            let filterActividadesProgramadas = response.data;
            let filterActividadesFinalizadas = response.data;
        
            filterActividadesProgramadas = response.data.filter(
               (item) => item.estado == "Programada"
             );

             filterActividadesFinalizadas = response.data.filter(
              (item) => item.estado == "Finalizada"
            );
            setActividadesProgramadas(filterActividadesProgramadas);
            setActividadesFinalizadas(filterActividadesFinalizadas);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const getLead = () => {
        setViewPreguntas(false)
        setLoading(true);
        leadService
          .get(myLead.id)
          .then((response) => {
            let filterLead = response.data;
            console.log(response.data);
            // filterMedios = response.data.filter(
            //   (item) => item.empresa_id == empresa_id
            // );
           
            setLead(filterLead);
           
            let fases = filterLead.Fase.Embudo.Fases
            setFases(fases)

            let indexFase =  fases.findIndex(object => {
              return object.nombre === filterLead.Fase.nombre;
            });

            console.log("MY INDICE", indexFase)
            console.log("MY INDICE", filterLead.Fase.nombre)

            setCurrent(indexFase)
            setDBCurrent(indexFase)

            form.setFieldsValue(filterLead)

            // if(response.data.LeadPregunta.length>0){
            //   setPreguntasDefault()
            // }

            console.log("PREGUNTAS",response.data.LeadPregunta)
             listPreguntas(response.data.LeadPregunta);

            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const finalizarLead = (data) =>{
       
        let fase_id;
        let lead_id = myLead.id
        let listaFases = fases.filter(item=>item.nombre == "Cerrado ganado")
        if(listaFases.length>0){
          console.log(listaFases)
          fase_id = listaFases[0].id
      
    faseService
      .changeFase({fase_id,lead_id})
      .then((response) => {
        console.log(response.data);
       setLoading(false);
        navigate("/ver-estudiante",{ state: { estudiante: data } });
        message.success("Guardado Correctamente");
     
      })
      .catch((e) => {
        setLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
    }
      }

      const listPreguntas = (myData) =>{
        setLoading(true);
        preguntaService
          .get(empresa_id)
          .then((response) => {
            let preguntas = [];
          
            for(let data of response.data){
              let infoData = data
              let buscar = myData.filter(item=>item.pregunta_id == data.id)
            
              if(buscar.length>0){
                if(data.tipo == "Múltiples opciones (Múltiple respuesta)"){
                  infoData.valorDefecto = stringToObjects(buscar[0].valor)
                } else{
                infoData.valorDefecto = buscar[0].valor
              }
  
              }
              preguntas.push(infoData)
            }
            // filterMedios = response.data.filter(
            //   (item) => item.empresa_id == empresa_id
            // );

            console.log("PREGUNTAS",preguntas)
        
            setPreguntas(preguntas)
            setLoading(false);

             setViewPreguntas(true)
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      }


      const stringToObjects = (data)=>{
        var mydata = data.replace("[","").replace("]","")
        let array = mydata.split(','); 
        let array2 = []
        
        for(let item of array){
            array2.push(JSON.parse(item))
        }
        return array2
      }


      const onFinish = (values) => {
      
        setLoading(true);
        let form = values
        form.respuestas = respuestas
        
        leadService.update(lead.id,form).then((response) => {
          
            getLead()
            setLoading(false);
            
           
            message.success("Guardado Correctamente");
          })
          .catch((e) => {
            setLoading(false);
            message.error("No se ha podido guardar");
            console.log(e);
          });
      }
   
      const sendValue=(value,id,tipo)=>{
     
        let respuesta = {};
        let valorEnviar;
        if(tipo != "Casilla de redacción"){
          valorEnviar = value
        }
        if(tipo == "Casilla de redacción" || tipo == "Fecha"){
          valorEnviar = value.target.value
        }

        if(tipo == "Múltiples opciones (Múltiple respuesta)"){
          valorEnviar = JSON.stringify(value)
       }

       respuesta = {
        valor:valorEnviar,
        lead_id:myLead.id,
        pregunta_id:id
       }
       

  

       let find = respuestas.filter((respuesta) => respuesta.pregunta_id == id && respuesta.lead_id == myLead.id);

       if (find.length == 0) {
       const pushItem = [...respuestas, respuesta];
      setRespuestas(pushItem);
    }
    else{
      const newState = respuestas.map((obj) => {
        if (obj.pregunta_id === id) {
          obj.valor = valorEnviar
          return {...obj}
        }
        return obj;
      });
      setRespuestas(newState);
    }
        // form.setFieldsValue({
        //   [id]: value
        // })
      }

      const changeFase = (value,item) =>{
        setViewModalFase(true)
       setFaseSelected(fases[value])
        setCurrent(value)
      }

      const finallyTarea = (id) =>{
        setViewModalResultado(true)
        setTareaSelected(id)
        // confirm({
        //   title:"Desea Finalizar la actividad?",
        //   okText:"Si",
        //   okType:'danger',
        //   cancelText:"No",
        //   onOk(){
        //     setLoading(true);
        // actividadService.finallyTarea(id)
        //   .then((response) => {
        //     listActividades()
        //     setLoading(false);
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //     setLoading(false);
        //   });
        //   }
        // })
        
      }

      const eliminarActividad = (id) =>{
confirm({
          title:"Desea eliminar la actividad?",
          okText:"Si",
          okType:'danger',
          cancelText:"No",
          onOk(){
            setLoading(true);
        actividadService.delete(id)
          .then((response) => {
            listActividades()
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
          }
        })
      } 

      const editarActividad = (item) =>{
          setViewEditActividad(true)
          setActividadSelected(item)
          console.log(item)
          formEditActividad.setFieldsValue({
            descripcion:item.descripcion,
            fecha:moment(item.fecha).format("YYYY-MM-DD"),
            resultado:item.resultado,
            hora:item.hora,
            estado:item.estado
          })
      }

      const actualizarTarea = (values) =>{
        setLoading(true)
        //console.log(values)
       // console.log(actividadSelected.id)
        let form = values;
        form.id = actividadSelected.id
        actividadService.updateAll(form).then(res=>{
          setLoading(false)
          listActividades();
          setViewEditActividad(false)
          formEditActividad.resetFields()
          message.success("Actualizado")
        })
      }

      const viewHistorial = () =>{
        setViewModalHistorial(true)
      }

      const viewNewEstudiante = () =>{
        setViewModalEstudiante(true)
      }
    

    if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
    return (
        <MyLayout>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>CRM</Breadcrumb.Item>
          <Breadcrumb.Item>Lead </Breadcrumb.Item>
        </Breadcrumb>

        <Divider orientation="left">Información General</Divider>

        <Card>
  
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
       onFinish={onFinish}
           form={form}
        >
          <Row>
          <Col span={8}>
              <Form.Item
                label="Fecha: "
                name="createdAt"
                
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha",
                   
                  },
                ]}
              >
                <Input type='text' disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Nombre: "
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nombre",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Identificación: "
                name="numId"
                rules={[
                  { required: false, message: "Por favor ingrese Identificación" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Celular: "
                name="celular"
                rules={[
                  { required: true, message: "Por favor ingrese Celular" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Correo: "
                name="correo"
                rules={[
                  { required: false, message: "Por favor ingrese Correo" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Telefono: "
                name="telefono"
                rules={[
                  { required: false, message: "Por favor ingrese Telefono" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Fecha Nacimiento: "
                name="fechaNacimiento"
                rules={[
                  { required: false, message: "Por favor ingrese Fecha Nacimiento" },
                ]}
              >
                <Input type='date' />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Direccion: "
                name="direccion"
                rules={[
                  { required: false, message: "Por favor ingrese Direccion" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Municipio: "
                name="municipio"
                rules={[
                  { required: false, message: "Por favor ingrese Municipio" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Medio de Contacto: "
                
                name="medio"
                rules={[
                  { required: true, message: "Por favor ingrese Medio" },
                ]}
              >
                <Select>
                  {medios.map((item) => (
                    <Select.Option key={item.nombre} >{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* <Col span={8}>
              <Form.Item
                label="Canal de Contacto: "
                
                name="canal"
                rules={[
                  { required: true, message: "Por favor ingrese Canal" },
                ]}
              >
                <Select>
                  {canales.map((item) => (
                    <Select.Option key={item.nombre} >{item.nombre}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}

            {/* <Col span={8}>
              <Form.Item
                label="Presupuesto: "
                
                name="presupuesto"
                rules={[
                  { required: false, message: "Por favor ingrese Presupuesto" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col> */}

            {preguntas && viewPreguntas && (
          preguntas.map(item=>{
            return <Col span={8}>
             
              <Form.Item
                label= {item.nombre}
                name={item.id}
                rules={[
                  { required: false, message: "Por favor ingrese "+item.nombre },
                ]}
              >
               

                {item.tipo == "Múltiples opciones (Única respuesta)" && (
                   <Select onChange={(e)=>{sendValue(e,item.id,item.tipo)}} defaultValue={item.valorDefecto}>
                     {stringToObjects(item.data).map((item)=>{
                      return  <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                    })}
                   </Select>
                )}

                {item.tipo == "Múltiples opciones (Múltiple respuesta)" && (
                   <Select onChange={(e)=>{sendValue(e,item.id,item.tipo)}} mode="multiple" defaultValue={item.valorDefecto}>
                    {stringToObjects(item.data).map((item)=>{
                        return  <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                      })}
                   </Select>
                )}

                  {item.tipo == "Casilla de redacción" && (
                   <Input onChange={(e)=>{sendValue(e,item.id,item.tipo)}} defaultValue={item.valorDefecto}></Input>
                )}

                {item.tipo == "Sí o No" && (
                   <Select onChange={(e)=>{sendValue(e,item.id,item.tipo)}} defaultValue={item.valorDefecto}>
                   <Select.Option value="Sí">Sí</Select.Option>
                   <Select.Option value="No">No</Select.Option>
                 </Select>
                )}

                {item.tipo == "Fecha" && (
                   <Input onChange={(e)=>{sendValue(e,item.id,item.tipo)}} type="date" defaultValue={item.valorDefecto}></Input>
                )}
               
              </Form.Item>
            </Col>
          })
        )}

{lead && (
<Col span={8}>
      
             
              <div style={{marginBottom:'20px'}}>  <label htmlFor="">Responsable:</label> <span className='responsable'>{lead.Usuario.nombre}</span></div>
             
            </Col>
             )}

            <Col span={24}>
              <Form.Item
                label="Observaciones generales: "
                
                name="observaciones"
                rules={[
                  { required: false, message: "Por favor ingrese Observaciones" },
                ]}
              >
               <TextArea  />
              </Form.Item>
            </Col>

           

            <Col span={24}>

            <Form.Item wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
      </Col>

      

          </Row>
        </Form>

        </Card>

        <Divider orientation="left">Estado de Negociación</Divider>
        <Card>
        <Row>
        <Col span={24}>
          { myLead.Fase && fases && (
            <Steps size="small" current={current}  onChange={changeFase} >
            {fases && (
               fases.map(item=>{
               return <Step key={item.id} title={item.nombre}   />
               })
            
             )}
            </Steps>
          )}
                
            </Col>
        </Row>

        <Row>
        <Col span={6} className="btn_historial">
        <a onClick={viewHistorial}>Ver Historial</a>
        </Col>

        <Col span={6} className="btn_historial">
        <a onClick={viewNewEstudiante}>Convertir a Estudiante</a>
        </Col>
         
        </Row>
        </Card>
    
        <Divider orientation="left">Actividades </Divider>
        <Card>
       
        <Button className='btn_actividad' type='primary' onClick={newActividad}>Nueva Actividad</Button>

        {actividadesProgramadas.length>0 && (
          <>
        <h3>Actividades Programadas</h3>
        <Timeline mode="left">

        {actividadesProgramadas.map((item)=> (
          
          <Timeline.Item label={item.tipo} dot={<ClockCircleOutlined/>}>
            <Card> 
            <div className='descripcion'>{item.descripcion} - <span className='fecha'>{moment(moment(item.fecha).format('YYYY-MM-DD') + " " + item.hora).startOf('hours').fromNow()}</span>   
            <div className="fecha">{moment(item.fecha).format('DD/MM/YYYY')} </div> 
              <div className="fecha">{ moment(moment(item.fecha).format('YYYY-MM-DD') + " " + item.hora).format('hh:mm a')} </div>
              {item.Usuario && (
              <div className="usuario">Creada por: {item.Usuario.nombre}</div>  
              )}
               </div>  
            
             
            
      
             

              { item.resultado != null && (
                <div className='resultado'>
                  <div> {item.tipoResultado} <hr /> <div className="fecha">   {moment(item.fechaResultado).format('DD/MM/YYYY')} </div> </div>
                 <div className='txtResultado'> {item.resultado}</div>
                  </div>
              )}

{item.estado == "Programada" && (
             <>
             <Button onClick={()=>finallyTarea(item.id)} >Finalizar Actividad</Button>
              <Button type='primary' onClick={()=>editarActividad(item)} ><EditOutlined/></Button>
              <Button type='danger' onClick={()=>eliminarActividad(item.id)} ><DeleteOutlined/></Button>
             </> 
              )}
              
            </Card> 
              </Timeline.Item>
          
          ))}
      </Timeline>
      </>
        )}


{actividadesFinalizadas.length>0 && (
    <>
<h3>Actividades Finalizadas</h3>
<Timeline mode="left">

  {actividadesFinalizadas.map((item)=> (
    
    <Timeline.Item label={item.tipo} color="green">
      
      
      <Card> 
      <div className='descripcion'>{item.descripcion}  <div className="fecha">{moment(item.fecha).format('DD/MM/YYYY')} </div> 
        <div className="fecha">{ moment(moment(item.fecha).format('YYYY-MM-DD') + " " + item.hora).format('hh:mm a')} </div>
        {item.Usuario && (
              <div className="usuario">Creada por: {item.Usuario.nombre}</div>  
              )}
         </div>  

       
       

        { item.resultado != null && (
                <div className='resultado'>
                  <div> {item.tipoResultado}   <hr /> <div className="fecha">   {moment(item.fechaResultado).format('DD/MM/YYYY')} </div> </div>
                 <div className='txtResultado'> {item.resultado}</div>
                 
                  </div>
              )}

<Button type='primary' onClick={()=>editarActividad(item)} ><EditOutlined/></Button>
              <Button type='danger' onClick={()=>eliminarActividad(item.id)} ><DeleteOutlined/></Button>

       
      </Card> 

      </Timeline.Item>
    
    ))}
</Timeline>
  </>
  )}


        </Card>



        <ActividadModal
        lead_id = {myLead.id}
        visible={viewNewActividad}
        updateVisible={setViewNewActividad}
        updateLoading={setLoading}
        updateListActividad={listActividades}
      ></ActividadModal>

<FaseModal
        fase_id = {faseSelected.id}
        lead_id = {myLead.id}
        fase_actual = {DBcurrent}
        visible={viewModalFase}
        updateVisible={setViewModalFase}
        updateLoading={setLoading}
        updateCurrent={setCurrent}
      ></FaseModal>

      <HistorialFaseModal visible={viewModalHistorial} updateVisible={setViewModalHistorial} lead_id = {myLead.id}>

      </HistorialFaseModal>

      {
        viewModalEstudiante && (
          <LeadEstudianteModal visible={viewModalEstudiante} finalizarLead={finalizarLead} updateVisible={setViewModalEstudiante} updateLoading={setLoading}>
            {lead}
          </LeadEstudianteModal>
        )
      }

      {viewModalResultado && (
          <ResultadoTareaModal
          tarea_id = {tareaSelected}
          visible={viewModalResultado}
          updateVisible={setViewModalResultado}
          updateLoading={setLoading}
          updateListActividad={listActividades}
          updateNewActividad={setViewNewActividad}
        ></ResultadoTareaModal>
      )}


      {viewEditActividad && (
        <Modal visible={viewEditActividad} title="Editar Actividad" onCancel={()=>{setViewEditActividad(false)}} onOk={formEditActividad.submit} okText="Actualizar">
          <Form form={formEditActividad} layout='vertical' onFinish={actualizarTarea}>

          <Form.Item
                  label="Tarea: "
                  name="estado"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Estado de Tarea",
                    },
                  ]}
                >
                 <Select>
                    <Select.Option value="Programada">Programada</Select.Option>
                    <Select.Option value="Finalizada">Finalizada</Select.Option>
                   
                 </Select>
                </Form.Item>

            <Form.Item  label="Descripción: "
                name="descripcion"  rules={[
                  {
                    required: true,
                    message: "Por favor ingrese descripcion",
                  },
                ]}>
              <TextArea />
            </Form.Item>


            <Form.Item
                  label="Fecha: "
                  name="fecha"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha",
                    },
                  ]}
                >
                 <Input type='date' />
                </Form.Item>

                <Form.Item  label="Resultado: "
                name="resultado"  rules={[
                  {
                    required: true,
                    message: "Por favor ingrese resultado",
                  },
                ]}>
              <Input />
            </Form.Item>

                <Form.Item
                  label="Hora: "
                  name="hora"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Hora",
                    },
                  ]}
                >
                 <Input type="time" use12Hours format="h:mm A"  minuteStep={15} placeholder='Hora' style={{ width: 140 }} />
                </Form.Item>
          </Form>
        </Modal>
      )}

      </MyLayout>
    );
};

export default VerLead;