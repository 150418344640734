import React, {createContext,useContext, useState} from 'react';

export const authContext = createContext()

export const useAuth = () =>{
    const context = useContext(authContext)
    if(!context) throw new Error("No User")
    return context
}

export function AuthProvider ({children}) {
    //let userTest;
     //let userTest = {"empresa_id": 1, "id": 1,"nombre": "Julian Benavides","password": "Julian123", "username": "julian" }
     const usuario = JSON.parse(window.localStorage.getItem('user'));
     const rol = window.localStorage.getItem('role');
     
    const [user,setUser] = useState(usuario)
    const [role,setRole] = useState(rol)
   
   

    const saveUser = (user) => {
        setUser(user)
        window.localStorage.setItem('user', JSON.stringify(user))
    }

    const saveRole = (role) => {
        setRole(role)
        window.localStorage.setItem('role', role)
    }


    return (
        <authContext.Provider value={{user,saveUser,role,saveRole}}>
            {children}
        </authContext.Provider>
    );
};

