import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import { Breadcrumb, Table, Button, Spin, Input } from "antd";
import MyLayout from "../../components/MyLayout";
import jornadaService from "../../services/jornada.service";
import sedeService from "../../services/sede.service";
import JornadaModal from "../../components/modals/JornadaModal";
import moment from 'moment'
import {SearchOutlined} from '@ant-design/icons';

const Jornada = () => {
    const [viewNewJornada, setViewNewJornada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jornadas, setJornadas] = useState([]);
    const [sede, setSede] = useState([]);
    const empresa_id = 1;

    useEffect(() => {
     
        listJornadas();
      }, []);


      const listJornadas = () => {
        setLoading(true);
        sedeService
          .getForain(empresa_id)
          .then((response) => {
            let filterJornada = response.data[0].Jornadas;
            console.log("DATA",response.data);
            // filterMedios = response.data.filter(
            //   (item) => item.empresa_id == empresa_id
            // );
            setSede(response.data[0])
            setJornadas(filterJornada);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

    const columns = [

        {
            title: "Sede",
           
             render: (text) => <div>{sede.nombre}</div>,
          },
        
        {
            title: "Jornada",
            dataIndex: "nombre",
            key: "nombre",
            // render: (text) => <a>{text}</a>,
          },
       
         
          {
            title: "Fecha Registro",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
          },

          {
            title: "Opciones",
           
            key: "action",
             //render: (medio) => <div>{medio.Medio.nombre}</div>,
          },
      ];

      
      const newJornada = () => {
        setViewNewJornada(true);
      };


  if (loading)
      return (
        <div className="spin_container">
          <Spin className="spin" size="large" />
        </div>
      );
    return (
        <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Institucional</Breadcrumb.Item>
        <Breadcrumb.Item>Jornadas</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newJornada}>
        Crear Jornada
      </Button>

      <JornadaModal
        visible={viewNewJornada}
        updateVisible={setViewNewJornada}
        updateLoading={setLoading}
        updateListJornada={listJornadas}
      ></JornadaModal>

      

      <Table
        columns={columns}
        dataSource={jornadas}
        pagination={{ pageSize: 4 }}
      />
    </MyLayout>
    );
};

export default Jornada;