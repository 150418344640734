import React, { useState, useEffect } from "react";
import MyLayout from "../../components/MyLayout";
import { Breadcrumb, Table, Button, Spin } from "antd";
import WorkgroupModal from "../../components/modals/WorkgroupModal";
import workgroupService from "../../services/workgroup.service";
const Workgroup = () => {
  const [viewNewWorkgroup, setViewNewWorkgroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workgroups, setWorkgroups] = useState([]);
  const empresa_id = 1;

  useEffect(() => {
    listWorkgroup();
  }, []);

  const listWorkgroup = () => {
    setLoading(true);
    workgroupService
      .getAll()
      .then((response) => {
        let filterWorkgroups = response.data;
        console.log(response.data);
        filterWorkgroups = response.data.filter(
          (item) => item.empresa_id == empresa_id
        );
        setWorkgroups(filterWorkgroups);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "1",
      // render: (text) => <a>{text}</a>,
    },
  ];

  const data = [
    {
      key: "1",
      nombre: "Ventas",
    },
    {
      key: "2",
      nombre: "Marketing",
    },
    {
      key: "3",
      nombre: "Administrativos",
    },
  ];

  const newWorkgroup = (usuario) => {
    setViewNewWorkgroup(true);
  };

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
  return (
    <MyLayout>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Ajustes</Breadcrumb.Item>
        <Breadcrumb.Item>Grupos de Trabajo</Breadcrumb.Item>
      </Breadcrumb>

      <Button type="primary" onClick={newWorkgroup}>
        Crear Grupo de Trabajo
      </Button>

      <WorkgroupModal
        visible={viewNewWorkgroup}
        updateVisible={setViewNewWorkgroup}
        updateLoading={setLoading}
        updateListWorkgroup={listWorkgroup}
      ></WorkgroupModal>

      <Table
        columns={columns}
        dataSource={workgroups}
        pagination={{ pageSize: 5 }}
      />
    </MyLayout>
  );
};

export default Workgroup;
