import http from "../utils/http-common";

class TarifaService {
    getAll() {
      return http.get("/tarifas");
    }
    get(id) {
      return http.get(`/tarifas/${id}`);
    }

    getForain(id) {
      return http.get(`/tarifas/forain/${id}`);
    }

    create(data) {
      return http.post("/tarifas", data);
    }
    update(id, data) {
      return http.put(`/tarifas/${id}`, data);
    }
    delete(id) {
      return http.delete(`/tarifas/${id}`);
    }
   
  }
  export default new TarifaService();