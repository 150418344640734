import http from "../utils/http-common";

class MejoramientoService {
    getAll() {
      return http.get("/mejoramiento");
    }
    get(id) {
      return http.get(`/mejoramiento/${id}`);
    }

    getForain(id) {
      return http.get(`/mejoramiento/forain/${id}`);
    }

    create(data) {
      return http.post("/mejoramiento", data);
    }
    update(id, data) {
      return http.put(`/mejoramiento/${id}`, data);
    }
    delete(id) {
      return http.delete(`/mejoramiento/${id}`);
    }

    inactive(id) {
      return http.get(`/mejoramiento/inactive/${id}`);
    }

    reactive(id) {
      return http.get(`/mejoramiento/reactive/${id}`);
    }
   
  }
  export default new MejoramientoService();