import http from "../utils/http-common";

class CursoService {
    getAll() {
      return http.get("/cursos");
    }
    get(id) {
      return http.get(`/cursos/${id}`);
    }

    getForain(id) {
      return http.get(`/cursos/forain/${id}`);
    }

    getForainEstado(id,id2) {
      return http.get(`/cursos/estado/${id}/${id2}`);
    }

    getForainEstadoAll(id,id2) {
      return http.get(`/cursos/all/${id}/${id2}`);
    }

    finalizar(id) {
      return http.get(`/cursos/finalizar/${id}`);
    }


    inactive(id) {
      return http.get(`/cursos/inactive/${id}`);
    }


    create(data) {
      return http.post("/cursos", data);
    }
    update(id, data) {
      return http.put(`/cursos/${id}`, data);
    }
    delete(id) {
      return http.delete(`/cursos/${id}`);
    }

    nextLevel(data) {
      return http.post("/cursos/nextlevel", data);
    }

    reactivar(id) {
      return http.get(`/cursos/reactivar/${id}`);
    }
   
  }
  export default new CursoService();