import React from 'react';
import {
    Modal,
    Form,
    Col,
    Row,
    Input,
    DatePicker,
    Select,
    message,
    TimePicker 
  } from "antd";
  import { Content } from "antd/lib/layout/layout";
  import actividadService from "../../services/actividad.service";
  import { useAuth } from '../../context/auth.context';
  import moment from 'moment';
  const { TextArea } = Input;

const ActividadModal = (props) => {
  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const asesor_id = user.id;
    const [form] = Form.useForm();
 
  const handleCancel = () => {
    props.updateVisible(false);
  };
  
  const onFinish = (values) => {
    //props.updateLoading(true);
    const form = values;
    form.asesor_id = asesor_id;
    form.lead_id = props.lead_id;
    form.hora = moment(values.hora._d).format('HH:mm:ss')
    console.log(form)
    actividadService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListActividad();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

    return (
        <Modal title="Crear nueva actividad" visible={props.visible} onOk={form.submit} onCancel={handleCancel}>
           
        <Content>
     
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row>

            <Col span={24}>
                <Form.Item
                  label="Tarea: "
                  name="estado"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Estado de Tarea",
                    },
                  ]}
                >
                 <Select>
                    <Select.Option value="Programada">Programada</Select.Option>
                    <Select.Option value="Finalizada">Finalizada</Select.Option>
                   
                 </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Tipo: "
                  name="tipo"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Tipo",
                    },
                  ]}
                >
                 <Select>
                    <Select.Option value="Llamada">Llamada</Select.Option>
                    <Select.Option value="WhatsApp">WhatsApp</Select.Option>
                    <Select.Option value="Nota">Nota</Select.Option>
                    <Select.Option value="Correo">Correo</Select.Option>
                    <Select.Option value="Reunión">Reunión</Select.Option>
                    <Select.Option value="Exámen Diagnóstico">Exámen Diagnóstico </Select.Option>
                    <Select.Option value="Entrega de resultados">Entrega de resultados </Select.Option>
                 </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Fecha: "
                  name="fecha"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Fecha",
                    },
                  ]}
                >
                 <DatePicker placeholder='Fecha'></DatePicker>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Hora: "
                  name="hora"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Hora",
                    },
                  ]}
                >
                 <TimePicker use12Hours format="h:mm A"  minuteStep={15} placeholder='Hora' style={{ width: 140 }} />
                </Form.Item>
              </Col>

              
              <Col span={24}>
                <Form.Item
                  label="Descripción: "
                  name="descripcion"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Descripción",
                    },
                  ]}
                >
                 <TextArea  />
                </Form.Item>
              </Col>



            </Row>
          </Form>
        </Content>
      </Modal>
    );
};

export default ActividadModal;