import React, { useState, useEffect } from 'react';
import pagoService from '../../services/pago.service';
import {
    Modal,
    Form,
    Col,
    Row,
    Input,
    message,
    Select, 
    Table,
     Button
  } from "antd";
  import {  OrderedListOutlined} from '@ant-design/icons';
import moment from 'moment';
 import { NumericFormat } from 'react-number-format';

const DetalleLiquidacionDocenteModal = (props) => {
    const [pagos, setPagos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewDetalle,setViewDetalle] = useState(false)
    const [detalleSelected,setDetalleSelected] = useState()
    const liquidacion = props.children

    const columns = [
        {
        title: "Cod",
        dataIndex: "relacion_id",
            key: "relacion_id",
    },

    {
        title: "Tipo",
        dataIndex: "tipo",
            key: "tipo",
    },
    {
        title: "Concepto",
        dataIndex: "concepto",
            key: "concepto",
    },

    {
        title: "Jornada",
        dataIndex: "jornada",
            key: "jornada",
    },

    {
        title: "horas",
        dataIndex: "horas",
            key: "horas",
    },

    {
        title: "No. Estudiantes",
        dataIndex: "numEstudiantes",
            key: "numEstudiantes",
    },
    {
        title: "Tarifa",
        render: (record) => <div>$ <NumericFormat value={record.tarifa} displayType="text" allowLeadingZeros thousandSeparator="," /> </div>,
    },

    {
        title: "SubTotal",
        render: (record) => <div>$ <NumericFormat value={record.valor} displayType="text" allowLeadingZeros thousandSeparator="," /> {record.moneda}</div>,
    },
   
    {
        title: "Opciones",
         render: (record) => <div>
          <Button type='info' onClick={()=>verDescripcion(record)}> <OrderedListOutlined/> </Button>
         
          </div>,
      },
   

    // {
    //     title: "Fecha Registro",
  
    //     render: (record) => {let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
    //   },
]

const columnsVista = [
    {
    title: "Fecha",
    render:(record)=><div>{moment(record.fecha).format("DD-MM-YYYY")}</div>
},

{
    title: "No. Estudiantes",
    dataIndex: "numEstudiantes",
        key: "numEstudiantes",
},

{
    title: "Horas",
    dataIndex: "horas",
        key: "horas",
},

{
    title: "Tarifa",
    render: (record) => <div>$ <NumericFormat value={record.tarifa} displayType="text" allowLeadingZeros thousandSeparator="," /> </div>,
},

{
    title: "SubTotal",
    render: (record) => <div>$ <NumericFormat value={record.valor} displayType="text" allowLeadingZeros thousandSeparator="," /> {record.moneda}</div>,
},










// {
//     title: "Fecha Registro",

//     render: (record) => {let myFecha = moment(record.createdAt).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
//   },
]

    useEffect(() => {
      
      }, []);

      const handleCancel = () => {
        props.updateVisible(false);
      };

      const verDescripcion = (record) =>{
        setDetalleSelected(record)
        setViewDetalle(true)
      }

     
    return (
        <>
        <Modal width={1080} visible={props.visible} onOk={handleCancel} onCancel={handleCancel}>
            <Table columns={columns} dataSource={liquidacion.DetalleLiquidacionDocentes}></Table>
        </Modal>
        {viewDetalle && (
          
        <Modal width={1080} visible={viewDetalle} onOk={()=>setViewDetalle(false)} onCancel={()=>setViewDetalle(false)}>
          
          <h3> Cod. {detalleSelected.relacion_id} / {detalleSelected.concepto} / {detalleSelected.jornada}  </h3>
            <Table columns={columnsVista} dataSource={detalleSelected.VistaLiquidacionDocentes}></Table>
        </Modal>
        )}
        </>

    );
};

export default DetalleLiquidacionDocenteModal;