import React, { useEffect, useState } from 'react';
import MyLayout from '../../components/MyLayout';
import { Table, Button, Breadcrumb, Modal, Form, Input, Select, message, TimePicker, Spin, Row, Col } from 'antd';
import formularioService from '../../services/formulario.service';
import moment from 'moment';
import { useAuth } from '../../context/auth.context';
import matriculaExamenService from "../../services/matricula_examen.service";
const Formulario = () => {

    const [formularios,setFormularios] = useState([])
    const [examenSelected,setExamenSelected] = useState()
    const {user,role} = useAuth()
    const [viewOpciones,setViewOpciones] = useState(false)
    const [formOpcionesExamen] = Form.useForm();
    const usuario_id = user.id;
    const [loading, setLoading] = useState(false);
    const [viewTRM,setViewTRM] = useState(false)
    const [viewInfoSolicitud,setViewInfoSolicitud] = useState(false)
    const [tipos,setTipos] = useState([{tipo:'General'},{tipo:'Teachers'},{tipo:'Advanced'},
    ,{tipo:'Académico'}])
    const [modos,setModos] = useState([{modo:'Remoto'},
    {modo:'Presencial'}])

    useEffect(()=>{
        cargarForms()
    },[])

    const cargarForms = () =>{
        formularioService.getAll().then(res=>{
            setFormularios(res.data)
        }).catch(error=>{
            console.log(error)
        })
    }

    const verOpcionesMatricula = (record) =>{
        setExamenSelected(record)
        setViewOpciones(true)
        if(record.examen == "TOEFL"){
            setViewTRM(true)
        }
    }

    const verInfo = (record) =>{
      setExamenSelected(record)
      setViewInfoSolicitud(true)
    }


    const matricular = (examen) =>{
      
        console.log(examen)
        const form = {}
     
        
        form.usuario_id = usuario_id;
        form.fecha = examen.fecha;
        form.hora = examen.hora;
        form.tipo = examen.tipo;
        form.modo = examen.modo;
        form.prospecto = examenSelected
        form.valorTRM = examen.trm
        setLoading(true)
        matriculaExamenService
          .createFromForm(form)
          .then((response) => {
            console.log(response.data);
           cargarForms()
           setViewOpciones(false)
           formOpcionesExamen.resetFields()
            setLoading(false)
            message.success("Guardado Correctamente");
          })
          .catch((e) => {
            setLoading(false)
            message.error("No se ha podido guardar");
            console.log(e);
          });
      }

    const columns = [
        {
           title:'Nombres', 
           render:(record)=><div>{record.nombres}</div>
    },
    {
        title:'Apellidos', 
        render:(record)=><div>{record.apellidos}</div>
 },
 {
    title:'Correo', 
    render:(record)=><div>{record.correo}</div>
},

{
    title:'Tipo Id', 
    render:(record)=><div>{record.tipoId}</div>
},

{
    title:'No. Documento', 
    render:(record)=><div>{record.numId}</div>
},

{
    title:'No. Contacto', 
    render:(record)=><div>{record.nombreContacto}</div>
},

{
    title:'Tipo Exámen', 
    render:(record)=><div>{record.examen}</div>
},

{
    title:'Fecha de Exámen', 
    render: (record) => {
      if(record.fechaPrueba){
      let myFecha = moment(record.fechaPrueba).format("DD/MM/YYYY"); 
      return ( <div>{myFecha}</div> )
    }
    else {
      return ""
    }
    }
},

{
    title:'Jornada', 
    render:(record)=><div>{record.jornada}</div>
},

{
    title:'Estado', 
    render:(record)=><div>{record.estado}</div>
},

{
    title: "Fecha Registro",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (fecha) => {let myFecha = moment(fecha).format("DD/MM/YYYY hh:mm:ss a"); return ( <div>{myFecha}</div> )}
  },


{
    title:'Opciones', 
    render:(record)=>
    {if(record.estado == "Registrado"){
    return <>
    <div><Button type="primary" onClick={()=>verOpcionesMatricula(record)}>Aprobar</Button></div>
    <div><Button type="default" onClick={()=>verInfo(record)}>Ver</Button></div>
    </>
    } else{
      return <div><Button type="default" onClick={()=>verInfo(record)}>Ver</Button></div>
    }
}
},
]
if (loading)
return (
  <div className="spin_container">
    <Spin className="spin" size="large" />
  </div>
);
    return (

    
        <MyLayout>

<Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Académico</Breadcrumb.Item>
        <Breadcrumb.Item>Solicitud de Formularios Ex. Internacionales</Breadcrumb.Item>
      </Breadcrumb>
          
          <Table columns={columns} dataSource={formularios} scroll={{ x: '100%'}}></Table>


        <Modal visible={viewOpciones} title="Opciones Matricula" okText="Matricular" onOk={formOpcionesExamen.submit} onCancel={()=>{setViewOpciones(false)}}>
        <Form form={formOpcionesExamen} layout='vertical' onFinish={matricular}>
            <Form.Item
                label="Fecha de Presentación: "
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha",
                  },
                ]}
              >
               <Input type="date"></Input>
              </Form.Item>


              <Form.Item
                  label="Tipo: "
                  name="tipo"
                  rules={[
                    {
                      required: false,
                      message: "Por favor ingrese Tipo",
                    },
                  ]}
                >
                 <Select >
                  {tipos.map((item=>{
                    return <Select.Option value={item.tipo}>{item.tipo}</Select.Option>
                  }))}
                 </Select>
                </Form.Item>
           

             
                <Form.Item
                  label="Modo: "
                  name="modo"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Modo",
                    },
                  ]}
                >
                 <Select>
                  {modos.map((item=>{
                    return <Select.Option value={item.modo}>{item.modo}</Select.Option>
                  }))}
                 </Select>
                </Form.Item>
            

<Form.Item
                label="Hora de Presentación: "
                name="hora"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Hora",
                  },
                ]}
              >
               <TimePicker use12Hours format="hh:mm A" placeholder="Hora de Presentación"></TimePicker>
              </Form.Item>


                

               {viewTRM && (
                <Form.Item
                  label="Ingrese valor TRM del dia: "
                  name="trm"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese Valor Trm del dia",
                    },
                  ]}
                >
                 <Input type="number"></Input>
                </Form.Item>
                )}

          
              </Form>
        </Modal>


{examenSelected && (
        <Modal width={1024} visible={viewInfoSolicitud} title="Solicitud Examen" onOk={()=>{setViewOpciones(false)}} onCancel={()=>{setViewInfoSolicitud(false)}}>
                  <Row>
                    <Col span={12}>
                     <strong>Nombres: </strong> {examenSelected.nombres}
                    </Col>

                    <Col span={12}>
                     <strong>Apellidos: </strong> {examenSelected.apellidos}
                    </Col>

                    <Col span={12}>
                     <strong>Tipo Id: </strong> {examenSelected.tipoId}
                    </Col>

                    <Col span={12}>
                     <strong>No. Id: </strong> {examenSelected.numId}
                    </Col>

                    <Col span={12}>
                     <strong>Tipo de Exámen: </strong> {examenSelected.examen}
                    </Col>

                    <Col span={12}>
                     <strong>Fecha de Exámen: </strong> {examenSelected.fechaPrueba}
                    </Col>

                    <Col span={12}>
                     <strong>Tipo de Exámen Solicitado: </strong> {examenSelected.tipo}
                    </Col>

                    <Col span={12}>
                     <strong>Correo: </strong> {examenSelected.correo}
                    </Col>

                    <Col span={12}>
                     <strong>Correo Candidato: </strong> {examenSelected.correoCandidato}
                    </Col>

                    <Col span={12}>
                     <strong>Skill state: </strong> {examenSelected.skillstate}
                    </Col>

                    <Col span={12}>
                     <strong>Skills: </strong> {examenSelected.skills}
                    </Col>

                    <Col span={12}>
                     <strong>Consentimiento Adulto : </strong> {examenSelected.consentimiento}
                    </Col>

                    <Col span={12}>
                     <strong>Requisitos especiales: </strong> {examenSelected.discapacidad}
                    </Col>

                    <Col span={12}>
                     <strong>Detalles de los requisitos especiales: </strong> {examenSelected.details}
                    </Col>

                    <Col span={12}>
                     <strong>Jornada de Presentación: </strong> {examenSelected.jornada}
                    </Col>

                    <Col span={12}>
                     <strong>Código Postal: </strong> {examenSelected.postcode}
                    </Col>

                    <Col span={12}>
                     <strong>Country: </strong> {examenSelected.country}
                    </Col>

                    <Col span={12}>
                     <strong>Town: </strong> {examenSelected.town}
                    </Col>
                  </Row>
        </Modal>
        )}

        </MyLayout>
    );
};

export default Formulario;