import http from "../utils/http-common";

class ConstanciaService {
    getAll() {
      return http.get("/constancia");
    }
    get(id) {
      return http.get(`/constancia/${id}`);
    }


    getForain(id) {
      return http.get(`/constancia/forain/${id}`);
    }

    create(data) {
      return http.post("/constancia", data);
    }
    update(id, data) {
      return http.put(`/constancia/${id}`, data);
    }
    delete(id) {
      return http.delete(`/constancia/${id}`);
    }
   
  }
  export default new ConstanciaService();