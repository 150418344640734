import http from "../utils/http-common";

class CargaService {
    getAll() {
      return http.get("/carga");
    }
    get(id) {
      return http.get(`/carga/forain/${id}`);
    }

    finallyTarea(id) {
      return http.get(`/carga/finally/${id}`);
    }

    create(data,tipo) {
      return http.post(`/carga/${tipo}`, data);
    }
    update(id, data) {
      return http.put(`/carga/${id}`, data);
    }
    delete(id) {
      return http.delete(`/carga/${id}`);
    }
   
  }
  export default new CargaService();