import React,{useState,useEffect} from 'react';
import {Link,useLocation} from 'react-router-dom'
import MyLayout from '../../components/MyLayout';
import calendarioService from '../../services/calendario.service';
import inasistenciasService from "../../services/inasistencia.service"
import inasistenciaAdminService from '../../services/inasistencia_admin.service';
import { Table,Modal, Form, Select, Input, Tooltip, Avatar, Row, Col, message, Button } from 'antd';
import moment from 'moment';
import {LogoutOutlined,LineChartOutlined, CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, UserOutlined, CheckSquareOutlined, CloseSquareOutlined, EditOutlined } from '@ant-design/icons'
import "./VerInasistencia.css"
import { useAuth } from '../../context/auth.context';
const {confirm} = Modal;

const VerInasistencia = () => {

    useEffect(()=>{
      
        loadEvents()
       // loadSeguimientos()
     
      },[])

    let location = useLocation();
    
    let myCurso = location.state.curso
    let matriculas = myCurso.Matriculas
   
    const [myEvents, setEvents] = useState([])
    const [columns, setColumns] = useState([])
    const [inasistencias, setInasistencias] = useState([])
    const [viewData,setViewData] = useState(false)
    const [viewModalAsistencia,setViewModalAsistencia] = useState(false)
    const [viewModalSeguimiento,setViewModalSeguimiento] = useState(false)
    const [form] = Form.useForm();
    const [formSeguimiento] = Form.useForm();
    const [itemSelected,setItemSelected] = useState()
    const [daySelected,setDaySelected] = useState()
    const {role,user} = useAuth()
    const [historialAsistencias,setViewHistorialAsistencias] = useState(false)
    const [seguimientos, setSeguimientos] = useState([])
    const [listaInasistencias,setListaInasistencias] = useState([])

    const reportarAsistencia = (record,day)=>{
      form.resetFields()
      setViewModalAsistencia(true)
      setItemSelected(record)
      setDaySelected(day)
    }

    const reportarSeguimiento = (record,day) =>{

      formSeguimiento.resetFields()
   
      setViewModalSeguimiento(true)
      setItemSelected(record)
      setDaySelected(day)
    }

    

    const reportarSiAsistencia = (record,day)=>{

      let form = {
        estudiante_id:record.estudiante_id,
        curso_id:record.curso_id,
        fecha:moment(day).format("YYYY-MM-DD"),
        asiste:"Asiste",
      }
      inasistenciasService.create(form).then((response) => {
        if(response.status === 200){
        loadEvents()
        setViewModalAsistencia(false)
        message.success("Guardada")
      } else{
        message.error("No se guardo el registro")
      }
      })
      .catch((e) => {
        console.log(e);
        message.error("No se guardo el registro")
      });
     
    }


    const validarHorario = (record) =>{
      if(record.horaInicial){

        let cadenaHorario = ""
        let dias = JSON.parse(record.diasHorario)
        for(let item of dias){
          cadenaHorario += item.substr(0,3) + ", "
        }

        cadenaHorario += "- "+ moment(record.fechaInicial + " "+record.horaInicial).format('hh:mm a') + " a " + moment(record.fechaInicial + " "+record.horaFinal).format('hh:mm a')
        
        return cadenaHorario
      } else{
        return "No hay calendario asignado"
      }

    }

    const onCancel = () =>{
      setViewModalAsistencia(false)
    }

    const formatName = (record)=>{
      let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
      nombre = nombre.toUpperCase()
      return nombre
    }

    const eliminar = (item) =>{
      confirm({
        title:"Desea desmarcar inasistencia?",
        okText:"Si",
        okType:'danger',
        cancelText:"No",
        onOk(){
          inasistenciasService.delete(item.id).then((response) => {
           
            loadEvents()
          })
          .catch((e) => {
            console.log(e);
         
          });
        }})
    }

    const eliminarSeguimiento = (item) =>{
     
      confirm({
        title:"Desea eliminar el seguimiento?",
        okText:"Si",
        okType:'danger',
        cancelText:"No",
        onOk(){
          inasistenciaAdminService.delete(item.id).then((response) => {
           
            loadEvents()
           
          })
          .catch((e) => {
            console.log(e);
         
          });
        }})
    }

    const handleClickTitle = () =>{
      console.log("click")
    }


    const loadEvents = ()=>{
        calendarioService.getForain(myCurso.id).then((res) => {

          inasistenciasService.getForain(myCurso.id).then((response) => {
            inasistenciaAdminService.getForain(myCurso.id).then(seguimientosAdmin=>{

              let myAsistencias = response.data.filter(item=>item.asiste == "No asiste")
              console.log(myAsistencias)
            setInasistencias(response.data)
            setListaInasistencias(myAsistencias)
            setSeguimientos(seguimientosAdmin.data)

            let eventos = res.data
          let listaEventos = []
          let listaFechas= [{
            title:' ',
            responsive: ['md'], 
            fixed:'left',
            render:(record) => { if(record.Estudiante.url != null){
            return <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64 }} src={record.Estudiante.url}/>
          }
           else{
            return <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64, xxl: 64 }} icon={<UserOutlined />}/>
          } 
        }
          },{
            title:"Estudiante",
            fixed:'left',
            render:(record)=>{ 
           return <div> <Tooltip title={`Fecha Matricula ${moment(record.createdAt).format('DD-MM-YYYY')}`}> {formatName(record.Estudiante)} </Tooltip>
              {record.estado == "Retirado" && (
            <div>({record.estado})</div>
          )}
              </div>
              }
              }]
          for(let item of eventos){
            
            let myData = {
              
              id: item.id,
            title: item.title,
            start: moment(item.start).format("DD-MM-YYYY"),
            end: moment(item.end).format("DD-MM-YYYY")
            
            }


            
           
            listaFechas.push({
              
              title:myData.start,
              
                render:(record)=> {
                  if(record.estado == "Activo" && myCurso.estado == 'Activo' && (role == "Docente" || role == "Superadministrador" || role == "Administrativo" || role == "Seguimiento" || role == "Cumplimiento")){
                  let verificacion = verificar(moment(item.start).format("YYYY-MM-DD"),record.Estudiante.id,response.data)
                  let verificacionSeguimiento = verificarSeguimiento(moment(item.start).format("YYYY-MM-DD"),record.Estudiante.id,seguimientosAdmin.data)
                  
                  if(verificacion.length == 0){
                    let matricula = verificarMatricula(item.start,record.createdAt)
                    if(matricula >=0){
                      if(role != "Superadministrador"){
                      if(verificarDia(item.start) != 6){
                      if(verificarFecha(item.start)<=38){
                    return <>
                 
                    <div className='pendiente' onClick={()=>reportarSiAsistencia(record,item.start)}><CheckSquareOutlined /></div>
                    <div className='pendiente' onClick={()=>reportarAsistencia(record,item.start)}><CloseSquareOutlined /></div>
                    </>
                    }{
                      return <div>No Registra</div>
                    }
                  } else{
                    
                    if(verificarFecha(item.start)<=62){
                      return <>
                      <div className='pendiente' onClick={()=>reportarSiAsistencia(record,item.start)}><CheckSquareOutlined /></div>
                      <div className='pendiente' onClick={()=>reportarAsistencia(record,item.start)}><CloseSquareOutlined /></div>
                      </>
                    }
                  }
                } else{
                  return <>
                 
                    <div className='pendiente' onClick={()=>reportarSiAsistencia(record,item.start)}><CheckSquareOutlined /></div>
                    <div className='pendiente' onClick={()=>reportarAsistencia(record,item.start)}><CloseSquareOutlined /></div>
                    </>
                }
                  } else{
                    return <div>N/A</div>
                  }
                  }else{
                    if(verificacion[0].asiste == "No asiste"){
                      
                    return <>

                    {verificarFecha(item.start)<=38 && role != "Superadministrador" && (
                    <Tooltip title={verificacion[0].detalle}> <div className='inasistencia' onClick={()=>eliminar(verificacion[0])}><CloseCircleOutlined /></div></Tooltip>
                    )}

                  { role == "Superadministrador" && (
                    <Tooltip title={verificacion[0].detalle}> <div className='inasistencia' onClick={()=>eliminar(verificacion[0])}><CloseCircleOutlined /></div></Tooltip>
                    )}

                    {verificarFecha(item.start)>38 && role != "Superadministrador" && (
                    <Tooltip title={verificacion[0].detalle + verificarFecha(item.start)}> <div className='inasistencia' ><CloseCircleOutlined /></div></Tooltip>
                    )}
                    
                    {verificacionSeguimiento.length == 0 && (role == "Administrativo" || role == "Superadministrador" || role == "Seguimiento" || role == "Cumplimiento") && (
                      
                        <Tooltip title="Crear Seguimiento"> <div className='NoSeguimiento' onClick={()=>reportarSeguimiento(record,item.start)}><EditOutlined /></div></Tooltip>
                      
                    )}

            {verificacionSeguimiento.length != 0 && (role == "Administrativo" || role == "Superadministrador" || role == "Seguimiento" || role == "Cumplimiento") && (
              
                      <Tooltip title={verificacionSeguimiento[0].detalle}>  <div className='seguimiento' onClick={()=>eliminarSeguimiento(verificacionSeguimiento[0])}><EditOutlined /></div></Tooltip>
                    
                  )}

                  {verificacionSeguimiento.length != 0 && (role == "Docente") && (
                      <Tooltip title={verificacionSeguimiento[0].detalle}> <div className='seguimiento' ><EditOutlined /></div></Tooltip>
                    
                  )}
                    </>
                  } else{
                    if(verificarFecha(item.start)<=38 && role != "Superadministrador"){
                    return  <div className='asistencia' onClick={()=>eliminar(verificacion[0])}><CheckCircleOutlined /> </div>
                  } else if(role == "Superadministrador"){
                    return  <div className='asistencia' onClick={()=>eliminar(verificacion[0])}><CheckCircleOutlined /> </div>
                  } 
                  
                  else{
                    return  <div className='asistencia' ><CheckCircleOutlined />  </div>
                  }
                  }

                  
                  }
                } else{
                  let verificacion = verificar(moment(item.start).format("YYYY-MM-DD"),record.Estudiante.id,response.data)
                  
                  if(verificacion.length == 0){
                    return <div ><QuestionCircleOutlined /></div>
                  }else{
                    if(verificacion[0].asiste == "No asiste"){
                    return  <Tooltip title={verificacion[0].detalle}><div className='inasistencia' ><CloseCircleOutlined /></div></Tooltip>
                  } else{
                    return <div className='asistencia'><CheckCircleOutlined /></div>
                  }
                  }
                }
              }
            },
            )
          }
         
          setEvents(listaEventos)
          setColumns(listaFechas)
          setViewData(true)
            
          })
        })

       
        })
        .catch((e) => {
          console.log(e);
       
        });
      }

      const verificarMatricula = (fecha,fechaMat) =>{
        let fechaCalendario = moment(fecha).format('YYYY-MM-DD 00:00:00')
        let fechaMatricula =  moment(fechaMat).format('YYYY-MM-DD 23:59:59')
   
        let diferencia = moment(fechaCalendario).diff(fechaMatricula,'days')
       
        return diferencia
      }


      const verificarFecha = (fecha) =>{
        let fechaCalendario = moment(fecha).format('YYYY-MM-DD 00:00:00')
        let fechaHoy =  moment().format('YYYY-MM-DD HH:mm:ss')
        let diferencia = moment(fechaHoy).diff(fechaCalendario,'hours',true)
        return diferencia
      }

      const verificarDia = (fecha)=>{
        console.log(moment(fecha).day())
        return moment(fecha).day()
      }

      const verificar = (fecha, estudiante,inasistenciasList) =>{
          return inasistenciasList.filter(item=>item.fecha==fecha && item.estudiante_id == estudiante)
      }

      const verificarSeguimiento = (fecha, estudiante,seguimientosList) =>{
        return seguimientosList.filter(item=>item.fecha==fecha && item.estudiante_id == estudiante)
    }

    const viewHistoAsistencias = () =>{
      setViewHistorialAsistencias(true)
    }

    const buscarEstudiante = (id) =>{
      let filtro = matriculas.filter(item=>item.Estudiante.id == id)
      console.log(filtro)
      return formatName(filtro[0].Estudiante)
    }

     const onFinish = (values) =>{
      setViewModalAsistencia(false)
      let form = {
        estudiante_id:itemSelected.estudiante_id,
        curso_id:itemSelected.curso_id,
        fecha:moment(daySelected).format("YYYY-MM-DD"),
        asiste:"No asiste",
        detalle:values.detalle
      }
      inasistenciasService.create(form).then((response) => {
       
        loadEvents()
        setViewModalAsistencia(false)
        message.success("Guardada")
      })
      .catch((e) => {
        console.log(e);
        message.error("No se registro la inasistencia")
      });
     }

     const inasistenciasColumns = [{
      title: 'Fecha',
      render:(record)=><div>{moment(record.fecha).format("DD-MM-YYYY")}</div>
     },
     {
      title: 'Estudiante',
      render:(record)=><div>{buscarEstudiante(record.estudiante_id)}</div>
     },
     {
      title: 'Detalle',
      dataIndex: 'detalle'
     },
     
    ]

    const seguimientosColumns = [{
      title: 'Fecha',
      render:(record)=><div>{moment(record.fecha).format("DD-MM-YYYY")}</div>
     },
     {
      title: 'Estudiante',
      render:(record)=><div>{buscarEstudiante(record.estudiante_id)}</div>
     },
     {
      title: 'Detalle',
      dataIndex: 'detalle'
     },

     {
      title: 'Creado por',
      render:(record)=><div>{record.Usuario.nombre}</div>
     },
     
    ]

     const onFinishSeguimiento = (values) =>{
      setViewModalSeguimiento(false)
      let form = {
        estudiante_id:itemSelected.estudiante_id,
        curso_id:itemSelected.curso_id,
        fecha:moment(daySelected).format("YYYY-MM-DD"),
        detalle:values.detalle,
        usuario_id: user.id
      }
      inasistenciaAdminService.create(form).then((response) => {
       
        loadEvents()
        setViewModalSeguimiento(false)
        message.success("Guardada")
      })
      .catch((e) => {
        console.log(e);
        message.error("No se registro la inasistencia")
      });
     }

    return (
        <MyLayout>


{myCurso && (
            <>
        <Row style={{margin:'10px'}}>

            <Col span={24}>
            <Row>
             <Col span={24}>
                <div className='info'>
             <div className='nombre'>{myCurso.curso} {myCurso.nivel} {myCurso.ciclo}</div>
             <div className='numId'> <strong>Docente:</strong> {myCurso.Docente.primerNombre} {myCurso.Docente.primerApellido}</div>
             </div> </Col>
                        <Col md={24} lg={12} className="columna">
                        
                            <div><strong>Codigo:</strong> {myCurso.id} </div>
                            <div><strong>Fecha Inicial:</strong> {moment(myCurso.fechaInicial).format("DD-MM-YYYY")} </div>
                            <div><strong>Periodo:</strong> {myCurso.periodo} </div>
                            <div><strong>Estado:</strong> {myCurso.estado} </div>
                            <div><strong>Horario de clase:</strong> {validarHorario(myCurso)}</div>
                            <div><strong>Horas del curso:</strong> {myCurso.horas} </div>


                        </Col>

                        <Col md={24} lg={12} className="columna">
                        <div><strong>Programa:</strong> {myCurso.idioma}</div>
                        <div><strong>Fecha Final:</strong> {moment(myCurso.fechaFinal).format("DD-MM-YYYY")}  </div>
                        <div><strong>Jornada:</strong> {myCurso.jornada} </div>
                        <div><strong>Fecha Creación:</strong> {moment(myCurso.createdAt).format("DD-MM-YYYY")}  </div>
                        <div> <strong>Modo:</strong> {myCurso.presentacion}</div>
                        <div><Button type="primary" onClick={viewHistoAsistencias}>Historial Inasistencias</Button></div>

            
                        </Col>
                     </Row>
            </Col>
           
        </Row>
        </>
)}

           {viewData  &&  (
            <Table scroll={{ x: 1500, y:500 }}  bordered  columns={columns} dataSource={matriculas}  >
            </Table>
            )}

{viewModalAsistencia && (
          <Modal visible={viewModalAsistencia} onCancel={onCancel} onOk={form.submit}>
            <Form  name="basic"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}>

              

              <Form.Item
              label="Observación: "
              name="detalle"
              rules={[
                {
                  required: false,
                  message: "Por favor ingrese Observación",
                },
              ]}>
                <Input></Input>
              </Form.Item>
            </Form>
          </Modal>
        )}


{viewModalSeguimiento && (
          <Modal visible={viewModalSeguimiento} onCancel={()=>{setViewModalSeguimiento(false)}} onOk={formSeguimiento.submit}>
            <Form  name="basic"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={onFinishSeguimiento}
            form={formSeguimiento}>

              

              <Form.Item
              label="Observación: "
              name="detalle"
              rules={[
                {
                  required: false,
                  message: "Por favor ingrese Observación",
                },
              ]}>
                <Input></Input>
              </Form.Item>
            </Form>
          </Modal>
        )}


        {historialAsistencias && (
          <Modal width={1024} title="Historial Asistencias y Seguimientos" visible={historialAsistencias} onCancel={()=>{setViewHistorialAsistencias(false)}} onOk={()=>{setViewHistorialAsistencias(false)}} >
            <h3>Inasistencias</h3>
            <Table dataSource={listaInasistencias} columns={inasistenciasColumns}> </Table>

            <h3>Seguimientos</h3>
            <Table dataSource={seguimientos} columns={seguimientosColumns}> </Table>
          </Modal>
        )}

        </MyLayout>

        
    );
};

export default VerInasistencia;