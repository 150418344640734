import http from "../utils/http-common"

class DerechoService {
  getAll() {
    return http.get("/derechosgrado")
  }
  get(id) {
    return http.get(`/derechosgrado/${id}`)
  }

  getForain(id) {
    return http.get(`/derechosgrado/forain/${id}`)
  }

  create(data) {
    return http.post("/derechosgrado", data)
  }
  update(id, data) {
    return http.put(`/derechosgrado/${id}`, data)
  }
  delete(id) {
    return http.delete(`/derechosgrado/${id}`)
  }
}
export default new DerechoService()
