import http from "../utils/http-common";

class MatriculaService {
    getAll() {
      return http.get("/matriculas");
    }
    get(id) {
      return http.get(`/matriculas/${id}`);
    }

    getForain(id) {
      return http.get(`/matriculas/forain/${id}`);
    }

    getAprobados(id) {
      return http.get(`/matriculas/aprobados/${id}`);
    }

    create(data) {
      return http.post("/matriculas", data);
    }

    retiro(data) {
      return http.post("/matriculas/retiro", data);
    }

    traslado(data) {
      return http.post("/matriculas/traslado", data);
    }

    updateDate(id, data) {
      return http.put(`/matriculas/fecha/${id}`, data);
    }


    updateDateRetiro(id, data) {
      return http.put(`/matriculas/fecha/retiro/${id}`, data);
    }


    update(id, data) {
      return http.put(`/matriculas/${id}`, data);
    }

    reactive(id) {
      return http.get(`/matriculas/reactive/${id}`);
    }

    delete(id) {
      return http.delete(`/matriculas/${id}`);
    }

    getActivos(id) {
      return http.get(`/matriculas/activos/${id}`);
    }
   
  }
  export default new MatriculaService();